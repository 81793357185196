import * as signalR from '@microsoft/signalr'
import { toast } from 'react-toastify'

import toastService from '@/components/toast/toast-service'
import { ISignalRProps } from '@/contexts/platform.context'

export const createConnectionPool = (data: ISignalRProps) => {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(data.RelatorioHub)
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build()

  connection.serverTimeoutInMilliseconds = 300000

  connection
    .start()
    .then(() => {
      getSecret(data)
    })
    .catch(() => {
      toastService.error('Erro ao iniciar conexão.')
    })

  function getSecret(data: ISignalRProps) {
    if (data.AccessInformations.Erro) {
      toastService.error(
        'Erro ao obter credenciais de acesso para visualizar notificações'
      )
      return
    }

    const communicateUrl = data.CommunicateUrl
    const concernedId = connection.connectionId

    fetch(
      `${communicateUrl}api/Notificacao/ObterSegredoDeComunicacaoTempoReal`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'access-control-allow-origin':
            'https://app-onixfront-hml.azurewebsites.net',
          'access-control-allow-credentials': 'true',
          'content-enconding': 'gzip'
        },
        body: JSON.stringify({
          idGrupoEconomico: data.AccessInformations.Data.IdGrupoEconomico,
          idUsuario: data.AccessInformations.Data.IdDoUsuarioSolicitante,
          idDaAplicacao: data.AccessInformations.Data.IdDoAplicativoOnix,
          idComunicacaoEmTempoReal: connection.connectionId
        })
      }
    )
      .then(response => {
        return response.text()
      })
      .then(secret => {
        postConcernedId(communicateUrl, concernedId, secret)
      })
      .catch(error => {
        console.error(
          'There was a problem with the fetch operation on getSecret:',
          error
        )
      })
  }

  function postConcernedId(
    communicateUrl: string,
    concernedId: any,
    secret: string
  ) {
    const xhr = new XMLHttpRequest()
    const url = `${communicateUrl}/api/Interessados/PersistirInteressadoComunicacaoTempoReal/${secret}?codigoInteressado=${concernedId}`

    xhr.open('POST', url, true)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.setRequestHeader('Content-Type', 'application/json')

    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status >= 200 && xhr.status < 300) {
          connection.on(secret, function (message) {
            toast(message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light'
            })
          })
        } else {
          console.error(
            'There was a problem with the fetch operation on postConcernedId:',
            xhr.statusText
          )
        }
      }
    }

    xhr.onerror = function () {
      console.error(
        'There was a problem with the fetch operation on postConcernedId:',
        xhr.statusText
      )
    }

    xhr.send()
  }
}
