import { useMemo, useState } from 'react'

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {
  Alert,
  Button,
  Modal,
  Radio,
  Textarea,
  TextInput
} from 'flowbite-react'
import Select from 'react-select'

import CustomTextInput from '@/components/basic/input-text/input-text.component'
import toastService from '@/components/toast/toast-service'
import { useEditarPedidosContext } from '@/contexts/editar-pedido.context'

interface IEditarPedidoModalProps {
  onClose: () => void
  isOpen: boolean
}

const EditarPedidoModal: React.FC<IEditarPedidoModalProps> = ({
  onClose,
  isOpen
}: IEditarPedidoModalProps) => {
  const { formik, itens, itemEmEdicao } = useEditarPedidosContext()

  const [novoPeriodo, setNovoPeriodo] = useState<number | undefined>()
  const [novaTarifa, setNovaTarifa] = useState<number | undefined>()
  const [novaQtdDiaria, setNovaQtdDiaria] = useState<number | undefined>()
  const [ativo, setAtivo] = useState<boolean | undefined>()
  const [justificativa, setJustificativa] = useState('')
  const [erros, setErros] = useState<{ campo: string; erro: string }[]>()

  const itemSelecionado = useMemo(() => {
    const item = itens?.find(i => i.itemId == itemEmEdicao)
    setNovoPeriodo(item?.diasCompra)
    setNovaTarifa(item?.valorLinha)
    setNovaQtdDiaria(item?.quantidadeDiaria)
    setAtivo(item?.status)
    setJustificativa('')
    return item
  }, [itens, itemEmEdicao])

  const setPeriodosOptions = () => {
    const periodos = []
    for (let index = 1; index < 32; index++) {
      periodos.push({
        value: index,
        label: `${index} Dias`
      })
    }
    return periodos
  }
  const periodosOptions = setPeriodosOptions()
  const selectedOption = periodosOptions?.find(
    option => option.value === novoPeriodo
  )

  const handleEditar = () => {
    formik.setValues({
      pedidoId: formik.values.pedidoId,
      itemIds: [itemEmEdicao],
      periodo: novoPeriodo ?? 1,
      quantidadeDiaria: novaQtdDiaria ?? 1,
      valorLinha: novaTarifa ?? 1,
      status: ativo ?? true,
      justificativa: justificativa
    })
    const validacao = validacaoForm()
    setErros(validacao)
    if (validacao?.length === 0) {
      formik.submitForm()
      onClose()
    } else toastService.error('A edição do item contêm erros')
  }

  const validacaoForm = () => {
    const erros = []
    if (
      novoPeriodo === undefined ||
      Number.isNaN(novoPeriodo) ||
      novoPeriodo < 1
    )
      erros.push({ campo: 'periodo', erro: 'Período inválido' })
    if (
      novaQtdDiaria === undefined ||
      Number.isNaN(novaQtdDiaria) ||
      novaQtdDiaria < 1
    )
      erros.push({ campo: 'qtdDiaria', erro: 'Quantidade diária inválida' })
    if (
      novaTarifa === undefined ||
      Number.isNaN(novaTarifa) ||
      novaTarifa < 0.01
    )
      erros.push({ campo: 'tarifa', erro: 'Tarifa inválida' })
    if (justificativa === undefined || justificativa === '')
      erros.push({
        campo: 'justificativa',
        erro: 'Justificativa deve ser preenchida'
      })
    return erros
  }

  return (
    <>
      <Modal
        show={isOpen}
        onClose={onClose}
        className='flex items-center'
        size='7xl'
      >
        <Modal.Header>
          <p className='text-center font-bold p-3'>Editar Benefício</p>
        </Modal.Header>
        <Modal.Body>
          <div className='grid grid-cols-12 bg-white rounded-xl gap-5 md:gap-0 p-5 mx-5 mb-5'>
            <div className='p-3 md:col-span-12 col-span-12 text-center md:text-left'>
              <span className='text-quaternary font-semibold pb-3 text-2xl'>
                {itemSelecionado?.nome}
              </span>
            </div>
            <div className='p-3 md:col-span-6 col-span-12 text-center md:text-left'>
              <p>
                Matrícula:
                <span className='text-gray-600 text-sm pl-2'>
                  {itemSelecionado?.matricula}
                </span>
              </p>
            </div>
            <div className='p-3 md:col-span-6 col-span-12 text-center md:text-left'>
              <p>
                CPF:
                <span className='text-gray-600 text-sm pl-2'>
                  {itemSelecionado?.cpf}
                </span>
              </p>
            </div>
          </div>
          <div className='grid grid-cols-12 bg-white rounded-xl gap-5 md:gap-0 p-5 mx-5 mb-5 border'>
            <div className='p-3 md:col-span-12 col-span-12 text-center md:text-left'>
              <span className='text-quaternary font-semibold pb-3 text-xl'>
                Valores Atuais
              </span>
            </div>
            <div className='p-3 md:col-span-12 col-span-12 text-center md:text-left'>
              <p className='pb-3'>
                Período:
                <span className='text-gray-600 text-sm pl-2'>
                  {itemSelecionado?.diasCompra}
                </span>
              </p>
              <p className='pt-3'>
                Benefício:
                <span className='text-gray-600 text-sm pl-2'>
                  {itemSelecionado?.nomeLinha}
                </span>
              </p>
            </div>
            <div className='p-3 md:col-span-6 col-span-12 text-center md:text-left'>
              <p>
                Tarifa:
                <span className='text-gray-600 text-sm pl-2'>
                  {itemSelecionado?.valorLinha.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </span>
              </p>
            </div>
            <div className='p-3 md:col-span-6 col-span-12 text-center md:text-left'>
              <p>
                Quantidade Diária:
                <span className='text-gray-600 text-sm pl-2'>
                  {itemSelecionado?.quantidadeDiaria}
                </span>
              </p>
            </div>
            <div className='p-3 md:col-span-12 col-span-12 text-center md:text-right'>
              <p className='font-semibold'>
                Valor Total:
                <span className='text-quaternary font-semibold pl-2'>
                  {itemSelecionado?.valorRecarga.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL'
                  })}
                </span>
              </p>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className='grid grid-cols-12 bg-white rounded-xl gap-5 md:gap-0 p-5 mx-5 mb-5 border'>
              <div className='p-3 md:col-span-12 col-span-12 text-center md:text-left'>
                <span className='text-quaternary font-semibold pb-3 text-xl'>
                  Novos Valores
                </span>
              </div>
              <div
                className='p-3 md:col-span-6 col-span-12 text-center md:text-left'
                data-testid='select-periodo'
              >
                <p>Período:</p>
                {
                  <Select
                    placeholder='Selecione um período'
                    className='text-gray-900 text-sm'
                    isSearchable
                    escapeClearsValue
                    isClearable={true}
                    noOptionsMessage={() => 'Sem resultados'}
                    value={selectedOption}
                    onChange={selected => setNovoPeriodo(selected?.value)}
                    options={periodosOptions}
                    required
                  />
                }
                {erros?.find(e => e.campo == 'periodo') != undefined ? (
                  <p
                    className='mt-2 text-sm text-red-600 dark:text-red-500'
                    data-testid='select-periodo-erro'
                  >
                    {erros?.find(e => e.campo == 'periodo')?.erro}
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <div className='p-3 md:col-span-12 col-span-12 text-center md:text-left'>
                <p>
                  Benefício:
                  <span className='text-gray-600 text-sm pl-2'>
                    {itemSelecionado?.nomeLinha}
                  </span>
                </p>
              </div>
              <div className='p-3 md:col-span-6 col-span-12 text-center md:text-left'>
                <p>Tarifa:</p>
                {
                  <CustomTextInput
                    type='number'
                    field='novoValorLinha'
                    color='light'
                    formik={formik}
                    defaultValue={itemSelecionado?.valorLinha}
                    value={novaTarifa}
                    onChange={value =>
                      setNovaTarifa(parseFloat(value.target.value))
                    }
                    min={0.01}
                    addon='R$'
                    step={0.01}
                    required
                    label=''
                    disabled={true}
                    data-testid='select-valor'
                    readOnly={true}
                  />
                }
                {erros?.find(e => e.campo == 'tarifa') != undefined ? (
                  <p
                    className='mt-2 text-sm text-red-600 dark:text-red-500'
                    data-testid='select-valor-erro'
                  >
                    {erros?.find(e => e.campo == 'tarifa')?.erro}
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <div className='p-3 md:col-span-6 col-span-12 text-center md:text-left'>
                <p>Quantidade Diária:</p>
                {
                  <TextInput
                    type='number'
                    color='light'
                    defaultValue={itemSelecionado?.quantidadeDiaria}
                    value={novaQtdDiaria}
                    onChange={value =>
                      setNovaQtdDiaria(parseInt(value.target.value))
                    }
                    min={1}
                    step={1}
                    required
                    data-testid='select-qtd-diaria'
                  />
                }
                {erros?.find(e => e.campo == 'qtdDiaria') != undefined ? (
                  <p
                    className='mt-2 text-sm text-red-600 dark:text-red-500'
                    data-testid='select-qtd-diaria-erro'
                  >
                    {erros?.find(e => e.campo == 'qtdDiaria')?.erro}
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <div className='p-3 md:col-span-12 col-span-12 text-center md:text-left'>
                <p>Situação: {}</p>
                <div className='flex gap-16 justify-left'>
                  <div className='p-4 flex gap-2 items-center'>
                    <span className='flex text-gray-600 text-sm'>Removido</span>
                    <Radio
                      checked={!ativo}
                      onClick={() => setAtivo(false)}
                      value='false'
                      name='radio-removido'
                      className='flex text-red-600 focus:ring-red-600'
                      data-testid='radio-removido'
                    />
                  </div>
                  <div className='p-4 flex gap-2 items-center'>
                    <span className='flex text-gray-600 text-sm'>Ativo</span>
                    <Radio
                      checked={ativo}
                      onClick={() => setAtivo(true)}
                      value='true'
                      name='radio-ativo'
                      className='flex text-quaternary focus:ring-quaternary'
                      data-testid='radio-ativo'
                    />
                  </div>
                </div>
              </div>
              <div className='p-3 md:col-span-12 col-span-12 text-center md:text-left'>
                <p>Justificativa:</p>
                {
                  <Textarea
                    id='justificativa'
                    className='bg-white'
                    onChange={value => setJustificativa(value.target.value)}
                    required
                    data-testid='input-justificativa'
                  />
                }
                {erros?.find(e => e.campo == 'justificativa') != undefined ? (
                  <p
                    className='mt-2 text-sm text-red-600 dark:text-red-500'
                    data-testid='input-justificativa-erro'
                  >
                    {erros?.find(e => e.campo == 'justificativa')?.erro}
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <div className='p-3 md:col-span-12 col-span-12 text-center md:text-right'>
                <p className='font-semibold'>
                  Novo Valor Total:
                  <span className='text-quaternary font-semibold pl-2'>
                    {(
                      (Number.isNaN(novaTarifa) || novaTarifa === undefined
                        ? 0
                        : novaTarifa) *
                      (Number.isNaN(novaQtdDiaria) ||
                      novaQtdDiaria === undefined
                        ? 0
                        : novaQtdDiaria) *
                      (novoPeriodo ?? 0)
                    ).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL'
                    })}
                  </span>
                </p>
                <Alert color='warning' className='mt-3'>
                  <div className='flex justify-center'>
                    <ExclamationTriangleIcon className='w-5 h-5 mr-2' />
                    <span>
                      Atenção! Após salvar a edição, o pedido deverá ser
                      reprocessado para a alteração refletir no valor.
                    </span>
                  </div>
                </Alert>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <>
            <div className='w-full flex justify-between'>
              <div className='flex justify-between gap-2 items-left'>
                <Button color='gray' onClick={onClose} data-testid='btn-close'>
                  Cancelar
                </Button>
              </div>
              <div className='flex justify-between gap-2 items-right'>
                <Button
                  color='success'
                  onClick={handleEditar}
                  className='bg-quaternary'
                  data-testid='btn-save'
                >
                  <span>Salvar</span>
                </Button>
              </div>
            </div>
          </>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditarPedidoModal
