import { SetStateAction, useMemo, useState } from 'react'

import {
  EllipsisVerticalIcon,
  ListBulletIcon
} from '@heroicons/react/24/outline'
import debounce from 'debounce'
import { Button, Dropdown, Select, Tooltip } from 'flowbite-react'

import Loader from '@/components/Loader'
import DevolucoesFilterForm from '@/components/pedidos/acompanhar-pedidos/devolucoes-filtros.component'
import AcoesDevolucoesModal from '@/components/pedidos/devolucoes/acoes-devolucoes.modal'
import DetalhesDevolucoesModal from '@/components/pedidos/devolucoes/detalhes-devolucoes.modal'
import LogsDevolucaoModal from '@/components/pedidos/devolucoes/logs-devolucoes-modal.component'
import MotivoDevolucaoModal from '@/components/pedidos/devolucoes/motivo-devolucao-modal.component'
import TratativasDevolucoesModal from '@/components/pedidos/devolucoes/tratativas-devolucoes.modal'
import TableItem from '@/components/Table'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import { useDevolucoesContext } from '@/contexts/devolucoes.context'
import { IDevolucao } from '@/interfaces/devolucoes'
import { TABLE_HEADERS_DEVOLUCOES_PAGE } from '@/pages/Pedidos/constants'
import { CurrencyFormat } from '@/utils/currency-format'
import { exportToXls } from '@/utils/export-to-xls'

const DevolucoesContainer = () => {
  const {
    devolucoesList,
    isLoadingDevolucoes,
    isFetchingDevolucoes,
    pageSize,
    totalPages,
    totalItens,
    setPageSize,
    setPage,
    setSearch,
    isActionsOpen,
    setIsActionsOpen,
    isResultadoModalOpen,
    setIsResultadoModalOpen,
    isTratativasOpen,
    setIsTratativasOpen,
    handleAbrirTratativas,
    setDevolucaoSelecionada,
    isHistoricoDevolucaoModalOpen,
    setIsHistoricoDevolucaoModalOpen,
    handleAbrirHistorico,
    handleDownloadComprovante
  } = useDevolucoesContext()
  const [isOpenMotivos, setIsOpenMotivos] = useState<boolean>(false)

  const handleAbrirMotivos = (devolucaoEmTratativa: IDevolucao) => {
    setIsOpenMotivos(true)
    setDevolucaoSelecionada(devolucaoEmTratativa)
  }

  const aguardandoDevolucao = (statusEmComparacao: string) => {
    return statusEmComparacao === 'AguardandoDevolver'
  }

  const devolucoesStatus: { [status: string]: string } = {
    ['Finalizado']: 'bg-quaternary',
    ['Devolvido']: 'bg-quaternary',
    ['Ativo']: 'bg-gray-400',
    ['AguardandoDevolver']: 'bg-orange-400',
    ['Cancelado']: 'bg-red-600'
  }

  const devolucoesTipos: { [status: string]: string } = {
    ['Saldo']: 'Desconto',
    ['Compra']: 'Reprocessamento',
    ['VrCredito']: 'Depósito',
    ['AguardandoTratativa']: 'Aguardando Tratativa'
  }

  const mapedProperties = useMemo(() => {
    return devolucoesList?.conteudo?.resultados?.map(item => {
      return {
        ...item,
        tipoDevolucao: devolucoesTipos[item.tipoDevolucao],
        unidade: (
          <Tooltip content={item.nomeUnidade} placement='top'>
            <p className='truncate max-w-[120px] overflow-hidden'>
              {item.nomeUnidade}
            </p>
          </Tooltip>
        ),
        descricaoBeneficio: (
          <Tooltip content={item.descricaoBeneficio} placement='top'>
            <p className='truncate max-w-[120px] overflow-hidden'>
              {item.descricaoBeneficio}
            </p>
          </Tooltip>
        ),
        nome: (
          <Tooltip content={item.nome} placement='top'>
            <p className='truncate max-w-[120px] overflow-hidden'>
              {item.nome}
            </p>
          </Tooltip>
        ),
        valor: CurrencyFormat(item.total),
        data: new Date(item.dataDevolucao).toLocaleDateString(),
        status: (
          <div className='flex gap-2'>
            <span
              className={`px-5 py-1 rounded-2xl text-xs ${
                devolucoesStatus[
                  item.status === 'CnabGerado' ? 'Ativo' : item.status
                ]
              } text-white text-center`}
            >
              {(() => {
                if (aguardandoDevolucao(item.status)) return 'Aberto'
                if (item.status === 'CnabGerado') return 'Ativo'
                return item.status
              })()}
            </span>
          </div>
        ),
        actions: (
          <div className='flex gap-2 cursor-pointer'>
            <Dropdown
              label=''
              placement='left-start'
              dismissOnClick={true}
              renderTrigger={() => (
                <EllipsisVerticalIcon className='w-5 h-5 cursor-pointer' />
              )}
            >
              {aguardandoDevolucao(item.status) ? (
                <Dropdown.Item onClick={() => handleAbrirTratativas(item)}>
                  Tratar Devolução
                </Dropdown.Item>
              ) : (
                <></>
              )}
              <Dropdown.Item onClick={() => handleAbrirMotivos(item)}>
                Motivo da Devolução
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleAbrirHistorico(item)}>
                Histórico
              </Dropdown.Item>
              {item.status === 'Finalizado' &&
              item.tipoDevolucao === 'VrCredito' ? (
                <Dropdown.Item
                  onClick={() =>
                    handleDownloadComprovante({
                      params: {
                        idItemVenda: item.id,
                        idUnidade: item.idUnidade
                      }
                    })
                  }
                >
                  Baixar Comprovante
                </Dropdown.Item>
              ) : (
                <></>
              )}
            </Dropdown>
          </div>
        )
      }
    })
  }, [devolucoesList])

  const handleSearch = debounce((e: SetStateAction<string>) => {
    setSearch(e)
  }, 500)

  const dadosArquivoDevolucoes = () => {
    return devolucoesList?.conteudo?.resultados?.map(item => {
      return {
        Data: new Date(item.dataDevolucao).toLocaleDateString(),
        CPF: item.cpf,
        Nome: item.nome,
        Matrícula: item.matricula,
        Identificador: item.identificador,
        Valor: item.total,
        Tipo: item.tipoDevolucao,
        Situação: item.status,
        'N. Pedido': item.numeroPedido,
        'Cod. Benefício': item.codigoDoBeneficio,
        Descrição: item.descricaoBeneficio,
        Unidade: item.nomeUnidade,
        CNPJ: item.cnpjUnidade,
        Motivo: item.motivo,
        Solução: item.solucao
      }
    })
  }

  return (
    <>
      <div className='pb-5'>
        <SearchTableItem
          isAddItem={false}
          isSearchInputEnabled={true}
          filtersEnabled
          setFilterText={e => handleSearch(e)}
          customActions={() => (
            <div className='flex gap-2 justify-end items-center'>
              <Button
                size='sm'
                color='light'
                id='export-devolucoes-report'
                data-testid='export-devolucoes-report'
                onClick={() =>
                  exportToXls(
                    dadosArquivoDevolucoes() as any,
                    'Devolucoes_relatorio'
                  )
                }
                className='shadow md:shadow-none border-none text-primary-dark'
              >
                <ListBulletIcon className='h-5 w-5 mr-1' />
                <span>Relatório</span>
              </Button>
              <Button
                size='sm'
                color='white'
                data-testid='actions-devolucoes'
                id='actions-devolucoes'
                className='bg-orange-500 hover:opacity-70 text-white transition shadow md:shadow-none hidden'
                onClick={() => setIsActionsOpen(true)}
              >
                Ações
              </Button>
            </div>
          )}
        >
          <DevolucoesFilterForm />
        </SearchTableItem>
        {isLoadingDevolucoes ? (
          <div className='h-96 w-full flex justify-center items-center'>
            <Loader size={'xl'} />
          </div>
        ) : (
          <div className='w-full bg-white rounded-md p-5'>
            <TableItem
              isActions={false}
              isRowClicable={false}
              data={mapedProperties || []}
              headers={TABLE_HEADERS_DEVOLUCOES_PAGE}
              deleteAction={() => void 0}
              hasCustomActions={false}
              idKey='id'
              isIdle={isLoadingDevolucoes || isFetchingDevolucoes}
            />
            <PaginationItem
              totalItems={totalItens}
              pageSize={pageSize}
              totalPages={totalPages}
              onPageChange={(pageParam: number) => setPage(pageParam)}
            >
              <Select
                id='select-page-size'
                data-testid='select-page-size'
                onChange={e => setPageSize(Number(e.target.value))}
              >
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </Select>
            </PaginationItem>
          </div>
        )}
      </div>
      <AcoesDevolucoesModal
        isOpen={isActionsOpen}
        onClose={() => {
          setIsActionsOpen(false)
          setIsResultadoModalOpen(true)
        }}
      />
      <DetalhesDevolucoesModal
        isOpen={isResultadoModalOpen}
        onClose={() => setIsResultadoModalOpen(false)}
      />
      <MotivoDevolucaoModal
        isOpen={isOpenMotivos}
        onClose={() => setIsOpenMotivos(false)}
      />
      <LogsDevolucaoModal
        isOpen={isHistoricoDevolucaoModalOpen}
        onClose={() => setIsHistoricoDevolucaoModalOpen(false)}
      />
      <TratativasDevolucoesModal
        isOpen={isTratativasOpen}
        onClose={() => setIsTratativasOpen(false)}
      />
    </>
  )
}

export default DevolucoesContainer
