export interface Colaborador {
  id: string
  idUnidade: string
  nomeUnidade: string
  nome: string
  cpf: string
  identificador: string
  cargo: string
  departamento: string
  beneficioTotal: number
}

export interface Unidade {
  idUnidade: string
  nomeUnidade: string
  colaboradores: Colaborador[]
}
export function combineByIdUnidade(colaboradores: Colaborador[]): Unidade[] {
  const grouped = colaboradores.reduce<{ [key: string]: Unidade }>(
    (acc, colaborador) => {
      const { idUnidade, nomeUnidade } = colaborador

      if (!acc[idUnidade]) {
        acc[idUnidade] = {
          idUnidade,
          nomeUnidade,
          colaboradores: []
        }
      }

      acc[idUnidade].colaboradores.push({
        id: colaborador.id,
        idUnidade: colaborador.idUnidade,
        nomeUnidade: colaborador.nomeUnidade,
        nome: colaborador.nome,
        cpf: colaborador.cpf,
        identificador: colaborador.identificador,
        cargo: colaborador.cargo,
        departamento: colaborador.departamento,
        beneficioTotal: colaborador.beneficioTotal
      })

      return acc
    },
    {}
  )

  return Object.values(grouped)
}
