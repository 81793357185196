import { Key, useEffect, useState } from 'react'

import {
  BuildingLibraryIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline'
import {
  Accordion,
  Button,
  Checkbox,
  Label,
  Spinner,
  TextInput
} from 'flowbite-react'
import { useFormik } from 'formik'
import Cookie from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import Select from 'react-tailwindcss-select'

import ConfirmarPedidoModal from '@/components/pedidos/criar-pedido/confirmar-pedido.modal'
import CriarPedidoForm from '@/components/pedidos/criar-pedido/criar-pedido.form'
import UnidadeNaoHabilitadaModal from '@/components/pedidos/criar-pedido/unidade-nao-habilitada.modal'
import StepModal from '@/components/step-modal/step-modal.component'
import toastService from '@/components/toast/toast-service'
import Constants from '@/configs/constants'
import { routeNames } from '@/configs/routePaths'
import { useCriarPedidosContext } from '@/contexts/criar-pedido.context'
import useLocalStorage from '@/hooks/common/use-local-storage'
import {
  useCreatePedidoMutation,
  useProcessarPedidovendaMutation
} from '@/hooks/mutations/use-pedidos-mutation'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { IPedidoDTO, IPedidoForm } from '@/interfaces/pedidos'
import { getCargosListPedidos } from '@/services/cargos'
import { getLocaisDeEntregaPedidos } from '@/services/locais-de-entrega'
import { getDepartamentosListPedidos } from '@/services/pedidos'
import { appendCurrentTime } from '@/utils'
import useIsOnRoute from '@/utils/isOnRoute'

interface ISelectOptions {
  value: string
  label: string
}

const CriarPedidoContainer = () => {
  const navigate = useNavigate()
  const {
    data,
    filteredData,
    checkedPedidos,
    setCheckedPedidos,
    allSelected,
    setAllSelected,
    setFilteredData,
    isSelectedContaCorrente,
    setIsSelectedContaCorrente,
    isloadingBranchesList,
    setIsModalOpen,
    isModalOpen,
    configuracoesCreditoCerto,
    permitirCriarPedidoPorUnidade,
    isOpenErrosModal,
    setIsOpenErrosModal
  } = useCriarPedidosContext()

  useEffect(() => {
    setFilteredData(data)
  }, [data])
  const { mutateAsync: createPedido, isLoading: isLoadingCriarPedido } =
    useCreatePedidoMutation()
  const { mutateAsync: processarPedido, isLoading: isLoadingProcessarPedido } =
    useProcessarPedidovendaMutation()
  const { data: userData } = useGetUserData()
  const [cnpjs, setCnpjs] = useLocalStorage('CnpjListToCreateOrder', '')
  const [userSearch, setUserSearch] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [cargosListByUnidade, setCargosListByUnidade] = useState<any[]>([])
  const [departamentosListByUnidade, setDepartamentosListByUnidade] = useState<
    any[]
  >([])
  const [locaisDeEntregaByUnidade, setLocaisDeEntregaByUnidade] = useState<
    any[]
  >([])

  const [selectedCargos, setSelectedCargos] = useState<{
    [key: string]: ISelectOptions[]
  }>({})
  const [selectedPeriodos, setSelectedPeriodos] = useState<{
    [key: string]: ISelectOptions[]
  }>({})
  const [selectedDepartamentos, setSelectedDepartamentos] = useState<{
    [key: string]: ISelectOptions[]
  }>({})
  const [selectedLocaisDeEntrega, setSelectedLocaisDeEntrega] = useState<{
    [key: string]: ISelectOptions[]
  }>({})

  const isOnRoute = useIsOnRoute(
    `/${routeNames.pedidos}/${routeNames.criarPedidos}`
  )

  const formik = useFormik({
    initialValues: {} as IPedidoForm,
    onSubmit: values => {
      if (!Object.values(checkedPedidos).some(checked => checked)) {
        toastService.error('Selecione ao menos uma empresa')
        return
      }
      const pedidos = data
        .filter((item: any) => checkedPedidos[item.idUnidade])
        .map((pedido: any) => ({
          idRequisicao: crypto.randomUUID(),
          idEmpregador: userData?.authenticatedAccountId ?? '',
          idUnidade: pedido.idUnidade,
          idAplicacao: process.env.VITE_APP_APP_ID ?? '',
          periodoUtilizacaoInicial: appendCurrentTime(
            values.periodoUtilizacaoInicial
          ),
          periodoUtilizacaoFinal: appendCurrentTime(
            values.periodoUtilizacaoFinal
          ),
          previsaoDisponibilidade: appendCurrentTime(
            values.previsaoDisponibilidade
          ),
          previsaoPagamento: appendCurrentTime(values.previsaoPagamento),
          tipoPedidoVenda: values.tipoPedidoVenda,
          usarCredito: isSelectedContaCorrente,
          utilizaEconomia: configuracoesCreditoCerto?.conteudo?.find(
            contrato => contrato.idUnidade === pedido.idUnidade
          )?.utilizaEconomia,
          idsLocaisDeEntrega:
            selectedLocaisDeEntrega[pedido.idUnidade]?.map(
              (item: any) => item.value
            ) ?? [],
          idsDepartamentos:
            selectedDepartamentos[pedido.idUnidade]?.map(
              (item: any) => item.value
            ) ?? [],
          idsCargos:
            selectedCargos[pedido.idUnidade]?.map((item: any) => item.value) ??
            [],
          periodos:
            selectedPeriodos[pedido.idUnidade]?.map(
              (item: any) => item.value
            ) ?? [],
          idsTrabalhadores: []
        }))

      const pedidoDTO: IPedidoDTO = {
        pedidos,
        criadoPor: userData?.userId ?? '',
        criadoEm: new Date()
          .toISOString()
          .replace('T', 'T')
          .replace(/\..+/, '')
          .concat('Z')
      }
      createPedido(pedidoDTO).then(data => {
        const response = data
        processarPedido({
          idsPedidoVenda: response.conteudo.map((item: any) => item.id)
        }).then(() => {
          setIsModalOpen(true)
          setCnpjs('')
        })
      })
    }
  })

  const handleSelectAll = () => {
    const newAllSelected = !allSelected
    setAllSelected(newAllSelected)
    const newCheckedPedidos = data.reduce((acc: any, pedido: any) => {
      if (permitirCriarPedidoPorUnidade[pedido.idUnidade]) {
        acc[pedido.idUnidade] = newAllSelected
      }
      return acc
    }, {})
    setCheckedPedidos(newCheckedPedidos)
  }

  const handleSearchUnidades = (e: any) => {
    const search = e.target.value.toLowerCase()
    setUserSearch(search === '' ? null : search)
    const filteredOptions = data.filter((pedido: any) =>
      pedido?.cnpj.toLowerCase().includes(search)
    )
    setFilteredData(filteredOptions)
  }

  const highlightSearchResults = (text: string, searchValue: string | null) => {
    const regex = new RegExp(`(${searchValue})`, 'gi')
    return text.split(regex).map((part: any, index: Key | null | undefined) =>
      regex.test(part) ? (
        <span className='font-bold' key={index}>
          {part}
        </span>
      ) : (
        part
      )
    )
  }

  const getCargosByUnidade = (idUnidade: string) => {
    setIsLoading(true)
    getCargosListPedidos({
      params: {
        idUnidade: idUnidade,
        inicio: 0,
        comprimento: 1000,
        direcao: 'desc',
        campo: 'Name'
      }
    })
      .then(response => {
        const responseData = response?.conteudo?.map((item: any) => ({
          value: item.jobRoleId,
          label: item.name
        }))
        setCargosListByUnidade(prev => {
          return {
            ...prev,
            [idUnidade]: responseData
          }
        })
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => setIsLoading(false))
  }

  const getDepartamentosByUnidade = (idUnidade: string) => {
    setIsLoading(true)
    getDepartamentosListPedidos({
      params: {
        idUnidade: idUnidade,
        inicio: 0,
        comprimento: 1000,
        direcao: 'desc',
        campo: 'Description'
      }
    })
      .then(response => {
        const responseData = response?.conteudo?.map((item: any) => ({
          value: item.departmentId,
          label: item.description
        }))
        setDepartamentosListByUnidade(prev => {
          return {
            ...prev,
            [idUnidade]: responseData
          }
        })
      })
      .finally(() => setIsLoading(false))
  }

  const getLocaisDeEntregaByUnidade = (idUnidade: string) => {
    setIsLoading(true)
    getLocaisDeEntregaPedidos({
      params: {
        idUnidade: idUnidade,
        inicio: 0,
        comprimento: 1000,
        direcao: 'desc',
        campo: 'Name'
      }
    })
      .then(response => {
        const responseData = response?.conteudo?.map((item: any) => ({
          value: item.workStationId,
          label: item.name
        }))
        setLocaisDeEntregaByUnidade(prev => {
          return {
            ...prev,
            [idUnidade]: responseData
          }
        })
      })
      .finally(() => setIsLoading(false))
  }

  const handleCheckboxChange = (id: string, event: any) => {
    const isChecked = event.target.checked
    setCheckedPedidos(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }))
    if (isChecked) {
      getCargosByUnidade(id)
      getDepartamentosByUnidade(id)
      getLocaisDeEntregaByUnidade(id)
    }
  }

  useEffect(() => {
    function getIdUnidade() {
      if (!cnpjs) return []
      return cnpjs
        .map(mapCnpjToIdUnidade)
        .filter((result: { idUnidade: string } | null) => result !== null)
    }

    function mapCnpjToIdUnidade(item: any) {
      const matchedData = filteredData?.find(
        data => data.cnpjValue === item.CNPJ
      )
      return matchedData ? { idUnidade: matchedData.idUnidade } : null
    }

    const idUnidades = getIdUnidade()

    idUnidades.forEach((idUnidade: { idUnidade: string }) => {
      handleCheckboxChange(idUnidade.idUnidade, { target: { checked: true } })
    })
  }, [cnpjs, filteredData])

  useEffect(() => {
    const pedido = filteredData?.find(
      (pedido: any) => !permitirCriarPedidoPorUnidade[pedido.idUnidade]
    )
    if (pedido) {
      setIsOpenErrosModal(true)
    }
  }, [])

  const isOnboardingFinishedCreateOrder = Cookie.get(
    Constants.iS_ONBOARDING_FINISHED_CREATE_ORDER
  )
    ? true
    : false

  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false)

  const handleBeforeExit = () => {
    setIsOnboardingOpen(false)
  }

  const handleClose = () => {
    setIsOnboardingOpen(false)
  }

  useEffect(() => {
    if (!isOnboardingFinishedCreateOrder) {
      setIsOnboardingOpen(true)
    }

    return () => {
      setIsOnboardingOpen(false)
    }
  }, [isOnboardingFinishedCreateOrder])

  const stepList = [
    {
      title: 'VAMOS CRIAR UM PEDIDO?',
      body: 'Nesse menu temos algumas novidades.​ O caminho continua o mesmo, por aqui, só o layout mudou para que fique mais intuitivo.​Temos duas opções para criar seu pedido:​Pedido por unidade​Pedido por colaborador específico​Vamos falar delas a seguir!',
      step: 1,
      totalSteps: 3,
      imageSrc: '/images/novidades.svg'
    },
    {
      position: 'right',
      title: 'Criar por unidade:​​',
      body: 'Aqui você cria o pedido para todos os colaboradores ATIVOS de uma ou mais unidades.​- Selecione a(as) unidade(s) desejada(s);​Preencha as informações do pedido de acordo com o desejado;​Data de início de fim de utilização: data que o benefício será utilizado​ Data prev. para pagamento e disponibilização: passe o mouse no ícone (i) para mais informações.',
      ref: '.criar-novos-pedidos',
      totalSteps: 3,
      step: 2
    },
    {
      title:
        'Conta o passo a passo para ele APROVAR O PEDIDO  na tela de acompanhamento',
      body: 'Aqui você aprova o pedido na tela de acompanhamento.',
      ref: '#incluir-pedidos-btn',
      totalSteps: 3,
      step: 3
    }
  ]

  return (
    <div className='criar-novos-pedidos'>
      <div className='grid grid-cols-12 gap-5 w-full'>
        <div className='col-span-12'>
          <Button
            color='light'
            className={`flex gap-2 justify-center items-center ${
              allSelected
                ? 'border-2 border-primary text-primary'
                : 'border-2 border-gray-200'
            }`}
            onClick={handleSelectAll}
          >
            <span>{allSelected ? 'Desmarcar todos' : 'Selecionar todos'}</span>
          </Button>
        </div>
        <div className='col-span-12 relative'>
          <TextInput
            className='bg-white'
            placeholder='Buscar em empresas...'
            type='text'
            onKeyUp={handleSearchUnidades}
          />
          <MagnifyingGlassIcon
            width={18}
            height={18}
            className='text-gray-400 absolute top-3 right-3'
          />
        </div>
        <div className='col-span-12 lg:grid flex flex-col-reverse grid-cols-12 gap-3'>
          <div className='grid col-span-8 w-full grid-cols-12'>
            {filteredData?.length === 0 && (
              <div className='col-span-12'>
                <div className='flex justify-start items-center gap-2'>
                  <QuestionMarkCircleIcon
                    width={24}
                    height={24}
                    className='text-gray-400'
                  />
                  <span className='text-sm text-gray-600'>
                    Nenhuma empresa encontrada
                  </span>
                </div>
              </div>
            )}
            {isloadingBranchesList && (
              <div className='col-span-12'>
                <div className='flex justify-start items-center gap-2'>
                  <Spinner color='success' size='lg' />
                  <span className='text-sm text-gray-600'>
                    Carregando empresas...
                  </span>
                </div>
              </div>
            )}

            {filteredData?.map((pedido: any) => {
              return (
                <div className='col-span-12' key={pedido.idUnidade}>
                  <div
                    className={`p-2 rounded mb-3 flex justify-start items-center gap-2
                      ${
                        !permitirCriarPedidoPorUnidade[pedido.idUnidade] &&
                        ' opacity-40 cursor-not-allowed '
                      }
            
                      ${
                        checkedPedidos[pedido.idUnidade]
                          ? 'bg-primary-dark text-white'
                          : 'bg-gray-200 text-black'
                      }`}
                    title={
                      !permitirCriarPedidoPorUnidade[pedido.idUnidade]
                        ? 'Não tem colaborador e benefício ativo'
                        : ''
                    }
                  >
                    <Checkbox
                      id={pedido.idUnidade}
                      checked={checkedPedidos[pedido.idUnidade]}
                      onChange={event =>
                        permitirCriarPedidoPorUnidade[pedido.idUnidade]
                          ? handleCheckboxChange(pedido.idUnidade, event)
                          : null
                      }
                      disabled={
                        !permitirCriarPedidoPorUnidade[pedido.idUnidade]
                      }
                      className={`${
                        !permitirCriarPedidoPorUnidade[pedido.idUnidade]
                          ? 'cursor-not-allowed '
                          : 'cursor-pointer'
                      }`}
                    />
                    <span>
                      {highlightSearchResults(pedido.cnpj, userSearch)}
                    </span>
                  </div>
                  {checkedPedidos[pedido.idUnidade] && (
                    <>
                      {checkedPedidos[pedido.idUnidade] && isLoading ? (
                        <div
                          className={`h-[100px] w-full flex justify-center items-center`}
                        >
                          <Spinner color='success' size='lg' />
                        </div>
                      ) : (
                        <Accordion className='mb-5' collapseAll>
                          <Accordion.Panel>
                            <Accordion.Title className='gap-0 p-2 text-sm text-black'>
                              Opções de filtros
                            </Accordion.Title>
                            <Accordion.Content>
                              <div className='md:grid grid-cols-12 gap-3 w-full'>
                                <div className='mb-3 col-span-6'>
                                  <Label
                                    htmlFor='selectedLocaisDeEntrega'
                                    className='font-semibold mb-2'
                                  >
                                    Locais de entrega
                                  </Label>
                                  <Select
                                    primaryColor='#2563EB'
                                    placeholder='Selecione um local de entrega'
                                    isMultiple
                                    isSearchable
                                    searchInputPlaceholder='Buscar locais de entrega...'
                                    value={
                                      selectedLocaisDeEntrega[pedido.idUnidade]
                                    }
                                    onChange={value =>
                                      setSelectedLocaisDeEntrega(
                                        (prev: any) => {
                                          return {
                                            ...prev,
                                            [pedido.idUnidade]: value
                                          }
                                        }
                                      )
                                    }
                                    options={
                                      locaisDeEntregaByUnidade[pedido.idUnidade]
                                    }
                                  />
                                </div>
                                <div className='mb-3 col-span-6'>
                                  <Label
                                    htmlFor='selectedDepartamentos'
                                    className='font-semibold mb-2'
                                  >
                                    Departamentos
                                  </Label>
                                  <Select
                                    primaryColor='#2563EB'
                                    placeholder='Selecione um departamento'
                                    isMultiple
                                    isSearchable
                                    searchInputPlaceholder='Buscar departamentos...'
                                    noOptionsMessage='Nada encontrado'
                                    value={
                                      selectedDepartamentos[pedido.idUnidade]
                                    }
                                    onChange={value =>
                                      setSelectedDepartamentos((prev: any) => {
                                        return {
                                          ...prev,
                                          [pedido.idUnidade]: value
                                        }
                                      })
                                    }
                                    options={
                                      departamentosListByUnidade[
                                        pedido.idUnidade
                                      ]
                                    }
                                  />
                                </div>
                                <div className='mb-3 col-span-6'>
                                  <Label
                                    htmlFor='selectedCargos'
                                    className='font-semibold mb-2'
                                  >
                                    Cargos
                                  </Label>
                                  <Select
                                    primaryColor='#2563EB'
                                    placeholder='Selecione um cargo'
                                    noOptionsMessage='Nada encontrado'
                                    isMultiple
                                    isSearchable
                                    searchInputPlaceholder='Busar cargos...'
                                    value={selectedCargos[pedido.idUnidade]}
                                    onChange={value =>
                                      setSelectedCargos((prev: any) => {
                                        return {
                                          ...prev,
                                          [pedido.idUnidade]: value
                                        }
                                      })
                                    }
                                    options={
                                      cargosListByUnidade[pedido.idUnidade]
                                    }
                                  />
                                </div>
                                <div className='mb-3 col-span-6'>
                                  <Label
                                    htmlFor='selectedPeriodos'
                                    className='font-semibold mb-2'
                                  >
                                    Períodos
                                  </Label>
                                  <Select
                                    primaryColor='#2563EB'
                                    placeholder='Selecione um período'
                                    noOptionsMessage='Nada encontrado'
                                    isMultiple
                                    value={selectedPeriodos[pedido.idUnidade]}
                                    onChange={value =>
                                      setSelectedPeriodos((prev: any) => {
                                        return {
                                          ...prev,
                                          [pedido.idUnidade]: value
                                        }
                                      })
                                    }
                                    options={pedido.idsPeriodos}
                                  />
                                </div>
                              </div>
                            </Accordion.Content>
                          </Accordion.Panel>
                        </Accordion>
                      )}
                    </>
                  )}
                </div>
              )
            })}
            {Object.values(permitirCriarPedidoPorUnidade).includes(false) && (
              <UnidadeNaoHabilitadaModal
                isOpen={isOpenErrosModal}
                onClose={() => setIsOpenErrosModal(false)}
              />
            )}
          </div>
          <div className='col-span-4 w-full'>
            {Object.values(checkedPedidos).some(checked => checked) && (
              <div className='bg-quaternary p-3 rounded-md shadow'>
                <div className='flex justify-start items-center gap-1'>
                  <BuildingLibraryIcon
                    width={18}
                    height={18}
                    className='text-white'
                  />
                  <span className='text-white font-semibold'>
                    Unidades selecionadas
                  </span>
                </div>
                <ul className='mt-3'>
                  {Object.entries(checkedPedidos).map(([id, checked]) => {
                    if (checked) {
                      const pedido = data.find(
                        (pedido: any) => pedido.idUnidade === id
                      )
                      return (
                        <li className='text-white text-xs pb-2' key={id}>
                          {pedido.cnpj}
                        </li>
                      )
                    }
                    return null
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <hr className='my-3 bg-cyan-700 h criar-pedido' />
      <h2 className='font-semibold pb-3'>Informações do Pedido</h2>
      <CriarPedidoForm formik={formik}>
        <div className='w-full flex flex-col md:flex-row gap-3 md:gap-0 justify-between items-center col-span-12'>
          <div className='flex gap-2 justify-center items-center'>
            <Checkbox
              id='termos'
              checked={isSelectedContaCorrente}
              onClick={e => setIsSelectedContaCorrente(e.currentTarget.checked)}
            />
            <Label className='font-semibold'>
              Utilizar crédito de conta corrente da empresa
            </Label>
          </div>
          <Button
            type='submit'
            className='flex gap-2 justify-center items-center w-full md:w-auto'
            disabled={isLoadingCriarPedido || isLoadingProcessarPedido}
            isProcessing={isLoadingCriarPedido || isLoadingProcessarPedido}
            id='incluir-pedidos-btn'
          >
            Incluir pedido
          </Button>
        </div>
      </CriarPedidoForm>
      <ConfirmarPedidoModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          navigate(`/${routeNames.pedidosPendentes}`)
        }}
        isPedidoComboleto={false}
      />
      {isOnRoute && (
        <StepModal
          isOpen={isOnboardingOpen}
          onClose={handleClose}
          onBeforeExit={handleBeforeExit}
          isOnboardingFinishedOrderReview={isOnboardingFinishedCreateOrder}
          steps={stepList}
          cookieName='isOnboardingFinishedCreateOrder'
        />
      )}
    </div>
  )
}

export default CriarPedidoContainer
