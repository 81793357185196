import { FunctionComponent } from 'react'

import { useNavigate } from 'react-router-dom'

interface ShortcurtCardProps {
  shortcut: any
}

const ShortcurtCard: FunctionComponent<ShortcurtCardProps> = ({ shortcut }) => {
  const navigate = useNavigate()

  return (
    <div
      onClick={() => navigate(shortcut.uri)}
      data-testid={shortcut.title}
      className='bg-white hover:bg-primary border-[1px] transition-colors border-primary  text-primary-hover hover:text-white border-primarytransition-colors gap-1 cursor-pointer rounded-xl shadow flex flex-col justify-center items-center w-full min-w-[200px] h-[200px] col-span-12'
    >
      {shortcut.icon}
      <span className='font-semibold'>{shortcut.title}</span>
    </div>
  )
}

export default ShortcurtCard
