import axios from 'axios'

interface GrantCodeResponse {
  redirect_uri: string
}

interface AccessTokenResponse {
  access_token: string
  refresh_token: string
  token_type: string
  expires_in: number
}

class Sensedia {
  private readonly apiUrl: string
  private readonly redirectUri: string
  private readonly headers: Record<string, string>
  private readonly clientId: string
  private readonly clientSecret: string

  constructor() {
    this.apiUrl = process.env.VITE_API_SENSEDIA_URL
    this.redirectUri = process.env.VITE_APP_APP_URL
    this.headers = {
      'Content-Type': 'application/json'
    }
    this.clientId = process.env.VITE_API_SENSEDIA_CLIENT_ID
    this.clientSecret = process.env.VITE_API_SENSEDIA_CLIENT_SECRET
  }

  public async getRedirectUri(): Promise<string> {
    try {
      const response = await axios.post<GrantCodeResponse>(
        `${this.apiUrl}/oauth/grant-code`,
        {
          client_id: this.clientId,
          redirect_uri: this.redirectUri
        },
        {
          headers: this.headers
        }
      )

      return response.data.redirect_uri
    } catch (error) {
      console.error('Error fetching redirect URI:', error)
      throw error
    }
  }

  public async getAccessToken(code: string): Promise<AccessTokenResponse> {
    try {
      const response = await axios.post<AccessTokenResponse>(
        `${this.apiUrl}/oauth/access-token`,
        {
          grant_type: 'authorization_code',
          code
        },
        {
          headers: {
            ...this.headers,
            Authorization:
              'Basic ' + btoa(`${this.clientId}:${this.clientSecret}`)
          }
        }
      )

      return response.data
    } catch (error) {
      console.error('Error fetching access token:', error)
      throw error
    }
  }
}

export default Sensedia
