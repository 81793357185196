import { ArrowLeftIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { Alert, Button, Label } from 'flowbite-react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import Loader from '@/components/Loader'
import TableItemPedidos from '@/components/Table/table-item-pedidos.component'
import { routeNames } from '@/configs/routePaths'
import { useGetConfirmarPedidos } from '@/hooks/queries/use-get-pedidos'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { TABLE_HEADERS_CONFIRMACAO_PEDIDOS } from '@/pages/Pedidos/constants'

const ConfirmarPedidosContainer = () => {
  const { data: userData } = useGetUserData()
  const [searchParams] = useSearchParams()
  const dataPedido = searchParams.get('data')
  const navigate = useNavigate()
  const formatCurrency = (value: number) =>
    value?.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    })
  const { data: itensPedidosList, isLoading } = useGetConfirmarPedidos({
    params: {
      idEmpregador: userData?.authenticatedAccountId || '',
      dataInicio: dataPedido || '',
      dataFim: dataPedido || '',
      inicio: 0,
      comprimento: 10,
      campo: 'CriadoEm',
      direcao: 'desc'
    }
  })
  const isVariosPedidos =
    (itensPedidosList?.conteudo?.resultados?.length as number) > 1
  const mapedData = itensPedidosList?.conteudo?.resultados?.map(item => ({
    ...item,
    total: formatCurrency(item.totalComEconomia) ?? '-',
    taxasOperadora: formatCurrency(item.totalComEconomia) ?? '-',
    taxasLocalEntrega:
      item.totalComEconomia.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }) ?? '-',
    taxaAdministrativa: formatCurrency(item.totalComEconomia) ?? '-',
    cobranca: formatCurrency(item.cobranca) ?? '-',
    desconto: formatCurrency(item.desconto) ?? '-',
    itens: formatCurrency(item.beneficios) ?? '-',
    valorRepasses: formatCurrency(item?.taxasOperadora) ?? '-',
    valorTarifas:
      formatCurrency(item?.taxasLocalEntrega + item?.taxaAdministrativa) ?? '-',
    valorTotalItensComEconomia:
      item.valorTotalItensComEconomia.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }) ?? '-'
  }))

  return (
    <>
      {isLoading ? (
        <div
          className='h-96 w-full flex justify-center items-center'
          data-testid='loader-pedidos-revisao'
        >
          <Loader size='xl' />
        </div>
      ) : (
        <div data-testid='table-pedidos-revisao'>
          <div className='mb-5 text-center md:text-left'>
            <Alert color={'success'}>
              <div className='flex flex-col md:flex-row justify-start items-center gap-2 mb-4'>
                <CheckCircleIcon className='md:w-8 md:h-8 w-12 h-12 text-green-500' />
                <span className='block text-2xl font-semibold'>
                  Pedido{isVariosPedidos ? 's' : ''} aprovado
                  {isVariosPedidos ? 's' : ''} com sucesso!
                </span>
              </div>
              <Label className='block mb-1 text-gray-600 leading-7'>
                Para conferir o processamento e detalhes do pedido, acesse o
                menu{' '}
                <Link
                  className='font-bold underline'
                  to={`/${routeNames.pedidosAprovados}`}
                >
                  <b>"Acompanhar Pedidos"</b>
                </Link>{' '}
                na aba "Pedidos Aprovados".
                <br /> O boleto será gerado após a aprovação e assim que
                tivermos o retorno do banco estará disponível no menu{' '}
                <Link
                  className='font-bold underline'
                  to={`/${routeNames.pedidosPendentes}`}
                >
                  "Acompanhar Pedidos"
                </Link>{' '}
                na aba <b>"Pedidos Aprovados"</b>.
              </Label>
              <Label className='block mt-4 text-gray-600 leading-7'>
                Pedidos gerados com valor de desconto maior ou igual ao seu
                valor não exige pagamento de título.
              </Label>
              <Label className='block mt-4 text-gray-800 leading-7 font-semibold'>
                Obrigado por escolher nosso Serviço de Gestão de Benefícios.
              </Label>
            </Alert>
          </div>
          <hr className='my-5' />
          <div>
            <h3 className='text-gray-800 text-xl font-semibold mb-5'>
              Confira os detalhes do seu pedido abaixo
            </h3>
          </div>
          <TableItemPedidos
            isActions={false}
            isRowClicable={false}
            data={mapedData || []}
            headers={TABLE_HEADERS_CONFIRMACAO_PEDIDOS}
            deleteAction={() => void 0}
            hasCustomActions={false}
            idKey='pedidoId'
            isIdle={isLoading}
          />
          <Button
            className='mt-5 hover:opacity-75 text-black cursor-pointer'
            color='light'
            data-testid='voltar-pedidos'
            onClick={() => navigate(`/${routeNames.pedidosPendentes}`)}
          >
            <ArrowLeftIcon className='w-5 h-5 mr-2' />
            <span>Voltar para pedidos</span>
          </Button>
        </div>
      )}
    </>
  )
}

export default ConfirmarPedidosContainer
