import { endPoints } from '@/configs/endPoints'
import { IBoletoResponse } from '@/interfaces/boletos'
import { API_COBRANCAS } from '@/utils/cobrancas-fetcher'

export async function exportarBoleto(
  pedidoVendaId: string
): Promise<IBoletoResponse> {
  const data = await API_COBRANCAS.get<IBoletoResponse>(
    `${endPoints.exportarBoleto}?idPedidoVenda=${pedidoVendaId}`
  )

  return data
}
