import { Button, Modal } from 'flowbite-react'

import { useDevolucoesContext } from '@/contexts/devolucoes.context'

interface MotivoDevolucaoModalProps {
  isOpen: boolean | false
  onClose: any
}

export default function MotivoDevolucaoModal({
  isOpen,
  onClose
}: MotivoDevolucaoModalProps) {
  const { devolucaoSelecionada } = useDevolucoesContext()

  return (
    <Modal show={isOpen} size={'2xl'} onClose={onClose} popup>
      <Modal.Header>
        <p className='text-center font-semibold p-3 text-quaternary text-2xl'>
          Motivo de devolução
        </p>
      </Modal.Header>
      <Modal.Body>
        <div className='grid grid-cols-12 bg-white rounded-xl md:gap-1 p-5 mx-5 mb-5 border'>
          <div className='p-2 col-span-12 text-left'>
            <span className='text-quaternary font-semibold pb-3 text-xl'>
              Motivo:
            </span>
          </div>
          <div className='p-3 col-span-12 text-left'>
            <p>{devolucaoSelecionada?.motivo}</p>
          </div>
          <div className='col-span-12 text-center p-2 pb-5 '>
            <hr />
          </div>
          <div className='p-2 col-span-12 text-left'>
            <span className='text-quaternary font-semibold pb-3 text-xl'>
              Solução:
            </span>
          </div>
          <div className='p-3 col-span-12 text-left'>
            <p>{devolucaoSelecionada?.solucao}</p>
          </div>
        </div>
        <div className='text-center'>
          <div className='flex justify-end gap-4'>
            <Button color='gray' onClick={onClose} data-testid='btn-close'>
              Sair
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
