import { useMemo, useState } from 'react'

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {
  Alert,
  Button,
  Modal,
  Radio,
  Textarea,
  TextInput
} from 'flowbite-react'
import { useQueryClient } from 'react-query'
import Select from 'react-select'

import { bancoOptions, tipoContaOptions } from './constants'
import toastService from '@/components/toast/toast-service'
import { QueryKey } from '@/configs/queryKeys'
import { useDevolucoesContext } from '@/contexts/devolucoes.context'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { tratarDevolucao } from '@/services/devolucoes'
import { CurrencyFormat } from '@/utils/currency-format'
import { cpfMask } from '@/utils/masks'

interface ITratativasDevolucoesModalProps {
  onClose: () => void
  isOpen: boolean
}

const TratativasDevolucoesModal: React.FC<ITratativasDevolucoesModalProps> = ({
  onClose,
  isOpen
}: ITratativasDevolucoesModalProps) => {
  const { dadosBancarios, devolucaoSelecionada } = useDevolucoesContext()
  const { data: userData } = useGetUserData()
  const queryClient = useQueryClient()
  const [processing, setProcessing] = useState<boolean>(false)

  const [erros, setErros] = useState<{ campo: string; erro: string }[]>()

  const [tipoTratativa, setTipoTratativa] = useState<string>('')

  const [novoBanco, setNovoBanco] = useState<string>()
  const selectedBancoOption = bancoOptions?.find(
    option => option.value === novoBanco
  )
  const [novoTipoConta, setNovoTipoConta] = useState<string>()
  const selectedTipoContaOption = tipoContaOptions?.find(
    option => option.value === novoTipoConta
  )
  const [novaAgencia, setNovaAgencia] = useState<string>()
  const [novoAgenciaDig, setNovoAgenciaDig] = useState<string>()
  const [novaConta, setNovaConta] = useState<string>()
  const [novoContaDig, setNovoContaDig] = useState<string>()

  const [observacao, setObservacao] = useState<string>()

  const devolucaoEmTratativa = useMemo(() => {
    const item = { dadosBancarios, devolucaoSelecionada }
    setNovoBanco(item?.dadosBancarios?.numeroDoBanco)
    setNovoTipoConta(item?.dadosBancarios?.tipoDeContaBancaria)
    setNovaAgencia(item?.dadosBancarios?.agenciaBancaria)
    setNovoAgenciaDig(item?.dadosBancarios?.digitoDaAgenciaBancaria)
    setNovaConta(item?.dadosBancarios?.contaBancaria)
    setNovoContaDig(item?.dadosBancarios?.digitoDaContaBancaria)
    setObservacao('')
    setTipoTratativa('')
    return item
  }, [devolucaoSelecionada, dadosBancarios])

  const validacaoForm = () => {
    const erros = []
    if (tipoTratativa === undefined || tipoTratativa === '') {
      erros.push({
        campo: 'tratativa',
        erro: 'Uma tratativa deve ser selecionada'
      })
      return erros
    }
    if (tipoTratativa === 'Creditar') {
      if (novoBanco === undefined || novoBanco === '')
        erros.push({ campo: 'banco', erro: 'Banco deve ser selecionado' })
      if (novoTipoConta === undefined || novoTipoConta === '')
        erros.push({
          campo: 'tipoConta',
          erro: 'Tipo de conta deve ser selecionado'
        })
      if (novaAgencia === undefined || novaAgencia === '')
        erros.push({ campo: 'agencia', erro: 'Agencia deve ser preenchida' })
      if (novaConta === undefined || novaConta === '')
        erros.push({ campo: 'conta', erro: 'Conta deve ser preenchida' })

      return erros
    }

    if (
      tipoTratativa === 'Reprocessar' &&
      (observacao === undefined || observacao === '')
    )
      erros.push({
        campo: 'observacao',
        erro: 'Observação deve ser preenchida'
      })

    return erros
  }

  const handleTratarDevolucao = () => {
    setProcessing(true)
    const validacao = validacaoForm()
    setErros(validacao)
    if (validacao?.length === 0) {
      const tratativa =
        tipoTratativa === 'Creditar'
          ? 'DepositoBancario'
          : tipoTratativa === 'Descontar'
          ? 'DescontarNaProximaFatura'
          : 'ReprocessarCredito'
      const DTO = {
        idPedidoVenda: devolucaoEmTratativa.devolucaoSelecionada?.idPedido,
        idVenda: devolucaoEmTratativa.devolucaoSelecionada?.idVenda,
        idItemVenda: devolucaoEmTratativa.devolucaoSelecionada?.id,
        atualizadoPor: userData?.userId,
        tratativa: tratativa,
        dadosBancariosDoTrabalhador: {
          numeroBanco: novoBanco,
          nomeBanco: bancoOptions?.find(option => option.value === novoBanco)
            ?.label,
          tipoConta: novoTipoConta,
          agencia: novaAgencia,
          digitoAgencia: novoAgenciaDig,
          conta: novaConta,
          digitoConta: novoContaDig
        },
        reprocessarCredito: {
          observacao: observacao
        }
      }
      tratarDevolucao(DTO)
        .then(() => {
          toastService.success(
            `Tratativa ${tipoTratativa} selecionada com sucesso!`
          )
          setProcessing(false)
          queryClient.invalidateQueries(QueryKey.devolucoesList)
          onClose()
        })
        .catch(() => {
          setProcessing(false)
          toastService.error(
            'Ocorreu um erro ao utilizar tratativa selecionada!'
          )
        })
    } else {
      setProcessing(false)
      toastService.error('A tratativa da devolução contêm erros')
    }
  }

  return (
    <Modal
      popup
      show={isOpen}
      onClose={onClose}
      className='flex items-center'
      size='7xl'
    >
      <Modal.Header>
        <p className='text-center font-semibold p-3 text-quaternary text-2xl'>
          Tratativas de Devolução
        </p>
      </Modal.Header>
      <Modal.Body>
        <span className='pb-3 mx-3 text-xl flex'>
          {devolucaoEmTratativa?.devolucaoSelecionada?.nome}
        </span>
        <span className='pb-3 mx-3 text-m flex'>
          {devolucaoEmTratativa?.devolucaoSelecionada?.codigoDoBeneficio +
            ' - ' +
            devolucaoEmTratativa?.devolucaoSelecionada?.descricaoBeneficio}
        </span>
        <div
          className={
            tipoTratativa === 'Descontar'
              ? 'grid grid-cols-12 bg-white rounded-xl gap-5 md:gap-0 p-5 mx-5 mb-5 border border-quaternary'
              : 'grid grid-cols-12 bg-white rounded-xl gap-5 md:gap-0 p-5 mx-5 mb-5 border'
          }
        >
          <div className='p-3 flex md:col-span-12 md:text-left gap-4'>
            <Radio
              checked={tipoTratativa === 'Descontar'}
              onClick={() => setTipoTratativa('Descontar')}
              value='Descontar'
              name='radio-descontar'
              className='text-quaternary focus:ring-quaternary'
              data-testid='radio-descontar'
            />
            <span className='text-quaternary font-semibold pb-3 text-xl'>
              Descontar na Próxima Fatura
            </span>
            {erros?.find(e => e.campo == 'tratativa') != undefined ? (
              <p
                className='mt-2 text-sm text-red-600 dark:text-red-500'
                data-testid='select-tratativa-erro'
              >
                {erros?.find(e => e.campo == 'tratativa')?.erro}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div
            className='p-3 md:col-span-12  md:text-left'
            data-testid='descontar-info'
          >
            <p>
              Será lançado o valor de{' '}
              <b>
                {CurrencyFormat(
                  devolucaoEmTratativa.devolucaoSelecionada?.total ?? ''
                )}
              </b>{' '}
              como crédito da empresa para utilização em seu próximo pedido.
            </p>
          </div>
        </div>

        <div
          className={
            tipoTratativa === 'Creditar'
              ? 'grid grid-cols-12 bg-white rounded-xl gap-5 md:gap-0 p-5 mx-5 mb-5 border border-quaternary'
              : 'grid grid-cols-12 bg-white rounded-xl gap-5 md:gap-0 p-5 mx-5 mb-5 border'
          }
        >
          <div className='p-3 flex md:col-span-12 md:text-left gap-4'>
            <Radio
              checked={tipoTratativa === 'Creditar'}
              onClick={() => setTipoTratativa('Creditar')}
              value='Creditar'
              name='radio-creditar'
              className='text-quaternary focus:ring-quaternary'
              data-testid='radio-creditar'
            />
            <span className='text-quaternary font-semibold pb-3 text-xl'>
              Crédito em Conta do Beneficiário
            </span>
            {erros?.find(e => e.campo == 'tratativa') != undefined ? (
              <p
                className='mt-2 text-sm text-red-600 dark:text-red-500'
                data-testid='select-tratativa-erro'
              >
                {erros?.find(e => e.campo == 'tratativa')?.erro}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div
            className='p-3 md:col-span-12 md:text-left'
            data-testid='creditar-info'
          >
            <p>
              Informe os dados da conta do beneficiário para depósito no valor
              de{' '}
              <b>
                {CurrencyFormat(
                  devolucaoEmTratativa.devolucaoSelecionada?.total ?? ''
                )}
              </b>
              .
            </p>
            <Alert color='warning' className='mt-3'>
              <div
                className='flex justify-center'
                data-testid='alerta-creditar'
              >
                <ExclamationTriangleIcon className='w-5 h-5 mr-2' />
                <span>
                  Atenção! NÃO INFORME CONTA-SALÁRIO, pois ela só aceita
                  créditos de salários
                </span>
              </div>
            </Alert>
          </div>

          <div
            className='p-3 col-span-6 text-left md:text-left'
            data-testid='select-banco'
          >
            <p>Banco:</p>
            <Select
              placeholder='Selecione um banco'
              isSearchable
              escapeClearsValue
              isClearable={true}
              noOptionsMessage={() => 'Sem resultados'}
              value={selectedBancoOption}
              onChange={selected => setNovoBanco(selected?.value)}
              options={bancoOptions}
            />
            {erros?.find(e => e.campo == 'banco') != undefined ? (
              <p
                className='mt-2 text-sm text-red-600 dark:text-red-500'
                data-testid='select-banco-erro'
              >
                {erros?.find(e => e.campo == 'banco')?.erro}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div
            className='p-3 md:col-span-6 md:text-left'
            data-testid='select-tipo-conta'
          >
            <p>Tipo Conta:</p>
            <Select
              placeholder='Selecione o tipo de conta'
              isSearchable
              escapeClearsValue
              isClearable={true}
              noOptionsMessage={() => 'Sem resultados'}
              value={selectedTipoContaOption}
              onChange={selected => setNovoTipoConta(selected?.value)}
              options={tipoContaOptions}
            />
            {erros?.find(e => e.campo == 'tipoConta') != undefined ? (
              <p
                className='mt-2 text-sm text-red-600 dark:text-red-500'
                data-testid='select-tipoConta-erro'
              >
                {erros?.find(e => e.campo == 'tipoConta')?.erro}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className='p-3 md:col-span-2 md:text-left'>
            <p>Agência:</p>
            <TextInput
              type='number'
              color='light'
              defaultValue={
                devolucaoEmTratativa.dadosBancarios?.agenciaBancaria
              }
              value={novaAgencia}
              onChange={value => setNovaAgencia(value.target.value)}
              data-testid='input-agencia'
            />
            {erros?.find(e => e.campo == 'agencia') != undefined ? (
              <p
                className='mt-2 text-sm text-red-600 dark:text-red-500'
                data-testid='input-agencia-erro'
              >
                {erros?.find(e => e.campo == 'agencia')?.erro}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className='p-3 md:col-span-1 md:text-left'>
            <p>Dig:</p>
            <TextInput
              type='number'
              color='light'
              defaultValue={
                devolucaoEmTratativa.dadosBancarios?.digitoDaAgenciaBancaria
              }
              value={novoAgenciaDig}
              onChange={value => setNovoAgenciaDig(value.target.value)}
              data-testid='input-agencia-dig'
            />
          </div>
          <div className='p-3 md:col-span-2 md:text-left'>
            <p>Conta:</p>
            <TextInput
              type='number'
              color='light'
              defaultValue={devolucaoEmTratativa.dadosBancarios?.contaBancaria}
              value={novaConta}
              onChange={value => setNovaConta(value.target.value)}
              data-testid='input-conta'
            />
            {erros?.find(e => e.campo == 'conta') != undefined ? (
              <p
                className='mt-2 text-sm text-red-600 dark:text-red-500'
                data-testid='input-conta-erro'
              >
                {erros?.find(e => e.campo == 'conta')?.erro}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className='p-3 md:col-span-1 md:text-left'>
            <p>Dig:</p>
            <TextInput
              type='number'
              color='light'
              defaultValue={
                devolucaoEmTratativa.dadosBancarios?.digitoDaContaBancaria
              }
              value={novoContaDig}
              onChange={value => setNovoContaDig(value.target.value)}
              data-testid='input-conta-dig'
            />
          </div>
          <div className='p-3 md:col-span-2 md:text-left'>
            <p>CPF:</p>
            <TextInput
              type='text'
              color='light'
              defaultValue={devolucaoEmTratativa.devolucaoSelecionada?.cpf}
              value={cpfMask(devolucaoEmTratativa.devolucaoSelecionada?.cpf)}
              data-testid='input-cpf'
              disabled
            />
          </div>
          <div className='p-3 md:col-span-4 md:text-left'>
            <p>Favorecido:</p>
            <TextInput
              type='text'
              color='light'
              defaultValue={devolucaoEmTratativa.devolucaoSelecionada?.nome}
              value={devolucaoEmTratativa.devolucaoSelecionada?.nome}
              data-testid='input-favorecido'
              disabled
            />
          </div>
        </div>

        <div
          className={
            tipoTratativa === 'Reprocessar'
              ? 'grid grid-cols-12 bg-white rounded-xl gap-5 md:gap-0 p-5 mx-5 mb-5 border border-quaternary'
              : 'grid grid-cols-12 bg-white rounded-xl gap-5 md:gap-0 p-5 mx-5 mb-5 border'
          }
        >
          <div className='p-3 flex md:col-span-12 md:text-left gap-4'>
            <Radio
              checked={tipoTratativa === 'Reprocessar'}
              onClick={() => setTipoTratativa('Reprocessar')}
              value='Reprocessar'
              name='radio-reprocessar'
              className='text-quaternary focus:ring-quaternary'
              data-testid='radio-reprocessar'
              disabled={
                devolucaoEmTratativa.devolucaoSelecionada?.tipoItemVenda ===
                'VrCredito'
              }
            />
            <span className='text-quaternary font-semibold pb-3 text-xl'>
              Reprocessar Crédito
            </span>
            {erros?.find(e => e.campo == 'tratativa') != undefined ? (
              <p
                className='mt-2 text-sm text-red-600 dark:text-red-500'
                data-testid='select-tratativa-erro'
              >
                {erros?.find(e => e.campo == 'tratativa')?.erro}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div
            className='p-3 md:col-span-12 md:text-left'
            data-testid='reprocessar-info'
          >
            {devolucaoEmTratativa.devolucaoSelecionada?.tipoItemVenda ===
            'VrCredito' ? (
              <p>
                Não é possível reprocessar para compra um item que foi destinado
                para depósito em conta.
              </p>
            ) : (
              <p>
                O motivo da devolução permite que o crédito seja reprocessado.
                Verifique se há alguma ação a ser tomada, clicando em
                <b> Motivo da Devolução</b> e logo em seguida selecione esta
                opção, caso deseje que o crédito seja processado novamente.
              </p>
            )}
          </div>
          <div className='p-3 md:col-span-12 md:text-left'>
            <p>Observação:</p>
            <Textarea
              id='observacao'
              className='bg-white'
              value={observacao}
              onChange={value => setObservacao(value.target.value)}
              data-testid='input-observacao'
              disabled={
                devolucaoEmTratativa.devolucaoSelecionada?.tipoItemVenda ===
                'VrCredito'
              }
            />
            {erros?.find(e => e.campo == 'observacao') != undefined ? (
              <p
                className='mt-2 text-sm text-red-600 dark:text-red-500'
                data-testid='input-observacao-erro'
              >
                {erros?.find(e => e.campo == 'observacao')?.erro}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <>
          <div className='w-full flex justify-between'>
            <div className='flex justify-between gap-2 items-left'>
              <Button color='gray' onClick={onClose} data-testid='btn-close'>
                Cancelar
              </Button>
            </div>
            <div className='flex justify-between gap-2 items-right'>
              <Button
                color='success'
                onClick={handleTratarDevolucao}
                className='bg-quaternary'
                isProcessing={processing}
                disabled={processing}
                data-testid='btn-save'
              >
                <span>Confirmar</span>
              </Button>
            </div>
          </div>
        </>
      </Modal.Footer>
    </Modal>
  )
}

export default TratativasDevolucoesModal
