import { SetStateAction, useMemo, useState } from 'react'

import debounce from 'debounce'
import { Button, Label, Modal, Select as SelectItem } from 'flowbite-react'
import Select from 'react-select'

import Loader from '@/components/Loader'
import TableItem from '@/components/Table'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import { useGetErrosPedido } from '@/hooks/queries/use-get-pedidos'
import { IRevisaoPedido } from '@/interfaces/pedidos'
import { TABLE_HEADERS_LISTA_ERROS_PEDIDO } from '@/pages/Pedidos/constants'
interface IErrosPedidoModalProps {
  onClose: () => void
  isOpen: boolean
  pedido: IRevisaoPedido | any
  isAcompanhamento?: boolean
}

const ErrosPedidoModal: React.FC<IErrosPedidoModalProps> = ({
  onClose,
  isOpen,
  pedido,
  isAcompanhamento = false
}: IErrosPedidoModalProps) => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const startIndex = (page - 1) * pageSize
  const [selectedCnpj, setSelectedCnpj] = useState<{
    value: string
    label: string
  }>(null)

  const handleUnidadeChange = (value: any) => {
    setSelectedCnpj(value)
  }

  const idsPedidos = isAcompanhamento
    ? pedido?.idsPedidos
      ? pedido.idsPedidos
      : null
    : [pedido.pedidoId]

  const idsUnidades = isAcompanhamento
    ? pedido?.idsUnidades
      ? pedido.idsUnidades
      : null
    : [pedido.unidadeId]

  const { data, isLoading, isFetching } = useGetErrosPedido({
    params: {
      inicio: startIndex,
      procurar: search,
      campo: 'CriadoEm',
      direcao: 'asc',
      comprimento: pageSize,
      idsPedidos: idsPedidos,
      idsUnidades: idsUnidades
    }
  })
  const branchesList = useMemo(() => {
    const uniqueBranches = new Map()
    data?.conteudo?.resultados?.forEach(item => {
      if (!uniqueBranches.has(item?.cnpj)) {
        uniqueBranches.set(item?.cnpj, {
          value: item?.idUnidade,
          label: `${item?.cnpj}`
        })
      }
    })
    return Array.from(uniqueBranches.values())
  }, [data])

  const mapedData = useMemo(() => {
    return data?.conteudo?.resultados?.filter(item => {
      if (selectedCnpj) {
        return item.cnpj === selectedCnpj.label
      }
      return true
    })
  }, [data, selectedCnpj])

  const handleSearch = debounce((e: SetStateAction<string>) => {
    setSearch(e)
  }, 500)

  const totalPages = Math.ceil(
    (data?.conteudo?.quantidadeDeItens ?? 0) / pageSize
  )

  return (
    <div data-testid='modal-erros'>
      <Modal
        show={isOpen}
        onClose={onClose}
        className='flex items-center'
        size='5xl'
      >
        <Modal.Header>
          <p className='text-center font-bold p-3'>Lista de Erros</p>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div
              className='h-96 w-full flex justify-center items-center'
              data-testid='loader-tarifas'
            >
              <Loader size={'xl'} />
            </div>
          ) : (
            <>
              <div data-testid='table-tarifas'>
                {isAcompanhamento && (
                  <div className='w-full flex justify-start itens-center mb-5 border rounded p-3'>
                    <div>
                      <Label htmlFor='cnpj-id'>Unidade</Label>
                      <Select
                        className='pt-2 w-[300px]'
                        id='cnpj-id'
                        data-testid='cnpj-id'
                        isClearable={true}
                        isSearchable
                        escapeClearsValue
                        isLoading={isFetching}
                        loadingMessage={() => 'Carregando...'}
                        placeholder='Selecione...'
                        noOptionsMessage={() => 'Sem resultados'}
                        onChange={handleUnidadeChange}
                        options={branchesList}
                      />
                    </div>
                  </div>
                )}
                <SearchTableItem
                  isAddItem={false}
                  isSearchInputEnabled={true}
                  setFilterText={e => handleSearch(e)}
                />
                <TableItem
                  isActions={false}
                  isRowClicable={false}
                  data={mapedData || []}
                  headers={TABLE_HEADERS_LISTA_ERROS_PEDIDO}
                  deleteAction={() => void 0}
                  hasCustomActions={false}
                  idKey='idPedido'
                  isIdle={isFetching}
                />
                <PaginationItem
                  totalItems={
                    data?.conteudo?.quantidadeDeItens ?? (0 as number)
                  }
                  totalPages={totalPages}
                  pageSize={pageSize}
                  onPageChange={(pageParam: number) => setPage(pageParam)}
                >
                  <SelectItem
                    id='select-page-size'
                    data-testid='select-page-size'
                    onChange={e => setPageSize(Number(e.target.value))}
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </SelectItem>
                </PaginationItem>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <div className='flex gap-2'>
              <Button color='gray' onClick={onClose} data-testid='btn-close'>
                Fechar
              </Button>
            </div>
          </>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ErrosPedidoModal
