import { memo, Suspense, useMemo } from 'react'

import { ArchiveBoxIcon } from '@heroicons/react/24/outline'
import { Link, Outlet, useLocation } from 'react-router-dom'

import Loader from '@/components/Loader'

const Encomendas = () => {
  const { pathname } = useLocation()

  const menuItems = [
    {
      title: 'Logística',
      icon: <ArchiveBoxIcon width={16} height={16} />,
      route: ''
    }
  ]

  const currentPageTitle = useMemo(() => {
    const current = menuItems.filter(item => pathname.includes(item.route))
    return current[0]?.title
  }, [pathname])

  return (
    <>
      <main className='h-full flex justify-center'>
        <div className='md:grid grid-cols-12 flex flex-col md:p-10 p-5 w-full max-w-[1600px]'>
          <div className='col-span-2'>
            <h3 className='font-semibold text-2xl mb-5'>{currentPageTitle}</h3>
            <div className='md:shadow rounded-xl md:bg-white'>
              <ul className='text-sm text-gray-700 dark:text-gray-200 flex md:block justify-between'>
                {menuItems.map((item, index) => (
                  <li key={index} className='bg-white md:bg-none rounded-t-md'>
                    <Link
                      to={`${item.route}`}
                      className={`flex items-center px-3 py-3 hover:bg-primary hover:text-white transition-all rounded-t-md
                        ${
                          pathname.includes(item.route)
                            ? 'bg-primary text-white'
                            : ''
                        }`}
                    >
                      {item.icon}
                      <span className='ml-3 font-semibold hidden md:block'>
                        {item.title}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className='col-span-9 pt-8 md:pl-5 md:mt-5'>
            <Suspense
              fallback={
                <div className='flex justify-center items-center h-full'>
                  <Loader size={'xl'} />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </div>
      </main>
    </>
  )
}

export default memo(Encomendas)
