import { useMemo } from 'react'

import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Alert, Button, Modal } from 'flowbite-react'

import Loader from '@/components/Loader'
import TableItem from '@/components/Table'
import { useGetUnidadesAtivas } from '@/hooks/queries/use-get-unidades-ativas'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { TABLE_HEADERS_LISTA_PERMITIR_CRIAR_PEDIDO } from '@/pages/Pedidos/constants'

interface IUnidadeNaoHabilitadaModalProps {
  onClose: () => void
  isOpen: boolean
}

const UnidadeNaoHabilitadaModal: React.FC<IUnidadeNaoHabilitadaModalProps> = ({
  onClose,
  isOpen
}: IUnidadeNaoHabilitadaModalProps) => {
  const { data: userData } = useGetUserData() || {}
  const { data, isLoading, isFetching } = useGetUnidadesAtivas({
    params: {
      idDoEmpregador: userData?.authenticatedAccountId || ''
    }
  })

  const mapedData = useMemo(() => {
    return data?.conteudo
      ?.filter(item => !item.permiteCriarPedido)
      .map(item => ({
        value: item.idUnidade,
        descricao: item.descricao,
        permiteCriarPedido: item.permiteCriarPedido,
        descricaoErro:
          'Não foram encontrados colaboradores com benefícios ativos.'
      }))
  }, [data])

  return (
    <div data-testid='modal-erros'>
      <Modal
        show={isOpen}
        onClose={onClose}
        className='flex items-center'
        size='5xl'
      >
        <Modal.Header>
          <p className='text-center font-bold p-3'>Alerta</p>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div
              className='h-96 w-full flex justify-center items-center'
              data-testid='loader-tarifas'
            >
              <Loader size={'xl'} />
            </div>
          ) : (
            <>
              <div data-testid='table-tarifas'>
                <Alert
                  color='warning'
                  className='mb-2'
                  icon={InformationCircleIcon}
                >
                  {'Não é possível criar o pedido para as seguintes unidades.'}
                </Alert>
                <TableItem
                  isActions={false}
                  isRowClicable={false}
                  data={mapedData || []}
                  headers={TABLE_HEADERS_LISTA_PERMITIR_CRIAR_PEDIDO}
                  deleteAction={() => void 0}
                  hasCustomActions={false}
                  idKey='idPedido'
                  isIdle={isFetching}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <div className='flex gap-2'>
              <Button color='gray' onClick={onClose} data-testid='btn-close'>
                Fechar
              </Button>
            </div>
          </>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default UnidadeNaoHabilitadaModal
