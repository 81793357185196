export enum routeNames {
  root = '/',
  login = '/login',
  dashboard = '/dashboard',
  selectAccounts = '/select-account',
  forgotPassword = '/Account/ForgotPassword',
  userProfile = '/User/Profile',
  homeDashboard = '/HomeV3/Dash',
  CheckRecharge = '/CheckRecharge/CheckCpf',
  registers = '/cadastros',
  beneficiarios = 'beneficiarios',
  encomendas = 'logistica',
  pedidos = 'pedidos',
  pedidosOld = 'DemandsV3/Index',
  pedidosAprovados = 'pedidos/aprovados',
  pedidosPendentes = 'pedidos/pendentes',
  pedidosView = 'pedidos/:id',
  departamentos = 'departamentos',
  cargos = 'cargos',
  locaisDeEntrega = 'locais-de-entrega',
  dadosDaEmpresa = 'dados-da-empresa',
  criarPedidos = 'criar-pedidos',
  editarPedidos = ':idPedido/editar-pedidos/:idOperadora/:idUnidade',
  revisarPedidos = 'revisar-pedidos',
  gestaoDeSaldo = 'DemandsV3/Index',
  contaCorrente = 'DemandsV3/Index',
  devolucoes = 'devolucoes',
  add = 'add',
  edit = 'edit/:id',
  confirmarPedidos = 'confirmacao'
}

export const OnixRouteNames = [
  '/DemandsImportV3/ImportSummary',
  '/AccountPeriods/CheckAccountPeriodEvents',
  '/Branch/GetMainScheduleBranchByAccountAndOperatorId',
  '/CurrentAccountStatement/GetBranchBalances',
  '/CurrentAccountStatement/GetDetails',
  '/CurrentAccountStatement/GetEmployeesOfMovement',
  '/CurrentAccountStatement/GetMovements',
  '/Demands/DisableAttacheedFile',
  '/Demands/EditEmployee',
  '/Demands/EditEmployeeFares',
  '/Demands/EditFares',
  '/Demands/EditRegister',
  '/Demands/GetRouteFromOperator',
  '/Demands/RemoveMultipleItemsFromDemand',
  '/Demands/GetAccountPeriod',
  '/Demands/GetEmployeesInDemandItems',
  '/Demands/GetOrdersFromDemand',
  '/DemandsV2/ApprovalDemands',
  '/DemandsV2/CanceledDemands',
  '/DemandsV2/GetApprovedDemands',
  '/DemandsV2/ValidateAccountPeriods',
  '/DemandsV3/CancelPayLot',
  '/DemandsV3/ConfirmPayLot',
  '/DemandsV3/EditEmployee',
  '/DemandsV3/GetApprovedDemands',
  '/DemandsV3/GetDemands',
  '/DemandsV3/Index',
  '/DemandsV3/GetDemandItems',
  '/DemandsV3/GetEconomicForecast',
  '/DemandsV3/RefreshDemandEconomy',
  '/DemandsV3/ValidateAndUpdateAccountPeriods',
  '/Directions/Delete',
  '/Directions/DirectionDetails',
  '/Directions/ReprocessRoterizacao',
  '/Directions/SelectRoute',
  '/Directions/UpdateAutomaticFilds',
  '/EmployeeBaseDemand/GetWsEmployeeLogs',
  '/EmployeeBaseDemandV3/GetDemandTable',
  '/EmployeeBaseDemandV3/GetTransactionDemands',
  '/EmployeeBaseDemandV3/GetTransactionDemandItems',
  '/EmployeeBaseDemandV3/GetWsEmployeeLogItems',
  '/EmployeeBaseEvent/GetEmployeeEvents',
  '/EmployeeBaseFare/GetEmployeeFaresList',
  '/EmployeeBaseV2/CreateUsers',
  '/EmployeeBaseV2/EditUsers',
  '/EmployeeBaseV2/DeleteAccessGroup',
  '/EmployeeBaseV2/DeleteDepartment',
  '/EmployeeBaseV2/DeleteEmployeeBase',
  '/EmployeeBaseV2/DeleteJobRole',
  '/EmployeeBaseV2/GetDepartments',
  '/EmployeeBaseV2/GetJobRoles',
  '/EmployeeBaseV2/GetProfiles',
  '/EmployeeBaseV2/RenderEmployeeBaseActionEdit',
  '/EmployeeBaseV3/FilterEmployees',
  '/EmployeeBaseV3/GetEmployeesBaseSearch',
  '/EmployeeBaseV3/GetProfiles',
  '/EmployeeBaseV3/GetUsersByBranchId',
  '/EmployeeBaseV3/GetWorkStationsByBranchId',
  '/EmployeeBaseV3/Index',
  '/ExtraCards/CreateByAjax',
  '/ExtraCards/GetByOperatorAndBranchId',
  '/ExpressDemands/LoadTableBenefit',
  '/Home/GetActiveBranchs',
  '/HomeV2/CheckLicenseAccount',
  '/Interactions/DisableAttacheedFile',
  '/ImportIncidents/FilterRequest',
  '/ImportIncidents/GetDataRequest',
  '/interactions/cancelIntegration',
  '/Interactions/Messages',
  '/KnowledgeBaseV3/Index',
  '/Operator/GetOperatorListThatAllowCreditStockByAccountId',
  '/ReportsV3/Index',
  '/RoutingRequest/Index',
  '/RoutingRequest/Index?isVrPortal=True',
  '/RoutingRequestImport/Import',
  '/RoutingRequest/GetAddress',
  '/RoutingRequest/GetWorkStations',
  '/RoutingRequestItem/CreateRoutingRequestItem',
  '/RoutingRequestItem/GetAddressByLatitudeAndLongitude',
  '/RoutingRequestItem/GetDistanceLimit',
  '/RoutingRequestItem/RoutingRouteItemIsEditable',
  '/RoutingRoadMapSummary/Create',
  '/RoutingRoadMapSummary/SearchEstablishment',
  '/RoutingRoadMapSummary/SearchPoints',
  '/RoutingV3/Index',
  '/RoutingV3/FilterRoutingRequests',
  '/Services/EmployeesBaseFaresByEmployeesBaseId',
  '/Services/CountClientMessages',
  '/Services/GetAllActiveOperators',
  '/Services/GetBranchs',
  '/Services/GetCalculatedComplement',
  '/Services/GetEmployeesBranch',
  '/Services/GetEmployeeById',
  '/Services/GetEmployeeCard',
  '/Services/GetOperators',
  '/Services/GetOperatorsByAccount',
  '/Services/GetOperatorByBranchIdAndServiceId',
  '/Services/GetOperatorByBranchIdWithCreditTransfer',
  '/Services/GetOperatorExtract',
  '/Services/GetOperatorFareByOperatorId',
  '/Services/GetPayLotsByBranchId',
  '/Services/GetPendingOrdersByBranchAndOperator',
  '/Services/GetUserAccounts',
  '/Services/GetCalculatedComplementByFareId',
  '/Services/GetOperatorByEmployeeBaseId',
  '/Services/Index',
  '/Services/OperatorUseVariableRate',
  '/Services/GetServicesByServiceGroup',
  '/Services/GetStatusDescription',
  '/Services/ReturnFileObject',
  '/ToolboxV3/Index',
  '/TransactionJson/SetProgressBar',
  '/UserAccount/ChangePassword',
  '/VersionNewV3/Index',
  '/VersionNewV3/GetInformativesByFilter',
  '/VersionNewV3/GetCommunicationInformative',
  '/VersionNewV3/MarkCommunicationAlertsAsRead',
  '/VersionNewV3/MarkCommunicationInformativeAsRead'
]
