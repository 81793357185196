import { QueryFunctionContext } from 'react-query'

import { endPoints } from '@/configs/endPoints'
import { BranchItems } from '@/interfaces/branches'
import { API } from '@/utils/fetcher'

export async function getAccounts(
  ctx: QueryFunctionContext<
    [string, { page: number; pageSize: number; SearchTerm: string }]
  >
): Promise<BranchItems> {
  const [, { page, pageSize, SearchTerm }] = ctx.queryKey
  const data = await API.get<BranchItems>(`${endPoints.accounts}`, {
    page,
    pageSize,
    SearchTerm
  })

  return data
}
