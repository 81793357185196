import { useMemo, useState } from 'react'

import {
  ArrowLeftIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/outline'
import { Alert, Button, Tooltip } from 'flowbite-react'

import Loader from '@/components/Loader'
import EditarPedidosErrosGrid from '@/components/pedidos/editar-pedido/erros-pedido-grid.component'
import EditarPedidosItensGrid from '@/components/pedidos/editar-pedido/itens-pedido-grid.component'
import ReprocessarPedidoModal from '@/components/pedidos/reprocessar-pedido-modal.component'
import { useEditarPedidosContext } from '@/contexts/editar-pedido.context'

const EditarPedidosContainer = () => {
  const { pedido, isLoadingPedido, handleReprocessarPedido } =
    useEditarPedidosContext()
  const [isOpen, setIsOpen] = useState(false)

  const mappedVenda = useMemo(() => {
    return pedido?.conteudo.resultados.map(item => {
      let qtdErros = 0
      let total = 0
      item.itensVenda.forEach(i => {
        qtdErros = qtdErros + i.errosItem.length
        total = total + i.total
      })

      return {
        pedidoId: item.idPedidoVenda,
        vendaId: item.id,
        unidade: item.nomeUnidade,
        operadora: item.nomeOperadora,
        tipoIdentificacao: item.tipoIdentificacaoOperadora,
        dataCriacao: item.criadoEm,
        qtdColaboradores: item.quantidadeDeCpfs,
        qtdErros: qtdErros,
        valor: total.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        }),
        agrupado: item.agrupado
      }
    })[0]
  }, [pedido])

  return (
    <>
      {isLoadingPedido ? (
        <div
          className='h-96 w-full flex justify-center items-center'
          data-testid='loader-pedido-edicao'
        >
          <Loader size={'xl'} />
        </div>
      ) : (
        <div
          className='max-w-[1600px] w-full flex mx-auto'
          data-testid='pedido-data'
        >
          <div className='grid grid-cols-12 bg-white rounded-xl w-full gap-5 md:gap-0 p-5 mx-5 mb-5 border'>
            <div className='p-3 md:col-span-12 col-span-12 text-center md:text-left'>
              <span className='text-quaternary font-semibold pb-3 text-xl'>
                {mappedVenda?.unidade} | {mappedVenda?.operadora}
              </span>
            </div>
            <div className='p-3 md:col-span-6 col-span-12 text-center md:text-left'>
              <p>
                Data de Criação:{' '}
                {new Date(mappedVenda?.dataCriacao ?? '').toLocaleDateString()}
              </p>
              <p>Quantidade Colaboradores: {mappedVenda?.qtdColaboradores}</p>
            </div>
            <div className='p-3 md:col-span-6 col-span-12 text-center md:text-left'>
              <p>
                Tipo de Identificação na Operadora:{' '}
                {mappedVenda?.tipoIdentificacao}
              </p>
              <p>Quantidade de Erros: {mappedVenda?.qtdErros}</p>
            </div>
            <div className='p-3 md:col-span-12 col-span-12 text-center md:text-left'>
              <p className='font-semibold'>Valor: {mappedVenda?.valor}</p>
            </div>
          </div>
        </div>
      )}
      {mappedVenda?.qtdErros != undefined && mappedVenda.qtdErros > 0 ? (
        <>
          <div className='m-4' data-testid='alerta-erros'>
            <Alert color='failure' className='mt-3'>
              <div className='flex justify-center'>
                <ExclamationTriangleIcon className='w-5 h-5 mr-2 text-red-600' />
                <span>
                  Atenção! Foram encontrados erros no pedido, é necessário
                  corrigi-los antes de aprova-lo.
                </span>
              </div>
            </Alert>
          </div>
          <div className='m-4'>
            <EditarPedidosErrosGrid />
          </div>
        </>
      ) : (
        <></>
      )}
      {mappedVenda?.agrupado != undefined && mappedVenda.agrupado ? (
        <>
          <div className='m-4' data-testid='alerta-agrupamento'>
            <Alert color='warning' className='mt-3'>
              <div className='flex justify-center items-center'>
                <ExclamationTriangleIcon className='w-5 h-5 mr-2' />
                <span>
                  <p>
                    Atenção! Este pedido possui itens que ultrapassaram o valor
                    máximo de compra permitido pela operadora.
                  </p>
                  <p>
                    Os itens foram divididos respeitando o valor máximo, e os
                    critérios estabelecidos por esta operadora, para dar
                    sequência na compra do pedido!
                  </p>
                </span>
              </div>
            </Alert>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className='m-4'>
        <EditarPedidosItensGrid />
      </div>
      <div className='w-full flex justify-between'>
        <div className='flex justify-between gap-2 items-left'>
          <Button
            color='light'
            className='flex gap-2 justify-center items-center'
            onClick={() => history.back()}
            data-testid='btn-voltar'
          >
            <div className='flex justify-center items-center gap-2'>
              <ArrowLeftIcon className='w-5 h-5' />
              <span>Voltar</span>
            </div>
          </Button>
        </div>
        <div className='flex justify-between gap-2 items-right'>
          <Tooltip
            content='Para as edições refletirem no pedido, ele deverá ser reprocessado'
            placement='top'
          >
            <Button
              color='success'
              className='flex gap-2 justify-center items-center bg-quaternary'
              onClick={() => setIsOpen(true)}
              data-testid='btn-reprocess'
            >
              <span>Reprocessar Pedido</span>
            </Button>
          </Tooltip>
        </div>
      </div>
      <ReprocessarPedidoModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={handleReprocessarPedido}
      />
    </>
  )
}

export default EditarPedidosContainer
