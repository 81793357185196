import { QueryFunctionContext } from 'react-query'

import { endPoints } from '../configs/endPoints'
import { API } from '../utils/fetcher'
import {
  ILocaisDeEntregaItems,
  ILocalDeEntregaDTO,
  ILocalDeEntregaPayload
} from '@/interfaces/locais-de-entrega'
import { IDadosRequestParams } from '@/interfaces/request'
import { API_PEDIDOS } from '@/utils/pedidos-fetcher'

export async function createLocaisDeEntrega(payload: ILocalDeEntregaDTO) {
  const response = await API.post<unknown>(endPoints.locaisDeEntrega, {
    ...payload
  })

  return response
}

export async function getLocaisDeEntrega(
  ctx: QueryFunctionContext<[string, { page: number; pageSize: number }]>
): Promise<ILocaisDeEntregaItems> {
  const [, { page, pageSize }] = ctx.queryKey
  const data = await API.get<ILocaisDeEntregaItems>(
    `${endPoints.locaisDeEntregaActive}`,
    {
      page,
      pageSize
    }
  )

  return data
}

export async function getLocalDeEntrega(
  ctx: QueryFunctionContext<[string, { workStationId: string }]>
): Promise<ILocalDeEntregaPayload> {
  const [, { workStationId }] = ctx.queryKey
  const data = await API.get<ILocalDeEntregaPayload>(
    `${endPoints.locaisDeEntrega}/${workStationId}`
  )

  return data
}

export async function editLocalDeEntrega(payload: ILocalDeEntregaDTO) {
  const response = await API.put<unknown>(
    `${endPoints.locaisDeEntrega}/${payload.workstationId}`,
    {
      ...payload
    }
  )
  return response
}

export async function deleteLocalDeEntrega(workStationId: string) {
  const response = await API.delete(
    `${endPoints.locaisDeEntrega}/${workStationId}`
  )
  return response
}

export async function getLocaisDeEntregaPedidos({
  params
}: {
  params: IDadosRequestParams
}) {
  const endpointLocaisDeEntrega = endPoints.locaisDeEntregaList.replace(
    '{id-unidade}',
    params.idUnidade
  )
  const response = await API_PEDIDOS.get<any>(endpointLocaisDeEntrega, {
    ...params
  })

  return response
}
