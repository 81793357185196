import { endPoints } from '@/configs/endPoints'
import { ILogin, ILoginPromise } from '@/interfaces/authentication'
import { API } from '@/utils/fetcher'

export async function login(payload: ILogin): Promise<ILoginPromise | any> {
  const response = await API.post<ILoginPromise>(endPoints.auth, {
    username: payload.username,
    password: payload.password
  })

  return response
}
