import { useMemo, useState } from 'react'

import {
  CheckBadgeIcon,
  DocumentArrowUpIcon,
  DocumentIcon
} from '@heroicons/react/24/outline'
import { Spinner } from 'flowbite-react'
import { FileWithPath, useDropzone } from 'react-dropzone'

import { formatBytes } from '@/utils/convert-bytes'

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
}

const focusedStyle = {
  borderColor: '#2196f3'
}

const acceptStyle = {
  borderColor: '#016517'
}

const rejectStyle = {
  borderColor: '#ff1744'
}

const DropZoneInput = () => {
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles
  } = useDropzone({
    accept: {
      'text/csv': []
    }
  })
  const files = useMemo(() => {
    setIsUploading(true)
    return acceptedFiles.map((file: FileWithPath) => (
      <li
        key={file.path}
        className='bg-gray-100 rounded-lg  mt-3 p-2 text-xs flex justify-between items-center gap-2'
      >
        <div className='flex gap-1 justify-center items-center'>
          <span>
            <DocumentIcon className='h-4 w-4 text-quaternary' />
          </span>
          {file.path} - {formatBytes(file.size)}
        </div>
        {isUploading ? (
          <Spinner className='h-4 w-4 text-quaternary' />
        ) : (
          <CheckBadgeIcon className='h-4 w-4 text-quaternary' />
        )}
      </li>
    ))
  }, [acceptedFiles])
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <>
      <div className='container relative'>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <DocumentArrowUpIcon className='h-8 w-8 text-quaternary' />
          <p className='text-md text-gray-600'>Arraste seu arquivo aqui</p>
          <span className='text-xs text-gray-400 mt-2'>
            Formatos permitidos: CSV, XLS, XLSX
          </span>
          <span className='text-xs text-gray-400'>Tamanho máximo: 10MB</span>
        </div>
        <ul className='list-none'>{files}</ul>
      </div>
    </>
  )
}

export default DropZoneInput
