export const endPoints = {
  accounts: '/User/Accounts',
  branches: '/User/Branches',
  notifitionItems: '/VersionNews/CountOfNews',
  userData: '/User/Profile',
  userPermissoes: '/permissoes',
  auth: '/Auth/User',
  vrIdUser: '/Auth/VrIdUser',
  userAccount: '/Auth/UserAccount',
  userBranch: '/Auth/UserBranch',
  userAllowed: '/User/AllowedToPortalNewVersion',
  cargos: '/JobRoles',
  cargosListPedidos: '/unidades/{id-unidade}/cargos',
  cargosList: '/JobRoles/WithActiveEmployeesAndFares',
  departamentos: '/Departments',
  departamentosList: '/Departments/WithActiveEmployeesAndFares',
  economiaAceite: '/economia/aceite',
  locaisDeEntrega: '/WorkStations',
  locaisDeEntregaActive: '/WorkStations/WithActiveEmployees',
  locaisDeEntregaList: '/unidades/{id-unidade}/locais-entrega',
  trabalhadores: '/listar-trabalhadores',
  contrato: '/listar-contratos',
  getContratoAceite: '/contratos/{id-empregador}/gestoes-saldo',
  aceiteGestao: '/contratos/{id-empregador}/gestoes-saldo/aceite',
  beneficiarios: '',
  dadosBancariosBeneficiario:
    '/pedidos/{id-pedido-venda}/vendas/{id-venda}/itens-venda/{id-item-venda}/dados-bancarios',
  beneficiariosList: '',
  cep: '/Address',
  news: '/VersionNews/HighlightedNews',
  blip: '/BlipChat/UserCredentials',
  authFromMicrosservice: '/ApiAuth/AuthenticateFromMicroservice',
  logoutExternal: '/Authentication/LogoutExternal',
  loginWithMicroserviceToken: '/ApiAuth/LoginWithMicroserviceToken',
  logisticList: '/LogisticsDetails',
  logistic: '/Logistics',
  pedidosList: '/listar-pedidos-aprovados',
  pedidosPendentesList: '/listar-pedidos-pendentes',
  pedidoDetalheItemsList: '/pedidos/detalhe/items-list',
  pedidoDetalheItemsListDetalhe: '/pedidos/detalhe/items-list-detalhe',
  getDevolucoesItemPedidoList: '/devolucoes',
  getDevolucoesList: '/devolucoes/listar-detalhes',
  tratarDevolucao: '/devolucoes',
  pedidosRevisaoList: '/listar-pedidos',
  pedidosRevisaoOperadorasList: '/pedidos/{id-pedido-venda}/operadoras',
  pedidosRevisaoEconomiaUnidadesList: '/pedidos/revisao/economia/unidades/list',
  logisticItem: '/LogisticsDetails',
  logisticStatusList: '/Logistics/Status',
  createPedido: '/pedidos',
  detalhePedido: '/pedidos/{id-pedido-venda}',
  departamentosListBusca: '/unidades/{id-unidade}/departamentos',
  unidadesAtivas: '/empregadores/{id-empregador}/unidades/ativas',
  pedidoDetalhe: '/pedidos/{id-pedido-venda}/detalhes',
  errosPedido: '/pedidos/listar-erros',
  revisarPedido: '/pedidos/aprovacoes',
  editarPedido: '/pedidos',
  cancelarPedido: '/pedidos/{id-pedido-venda}',
  reprocessarPedido: '/pedidos/reprocessamentos',
  processarPedido: '/pedidos/processamentos',
  arquivoPedidoEmRevisao: '/pedido',
  pendenciaslist: '/pedidos/{id-pedido-venda}/listar-vendas',
  vendasList: '/vendas',
  pedidoEdicao: '/pedidos/{id-pedido-venda}/listar-vendas',
  vendas: '/pedidos/{id-pedido-venda}/listar-vendas',
  pedidoEdicaoErros: '/pedido/editar/erros',
  pedidoEdicaoItens: '/pedidos/{id-pedido-venda}/vendas/detalhes',
  editarItens: '/pedidos/{id-pedido-venda}/vendas/{id-venda}/itens-venda',
  pedidoPendencias: '/pedido/pendencias',
  arquivoPendencias: '/pedido/pendencias',
  pedidoRepasses: '/pedidos/{id-pedido-venda}/taxas',
  pedidoCobrancas: '/pedido/cobrancas',
  pedidoLancamentos: '/pedidos/{id-pedido-venda}/lancamentos-financeiros',
  pedidoTarifas: '/pedido/tarifas',
  pedidoDescontos: '/pedido/descontos',
  pedidoLista: '/listar-pedidos',
  logsDevolucoes: '/devolucoes/logs',
  comprovanteDevolucao: '/devolucoes/comprovantes',
  downloadDevolucoesReport: '/pedidos/devolucoes/download',
  exportarBoleto: '/boletos',
  configuracoesCreditoCerto: '/listar-creditos-certos',
  exportarNotaFiscal: '/notas-fiscais/download'
}
