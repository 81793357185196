import {
  Dispatch,
  SetStateAction,
  useState,
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useEffect
} from 'react'

import { FormikProps, useFormik } from 'formik'
import Cookie from 'js-cookie'
import { useSearchParams } from 'react-router-dom'

import toastService from '@/components/toast/toast-service'
import Constants from '@/configs/constants'
import {
  useCancelarPedidoMutation,
  useEditarPedidoMutation,
  useReprocessarPedidoMutation,
  useRevisarPedidoMutation
} from '@/hooks/mutations/use-pedidos-mutation'
import {
  useGetAceiteGestao,
  useGetContratos
} from '@/hooks/queries/use-get-contratos'
import {
  useGetEconomiaUnidades,
  useGetLancamentos,
  useGetRepasses,
  useGetRevisaoPedidos,
  useGetVendas
} from '@/hooks/queries/use-get-pedidos'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import {
  IAceiteGestaoInfoResultado,
  IContratoList
} from '@/interfaces/contrato'
import {
  ILancamento,
  IRepasse,
  IRevisaoEconomiaPedidoUnidade,
  IRevisaoPedido,
  IVendaResultado
} from '@/interfaces/pedidos'
import { getArquivoPedidoEmRevisao } from '@/services/pedidos'
import { appendCurrentTime } from '@/utils'
import { exportToXls } from '@/utils/export-to-xls'

interface RevisarPedidoContextProps {
  pedidoId: string
  setPedidoId: Dispatch<SetStateAction<string>>
  unidade: string
  setUnidade: Dispatch<SetStateAction<string>>
  unidadeId: string
  setUnidadeId: Dispatch<SetStateAction<string>>
  pedidosList: IRevisaoPedido[] | undefined
  isLoadingPedidos: boolean
  isFetchingPedidos: boolean
  pedidoOperadorasList: IVendaResultado | undefined
  isLoadingPedidoOperadoras: boolean
  isDrawerOpen: boolean
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
  formik: FormikProps<IFormProps>
  handleCancelarPedidos: () => void
  handleReprocessarPedido: () => void
  handleRevisarPedido: () => void
  handleExportarPedido: () => Promise<any>
  isRepassesModalOpen: boolean
  setIsRepassesModalOpen: Dispatch<SetStateAction<boolean>>
  isTarifasModalOpen: boolean
  setIsTarifasModalOpen: Dispatch<SetStateAction<boolean>>
  repassesList: IRepasse | undefined
  isLoadingRepasses: boolean
  setRepassesPage: Dispatch<SetStateAction<number>>
  repassesTotalPages: number
  repassesPageSize: number
  setRepassesPageSize: Dispatch<SetStateAction<number>>
  handleOpenRepassesModal: (
    pedidoOpcaoId: string,
    tipoTaxaOpcao: string,
    vendaOpcaoId?: string
  ) => void
  isLancamentosModalOpen: boolean
  setIsLancamentosModalOpen: Dispatch<SetStateAction<boolean>>
  lancamentosList: ILancamento | undefined
  isLoadingLancamentos: boolean
  setLancamentosPage: Dispatch<SetStateAction<number>>
  lancamentosTotalPages: number
  lancamentosPageSize: number
  setLancamentosPageSize: Dispatch<SetStateAction<number>>
  handleOpenLancamentosModal: (
    pedidoOpcaoId: string,
    tipoLancamento: number
  ) => void
  setSearch: Dispatch<SetStateAction<string>>
  lancamentosTipo: number
  ignorarErros: boolean
  setIgnorarErros: Dispatch<SetStateAction<boolean>>
  unidadesList: IRevisaoEconomiaPedidoUnidade[] | undefined
  isLoadingUnidades: boolean
  checkedUnidades: { [key: string]: boolean }
  setCheckedUnidades: Dispatch<SetStateAction<{ [key: string]: boolean }>>
  handleExportEconomia: () => void
  isReprocessarOpen: boolean
  setIsReprocessarOpen: Dispatch<SetStateAction<boolean>>
  podeAprovar: boolean
  handlePodeAprovar: (valor: boolean) => void
  isSubmitingPedido: boolean
  isReprocessandoPedido: boolean
  isLoadingContratos: boolean
  contratos: IContratoList
  handleUtilizarEconomiaPedido: (
    pedidoAlterado: IRevisaoPedido,
    utilizaEconomia: boolean
  ) => void
  aceiteGestao: IAceiteGestaoInfoResultado
  setEconomiaVisivel: Dispatch<SetStateAction<boolean>>
  economiaVisivel: boolean
  handlePrevisaoPagamentoChange: (novaPrevisao: string) => void
  handlePrevisaoDisponibilidadeChange: (novaDisponibilidade: string) => void
  handleMouseOverPrevisaoDisponibilidade: () => void
  getPagMinDate: () => string
  getMinDisponibilidadeDate: () => string
  handleUtilizarEconomiaPedidoFlex: (
    pedidoAlterado: IRevisaoPedido,
    utilizaEconomia: boolean
  ) => void

  handleChangeEconomiaTipoFlex: (
    pedidoAlterado: IRevisaoPedido,
    utilizaEconomia: boolean
  ) => void

  handleEconomiaAlterada: (
    pedidoAlteradoId: string,
    utilizaEconomia: boolean
  ) => void

  handleClose: () => void
  handleBeforeExit: () => void
  isOnboardingFinishedOrderReview: boolean
  isOnboardingOpen: boolean
}

interface IFormProps {
  idsPedidoVenda: string[]
  periodoUtilizacaoInicial: string
  periodoUtilizacaoFinal: string
  previsaoPagamento: string
  previsaoDisponibilidade: string
  tipoPedidoVenda: string
  ignoraErros: boolean
}

const RevisarPedidosContext = createContext<
  RevisarPedidoContextProps | undefined
>(undefined)

const RevisarPedidosProvider = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams()
  const criadoEm = searchParams.get('data')
  const { data: userData } = useGetUserData()
  const [pedidoId, setPedidoId] = useState<string>('')
  const [vendaId, setVendaId] = useState<string | undefined>()
  const [unidadeId, setUnidadeId] = useState<string>('')
  const [unidade, setUnidade] = useState<string>('')
  const [checkedUnidades, setCheckedUnidades] = useState<{
    [key: string]: boolean
  }>({})
  const [ignorarErros, setIgnorarErros] = useState<boolean>(false)
  const [podeAprovar, setPodeAprovar] = useState<boolean>(true)
  const [economiaVisivel, setEconomiaVisivel] = useState<boolean>(true)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [isReprocessarOpen, setIsReprocessarOpen] = useState<boolean>(false)
  const [isRepassesModalOpen, setIsRepassesModalOpen] = useState<boolean>(false)
  const [isTarifasModalOpen, setIsTarifasModalOpen] = useState<boolean>(false)
  const [isLancamentosModalOpen, setIsLancamentosModalOpen] =
    useState<boolean>(false)
  const { mutate: cancelarPedido } = useCancelarPedidoMutation()
  const { mutate: revisarPedido, isLoading: isSubmitingPedido } =
    useRevisarPedidoMutation(criadoEm ?? '')
  const { mutate: reprocessarPedido, isLoading: isReprocessandoPedido } =
    useReprocessarPedidoMutation()
  const [acao, setAcao] = useState<'Revisao' | 'Reprocessamento' | ''>('')
  const { mutateAsync: editarPedido } = useEditarPedidoMutation()
  const {
    data: pedidosList,
    isLoading: isLoadingPedidos,
    isFetching: isFetchingPedidos
  } = useGetRevisaoPedidos({
    params: {
      idEmpregador: userData?.authenticatedAccountId ?? '',
      dataInicio: criadoEm ?? '',
      dataFim: criadoEm ?? '',
      inicio: 0,
      comprimento: 1000,
      campo: 'CriadoEm',
      direcao: 'desc'
    }
  })

  const { data: pedidoOperadorasList, isLoading: isLoadingPedidoOperadoras } =
    useGetVendas({
      params: {
        pedidoId,
        unidadeId: unidadeId,
        inicio: 0,
        comprimento: 1000,
        situacoesItem: ['Ativo', 'Erro'],
        agrupar: true
      }
    })

  const { data: contratos, isLoading: isLoadingContratos } = useGetContratos({
    params: {
      idEmpregador: userData?.authenticatedAccountId || '',
      idUnidades: userData?.branches.map(b => b.branchId)
    }
  })

  const { data: aceiteGestao } = useGetAceiteGestao({
    params: {
      idEmpregador: userData?.authenticatedAccountId || ''
    }
  })

  const [economias, setEconomias] = useState<
    { idPedido: string; utilizaEconomia: boolean }[]
  >([])
  const [economiaAlterada, setEconomiaAlterada] = useState<string[]>([])

  const [search, setSearch] = useState('')
  const [taxasTipo, setTaxasTipo] = useState<string>('')
  const [repassesPageSize, setRepassesPageSize] = useState<number>(1000)
  const [repassesPage, setRepassesPage] = useState<number>(1)
  const repassesInicio = (repassesPage - 1) * repassesPageSize
  const { data: repassesList, isLoading: isLoadingRepasses } = useGetRepasses({
    params: {
      pedidoId: pedidoId,
      vendaId: vendaId,
      inicio: repassesInicio,
      comprimento: repassesPageSize,
      procurar: search,
      tipo: taxasTipo
    }
  })
  const repassesTotalPages = Math.ceil(
    (repassesList?.conteudo?.quantidadeDeItems ?? 0) / repassesPageSize
  )

  const [lancamentosTipo, setLancamentosTipo] = useState<number>(0)
  const [lancamentosPageSize, setLancamentosPageSize] = useState<number>(10)
  const [lancamentosPage, setLancamentosPage] = useState<number>(1)
  const lancamentosInicio = (lancamentosPage - 1) * lancamentosPageSize
  const { data: lancamentosList, isLoading: isLoadingLancamentos } =
    useGetLancamentos({
      params: {
        pedidoId,
        procurar: search,
        inicio: lancamentosInicio,
        comprimento: lancamentosPageSize,
        tipoLancamentoFinanceiro: lancamentosTipo
      }
    })
  const lancamentosTotalPages = Math.ceil(
    (lancamentosList?.conteudo?.quantidadeDeItems ?? 0) / lancamentosPageSize
  )

  const formik = useFormik({
    initialValues: {
      idsPedidoVenda: pedidosList?.map(p => p.pedidoId),
      previsaoDisponibilidade: new Date().toISOString().split('T')[0],
      previsaoPagamento: new Date().toISOString().split('T')[0],
      periodoUtilizacaoInicial: new Date().toISOString().split('T')[0],
      periodoUtilizacaoFinal: new Date().toISOString().split('T')[0],
      tipoPedidoVenda: '',
      ignoraErros: ignorarErros
    } as IFormProps,
    onSubmit: values => {
      const DTO = {
        periodoUtilizacaoInicial: appendCurrentTime(
          values.periodoUtilizacaoInicial
        ),
        periodoUtilizacaoFinal: appendCurrentTime(
          values.periodoUtilizacaoFinal
        ),
        previsaoDisponibilidade: appendCurrentTime(
          values.previsaoDisponibilidade
        ),
        previsaoPagamento: appendCurrentTime(values.previsaoPagamento),
        tipoPedidoVenda: values.tipoPedidoVenda,
        ids: pedidosList?.map(p => p.pedidoId),
        atualizadoPor: userData?.userId,
        utilizaEconomia: values.tipoPedidoVenda !== 'Mensal' ? false : undefined
      }
      editarPedido(DTO).then(() => {
        if (acao === 'Reprocessamento') {
          const DTO = {
            idsPedidoVenda: pedidosList?.map(p => p.pedidoId),
            criadoPor: userData?.userId,
            idEmpregador: userData?.authenticatedAccountId,
            errosIgnorados: ignorarErros,
            idAplicacao: process.env.VITE_APP_APP_ID
          }
          reprocessarPedido(DTO)
        }
        if (acao === 'Revisao') {
          const DTO = {
            idsPedidoVenda: pedidosList?.map(p => p.pedidoId)
          }
          revisarPedido(DTO)
        }
        setAcao('')
      })
    }
  })

  const { data: unidadesList, isLoading: isLoadingUnidades } =
    useGetEconomiaUnidades({
      params: {
        pedidoId,
        previsaoDisponibilidade:
          formik?.values?.previsaoDisponibilidade ??
          new Date().toISOString().split('T')[0]
      }
    })

  const handleRevisarPedido = () => {
    const dataPagamento = new Date(formik.values.previsaoPagamento)
    const dataDisponibilidade = new Date(formik.values.previsaoDisponibilidade)
    const dataAtual = new Date()
    let possuiErro = false

    if (dataPagamento < dataAtual) {
      toastService.error(
        'Data de Previsão de Pagamento deve ser maior que a data de hoje'
      )
      possuiErro = true
    } else if (
      contratos?.conteudo?.find(c => c.idUnidade === null)?.modalidade ===
      'PrePago'
    ) {
      if (dataDisponibilidade < dataPagamento) {
        toastService.error(
          'Data de previsão de Disponibilização deve ser maior que a data de pagamento'
        )
        possuiErro = true
      }
      if (
        (dataDisponibilidade.getTime() - dataPagamento.getTime()) /
          (1000 * 60 * 60 * 24) <
        7
      ) {
        toastService.error(
          'Data de Disponibilidade deve ser pelo menos 7 dias após a data de pagamento'
        )
        possuiErro = true
      }
    }
    if (!possuiErro) {
      setAcao('Revisao')
      formik.submitForm()
    }
  }

  const handleReprocessarPedido = () => {
    setAcao('Reprocessamento')
    formik.submitForm()
  }

  const handleUtilizarEconomiaPedido = (
    pedidoAlterado: IRevisaoPedido,
    utilizaEconomia: boolean
  ) => {
    setPodeAprovar(false)
    const DTO = {
      ids: [pedidoAlterado.pedidoId],
      atualizadoPor: userData?.userId,
      tipoSituacaoPedidoVenda: 'Alterado',
      utilizaEconomia: utilizaEconomia
    }
    editarPedido(DTO)
  }

  const handleCancelarPedidos = () => {
    pedidosList?.forEach(pedido => {
      const DTO = {
        id: pedido.pedidoId,
        canceladoPor: userData?.userId
      }
      cancelarPedido(DTO)
    })
  }

  const handleExportarPedido = () => {
    const arquivo = getArquivoPedidoEmRevisao(pedidoId)
    return arquivo
  }

  const handleOpenRepassesModal = (
    pedidoOpcaoId: string,
    tipoTaxaOpcao: string,
    vendaOpcaoId?: string
  ) => {
    setSearch('')
    setPedidoId(pedidoOpcaoId)
    setTaxasTipo(tipoTaxaOpcao)
    setVendaId(vendaOpcaoId)
    if (tipoTaxaOpcao === 'Repasse') setIsRepassesModalOpen(true)
    else setIsTarifasModalOpen(true)
  }

  const handleOpenLancamentosModal = (
    pedidoOpcaoId: string,
    tipoLancamento: number
  ) => {
    setSearch('')
    setPedidoId(pedidoOpcaoId)
    setLancamentosTipo(tipoLancamento)
    setIsLancamentosModalOpen(true)
  }

  const handleExportEconomia = () => {
    if (unidadesList !== undefined) {
      const unidadesMapeadas = unidadesList.map(u => {
        const unidade = u.unidade
        const totalItens = u.totalItens
        const totalEconomia = u.totalEconomia
        const valor = u.valor
        return {
          Unidade: unidade,
          Total_Itens: totalItens,
          Total_Economia: totalEconomia,
          Valor: valor
        }
      })
      exportToXls(unidadesMapeadas, 'Credito_Certo_previsao')
    }
  }

  const handlePodeAprovar = (valor: boolean) => {
    setPodeAprovar(valor)
  }

  const handlePrevisaoPagamentoChange = (novaPrevisao: string) => {
    formik.setFieldValue('previsaoPagamento', novaPrevisao)

    const novaPrevisaoDisponibilidade = new Date(novaPrevisao)
    novaPrevisaoDisponibilidade.setDate(
      novaPrevisaoDisponibilidade.getDate() + 7
    )
    formik.setFieldValue(
      'previsaoDisponibilidade',
      novaPrevisaoDisponibilidade.toISOString().split('T')[0]
    )
    handlePodeAprovar(false)
  }

  const handlePrevisaoDisponibilidadeChange = (novaDisponibilidade: string) => {
    formik.setFieldValue('previsaoDisponibilidade', novaDisponibilidade)
    handlePodeAprovar(false)
  }

  const handleMouseOverPrevisaoDisponibilidade = () => {
    const novaPrevisao = formik.values.previsaoDisponibilidade
    const minDate = getMinDisponibilidadeDate()

    if (new Date(novaPrevisao) < new Date(minDate)) {
      formik.setFieldValue('previsaoDisponibilidade', minDate)
    }
  }

  const getPagMinDate = () => {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    return tomorrow.toISOString().split('T')[0]
  }

  const getMinDisponibilidadeDate = () => {
    const today = new Date()
    today.setDate(today.getDate() + 7)
    return today.toISOString().split('T')[0]
  }

  const handleUtilizarEconomiaPedidoFlex = (
    pedidoAlterado: IRevisaoPedido,
    utilizaEconomia: boolean
  ) => {
    setPodeAprovar(false)
    const DTO = {
      ids: [pedidoAlterado.pedidoId],
      atualizadoPor: userData?.userId,
      tipoSituacaoPedidoVenda: 'Alterado',
      utilizaEconomia: utilizaEconomia
    }
    editarPedido(DTO)
  }

  const handleChangeEconomiaTipoFlex = (pedidoAlteradoId, utilizaEconomia) => {
    const economiasAtualizadas = economias.map(economia => {
      if (economia.idPedido === pedidoAlteradoId) {
        return { ...economia, utilizaEconomia: utilizaEconomia }
      }
      return economia
    })
    setEconomias(economiasAtualizadas)
    handleEconomiaAlterada(pedidoAlteradoId, utilizaEconomia)
  }

  const handleEconomiaAlterada = (
    pedidoAlteradoId: string,
    utilizaEconomia: boolean
  ) => {
    const economiaAlteradaAtual = []
    economiaAlterada.forEach(economia => {
      if (economia !== pedidoAlteradoId) economiaAlteradaAtual.push(economia)
    })
    if (utilizaEconomia) economiaAlteradaAtual.push(pedidoAlteradoId)
    setEconomiaAlterada(economiaAlteradaAtual)
  }

  const isOnboardingFinishedOrderReview = Cookie.get(
    Constants.iS_ONBOARDING_FINISHED_ORDER_REVIEW
  )
    ? true
    : false

  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false)

  const handleBeforeExit = () => {
    setIsOnboardingOpen(false)
  }

  const handleClose = () => {
    setIsOnboardingOpen(false)
  }

  useEffect(() => {
    if (!isOnboardingFinishedOrderReview && !isLoadingPedidos) {
      setIsOnboardingOpen(true)
    }

    return () => {
      setIsOnboardingOpen(false)
    }
  }, [isOnboardingFinishedOrderReview, isLoadingPedidos])

  const contextValue = useMemo(
    () => ({
      isReprocessandoPedido,
      isFetchingPedidos,
      isSubmitingPedido,
      pedidoId,
      setPedidoId,
      unidade,
      setUnidade,
      unidadeId,
      setUnidadeId,
      pedidosList,
      isLoadingPedidos,
      pedidoOperadorasList,
      isLoadingPedidoOperadoras,
      formik,
      isDrawerOpen,
      setIsDrawerOpen,
      handleCancelarPedidos,
      handleReprocessarPedido,
      handleRevisarPedido,
      handleExportarPedido,
      isRepassesModalOpen,
      setIsRepassesModalOpen,
      isTarifasModalOpen,
      setIsTarifasModalOpen,
      repassesList,
      isLoadingRepasses,
      setRepassesPage,
      repassesTotalPages,
      repassesPageSize,
      setRepassesPageSize,
      handleOpenRepassesModal,
      isLancamentosModalOpen,
      setIsLancamentosModalOpen,
      lancamentosList,
      isLoadingLancamentos,
      setLancamentosPage,
      lancamentosTotalPages,
      lancamentosPageSize,
      setLancamentosPageSize,
      handleOpenLancamentosModal,
      setSearch,
      lancamentosTipo,
      ignorarErros,
      setIgnorarErros,
      unidadesList,
      isLoadingUnidades,
      checkedUnidades,
      setCheckedUnidades,
      handleExportEconomia,
      isReprocessarOpen,
      setIsReprocessarOpen,
      podeAprovar,
      handlePodeAprovar,
      isLoadingContratos,
      contratos,
      handleUtilizarEconomiaPedido,
      aceiteGestao,
      setEconomiaVisivel,
      economiaVisivel,
      handlePrevisaoPagamentoChange,
      getPagMinDate,
      handlePrevisaoDisponibilidadeChange,
      handleMouseOverPrevisaoDisponibilidade,
      getMinDisponibilidadeDate,
      handleUtilizarEconomiaPedidoFlex,
      handleChangeEconomiaTipoFlex,
      handleEconomiaAlterada,
      isOnboardingOpen,
      handleBeforeExit,
      handleClose,
      isOnboardingFinishedOrderReview
    }),
    [
      isFetchingPedidos,
      isReprocessandoPedido,
      isSubmitingPedido,
      pedidoId,
      setPedidoId,
      unidade,
      setUnidade,
      unidadeId,
      setUnidadeId,
      pedidosList,
      isLoadingPedidos,
      pedidoOperadorasList,
      isLoadingPedidoOperadoras,
      formik,
      isDrawerOpen,
      setIsDrawerOpen,
      handleCancelarPedidos,
      handleReprocessarPedido,
      handleRevisarPedido,
      handleExportarPedido,
      isRepassesModalOpen,
      setIsRepassesModalOpen,
      isTarifasModalOpen,
      setIsTarifasModalOpen,
      repassesList,
      isLoadingRepasses,
      setRepassesPage,
      repassesTotalPages,
      repassesPageSize,
      setRepassesPageSize,
      handleOpenRepassesModal,
      isLancamentosModalOpen,
      setIsLancamentosModalOpen,
      lancamentosList,
      isLoadingLancamentos,
      setLancamentosPage,
      lancamentosTotalPages,
      lancamentosPageSize,
      setLancamentosPageSize,
      handleOpenLancamentosModal,
      setSearch,
      lancamentosTipo,
      ignorarErros,
      setIgnorarErros,
      unidadesList,
      isLoadingUnidades,
      checkedUnidades,
      setCheckedUnidades,
      handleExportEconomia,
      isReprocessarOpen,
      setIsReprocessarOpen,
      podeAprovar,
      handlePodeAprovar,
      isLoadingContratos,
      contratos,
      handleUtilizarEconomiaPedido,
      aceiteGestao,
      setEconomiaVisivel,
      economiaVisivel,
      handlePrevisaoPagamentoChange,
      getPagMinDate,
      handlePrevisaoDisponibilidadeChange,
      handleMouseOverPrevisaoDisponibilidade,
      getMinDisponibilidadeDate,
      handleUtilizarEconomiaPedidoFlex,
      handleChangeEconomiaTipoFlex,
      handleEconomiaAlterada,
      isOnboardingOpen,
      handleBeforeExit,
      handleClose,
      isOnboardingFinishedOrderReview
    ]
  )

  return (
    <RevisarPedidosContext.Provider value={contextValue}>
      {children}
    </RevisarPedidosContext.Provider>
  )
}

const useRevisarPedidosContext = () => {
  const context = useContext(RevisarPedidosContext)
  if (context === undefined) {
    throw new Error(
      'useRevisarPedidosContext must be used within a RevisarPedidosProvider'
    )
  }
  return context
}

export { RevisarPedidosProvider, useRevisarPedidosContext }
