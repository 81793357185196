import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Label, Popover } from 'flowbite-react'
import { FormikProps } from 'formik'

import CustomTextInput from '@/components/basic/input-text/input-text.component'
import CustomSelect from '@/components/basic/select/select.component'
import { Skeleton } from '@/components/ui/skeleton'
import { useGetContratos } from '@/hooks/queries/use-get-contratos'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { IPedidoForm } from '@/interfaces/pedidos'

interface ICriarPedidoProps {
  children?: React.ReactNode
  formik: FormikProps<IPedidoForm>
}

const CriarPedidoForm = ({ children, formik }: ICriarPedidoProps) => {
  const { data: userData } = useGetUserData()

  function getPagMinDate() {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    return tomorrow.toISOString().split('T')[0]
  }

  const { data: contratos, isLoading: isLoadingContratos } = useGetContratos({
    params: {
      idEmpregador: userData?.authenticatedAccountId || '',
      idUnidades: []
    }
  })

  function getMinDisponibilidadeDate() {
    if (
      contratos?.conteudo?.find(c => c.idUnidade === null)?.modalidade ===
      'Prepago'
    ) {
      const previsaoPagamento = formik.getFieldProps('previsaoPagamento').value
      if (previsaoPagamento != undefined) {
        const pagamentoDate = new Date(previsaoPagamento)
        pagamentoDate.setDate(pagamentoDate.getDate() + 7)
        return pagamentoDate.toISOString().split('T')[0]
      }
    }
    const todayDate = new Date()
    todayDate.setDate(todayDate.getDate() + 7)
    return todayDate.toISOString().split('T')[0]
  }

  function handlePrevisaoDisponibilidade(novaPrevisao: string) {
    const novaPrevisaoDate = new Date(novaPrevisao)
    formik.setFieldValue(
      'previsaoPagamento',
      novaPrevisaoDate.toISOString().split('T')[0]
    )
    if (
      contratos?.conteudo?.find(c => c.idUnidade === null)?.modalidade ===
      'Prepago'
    ) {
      const disponibilidadeDate = new Date(
        formik.getFieldProps('previsaoDisponibilidade').value
      )
      novaPrevisaoDate.setDate(novaPrevisaoDate.getDate() + 7)

      if (novaPrevisaoDate > disponibilidadeDate) {
        formik.setFieldValue(
          'previsaoDisponibilidade',
          novaPrevisaoDate.toISOString().split('T')[0]
        )
      }
    }
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className='flex flex-col md:grid grid-cols-12 w-full p-3 bg-white gap-5 mb-5 rounded-lg'
    >
      {isLoadingContratos ? (
        <div className='grid grid-cols-12 col-span-12 w-full gap-3'>
          {Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className='col-span-3'>
              <Skeleton className='w-full h-12' />
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className='col-span-3'>
            <Label htmlFor='periodoUtilizacaoInicial'>
              Data de Início Utilização *
            </Label>
            <CustomTextInput
              type='date'
              field='periodoUtilizacaoInicial'
              // min={getPagMinDate()}
              formik={formik}
              required
            />
          </div>
          <div className='col-span-3'>
            <CustomTextInput
              type='date'
              field='periodoUtilizacaoFinal'
              formik={formik}
              disabled={!formik.values.periodoUtilizacaoInicial}
              min={formik.values.periodoUtilizacaoInicial || getPagMinDate()}
              label='Data de Fim Utilização *'
              required
            />
          </div>
          <div className='col-span-3'>
            <Label
              htmlFor='previsaoPagamento'
              className='flex pb-1 gap-1 justify-start items-center'
            >
              <span>Data Prev. para pagamento</span>
              <Popover
                trigger='hover'
                content={
                  <div className='w-64 text-xs text-gray-800 dark:text-gray-400'>
                    <div className='px-3 py-2'>
                      <p>
                        Ao Informar a data prevista para pagamento, leve em
                        conta que a disponibilização só ocorrerá após a
                        confirmação do pagamento respeitando o prazo mínimo para
                        o produto.
                      </p>
                      <ul className='list-disc ml-5'>
                        <li>Créditos eletrônicos até 5 dias úteis;</li>
                        <li>
                          Entregas de bilhetes ou cartões até 10 dias úteis.
                        </li>
                      </ul>
                    </div>
                  </div>
                }
                placement='top'
              >
                <InformationCircleIcon className='h-4 w-4 text-primary' />
              </Popover>
            </Label>
            <CustomTextInput
              type='date'
              field='previsaoPagamento'
              formik={formik}
              min={getPagMinDate()}
              onChange={value =>
                handlePrevisaoDisponibilidade(value.target.value)
              }
              required
            />
          </div>
          <div className='col-span-3'>
            <Label
              htmlFor='previsaoDisponibilidade'
              className='flex pb-1 gap-1 justify-start items-center'
            >
              <span>Data Prev. Disponibilização</span>
              <Popover
                trigger='hover'
                content={
                  <div className='w-64 text-xs text-gray-800 dark:text-gray-400'>
                    <div className='px-3 py-2'>
                      <p>
                        Ao Informar a data prevista para disponibilização,
                        acataremos a data preenchida, desde que essa respeite o
                        prazo mínimo para o produto. Se o prazo inserido for
                        igual a 5 dias úteis e houver entrega de bilhetes e
                        cartões, esses serão entregues em até 10 dias uteis.
                      </p>
                    </div>
                  </div>
                }
                placement='top'
              >
                <InformationCircleIcon className='h-4 w-4 text-primary' />
              </Popover>
            </Label>
            <CustomTextInput
              type='date'
              field='previsaoDisponibilidade'
              formik={formik}
              required
              min={getMinDisponibilidadeDate()}
            />
          </div>
          <div className='col-span-4'>
            <CustomSelect
              options={[
                {
                  label: 'Mensal',
                  value: 'Mensal'
                },
                {
                  label: 'Complementar',
                  value: 'Completo'
                },
                {
                  label: 'Admitidos',
                  value: 'Admitidos'
                },
                {
                  label: 'Reembolso',
                  value: 'Reembolso'
                }
              ]}
              field='tipoPedidoVenda'
              formik={formik}
              label='Tipo de Pedido de Venda *'
              required
            />
          </div>
        </>
      )}
      <div
        className={`${isLoadingContratos && 'pointer-events-none'} col-span-12`}
      >
        {children}
      </div>
    </form>
  )
}

export default CriarPedidoForm
