import { UseQueryResult, useQuery } from 'react-query'

import { QueryKey } from '@/configs/queryKeys'
import {
  IDevolucoesPedido,
  IDevolucoesPedidoParams,
  IDevolucoesResultado
} from '@/interfaces/devolucoes'
import {
  downloadDevolucoesReport,
  getDevolucoesItemPedidoList,
  getDevolucoesList
} from '@/services/devolucoes'

export function useGetDevolucoesItemPedidoList({
  params
}: {
  params: IDevolucoesPedidoParams
}): UseQueryResult<IDevolucoesPedido> {
  return useQuery(
    [QueryKey.pedidoItemListDetail, { params }],
    getDevolucoesItemPedidoList,
    {
      keepPreviousData: true,
      retry: false,
      enabled: !!params.idPedidoVenda
    }
  )
}

export function useGetDevolucoesList({
  params
}: {
  params: {
    inicio: number
    comprimento: number
    procurar?: string
    idEmpregador: string
    dataInicio?: string
    dataFim?: string
    campo: string
    direcao: 'asc' | 'desc'
    situacao?: string
    numeroPedido?: string
    cpf?: string
    idsUnidades: string[]
    tipoDevolucao?: string
  }
}): UseQueryResult<IDevolucoesResultado> {
  return useQuery([QueryKey.devolucoesList, { params }], getDevolucoesList, {
    keepPreviousData: true,
    retry: true,
    enabled: !!params.idEmpregador
  })
}

export const useDownloadDevolucoesReport = ({
  id
}: {
  id: string
}): UseQueryResult<{ url: string }> => {
  return useQuery(
    [QueryKey.downloadDevolucoesReport, { id }],
    downloadDevolucoesReport,
    {
      retry: false,
      enabled: false,
      initialData: {
        url: 'https://www.stats.govt.nz/assets/Uploads/Annual-enterprise-survey/Annual-enterprise-survey-2023-financial-year-provisional/Download-data/annual-enterprise-survey-2023-financial-year-provisional.csv'
      }
    }
  )
}
