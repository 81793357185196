import { useMemo } from 'react'

import { Button, Modal } from 'flowbite-react'

import TableItemPedidos from '@/components/Table/table-item-pedidos.component'
import { useDevolucoesContext } from '@/contexts/devolucoes.context'
import { TABLE_HEADERS_LOGS_DEVOLUCOES } from '@/pages/Pedidos/constants'

interface LogsDevolucaoModalProps {
  isOpen: boolean | false
  onClose: any
}

export default function LogsDevolucaoModal({
  isOpen,
  onClose
}: LogsDevolucaoModalProps) {
  const { logsDevolucao } = useDevolucoesContext()

  const mappedLogs = useMemo(() => {
    return logsDevolucao?.map(item => {
      return {
        ...item,
        data: new Date(item.criadoEm).toLocaleDateString()
      }
    })
  }, [logsDevolucao])

  return (
    <Modal show={isOpen} size={'5xl'} onClose={onClose} popup>
      <Modal.Header>
        <p className='text-center font-semibold p-3 text-quaternary text-2xl'>
          Acompanhamento da devolução
        </p>
      </Modal.Header>
      <Modal.Body>
        <div className='text-center'>
          <div className='border border-gray-200 rounded-lg my-3'>
            <TableItemPedidos
              isActions={false}
              isRowClicable={false}
              data={mappedLogs || []}
              headers={TABLE_HEADERS_LOGS_DEVOLUCOES}
              deleteAction={() => void 0}
              hasCustomActions={false}
              idKey='itemId'
              isIdle={false}
            />
          </div>
          <div className='flex justify-end'>
            <Button
              color='gray'
              size='sm'
              className='w-16'
              onClick={onClose}
              data-testid='btn-close'
            >
              Sair
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
