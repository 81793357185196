import { useMemo, useRef } from 'react'

import { Button, Label } from 'flowbite-react'
import SelectMulti from 'react-select'

import CustomTextInput from '@/components/basic/input-text/input-text.component'
import CustomSelect from '@/components/basic/select/select.component'
import { useDevolucoesContext } from '@/contexts/devolucoes.context'
import { cpfMask } from '@/utils/masks'

const DevolucoesFilterForm = () => {
  const { formik, isLoadingDevolucoes, branches } = useDevolucoesContext()

  const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    formik.setFieldValue('cpf', cpfMask(target.value))
  }

  const formRef = useRef<HTMLFormElement>(null)
  const handleClearForm = () => {
    formik.resetForm({
      values: {
        dataInicio: new Date(new Date().setMonth(new Date().getMonth() - 1))
          .toISOString()
          .split('T')[0],
        dataFim: new Date().toISOString().split('T')[0],
        situacao: '',
        tipoDevolucao: '',
        numeroPedido: '',
        cpf: '',
        unidades: branches?.map((item: any) => ({
          value: item.branchId,
          label: `${item.name}-${item.cnpj}`
        }))
      }
    })
    formik.submitForm()
    formRef.current?.reset()
  }
  const mappedData = useMemo(() => {
    return branches?.map((item: any) => ({
      value: item.branchId,
      label: `${item.name}-${item.cnpj}`
    }))
  }, [branches])

  const handleSelectedBranch = async (selectedOption: any) => {
    formik.setFieldValue('unidades', selectedOption)
  }

  function getInicioMinDate() {
    const fim = new Date(formik.values.dataFim)
    if (fim.toString() === 'Invalid Date')
      return new Date(new Date().setMonth(new Date().getMonth() - 2))
        .toISOString()
        .split('T')[0]
    fim.setDate(fim.getDate() - 90)
    return fim.toISOString().split('T')[0]
  }

  function getFimMaxDate() {
    const inicio = new Date(formik.values.dataInicio)
    if (inicio.toString() === 'Invalid Date')
      return new Date(new Date().setMonth(new Date().getMonth() + 1))
        .toISOString()
        .split('T')[0]
    inicio.setDate(inicio.getDate() + 90)
    return inicio.toISOString().split('T')[0]
  }

  return (
    <form
      ref={formRef}
      onSubmit={formik.handleSubmit}
      className='flex flex-col md:grid grid-cols-12 w-full p-3 bg-white gap-5 mb-5 rounded-lg'
    >
      <div className='col-span-8'>
        <Label htmlFor='branches'>Unidade</Label>
        <SelectMulti
          id='idUnidade'
          isMulti
          onChange={handleSelectedBranch}
          value={formik.values.unidades}
          options={mappedData}
          className='text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500  w-full'
          isSearchable
          escapeClearsValue
          isClearable={false}
          noOptionsMessage={() => 'Sem resultados'}
          placeholder='Selecione...'
          styles={{
            menu: base => ({
              ...base,
              width: '100%'
            })
          }}
        />
      </div>
      <div className='col-span-4'>
        <CustomTextInput
          type='text'
          field='numeroPedido'
          formik={formik}
          label='Nº Pedido'
        />
      </div>
      <div className='col-span-4'>
        <CustomTextInput
          type='date'
          field='dataInicio'
          formik={formik}
          defaultValue={formik.values.dataInicio}
          min={getInicioMinDate()}
          max={formik.values.dataFim}
          label='Devoluções de:'
        />
      </div>
      <div className='col-span-4'>
        <CustomTextInput
          type='date'
          field='dataFim'
          formik={formik}
          defaultValue={formik.values.dataFim}
          min={formik.values.dataInicio}
          max={getFimMaxDate()}
          label='Até:'
        />
      </div>
      <div className='col-span-4'>
        <CustomTextInput
          type='text'
          field='cpf'
          formik={formik}
          onKeyUp={e => handleCpfChange(e as any)}
          label='CPF'
        />
      </div>
      <div className='col-span-4'>
        <CustomSelect
          options={[
            {
              label: 'Todos',
              value: 'Todos'
            },
            {
              label: 'Finalizado',
              value: 'Finalizado'
            },
            {
              label: 'Aberto',
              value: 'AguardandoDevolver'
            },
            {
              label: 'Ativo',
              value: 'Ativo'
            },
            {
              label: 'Cancelado',
              value: 'Cancelado'
            }
          ]}
          field='situacao'
          formik={formik}
          label='Situação'
        />
      </div>
      <div className='col-span-4'>
        <CustomSelect
          options={[
            {
              label: 'Todos',
              value: 'Todos'
            },
            {
              label: 'Aguardando Tratativa',
              value: 'AguardandoTratativa'
            },
            {
              label: 'Desconto',
              value: 'DescontarNaProximaFatura'
            },
            {
              label: 'Depósito',
              value: 'DepositoBancario'
            },
            {
              label: 'Reprocessamento',
              value: 'ReprocessarCredito'
            }
          ]}
          field='tipoDevolucao'
          formik={formik}
          label='Tipo'
        />
      </div>
      <div className='col-span-4 flex justify-end items-end'>
        <div className='flex'>
          <Button
            color='ghost'
            size={'sm'}
            className='text-xs'
            onClick={handleClearForm}
          >
            Limpar
          </Button>
          <Button
            className='bg-quaternary ml-2'
            type='submit'
            size={'sm'}
            disabled={isLoadingDevolucoes}
            isProcessing={isLoadingDevolucoes}
          >
            Filtrar
          </Button>
        </div>
      </div>
    </form>
  )
}
export default DevolucoesFilterForm
