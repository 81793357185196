import { useRef, useState } from 'react'

import { Tabs, TabsRef } from 'flowbite-react'

import { TAB_THEME } from '../detalhe/tab-theme'
import CriarPedidoPorColaboradorContainer from '@/containers/pedidos/criar-pedido-por-colaborador.container'
import CriarPedidoContainer from '@/containers/pedidos/criar-pedido.container'
import { CriarPedidosProvider } from '@/contexts/criar-pedido.context'

const CriarPedidoView = () => {
  const tabsRef = useRef<TabsRef>(null)
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className='w-full bg-white rounded-md p-5 md:-mt-8'>
      <CriarPedidosProvider>
        <Tabs
          aria-label='Tabs with underline'
          variant='default'
          theme={TAB_THEME}
          ref={tabsRef}
          onActiveTabChange={tab => setActiveTab(tab)}
        >
          <Tabs.Item active title='Criar por Unidade'>
            {activeTab === 0 && <CriarPedidoContainer />}
          </Tabs.Item>
          <Tabs.Item title='Criar por Colaborador'>
            {activeTab === 1 && <CriarPedidoPorColaboradorContainer />}
          </Tabs.Item>
        </Tabs>
      </CriarPedidosProvider>
    </div>
  )
}

export default CriarPedidoView
