import Constants from '@/configs/constants'

const ContactBanner = () => {
  return (
    <div className='max-w-[1600px] w-full flex mx-auto'>
      <div className='grid grid-cols-12 bg-white rounded-xl w-full gap-5 md:gap-0 p-5 mx-5 mb-5 shadow-lg'>
        <div className='md:col-span-3 col-span-12 flex mx-auto justify-center items-center'>
          <img
            src='/images/head_phone_green.svg'
            alt='Logo'
            width={145}
            height={45}
            title='Fale conosco'
            className='w-20 md:w-28'
          />
        </div>
        <div className='p-3 md:col-span-9 col-span-12 text-center md:text-left'>
          <p className='font-semibold pb-3 text-lg'>
            Central de Relacionamento VR
          </p>
          <p>
            <span className='text-quaternary'>
              <a href={`tel:${Constants.CONTACT_TELVR}`}>
                {Constants.CONTACT_TELVR}
              </a>
            </span>
            - Capital e regiões metropolitanas
          </p>
          <p>
            <span className='text-quaternary'>
              <a href={`tel:${Constants.CONTACT_OTERS}`}>
                {Constants.CONTACT_OTERS}
              </a>
            </span>
            - Demais localidades
          </p>
          <p className='pt-1 mb-0'>
            Para acessar o Atendimento Online, clique no botão de ajuda,
            localizado no canto inferior direito da tela.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ContactBanner
