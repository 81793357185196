import { FC } from 'react'

import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { Button, Label, TextInput } from 'flowbite-react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import LoginHeader from '@/components/structure/login-header'

const ForgotPassword: FC = () => {
  const validationSchema = Yup.object().shape({
    emailOrCpf: Yup.string().required('Este campo é obrigatório')
  })

  const formik = useFormik({
    initialValues: {
      emailOrCpf: ''
    },
    validationSchema,
    onSubmit: async values => {
      return values
    }
  })

  return (
    <main className='h-screen flex flex-1 flex-col'>
      <LoginHeader />
      <div className='flex md:flex-row flex-col-reverse justify-center items-center p-10 h-full z-10 md:w-8/12 mx-auto'>
        <div className='flex-1 p-10'>
          <div className='border rounded p-10 md:max-w-md'>
            <form onSubmit={formik.handleSubmit}>
              <div className='mb-2 block'>
                <Label htmlFor='emailOrCpf' value='E-mail ou Cpf' />
              </div>
              <TextInput
                id='emailOrCpf'
                type='text'
                placeholder='Digite seu e-mail ou CPF'
                color={formik.errors.emailOrCpf ? 'failure' : ''}
                helperText={formik.errors.emailOrCpf}
                {...formik.getFieldProps('emailOrCpf')}
                required
              />
              <Button
                type='submit'
                className='bg-green-400 mt-5 flex'
                color='success'
                isProcessing={formik.isSubmitting}
                disabled={!formik.dirty}
              >
                Solicitar alteração de senha
                <ArrowRightIcon width={15} className='ml-2' />
              </Button>
            </form>
          </div>
        </div>
        <div className='md:flex-1 p-10'>
          <h1 className='text-3xl font-bold text-center md:text-left'>
            RECUPERAÇÃO DE SENHA DO ABSCARD
          </h1>
          <p className='text-xl mt-3 text-center md:text-left'>
            Informe seu e-mail cadastrado em nosso Portal e em breve receberá as
            instruções de recuperação em seu e-mail.
          </p>
        </div>
      </div>
    </main>
  )
}

export default ForgotPassword
