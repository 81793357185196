import { useEffect, useState } from 'react'

import debounce from 'debounce'
import { Button } from 'flowbite-react'
import Select from 'react-select'

import toastService from '@/components/toast/toast-service'
import { useUserContext } from '@/contexts/user.context'
import { useGetAccountsData } from '@/hooks/queries/use-get-account-data'
import { getAllowedPortalUser } from '@/services/users'
interface Option {
  value: string
  label: string
}

const SelectAccountContainer = () => {
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false)
  const [selectedAccount, setSelectedAccount] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState<any>(null)
  const [currentPage, setCurrentPage] = useState<unknown>(1)
  const [options, setOptions] = useState<Option[]>([])
  const [originalOptions, setOriginalOptions] = useState<Option[]>([])
  const {
    data: accounts,
    isLoading,
    isFetching
  } = useGetAccountsData({
    page: currentPage as number,
    pageSize: 200,
    SearchTerm: searchTerm === '' ? null : searchTerm
  })
  const hasNextPage = accounts?.hasNextPage

  const { handleSelectAccount, isLoadingRedirect, logOut } = useUserContext()

  useEffect(() => {
    const newOptions: Option[] =
      accounts?.items.map((item: any) => ({
        value: item.accountId,
        label: `${item.name} - ${item.cnpj}`
      })) ?? []
    setOptions(newOptions)
    setOriginalOptions(newOptions)
  }, [accounts])

  useEffect(() => {
    async function checkIfisOneAccount() {
      if (accounts?.totalItemsBeforeFilter === 1) {
        setSelectedAccount({
          value: accounts.items[0]?.accountId,
          label: `${accounts.items[0]?.name} - ${accounts.items[0]?.cnpj}`
        })
        handleSelectAccount(accounts.items[0]?.accountId, true)
      }
    }
    checkIfisOneAccount()
  }, [accounts])

  const handleClick = async () => {
    setIsLoadingPage(true)
    try {
      const response = await getAllowedPortalUser()
      handleSelectAccount(
        selectedAccount.value || '',
        response.allowedToPortalNewVersion
      )
    } catch (error) {
      toastService.error(
        'Falha ao selecionar o grupo econômico, por favor tente novamente.'
      )
    }
  }

  const handleEventChange = (value: any) => {
    setSelectedAccount(value)
  }

  const fetchOptions = async () => {
    setCurrentPage((prevPage: number) => prevPage + 1)
  }
  const handleSearch = debounce((event: any) => {
    const searchTerm = event.target.value
    if (!!searchTerm.length) {
      setCurrentPage(null)
    } else {
      setCurrentPage(1)
    }

    setSearchTerm(searchTerm)

    if (searchTerm === '') {
      setOptions(originalOptions)
    } else {
      const filteredOptions = originalOptions.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
      setOptions(filteredOptions)
    }
  }, 500)

  const handleScroll = (event: React.UIEvent<HTMLDivElement> | any) => {
    const { target } = event
    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      !isLoading &&
      hasNextPage
    ) {
      fetchOptions()
    }
  }

  const handleOnMenuClose = () => {
    setCurrentPage(1)
    setSearchTerm(null)
  }

  return (
    <main className='h-screen flex flex-1 flex-col'>
      <div className='flex md:flex-row flex-col-reverse justify-center items-center p-10 h-full z-10 md:w-6/12 mx-auto'>
        <div className='flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 flex-col relative w-full'>
          <div className='bg-quaternary rounded'>
            <p className='font-semibold text-center text-white p-5'>
              Identificamos que você possui acesso em mais de uma conta, <br />
              selecione qual deseja utilizar.
            </p>
          </div>

          <div className='md:p-10 p-5'>
            {isLoading ? (
              <div role='status' className='max-w-sm animate-pulse'>
                <div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4'></div>
                <div className='h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5'></div>
              </div>
            ) : (
              <>
                <label
                  htmlFor='countries'
                  className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  data-testid='select-company'
                >
                  Selecione uma empresa:
                </label>
                <Select
                  isClearable={true}
                  isSearchable
                  escapeClearsValue
                  defaultValue={selectedAccount}
                  onChange={(newValue: any) => handleEventChange(newValue)}
                  isLoading={isLoading || isFetching}
                  loadingMessage={() => 'Carregando...'}
                  onMenuClose={handleOnMenuClose}
                  placeholder='Selecione...'
                  noOptionsMessage={() => 'Sem resultados'}
                  options={options}
                  onKeyDown={handleSearch}
                  onMenuScrollToBottom={handleScroll}
                />
              </>
            )}
            <div className='flex justify-between items-center flex-row mt-5'>
              <Button
                type='button'
                color='light'
                onClick={logOut}
                data-testid='change-user'
              >
                Trocar usuário
              </Button>
              <Button
                type='button'
                color='success'
                className='bg-primary-dark hover:bg-primary-hover'
                disabled={
                  !selectedAccount || isLoadingRedirect || isLoadingPage
                }
                isProcessing={
                  isLoadingRedirect || isLoadingPage || isLoadingPage
                }
                onClick={handleClick}
              >
                Entrar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default SelectAccountContainer
