import { Steps } from 'intro.js-react'
import Cookie from 'js-cookie'

import StepStructure from '@/components/step-structure'

interface StepModalProps {
  onClose: () => void
  isOpen: boolean
  onBeforeExit: () => void
  image?: string
  isOnboardingFinishedOrderReview: boolean
  steps: StepsProps[]
  cookieName: string
}

interface StepsProps {
  step: number
  title: string
  body: string
  imageSrc?: string
  position?: string
  ref?: string
  totalSteps: number
}

const StepModal = ({
  onBeforeExit,
  cookieName,
  steps
}: StepModalProps & { onBeforeExit: () => void; isOpen: boolean }) => {
  const handleSkipOnboarding = () => {
    Cookie.set(cookieName, 'true')
  }
  return (
    <Steps
      onBeforeExit={onBeforeExit}
      onExit={() => void 0}
      enabled={false}
      onComplete={handleSkipOnboarding}
      steps={steps.map(step => {
        return {
          position: step?.position || 'left',
          element: step.ref?.length ? step.ref : '#element',
          intro: <StepStructure {...step} totalSteps={steps.length} />
        }
      })}
      options={{
        prevLabel: 'Voltar',
        nextLabel: 'Próximo',
        doneLabel: 'Terminar',
        showSkipButton: true,
        hidePrev: true,
        exitOnEsc: false,
        exitOnOverlayClick: false,
        showBullets: false,
        tooltipClass: 'step-by-step',
        buttonClass: 'step-by-step__buttons'
      }}
      initialStep={0}
    />
  )
}

export default StepModal
