import { SetStateAction, useMemo, useState } from 'react'

import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import debounce from 'debounce'
import { Alert, Modal } from 'flowbite-react'

import TableItem from '@/components/Table'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import { IItensVenda } from '@/interfaces/pedidos'
import { TABLE_HEADERS_DETALHES_PEDIDO_ITENS } from '@/pages/Pedidos/constants'

interface IPedidosModalDetailProps {
  onClose: () => void
  isOpen: boolean
  selectedItensVenda: IItensVenda[]
  cnpjUnidade: string
  agrupado: boolean
}

const PedidosModalDetail: React.FC<IPedidosModalDetailProps> = ({
  onClose,
  isOpen,
  selectedItensVenda,
  cnpjUnidade,
  agrupado
}: IPedidosModalDetailProps) => {
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const itemsPerPage = 5

  const handleSearch = debounce((e: SetStateAction<string>) => {
    setSearch(e)
    setPage(1)
  }, 200)

  const mappedItens = useMemo(() => {
    return selectedItensVenda?.map(item => {
      return {
        ...item,
        cnpj: cnpjUnidade,
        funcionario: (
          <>
            <span className='flex gap-3 justify-between'>
              <span className='font-semibold'>Nome:</span>{' '}
              {item.trabalhador.nome}
            </span>
            <span className='flex gap-3 justify-between'>
              <span className='font-semibold'>CPF:</span> {item.trabalhador.cpf}
            </span>
            <span className='flex gap-3 justify-between'>
              <span className='font-semibold'> Matrícula:</span>{' '}
              {item.trabalhador.matricula}
            </span>
            <span className='flex gap-3 justify-between'>
              <span className='font-semibold'> Departamento:</span>{' '}
              {item.trabalhador.departamento ?? '-'}
            </span>
            <span className='flex gap-3 justify-between'>
              <span className='font-semibold'> Cargo:</span>{' '}
              {item.trabalhador.cargo}
            </span>
          </>
        ),
        linha: (
          <>
            <span className='flex gap-3 justify-between'>
              <span className='font-semibold'>Código:</span> {item.codigoTarifa}
            </span>
            <span className='flex gap-3 justify-between'>
              <span className='font-semibold'>Linha:</span>{' '}
              {item.descricaoTarifa}
            </span>
            <span className='flex gap-3 justify-between'>
              <span className='font-semibold'>Valor Unitário:</span>
              {item.tarifa.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })}
            </span>
          </>
        ),
        recarga: (
          <>
            <span className='flex gap-3 justify-between'>
              <span className='font-semibold'> Dias para compra:</span>
              {item.diasUso}
            </span>
            <span className='flex gap-3 justify-between'>
              <span className='font-semibold'> Quantidade por dia:</span>
              {item.quantidadePorDia}
            </span>
          </>
        ),
        economia: (
          <>
            <span className='flex gap-3 justify-between'>
              <span className='font-semibold'>Economia gerada:</span>{' '}
              {(item.total - item.totalComEconomia).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })}
            </span>
            <span className='flex gap-3 justify-between'>
              <span className='font-semibold'> Valor Item:</span>{' '}
              {item.total.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })}
            </span>
            <span className='flex gap-3 justify-between'>
              <span className='font-bold text-black'> Total com Economia:</span>{' '}
              {item.totalComEconomia.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })}
            </span>
          </>
        )
      }
    })
  }, [selectedItensVenda, cnpjUnidade])

  const filteredItens = useMemo(() => {
    return mappedItens?.filter(item => {
      return [item.trabalhador.nome, item.trabalhador.cpf]
        .join(',')
        .toLowerCase()
        .includes(search)
    })
  }, [search, mappedItens])

  const paginatedItens = useMemo(() => {
    const startIndex = (page - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    return filteredItens?.slice(startIndex, endIndex)
  }, [page, filteredItens])

  return (
    <>
      <Modal
        popup
        show={isOpen}
        onClose={onClose}
        className='flex items-center'
        size='[100%]'
      >
        <Modal.Header>
          <p className='text-center font-bold p-3'>
            Detalhes do item do pedido
          </p>
        </Modal.Header>
        <Modal.Body>
          {agrupado ? (
            <>
              <div className='mb-3' data-testid='alerta-agrupamento'>
                <Alert color='warning' className='mt-3'>
                  <div className='flex justify-center items-center'>
                    <ExclamationTriangleIcon className='min-w-5 w-5 h-5 mr-2' />
                    <span>
                      <p className='text-sm'>
                        Atenção! Este pedido possui itens que ultrapassaram o
                        valor máximo de compra permitido pela operadora.
                      </p>
                      <p className='text-sm'>
                        Os itens foram divididos respeitando o valor máximo, e
                        os critérios estabelecidos por esta operadora, para dar
                        sequência na compra do pedido!
                      </p>
                    </span>
                  </div>
                </Alert>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className=''>
            <div className='flex justify-between items-end'>
              <SearchTableItem
                isAddItem={false}
                isSearchInputEnabled={true}
                setFilterText={e => handleSearch(e)}
              />
            </div>
            <TableItem
              isActions={false}
              isRowClicable={false}
              data={paginatedItens || []}
              headers={TABLE_HEADERS_DETALHES_PEDIDO_ITENS}
              deleteAction={() => void 0}
              hasCustomActions={false}
              idKey='vendaId'
              isIdle={false}
            />
            <PaginationItem
              totalItems={filteredItens.length as number}
              totalPages={Math.ceil(filteredItens.length / itemsPerPage)}
              pageSize={itemsPerPage}
              onPageChange={(pageParam: number) => setPage(pageParam)}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PedidosModalDetail
