import { QueryFunctionContext } from 'react-query'

import { endPoints } from '@/configs/endPoints'
import { IPendencias, IPendenciasParams } from '@/interfaces/pendencias'
import { API_PEDIDOS } from '@/utils/pedidos-fetcher'

export async function getPendenciasPedidos(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: IPendenciasParams
      }
    ]
  >
): Promise<IPendencias> {
  const [, { params }] = ctx.queryKey
  const route = endPoints.pendenciaslist.replace(
    '{id-pedido-venda}',
    params.idPedido
  )
  const data = await API_PEDIDOS.post<IPendencias>(route, {
    ...params
  })

  return data
}
