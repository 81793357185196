import { memo, useEffect, useMemo, useState } from 'react'

import {
  ArrowLeftEndOnRectangleIcon,
  ArrowUturnLeftIcon,
  CurrencyDollarIcon,
  ListBulletIcon,
  PlusCircleIcon,
  UsersIcon
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { Tooltip } from 'flowbite-react'
import Cookie from 'js-cookie'
import { Link, Outlet, useLocation } from 'react-router-dom'

import StepModal from '@/components/step-modal/step-modal.component'
import { accessControl } from '@/configs/accessControl'
import Constants from '@/configs/constants'
import { routeNames } from '@/configs/routePaths'
import { useUserContext } from '@/contexts/user.context'
import useLocalStorage from '@/hooks/common/use-local-storage'
import { IPermissoesResponse } from '@/interfaces/user'
import { getUserPermissions } from '@/services/users'

const Pedidos = () => {
  const [menuExpanded, setMenuExpanded] = useLocalStorage(
    'isMenuExpanded',
    'false'
  )

  const [isExpanded, setIsExpanded] = useState(menuExpanded === 'true')
  const { pathname } = useLocation()
  const { userPermissions, isAllowedV2Pedidos, setStoragePermissions } =
    useUserContext()
  const isOnRoute = [
    `/${routeNames.pedidos}`,
    `/${routeNames.pedidosAprovados}`
  ].includes(pathname)

  useEffect(() => {
    if (window.location.href.includes('revisar-pedidos')) {
      setIsExpanded(true)
      setMenuExpanded('true')
    }
  }, [pathname])

  const menuItems = [
    {
      // ref: 'acompanhar-pedidos',
      title: 'Acompanhar Pedidos',
      id: 'acompanhar-pedidos',
      icon: <ListBulletIcon width={16} height={16} id='#' />,
      route: `${window.location.origin}/${
        isAllowedV2Pedidos
          ? 'pedidos'
          : 'DemandsV3/Index?RedirectToTab=demandsTab'
      }`,
      matchRoutes: [
        'pedidos',
        'revisar-pedidos',
        'editar-pedidos',
        'pendentes',
        'aprovados'
      ],
      permissions: []
    },
    {
      title: 'Novos Pedidos',
      id: 'novos-pedidos',
      icon: <PlusCircleIcon width={16} height={16} />,
      route: `${window.location.origin}/${
        isAllowedV2Pedidos
          ? 'pedidos/criar-pedidos'
          : 'DemandsV3/Index?RedirectToTab=createDemandsTab'
      }`,
      matchRoutes: ['criar-pedidos'],
      permissions: [accessControl.criarPedido]
      // id: '#acompanhar-pedidos'
    },
    {
      title: 'Conta Corrente RH',
      id: 'conta-corrente-rh',
      icon: <UsersIcon width={16} height={16} />,
      route: `${window.location.origin}/DemandsV3/Index?RedirectToTab=checkingAccountTab`,
      matchRoutes: [],
      permissions: []
    },
    {
      title: 'Devoluções',
      id: 'devolucoes',
      icon: (
        <ArrowUturnLeftIcon width={16} height={16} className='devolucoes' />
      ),
      route: `${window.location.origin}/${
        isAllowedV2Pedidos
          ? 'pedidos/devolucoes'
          : 'DemandsV3/Index?RedirectToTab=devolutionTab'
      }`,
      matchRoutes: [],
      permissions: [],
      className: 'devolucoes'
    },
    {
      title: 'Crédito Certo',
      id: 'credito-certo',
      icon: <CurrencyDollarIcon width={16} height={16} />,
      route: `${window.location.origin}/DemandsV3/Index?RedirectToTab=balanceTab`,
      matchRoutes: [],
      permissions: [],
      className: 'credito-certo'
    }
  ].filter(
    item =>
      userPermissions.find(permissao =>
        item.permissions.includes(permissao.code)
      ) !== undefined || item.permissions.length === 0
  )

  const currentPageTitle = useMemo(() => {
    const current = menuItems.filter(
      item =>
        window.location.href === `${item.route}` ||
        item.matchRoutes.includes(pathname.split('/')[2]) ||
        item.matchRoutes.includes(pathname.split('/')[3])
    )
    return current[0]?.title
  }, [pathname])

  const handleExpandMenu = () => {
    setIsExpanded(!isExpanded)
    setMenuExpanded(`${!isExpanded}`)
  }

  const isOnboardingFinished = !!Cookie.get(Constants.iS_ONBOARDING_FINISHED)

  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false)

  const handleBeforeExit = () => {
    setIsOnboardingOpen(false)
  }

  const handleClose = () => {
    setIsOnboardingOpen(false)
  }

  useEffect(() => {
    if (!isOnboardingFinished) {
      setIsOnboardingOpen(true)
    }

    return () => {
      setIsOnboardingOpen(false)
    }
  }, [isOnboardingFinished])

  const stepList = [
    {
      title: 'Olá! A jornada para criar pedidos na plataforma foi otimizada.',
      body: 'Preparamos um tutorial para te ajudar nesta nova experiência! Vamos começar!',
      step: 1,
      ref: '#element',
      totalSteps: 6
    },
    {
      title: 'Texto falando do menu acompanhar pedidos',
      body: 'bla bla ',
      step: 2,
      ref: '#acompanhar-pedidos',
      totalSteps: 6
    },
    {
      title: 'Texto falando do menu novos pedidos',
      body: 'bla bla ',
      step: 3,
      ref: '#novos-pedidos',
      totalSteps: 6
    },
    {
      title: '​Texto falando do menu conta corrente',
      body: 'bla bla ',
      step: 4,
      ref: '#conta-corrente-rh',
      totalSteps: 6
    },
    {
      title: 'Texto falando do menu devolução',
      body: 'bla bla ',
      step: 5,
      ref: '#devolucoes',
      totalSteps: 6
    },
    {
      title: 'Texto falando do menu credito certo',
      body: 'bla bla ',
      step: 6,
      ref: '#credito-certo',
      totalSteps: 6
    }
  ]

  useEffect(() => {
    getUserPermissions().then((data: IPermissoesResponse) => {
      setStoragePermissions(data.conteudo.controlesDeAcesso)
    })
  }, [])

  return (
    <main className='h-full flex justify-center'>
      <div className='md:grid relative gap-5 grid-cols-12 flex flex-col md:p-10 p-5 w-full max-w-[1600px]'>
        {!isExpanded && (
          <div
            className={classNames(
              isExpanded ? 'block md:hidden' : 'col-span-2',
              'transition-all ease-in-out duration-300'
            )}
          >
            <h3 className='font-semibold text-xl mb-5'>{currentPageTitle}</h3>
            <div className=' rounded-lg md:bg-white overflow-hidden sticky top-5'>
              <ul className='text-sm text-gray-700 dark:text-gray-200 flex md:block justify-between'>
                {menuItems.map(item => (
                  <li
                    key={item.id}
                    id={item.id}
                    className='bg-white md:bg-none rounded-md md:rounded-none'
                  >
                    <Link
                      to={`${item.route}`}
                      className={`flex items-center px-4 py-5 hover:bg-primary hover:text-white transition-all rounded-md md:rounded-none ${
                        item.className
                      } ${
                        window.location.href === `${item.route}`
                          ? 'bg-primary text-white'
                          : item.matchRoutes.includes(pathname.split('/')[2]) ||
                            item.matchRoutes.includes(pathname.split('/')[3])
                          ? 'bg-primary text-white'
                          : ''
                      }`}
                    >
                      {item.icon}
                      <span
                        className={classNames(
                          isExpanded ? 'block' : 'hidden',
                          'ml-3 font-semibold hidden md:block'
                        )}
                      >
                        {item.title}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {isExpanded && (
          <div
            className={classNames(
              isExpanded
                ? 'hidden md:block md:fixed left-5 top-48 mt-2'
                : 'hidden',
              'rounded-lg md:bg-white w-[50px] z-20 shadow-lg'
            )}
          >
            <ul className='text-sm text-gray-700 dark:text-gray-200 overflow-hidden rounded-lg flex md:block justify-between'>
              {menuItems.map(item => (
                <li
                  key={item.id}
                  id={item.id}
                  className='bg-white md:bg-none rounded-md md:rounded-none'
                >
                  <Link
                    title={item.title}
                    to={`${item.route}`}
                    className={`flex items-center px-4 py-5 hover:bg-primary hover:text-white transition-all rounded-md md:rounded-none ${
                      window.location.href === `${item.route}`
                        ? 'bg-primary text-white'
                        : item.matchRoutes.includes(pathname.split('/')[2]) ||
                          item.matchRoutes.includes(pathname.split('/')[3])
                        ? 'bg-primary text-white'
                        : ''
                    }`}
                  >
                    <Tooltip placement='right' content={item.title}>
                      {item.icon}
                    </Tooltip>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div
          className={classNames(
            isExpanded ? 'col-span-12 md:ml-12' : 'col-span-10',
            'pt-8'
          )}
        >
          <Outlet />
        </div>
        <div className='bg-primary p-2 rounded-full fixed md:block hidden left-7 bottom-7'>
          <Tooltip
            placement='right'
            data-testid='expand-menu'
            content={isExpanded ? 'Expandir menu' : 'Recolher'}
          >
            <ArrowLeftEndOnRectangleIcon
              width={18}
              height={18}
              className={classNames(
                isExpanded ? 'transform rotate-180' : 'transform rotate-0',
                'text-white cursor-pointer transition-all ease-in-out duration-300'
              )}
              onClick={handleExpandMenu}
            />
          </Tooltip>
        </div>
      </div>
      {isOnRoute && (
        <StepModal
          isOpen={isOnboardingOpen}
          onClose={handleClose}
          onBeforeExit={handleBeforeExit}
          isOnboardingFinishedOrderReview={isOnboardingFinished}
          steps={stepList}
          cookieName={Constants.iS_ONBOARDING_FINISHED}
        />
      )}
    </main>
  )
}

export default memo(Pedidos)
