import { Status } from '@/interfaces/common'

export const OPTIONS = [
  { value: '1', label: 'Solicitado' },
  { value: '2', label: 'Entregue' },
  { value: '3', label: 'Colet Recusada' },
  { value: '4', label: 'Inativo' },
  { value: '5', label: 'Coleta Gerada' },
  { value: '6', label: 'Entrega Gerada' },
  { value: '7', label: 'Entrega Solicitada' },
  { value: '8', label: 'Entrega Recusada' },
  { value: '9', label: 'Coleta Processada' },
  { value: '10', label: 'Entrega Processada' },
  { value: '11', label: 'Coleta Finalizada' }
]

export const TABLE_HEADERS_ACOMPANHAR_PEDIDOS = {
  ['numeroPedido']: 'N. Pedido',
  ['cnpj']: 'CNPJ',
  ['nomeUnidade']: 'Nome da Unidade',
  ['criadoEm']: 'Criado em',
  ['pagamento']: 'Pagamento',
  ['entrega']: 'Entrega',
  ['total']: 'Total',
  ['situacaoFinanceira']: 'Situação Financeira',
  ['situacaoOperacional']: 'Situação Operacional'
}

export const TABLE_HEADERS_PEDIDOS_PENDENTES = {
  ['qtUnidades']: 'Qt. Unidades',
  ['qtPedidos']: 'Qt. Pedidos',
  ['dataCriacao']: 'Data Criação',
  ['previsaoEntrega']: 'Prev. Entrega',
  ['status']: 'Situação Pedido',
  ['acoes']: ''
}

export const TABLE_HEADERS_REVISAO_PEDIDOS = {
  ['detalhes']: '',
  ['situacao']: 'Situação',
  ['numero']: 'Nº do pedido',
  ['cnpjUnidade']: 'CNPJ',
  ['unidade']: 'Unidade',
  ['total']: 'Total',
  ['economia']: 'Economia',
  ['itens']: 'Itens',
  ['repasses']: 'Repasses',
  ['cobrancas']: 'Cobranças',
  ['tarifas']: 'Tarifas',
  ['descontos']: 'Descontos'
}

export const TABLE_HEADERS_REVISAO_PEDIDOS_OPERADORA = {
  ['situacao']: 'Situação',
  ['operadora']: 'Operadora',
  ['tipoCartao']: 'Tipo de cartão',
  ['qtdColaboradores']: 'Quantidade de Colaboradores',
  ['itens']: 'Itens',
  ['repasses']: 'Repasses',
  ['acoes']: 'Ações'
}

export const TABLE_HEADERS_DETALHES_PEDIDOS_OPERADORA = {
  ['operadora']: 'Fornecedor',
  ['qtdColaboradores']: 'Quantidade',
  ['itens']: 'Valor Total',
  ['acoes']: 'Ações'
}

export const TABLE_HEADERS_ITENS_PEDIDOS = {
  ['itemId']: 'N. Pedido',
  ['quantidade']: 'Quantidade',
  ['valorTotal']: 'Valor Total',
  ['detalhes']: ''
}

export const TABLE_HEADERS_REPASSES = {
  ['itemId']: 'Descrição',
  ['valorTotal']: 'Valor Total'
}

export const TABLE_HEADERS_DEVOLUCOES = {
  ['cpf']: 'CPF',
  ['nome']: 'Nome',
  ['identificador']: 'Identificador',
  ['total']: 'Valor',
  ['dataDevolucao']: 'Data Demanda',
  ['tipoDevolucao']: 'Descrição status',
  ['status']: 'Status Devolução'
}

export const TABLE_HEADERS_ITENS_PEDIDOS_DETALHE = {
  ['cnpj']: 'CNPJ',
  ['cpf']: 'CPF',
  ['nome']: 'Nome',
  ['nomeLinha']: 'Nome Linha',
  ['codigoLinha']: 'Cod. Linha',
  ['matricula']: 'Matrícula',
  ['codigoPostoTrabalho']: 'Cod. Posto Trabalho',
  ['departamento']: 'Departamento',
  ['cargo']: 'Cargo',
  ['codigoOperadora']: 'Cod. Operadora',
  ['qtdDiasTrabalhados']: 'Qtd Dias Trabalhados',
  ['valorLinha']: 'Valor Linha',
  ['qtdPorDia']: 'Qtd Por Dia',
  ['totalBeneficio']: 'Total Benefício',
  ['economiaGerada']: 'Economia Gerada',
  ['totalPedido']: 'Total Pedido'
}

export const TABLE_HEADERS_PENDENCIAS_PEDIDO = {
  ['usuario']: 'Usuário',
  ['cpf']: 'CPF',
  ['beneficio']: 'Benefício',
  ['descricao']: 'Descrição do Erro'
}

export const TABLE_HEADERS_BENEFICIARIOS_PEDIDOS = {
  ['cpf']: 'CPF',
  ['nome']: 'Beneficiário',
  ['identificador']: 'Matrícula',
  ['cargo']: 'Cargo',
  ['departamento']: 'Departamento',
  ['beneficioTotal']: 'Valor',
  ['acoes']: ''
}

export const TABLE_HEADERS_REPASSES_PEDIDO = {
  ['operadora']: 'Operadora',
  ['tipo']: 'Tipo de Taxa',
  ['descricao']: 'Descrição',
  ['valorUnitario']: 'Valor Unitário',
  ['quantidade']: 'Quantidade',
  ['valorTotal']: 'Valor Total'
}

export const TABLE_HEADERS_LANCAMENTOS_PEDIDO = {
  ['descricao']: 'Descrição',
  ['valor']: 'Valor'
}

export const TABLE_HEADERS_TARIFAS = {
  ['descricao']: 'Descrição',
  ['valorTotal']: 'Valor'
}

export const TABLE_HEADERS_LISTA_ERROS_PEDIDO = {
  ['cnpj']: 'CNPJ',
  ['erro']: 'Descrição'
}

export const TIPO_LANCAMENTO = [
  { value: 0, label: 'Descontos' },
  { value: 1, label: 'Cobranças' }
]

export const TABLE_HEADERS_GESTAO_ECONOMIA_PEDIDO = {
  ['unidadeId']: '',
  ['unidade']: 'Unidade',
  ['totalItens']: 'Total Itens',
  ['totalEconomia']: 'Total Economia',
  ['valor']: 'Valor'
}

export const TABLE_HEADERS_DEVOLUCOES_PAGE = {
  ['data']: 'Data',
  ['cpf']: 'CPF',
  ['nome']: 'Nome',
  ['tipoDevolucao']: 'Tipo',
  ['status']: 'Situação',
  ['valor']: 'Valor',
  ['codigoDoBeneficio']: 'Cod. Benefício',
  ['descricaoBeneficio']: 'Benefício',
  ['numeroPedido']: 'Pedido',
  ['matricula']: 'Matrícula',
  ['identificador']: 'Id Operadora',
  ['unidade']: 'Unidade',
  ['actions']: ''
}

export const TABLE_HEADERS_EDITAR_PEDIDO_ERROS = {
  ['motivo']: 'Motivo do Erro',
  ['nome']: 'Nome',
  ['matricula']: 'Matrícula',
  ['cpf']: 'CPF'
}

export const TABLE_HEADERS_EDITAR_PEDIDO_ITENS = {
  ['checkbox']: '',
  ['acoes']: '',
  ['situacao']: 'Situação',
  ['ativo']: 'Ativo',
  ['funcionario']: 'Funcionário',
  ['linha']: 'Linha',
  ['recarga']: 'Dados da Recarga'
}

export const TABLE_HEADERS_DETALHES_PEDIDO_ITENS = {
  ['cnpj']: 'CNPJ',
  ['funcionario']: 'Funcionário',
  ['linha']: 'Linha',
  ['recarga']: 'Dados da Recarga',
  ['economia']: 'Economia'
}

export const TABLE_HEADERS_CONFIRMACAO_PEDIDOS = {
  ['cnpjUnidade']: 'CNPJ',
  ['nomeUnidade']: 'Razão Social',
  ['numeroPedido']: 'Pedido',
  ['valorTotalItensComEconomia']: 'Vlr. Itens',
  ['valorTarifas']: 'Vlr. Tarifas',
  ['valorRepasses']: 'Vlr. Repasses',
  ['cobranca']: 'Vlr. Cobranças',
  ['desconto']: 'Vlr. Descontos',
  ['total']: 'Vlr. Total'
}

export const TABLE_HEADERS_LOGS_DEVOLUCOES = {
  ['data']: 'Data',
  ['descricao']: 'Descrição'
}

export const mapedTipoSituacaoFinanceira = {
  ['NaoPago']: { title: 'Não Pago', color: 'bg-red-600' },
  ['Pago']: { title: 'Pago', color: 'bg-primary-hover' },
  ['AguardandoLiberacao']: {
    title: 'Aguardando Liberação',
    color: 'bg-yellow-400'
  },
  ['Liberado']: { title: 'Liberado', color: 'bg-yellow-400' },
  ['Cancelado']: { title: 'Cancelado', color: 'bg-gray-400' }
}

export const mapedTipoSituacaoOperacional = {
  ['NaoConfirmado']: { title: 'Não Confirmado', color: 'bg-red-600' },
  ['Confirmado']: { title: 'Confirmado', color: 'bg-yellow-400' },
  ['EmProducao']: { title: 'Em Produção', color: 'bg-yellow-400' },
  ['Finalizado']: { title: 'Finalizado', color: 'bg-primary-hover' },
  ['Cancelado']: { title: 'Cancelado', color: 'bg-gray-400' }
}

export const TABLE_HEADERS_LISTA_PERMITIR_CRIAR_PEDIDO = {
  ['descricao']: 'Unidade',
  ['descricaoErro']: 'Descrição do Erro'
}

export const statusDetailsByStatus: Record<
  Status,
  { badgeClass: string; text: string; action: string }
> = {
  Erro: { badgeClass: 'bg-red-600', text: 'Erro', action: 'Revisar pedido' },
  EmProcessamento: {
    badgeClass: 'bg-yellow-400',
    text: 'Em Processamento',
    action: 'Reprocessar pedido'
  },
  Pendente: {
    badgeClass: 'bg-gray-400',
    text: 'Pendente',
    action: 'Reprocessar Pedido'
  },
  Processado: {
    badgeClass: 'bg-primary-hover',
    text: 'Processado',
    action: 'Aprovar Pedido'
  }
}

export const stepListPedidosPendentes = [
  {
    title: '​',
    body: '​Essa é a sua área para aprovar os pedidos.<br> ​Somente após a aprovação seu pedido estará confirmado na plataforma.​',
    step: 1,
    totalSteps: 3
  },
  {
    title: 'Agora o seu pedido pode ter 3 situações, são eles:',
    body: `
    <ul class="intro-list">
      <li>Em processamento: enquanto seu pedido é validado, essa será a situação.</li>
      <li>Erro: nesta situação você precisa verificar seu pedido clicando em "Erro" para visualizar os detalhes.</li>
      <li>Processado: quer dizer que seu pedido foi validado, para confirmá-lo você deve clicar em “Aprovar Pedido”.</li>
    </ul>
  `,
    step: 2,
    ref: '#table-pedidos-pendentes',
    totalSteps: 3
  },
  {
    title: '',
    body: 'Se desejar receber uma atualização instantânea sobre o processamento do seu pedido, clique neste botão.',
    step: 3,
    totalSteps: 3,
    ref: '.recarregar'
  }
]

export const stepListCriarPedido = [
  {
    title: 'Vamos criar um pedido?',
    body: 'A jornada continua a mesma para criar um pedido, a novidade é uma experiência mais simples.',
    step: 1,
    totalSteps: 3
  },
  {
    position: 'right',
    title: 'Criar por unidade:​​',
    body: 'Nesta tela você continua no controle para escolher quais empresas deseja criar o pedido.',
    ref: '.criar-novos-pedidos',
    totalSteps: 3,
    step: 2
  },
  {
    title: '',
    body: 'A inclusão de pedido foi atualizada.​ Ao incluir seu pedido, ele será validado.​ O resultado será apresentado em uma nova tela, com uma experiência mais completa.',
    ref: '#incluir-pedidos-btn',
    totalSteps: 3,
    step: 3
  }
]

export const stepListRevisarPedido = [
  {
    step: 1,
    title: 'Economia com crédito certo​',
    body: 'Nesta coluna vamos mostrar a economia obtida pelo crédito certo.',
    ref: 'tbody td:nth-child(7)',
    totalSteps: 5
  },
  {
    step: 2,
    title:
      'Neste item de situação você poderá acompanhar o status do seu pedido:',
    body: '<div style="margin-bottom:10px"><b>Erro:</b> Exibe os itens para revisão, que impedem a aprovação do pedido.</div>​<b>Sucesso:</b> ​Neste status seu pedido está validado, para confirmá-lo na você deve clicar em “Aprovar Pedido”.',
    ref: 'tbody td:nth-child(2)',
    totalSteps: 5
  },
  {
    step: 3,
    title: '',
    body: 'Utilize esse sinal ( + ) para expandir os detalhes do pedido e acessar valores e taxa por operadora e por trabalhador.',
    ref: '#detalhes-pedido',
    totalSteps: 5
  },
  {
    step: 4,
    title: '',
    body: `
      <div>
        <p><strong>Cancelar pedido:</strong> Se precisar cancelar seu pedido, use essa opção.​</p>
        <p><strong>Reprocessar pedido:</strong> Utilize essa opção para validar alguma alteração em seu pedido.​</p>
        <p><strong>Aprovar Pedido:</strong> Utilize essa opção para confirmar seu pedido.​</p>
      </div>
    `,
    ref: '#GerenciamentoPedidos',
    totalSteps: 5
  },
  {
    step: 5,
    title: '',
    body: '​Aqui você consegue conferir os valores totais do seu pedido.',
    ref: '#table-pedidos-revisao',
    totalSteps: 5
  }
]

export const stepListPedidos = [
  {
    title: 'Olá! A jornada para criar pedidos na plataforma foi otimizada.',
    body: 'Preparamos um tutorial para te contar as novidades.',
    step: 1,
    ref: '#element',
    totalSteps: 6
  },
  {
    title: 'Acompanhar Pedidos:',
    body: 'Aqui você pode acompanhar todos os seus pedidos.​ <br>É aqui que você vai visualizar o status dos seus pedidos.',
    step: 2,
    ref: '#acompanhar-pedidos',
    totalSteps: 6
  },
  {
    title: 'Novos Pedidos​',
    body: 'Aqui você realiza a criação de novos pedidos. <br><b>Essa jornada foi otimizada!</b> 🚀​',
    step: 3,
    ref: '#novos-pedidos',
    totalSteps: 6
  },
  {
    title: 'Conta Corrente RH​',
    body: 'Aqui você pode consultar o extrato e o saldo da sua conta corrente.',
    step: 4,
    ref: '#conta-corrente-rh',
    totalSteps: 6
  },
  {
    title: 'Devoluções',
    body: 'Quando um trabalhador não pode receber a compra de VT, ele é listado nesta tela. <br>Estes trabalhadores ficam disponíveis para você decidir qual a melhor forma de disponibilizar o benefício.',
    step: 5,
    ref: '#devolucoes',
    totalSteps: 6
  },
  {
    title: 'Crédito Certo​',
    body: 'A Gestão de Saldo agora se chama Crédito Certo!​ <br>Você pode configurar como deseja utilizar o saldo nos pedidos, garantindo a melhor eficiência e segurança.',
    step: 6,
    ref: '#credito-certo',
    totalSteps: 6
  },
  {
    title:
      'Para acessar o histórico de pedidos realizados anteriormente é necessário acessar o menu relatórios.',
    body: '',
    step: 7,
    ref: '#relatórios',
    totalSteps: 6
  }
]
