export const CurrencyFormat = (value: any) => {
  let valueToConvert = value
  if (typeof value !== 'number') {
    valueToConvert = parseFloat(value)
  }
  return valueToConvert?.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  })
}
