import { useState } from 'react'

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { Dropdown, Spinner } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'

import PendenciasPedidoModal from '../pendencias-pedido.modal'
import ResumoPedidoModal from '../resumo-pedido.modal'
import DeleteModal from '@/components/delete-modal'
import toastService from '@/components/toast/toast-service'
import { usePedidosContext } from '@/contexts/pedidos.context'
import { useCancelarPedidoMutation } from '@/hooks/mutations/use-pedidos-mutation'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { IBoletoResponse } from '@/interfaces/boletos'
import { INotaFiscalResponse } from '@/interfaces/notas-fiscais'
import { IPedido } from '@/interfaces/pedidos'
import { exportarBoleto } from '@/services/boletos'
import { exportarNotaFiscal } from '@/services/notas-fiscais'

interface CustomActionsMenuAcompanharPedidosProps {
  pedido: IPedido
}

const CustomActionsMenuAcompanharPedidos = ({
  pedido
}: CustomActionsMenuAcompanharPedidosProps) => {
  const { contratos } = usePedidosContext()
  const navigate = useNavigate()
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false)
  const [isOpenPendenciasModal, setIsOpenPendenciasModal] = useState(false)
  const [isCancelarPedidoOpen, setIsCancelarPedidoOpen] = useState(false)
  const [selectedId, setSelectedId] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const { data: userData } = useGetUserData()
  const { mutate: cancelarPedido } = useCancelarPedidoMutation()

  const handleCancelarPedido = () => {
    const DTO = {
      id: selectedId,
      canceladoPor: userData?.userId
    }
    cancelarPedido(DTO)
  }

  const handleOpenCancelarPedido = (id: string) => {
    setSelectedId(id)
    setIsCancelarPedidoOpen(true)
  }

  const downloadBoleto = async (pedidoVendaId: string) => {
    setIsLoading(true)
    exportarBoleto(pedidoVendaId)
      .then((data: IBoletoResponse) => {
        const boletoUrl = data?.conteudo.linkPdf
        window.open(boletoUrl, '_blank')
      })
      .catch(() => {
        toastService.alert('O boleto não está disponível.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const downloadNotaFiscal = async (
    pedidoVendaId: string,
    dataInicio: string,
    dataFim: string
  ) => {
    setIsLoading(true)

    exportarNotaFiscal([pedidoVendaId], dataInicio, dataFim)
      .then((data: INotaFiscalResponse) => {
        const notaFiscalUrl = data?.conteudo

        if (typeof notaFiscalUrl === 'string') {
          window.open(notaFiscalUrl, '_blank')
        } else {
          toastService.alert('A URL da Nota Fiscal não está disponível.')
        }
      })
      .catch(() => {
        toastService.alert('Nota Fiscal não está disponível.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <>
      <Dropdown
        label=''
        placement='left-start'
        dismissOnClick={true}
        renderTrigger={() => (
          <EllipsisVerticalIcon className='w-5 h-5 cursor-pointer' />
        )}
      >
        <>
          {isLoading ? (
            <Spinner
              data-testid='loading'
              color='success'
              aria-label='Carregando...'
            />
          ) : (
            <Dropdown.Item
              className='m-0 p-0'
              onClick={() =>
                downloadNotaFiscal(
                  pedido.id,
                  pedido.criadoEmRaw,
                  pedido.criadoEmRaw
                )
              }
            >
              <span className=' pt-2 pb-2 pl-4'>Link NFE</span>
            </Dropdown.Item>
          )}
        </>

        {contratos?.conteudo?.find(c => c.idUnidade === null)?.modalidade ===
        'Prepago' ? (
          <Dropdown.Item onClick={() => downloadBoleto(pedido.id)}>
            {isLoading ? (
              <Spinner
                data-testid='loading'
                color='success'
                aria-label='Carregando...'
              />
            ) : (
              <span>Exportar Boleto</span>
            )}
          </Dropdown.Item>
        ) : (
          pedido.situacaoFinanceiraRaw === 'Liberado' && (
            <>
              {isLoading ? (
                <Spinner
                  data-testid='loading'
                  color='success'
                  aria-label='Carregando...'
                />
              ) : (
                <Dropdown.Item
                  className='m-0 p-0'
                  onClick={() => downloadBoleto(pedido.id)}
                >
                  <span className='p-3'>Exportar Boleto</span>
                </Dropdown.Item>
              )}
            </>
          )
        )}
        <Dropdown.Item onClick={() => setIsOpenPendenciasModal(true)}>
          Pendências
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            navigate(`/pedidos/${pedido?.id}?data=${pedido?.criadoEmRaw}`)
          }
        >
          Detalhar
        </Dropdown.Item>
        {!pedido?.rpsGerada && (
          <Dropdown.Item onClick={() => handleOpenCancelarPedido(pedido?.id)}>
            {isLoading ? (
              <Spinner color='success' aria-label='Carregando...' />
            ) : (
              <span className='text-red-500 font-semibold'>
                Cancelar Pedido
              </span>
            )}
          </Dropdown.Item>
        )}
      </Dropdown>
      <ResumoPedidoModal
        onClose={() => setIsResumeModalOpen(false)}
        isOpen={isResumeModalOpen}
      />
      {isOpenPendenciasModal && (
        <PendenciasPedidoModal
          onClose={() => setIsOpenPendenciasModal(false)}
          isOpen={isOpenPendenciasModal}
          pedidoId={pedido.id}
          unidadeId={pedido?.idUnidade}
        />
      )}

      <DeleteModal
        content='cancelar'
        isOpen={isCancelarPedidoOpen}
        onClose={() => setIsCancelarPedidoOpen(false)}
        onDelete={handleCancelarPedido}
      />
    </>
  )
}

export default CustomActionsMenuAcompanharPedidos
