import { useQuery, UseQueryResult } from 'react-query'

import toastService from '@/components/toast/toast-service'
import { IConfiguracaoCreditoCertoList } from '@/interfaces/configuracaoCreditoCerto'
import { obterConfiguracoesDoCreditoCerto } from '@/services/credito-certo'

export function useObterConfiguracoesDoCreditoCerto({
  params
}: {
  params: {
    idEmpregador: string
    IdsUnidades?: string[]
  }
}): UseQueryResult<IConfiguracaoCreditoCertoList> {
  return useQuery(
    ['credito-certo', { params }],
    obterConfiguracoesDoCreditoCerto,
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day,
      cacheTime: 10 * (60 * 1000), // 10 mins
      retry: true,
      refetchOnReconnect: false,
      enabled: !!params.idEmpregador && !!params.IdsUnidades,
      onError: () => {
        toastService.error(
          'Falha ao buscar as informações. Por favor, tente novamente.'
        )
      }
    }
  )
}
