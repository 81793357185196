import { useEffect } from 'react'

import { Flowbite } from 'flowbite-react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { PlatformProvider } from './contexts/platform.context'
import { UserProvider } from './contexts/user.context'
import { Router } from './routes/index.routes'
import Sensedia from './services/sensedia'
import { customTheme } from './styles/theme'
import { TokenStorage } from './utils/token-storage'

import './styles/global.css'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  const sensedia = new Sensedia()
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  })

  async function getGrandCode() {
    try {
      const redirectUri = await sensedia.getRedirectUri()
      const authorizationCode = redirectUri.split('code=')[1]
      const accessToken = await sensedia.getAccessToken(authorizationCode)
      TokenStorage.setAuthorizationCode(
        accessToken.access_token,
        accessToken.expires_in
      )
    } catch (error) {
      console.error('Error in example usage:', error)
    }
  }

  useEffect(() => {
    getGrandCode()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Flowbite theme={{ theme: customTheme }}>
        <BrowserRouter>
          <UserProvider>
            <PlatformProvider>
              <div className='h-screen bg-gray-100 flex flex-col'>
                <Router />
              </div>
            </PlatformProvider>
          </UserProvider>
        </BrowserRouter>
      </Flowbite>
      <ToastContainer
        position='top-right'
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme='light'
      />
    </QueryClientProvider>
  )
}

export default App
