import {
  Dispatch,
  SetStateAction,
  useState,
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useMemo
} from 'react'

import { useCreatePedidoMutation } from '@/hooks/mutations/use-pedidos-mutation'
import { useObterConfiguracoesDoCreditoCerto } from '@/hooks/queries/use-get-configuracoes-credito-certo'
import { useGetContratos } from '@/hooks/queries/use-get-contratos'
import { useGetUnidadesAtivas } from '@/hooks/queries/use-get-unidades-ativas'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { IConfiguracaoCreditoCertoList } from '@/interfaces/configuracaoCreditoCerto'
import { IContratoList } from '@/interfaces/contrato'
import { IPedido } from '@/interfaces/pedidos'

interface CriarPedidoContextProps {
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  data: any
  setData: Dispatch<SetStateAction<[]>>
  checkedPedidos: { [key: string]: boolean }
  setCheckedPedidos: Dispatch<SetStateAction<{ [key: string]: boolean }>>
  allSelected: boolean
  filteredData: IPedido[]
  setAllSelected: Dispatch<SetStateAction<boolean>>
  setFilteredData: Dispatch<SetStateAction<[]>>
  isSelectedContaCorrente: boolean
  setIsSelectedContaCorrente: Dispatch<SetStateAction<boolean>>
  isLoadingCriarPedido: boolean
  isloadingBranchesList: boolean
  branchesList:
    | {
        value: any
        label: string
      }[]
    | undefined
  isLoadingContratos: boolean
  contratos: IContratoList
  configuracoesCreditoCerto: IConfiguracaoCreditoCertoList
  permitirCriarPedidoPorUnidade: { [key: string]: boolean }
  isOpenErrosModal: boolean
  setIsOpenErrosModal: Dispatch<SetStateAction<boolean>>
}

const CriarPedidosContext = createContext<CriarPedidoContextProps | undefined>(
  undefined
)

const CriarPedidosProvider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState<[]>([])
  const [filteredData, setFilteredData] = useState<[]>(data)
  const [checkedPedidos, setCheckedPedidos] = useState<{
    [key: string]: boolean
  }>({})
  const [allSelected, setAllSelected] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSelectedContaCorrente, setIsSelectedContaCorrente] = useState(false)
  const { data: userData } = useGetUserData()
  const [isOpenErrosModal, setIsOpenErrosModal] = useState(false)

  const { data: branches, isLoading: isloadingBranchesList } =
    useGetUnidadesAtivas({
      params: {
        idDoEmpregador: userData?.authenticatedAccountId || ''
      }
    })
  const { isLoading: isLoadingCriarPedido } = useCreatePedidoMutation()
  const branchesList = useMemo(() => {
    return branches?.conteudo?.map((item: any) => ({
      value: item.idUnidade,
      label: `${item.descricao} `,
      permiteCriarPedido: item.permiteCriarPedido
    }))
  }, [branches])

  const [permitirCriarPedidoPorUnidade, setPermitirCriarPedidoPorUnidade] =
    useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    const permitirCriarPedido = branchesList?.reduce((acc, item) => {
      acc[item.value] = item.permiteCriarPedido
      return acc
    }, {} as { [key: string]: boolean })
    setPermitirCriarPedidoPorUnidade(permitirCriarPedido || {})
    !permitirCriarPedido && setIsOpenErrosModal(true)
  }, [branches])

  const { data: contratos, isLoading: isLoadingContratos } = useGetContratos({
    params: {
      idEmpregador: userData?.authenticatedAccountId || '',
      idUnidades: userData?.branches.map(b => b.branchId)
    }
  })

  const { data: configuracoesCreditoCerto } =
    useObterConfiguracoesDoCreditoCerto({
      params: {
        idEmpregador: userData?.authenticatedAccountId || '',
        IdsUnidades: userData?.branches.map(b => b.branchId)
      }
    })

  useEffect(() => {
    const DTO = branches?.conteudo?.map(item => ({
      idRequisicao: crypto.randomUUID(),
      idEmpregador: userData?.authenticatedAccountId || '',
      idUnidade: item.idUnidade,
      cnpj: item.descricao,
      cnpjValue: item.descricao.split(' - ')[0],
      idAplicacao: process.env.VITE_APP_APP_ID ?? '',
      periodoUtilizacaoInicial: '',
      periodoUtilizacaoFinal: '',
      previsaoPagamento: ' ',
      previsaoDisponibilidade: '',
      tipoPedidoVenda: '',
      usarCredito: false,
      idsLocaisDeEntrega: [],
      idsDepartamentos: [],
      idsCargos: [],
      idsTrabalhadores: [],
      periodos: [],
      idsPeriodos: Array.from({ length: 31 }, (_, index) => ({
        value: index + 1,
        label: `Período de ${index + 1} dias`
      }))
    }))
    setData(DTO as any)
  }, [branches])

  const contextValue = useMemo(
    () => ({
      data,
      setData,
      checkedPedidos,
      setCheckedPedidos,
      allSelected,
      filteredData,
      setAllSelected,
      setFilteredData,
      isSelectedContaCorrente,
      setIsSelectedContaCorrente,
      isLoadingCriarPedido,
      branchesList,
      isloadingBranchesList,
      isModalOpen,
      setIsModalOpen,
      isLoadingContratos,
      contratos,
      configuracoesCreditoCerto,
      permitirCriarPedidoPorUnidade,
      isOpenErrosModal,
      setIsOpenErrosModal
    }),
    [
      data,
      setData,
      checkedPedidos,
      setCheckedPedidos,
      allSelected,
      filteredData,
      setAllSelected,
      setFilteredData,
      isSelectedContaCorrente,
      setIsSelectedContaCorrente,
      isLoadingCriarPedido,
      branchesList,
      isloadingBranchesList,
      isModalOpen,
      setIsModalOpen,
      isLoadingContratos,
      contratos,
      configuracoesCreditoCerto,
      permitirCriarPedidoPorUnidade,
      isOpenErrosModal,
      setIsOpenErrosModal
    ]
  )

  return (
    <CriarPedidosContext.Provider value={contextValue}>
      {children}
    </CriarPedidosContext.Provider>
  )
}

const useCriarPedidosContext = () => {
  const context = useContext(CriarPedidosContext)
  if (context === undefined) {
    throw new Error(
      'useCriarPedidosContext must be used within a CriarPedidosProvider'
    )
  }
  return context
}

export { CriarPedidosProvider, useCriarPedidosContext }
