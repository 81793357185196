import { Button, Modal } from 'flowbite-react'

import { useAceiteEconomiaMutation } from '@/hooks/mutations/use-pedidos-mutation'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'

interface IPrimeiraEconomiaModalProps {
  onClose: () => void
  isOpen: boolean
  onAccept: () => void
  valorPadrao: number
}

const PrimeiraEconomiaModal: React.FC<IPrimeiraEconomiaModalProps> = ({
  onClose,
  isOpen,
  onAccept,
  valorPadrao
}: IPrimeiraEconomiaModalProps) => {
  const { data: userData } = useGetUserData()
  const { mutateAsync: aceiteEconomia, isLoading } = useAceiteEconomiaMutation()
  const handleAceite = () => {
    const DTO = {
      idEmpregador: userData?.authenticatedAccountId || ''
    }
    aceiteEconomia(DTO).then(() => {
      onAccept()
      onClose()
    })
  }

  return (
    <div data-testid='modal-primeira-economia'>
      <Modal
        show={isOpen}
        onClose={onClose}
        className='flex items-center'
        size={'9xl'}
        popup
      >
        <Modal.Header className='p-1 gap-2 mb-5'>
          <p className='text-center font-bold p-3'>Contrato Crédito Certo</p>
        </Modal.Header>
        <Modal.Body>
          <div className='w-full p-1 bg-white gap-5 mb-5 rounded-lg'>
            <h5 className='font-weight-bold text-quaternary text-3xl'>
              Ative Já!
            </h5>
            <p className='font-bold pt-2 pb-2'>Termo de Aceite e Condições</p>
            <p>
              <b>Crédito Certo</b> é uma ferramenta exclusiva, que gera sensível
              economia para a empresa no pedido do vale-transporte. <br />
              <b>Diferente de outras soluções do mercado</b>, apresentamos, no
              momento do pedido a oportunidade de economia para sua empresa, com
              isso, você tem a opção de reduzir total ou parcialmente a
              concessão do benefício no respectivo mês, solicitando apenas o que
              for necessário para cada trabalhador.
            </p>
            <p className='pt-2'>
              <b>
                Para usar a funcionalidade e obter a redução no valor do pedido
                antes de finalizar a compra na revisão do Pedido, basta
                selecionar:
              </b>
            </p>

            <ul className='list ml-5 pb-2'>
              <li>1. A data prevista para disponibilização do benefício;</li>
              <li>2. O pedido que deseja aplicar a redução;</li>
              <li>3. Reprocessar o pedido.</li>
            </ul>
            <p>
              Ao contratar o Crédito Certo, será cobrado um percentual sobre a
              redução de valor aplicado no pedido. Conforme tabela padrão atual
              esse valor é de <span className='font-bold'>{valorPadrao}%</span>
            </p>
            <p>
              Uma <b>vez ativado o serviço</b> esse será replicado a{' '}
              <b>todos os CNPJ do grupo</b>, desde que não tenham condições pré
              contratadas no <b>modelo automático</b>.
            </p>
            <p className='pt-5'>
              <b>
                Veja algumas funcionalidades e informações disponíveis
                contempladas no serviço:
              </b>
            </p>
            <ul className='list-disc ml-5 pl-2'>
              <li>
                Valor Acumulado estimado por trabalhador e Operadora
                <span className='text-sm'>
                  (*Servi&ccedil;o dispon&iacute;vel apenas para algumas
                  Operadoras de vale-transporte, podendo sofrer
                  altera&ccedil;&otilde;es sem aviso pr&eacute;vio, consulte
                  nossa equipe)
                </span>
              </li>
              <li>
                Extrato com saldo previsto nos cart&otilde;es
                <span className='text-sm'>
                  (*Quando disponibilizado pelo sistema de transporte
                  p&uacute;blico.)
                </span>
              </li>
              <li>
                Simula&ccedil;&atilde;o da economia, antes de efetivar o pedido
              </li>
              <li>
                Proje&ccedil;&atilde;o de seguran&ccedil;a calculada com base na
                data de disponibiliza&ccedil;&atilde;o do pr&oacute;ximo pedido,
                garantindo margem de seguran&ccedil;a no valor a ser reduzido
              </li>
              <li>
                Configura&ccedil;&atilde;o do per&iacute;odo
                <span className='text-sm'>(Seg. a Sex ou Seg. a Dom)</span>
                para c&aacute;lculo dos dias de trabalho a serem adicionados na
                proje&ccedil;&atilde;o de seguran&ccedil;a
              </li>
              <li>
                Relat&oacute;rios permanentes dos resultados obtidos e
                altera&ccedil;&otilde;es feitas em cada pedido
              </li>
              <li>
                Emiss&atilde;o dos recibos de benef&iacute;cios em atendimento
                &agrave; legisla&ccedil;&atilde;o
                <span className='text-sm'>
                  (mesmo quando n&atilde;o for feito pedido para o
                  usu&aacute;rio no per&iacute;odo)
                </span>
              </li>
              <li>
                Exporta&ccedil;&atilde;o de dados para descontos na folha de
                pagamento.
              </li>
            </ul>
          </div>
          <div className='flex justify-center gap-4'>
            <Button color='gray' onClick={onClose} data-testid='btn-close'>
              Fechar
            </Button>
            <Button
              color='success'
              data-testid='btn-confirm'
              onClick={handleAceite}
              disabled={isLoading}
              isProcessing={isLoading}
            >
              Ativar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default PrimeiraEconomiaModal
