import { useMemo } from 'react'

import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Label, Popover } from 'flowbite-react'

import CustomTextInput from '@/components/basic/input-text/input-text.component'
import CustomSelect from '@/components/basic/select/select.component'
import { useRevisarPedidosContext } from '@/contexts/revisar-pedido.context'

const RevisarPedidoForm = () => {
  const {
    formik,
    handlePodeAprovar,
    pedidosList,
    isReprocessarOpen,
    contratos,
    setEconomiaVisivel,
    handlePrevisaoDisponibilidadeChange,
    getPagMinDate
  } = useRevisarPedidosContext()

  useMemo(() => {
    const datas = pedidosList
      ?.map(item => {
        return {
          periodoUtilizacaoInicial: new Date(item.periodoUtilizacaoInicial)
            .toISOString()
            .split('T')[0],
          periodoUtilizacaoFinal: new Date(item.periodoUtilizacaoFinal)
            .toISOString()
            .split('T')[0],
          previsaoPagamento: new Date(item.previsaoPagamento)
            .toISOString()
            .split('T')[0],
          previsaoDisponibilidade: new Date(item.previsaoDisponibilidade)
            .toISOString()
            .split('T')[0],
          tipoPedidoVenda: item.tipoPedidoVenda
        }
      })
      .at(0)
    formik.setFieldValue(
      'periodoUtilizacaoInicial',
      datas?.periodoUtilizacaoInicial
    )
    formik.setFieldValue(
      'periodoUtilizacaoFinal',
      datas?.periodoUtilizacaoFinal
    )
    formik.setFieldValue('previsaoPagamento', datas?.previsaoPagamento)
    if (!isReprocessarOpen)
      formik.setFieldValue(
        'previsaoDisponibilidade',
        datas?.previsaoDisponibilidade
      )
    formik.setFieldValue('previsaoPagamento', datas?.previsaoPagamento)
    formik.setFieldValue('tipoPedidoVenda', datas?.tipoPedidoVenda)
    setEconomiaVisivel(datas?.tipoPedidoVenda === 'Mensal')

    return datas
  }, [pedidosList])

  function getMinDisponibilidadeDate() {
    if (
      contratos?.conteudo?.find(c => c.idUnidade === null)?.modalidade ===
      'Prepago'
    ) {
      const previsaoPagamento = formik.getFieldProps('previsaoPagamento').value
      if (previsaoPagamento != undefined) {
        const pagamentoDate = new Date(previsaoPagamento)
        pagamentoDate.setDate(pagamentoDate.getDate() + 7)
        return pagamentoDate.toISOString().split('T')[0]
      }
    }
    const todayDate = new Date()
    todayDate.setDate(todayDate.getDate() + 7)
    return todayDate.toISOString().split('T')[0]
  }

  function handlePrevisaoPagamento(novaPrevisao: string) {
    const novaPrevisaoDate = new Date(novaPrevisao)
    formik.setFieldValue(
      'previsaoPagamento',
      novaPrevisaoDate.toISOString().split('T')[0]
    )
    if (
      contratos?.conteudo?.find(c => c.idUnidade === null)?.modalidade ===
      'Prepago'
    ) {
      const disponibilidadeDate = new Date(
        formik.getFieldProps('previsaoDisponibilidade').value
      )
      novaPrevisaoDate.setDate(novaPrevisaoDate.getDate() + 7)

      if (novaPrevisaoDate > disponibilidadeDate) {
        formik.setFieldValue(
          'previsaoDisponibilidade',
          novaPrevisaoDate.toISOString().split('T')[0]
        )
      }
    }
    handlePodeAprovar(false)
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className='flex flex-col md:grid grid-cols-12 w-full p-3 bg-white gap-5 mb-5 rounded-lg'
    >
      <div className='flex flex-col col-span-4 justify-left items-left gap-2'>
        <div>
          <Label>Data de Início Utilização</Label>
          <div className='gap-2'>
            <CustomTextInput
              type='date'
              field='periodoUtilizacaoInicial'
              data-testid='periodoUtilizacaoInicial'
              formik={formik}
              max={formik.values.periodoUtilizacaoFinal}
              value={formik.values.periodoUtilizacaoInicial}
              label=''
              required
            />
          </div>
        </div>
        <div>
          <div className='flex items-center gap-2 pb-1'>
            <Label>Data Previsão para Pagamento</Label>
            <Popover
              trigger='hover'
              content={
                <div className='w-64 text-xs text-gray-800 dark:text-gray-400'>
                  <div className='px-3 py-2'>
                    <p>
                      Ao alterar a data previsão para pagamento o pedido terá de
                      ser reprocessado.
                    </p>
                  </div>
                </div>
              }
              placement='top'
            >
              <InformationCircleIcon className='h-4 w-4 text-primary' />
            </Popover>
          </div>

          <div className='gap-2'>
            <CustomTextInput
              type='date'
              field='previsaoPagamento'
              data-testid='previsaoPagamento'
              formik={formik}
              min={getPagMinDate()}
              value={formik?.values.previsaoPagamento}
              onChange={value => handlePrevisaoPagamento(value.target.value)}
              label=''
              required
            />
          </div>
        </div>
      </div>
      <div className='flex flex-col col-span-4 justify-right items-right gap-2'>
        <div>
          <Label>Data de Fim Utilização</Label>
          <div className='gap-2'>
            <CustomTextInput
              type='date'
              field='periodoUtilizacaoFinal'
              data-testid='periodoUtilizacaoFinal'
              formik={formik}
              min={formik.values.periodoUtilizacaoInicial}
              value={formik?.values.periodoUtilizacaoFinal}
              label=''
              required
            />
          </div>
        </div>
        <div>
          <div className='flex items-center gap-2 pb-1'>
            <Label>Data Previsão Disponibilização</Label>
            <Popover
              trigger='hover'
              content={
                <div className='w-64 text-xs text-gray-800 dark:text-gray-400'>
                  <div className='px-3 py-2'>
                    <p>
                      Ao alterar a data de disponibilização o pedido terá de ser
                      reprocessado.
                    </p>
                  </div>
                </div>
              }
              placement='top'
            >
              <InformationCircleIcon className='h-4 w-4 text-primary' />
            </Popover>
          </div>
          <div className='gap-2'>
            <CustomTextInput
              type='date'
              field='previsaoDisponibilidade'
              data-testid='previsaoDisponibilidade'
              formik={formik}
              min={getMinDisponibilidadeDate()}
              value={formik?.values.previsaoDisponibilidade}
              label=''
              className='w-full'
              required
              onChange={e =>
                handlePrevisaoDisponibilidadeChange(e.target.value)
              }
            />
          </div>
        </div>
      </div>
      <div className='col-span-4'>
        <CustomSelect
          options={[
            {
              label: 'Mensal',
              value: 'Mensal'
            },
            {
              label: 'Complementar',
              value: 'Completo'
            },
            {
              label: 'Admitidos',
              value: 'Admitidos'
            },
            {
              label: 'Reembolso',
              value: 'Reembolso'
            }
          ]}
          field='tipoPedidoVenda'
          data-testid='tipoPedidoVenda'
          formik={formik}
          value={formik.values.tipoPedidoVenda}
          onChange={value => {
            setEconomiaVisivel(value.target.value === 'Mensal')
            handlePodeAprovar(false)
            formik.setFieldValue('tipoPedidoVenda', value.target.value)
          }}
          label='Tipo de Pedido de Venda'
        />
      </div>
    </form>
  )
}

export default RevisarPedidoForm
