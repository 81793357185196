import { QueryFunctionContext } from 'react-query'

import { endPoints } from '@/configs/endPoints'
import {} from '@/interfaces/contrato'
import { IConfiguracaoCreditoCertoList } from '@/interfaces/configuracaoCreditoCerto'
import { API_PEDIDOS } from '@/utils/pedidos-fetcher'

export async function obterConfiguracoesDoCreditoCerto(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          idEmpregador?: string
          IdsUnidades?: string[]
        }
      }
    ]
  >
): Promise<IConfiguracaoCreditoCertoList> {
  const [, params] = ctx.queryKey
  const response = await API_PEDIDOS.post<IConfiguracaoCreditoCertoList>(
    endPoints.configuracoesCreditoCerto,
    {
      idEmpregador: params.params.idEmpregador,
      IdsUnidades: params.params.IdsUnidades
    }
  )

  return response
}
