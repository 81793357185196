'use client'

import { Breadcrumb } from 'flowbite-react'
import { HiHome } from 'react-icons/hi'

import { routeNames } from '@/configs/routePaths'

interface IBreadCrumbsProps {
  title: string
  url: string
}

function BreadCrumbs({ links }: { links: IBreadCrumbsProps[] }) {
  return (
    <Breadcrumb className='w-full text-xs'>
      <Breadcrumb.Item href={routeNames.dashboard} icon={HiHome}>
        Início
      </Breadcrumb.Item>
      {links.map((link, index) => (
        <Breadcrumb.Item href={link.url} key={index}>
          {link.title}
        </Breadcrumb.Item>
      ))}{' '}
    </Breadcrumb>
  )
}

export default BreadCrumbs
