import { QueryFunctionContext, UseInfiniteQueryResult } from 'react-query'

import { endPoints } from '../configs/endPoints'
import { ICargo, ICargos, ICargoItems } from '../interfaces/cargos'
import { API } from '../utils/fetcher'
import { IPagination } from '@/interfaces/pagination'
import { IDadosRequestParams } from '@/interfaces/request'
import { API_PEDIDOS } from '@/utils/pedidos-fetcher'

export async function createCargos(payload: ICargos) {
  const { Code, Name } = payload
  const response = await API.post<unknown>(endPoints.cargos, {
    Code,
    Name
  })

  return response
}

export async function getCargos(
  ctx: QueryFunctionContext<[string, { page: number; pageSize: number }]>
): Promise<ICargoItems> {
  const [, { page, pageSize }] = ctx.queryKey
  const data = await API.get<ICargoItems>(`${endPoints.cargos}`, {
    page,
    pageSize
  })

  return data
}

export async function getCargosList(
  ctx: QueryFunctionContext<[string, { page: number; pageSize: number }]>
): Promise<ICargoItems> {
  const [, { page, pageSize }] = ctx.queryKey
  const data = await API.get<ICargoItems>(`${endPoints.cargosList}`, {
    page,
    pageSize
  })

  return data
}

export async function getCargosInfinite(
  ctx: QueryFunctionContext<[string, { dataQuery: any }]>
): Promise<UseInfiniteQueryResult<IPagination<ICargo[]>>> {
  const { pageParam } = ctx
  const [, { dataQuery }] = ctx.queryKey
  const data = await API.get<ICargo>(
    `${endPoints.cargos}/?page=${pageParam || 1}`,
    dataQuery
  )

  return data
}

export async function getCargo(
  ctx: QueryFunctionContext<[string, { cargoId: string }]>
): Promise<ICargo[]> {
  const [, { cargoId }] = ctx.queryKey
  const data = await API.get<ICargo[]>(`${endPoints.cargos}/${cargoId}`)

  return data
}

export async function editCargos(payload: ICargos) {
  const { Code, Name, cargoId } = payload
  const response = await API.put<unknown>(`${endPoints.cargos}/${cargoId}`, {
    Code,
    Name
  })
  return response
}

export async function deleteCargos(payload: {
  cargoId: string
  newJobRole?: string
}) {
  const { cargoId, newJobRole } = payload
  const response = await API.delete(`${endPoints.cargos}/${cargoId}`, {
    newJobRole
  })
  return response
}

export async function getCargosListPedidos({
  params
}: {
  params: IDadosRequestParams
}) {
  const endpointCargos = endPoints.cargosListPedidos.replace(
    '{id-unidade}',
    params.idUnidade
  )
  const response = await API_PEDIDOS.get<any>(endpointCargos, {
    ...params
  })

  return response
}
