import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import {
  Alert,
  Button,
  Modal,
  Radio,
  Textarea,
  TextInput
} from 'flowbite-react'
import Select, { GroupBase, OptionsOrGroups } from 'react-select'

import { useEditarPedidosContext } from '@/contexts/editar-pedido.context'
import { IEditarPedidoModalProps } from '@/interfaces/pedidos'

const EditarPedidoEmMassaModal: React.FC<IEditarPedidoModalProps> = ({
  onClose,
  isOpen
}: IEditarPedidoModalProps) => {
  const {
    formikItemsBulkEdit,
    handleEditar,
    selectedOption,
    setNovoPeriodo,
    erros,
    periodosOptions,
    novaQtdDiaria,
    ativo,
    setJustificativa,
    setNovaQtdDiaria,
    setAtivo
  } = useEditarPedidosContext()

  return (
    <Modal
      show={isOpen}
      onClose={onClose}
      className='flex items-center'
      size='7xl'
    >
      <Modal.Header>
        <p className='text-center font-bold p-3 pb-1'>
          Editar Benefício Em Massa
        </p>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={formikItemsBulkEdit.handleSubmit}
          data-testid='form-items-bulk-edit'
        >
          <div className='grid grid-cols-12 bg-white rounded-xl gap-5 md:gap-0 p-5 mx-5 mb-3 border'>
            <div className='p-3 md:col-span-12 col-span-12 text-center md:text-left'>
              <span className='text-quaternary font-semibold pb-3 text-xl'>
                Novos Valores
              </span>
            </div>
            <div
              className='p-3 md:col-span-6 col-span-12 text-center md:text-left'
              data-testid='select-periodo'
            >
              <p>Período:</p>
              {
                <Select
                  options={
                    periodosOptions as OptionsOrGroups<
                      { value: number; label: string },
                      GroupBase<{ value: number; label: string }>
                    >
                  }
                  value={selectedOption}
                  onChange={selected => setNovoPeriodo(selected?.value)}
                  defaultValue={null}
                  placeholder='Selecione um período'
                  className='text-gray-900 text-sm'
                  isSearchable
                  escapeClearsValue
                  isClearable
                  noOptionsMessage={() => 'Sem resultados'}
                  required
                />
              }
              {erros?.find(e => e.campo == 'periodo') != undefined ? (
                <p
                  className='mt-2 text-sm text-red-600 dark:text-red-500'
                  data-testid='select-periodo-erro'
                >
                  {erros?.find(e => e.campo == 'periodo')?.erro}
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className='p-3 md:col-span-6 col-span-12 text-center md:text-left'>
              <p>Quantidade Diária:</p>
              {
                <TextInput
                  type='number'
                  color='light'
                  defaultValue={null}
                  value={novaQtdDiaria}
                  onChange={value =>
                    setNovaQtdDiaria(parseInt(value.target.value))
                  }
                  min={1}
                  step={1}
                  required
                  data-testid='select-qtd-diaria'
                />
              }
              {erros?.find(e => e.campo == 'qtdDiaria') != undefined ? (
                <p
                  className='mt-2 text-sm text-red-600 dark:text-red-500'
                  data-testid='select-qtd-diaria-erro'
                >
                  {erros?.find(e => e.campo == 'qtdDiaria')?.erro}
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className='p-3 md:col-span-12 col-span-12 text-center md:text-left'>
              <p>Situação: {}</p>
              <div className='flex gap-16 justify-left'>
                <div className='p-4 flex gap-2 items-center'>
                  <span className='flex text-gray-600 text-sm'>Removido</span>
                  <Radio
                    checked={!ativo}
                    onClick={() => setAtivo(false)}
                    value='false'
                    name='radio-removido'
                    className='flex text-red-600 focus:ring-red-600'
                    data-testid='radio-removido'
                  />
                </div>
                <div className='p-4 flex gap-2 items-center'>
                  <span className='flex text-gray-600 text-sm'>Ativo</span>
                  <Radio
                    checked={ativo}
                    onClick={() => setAtivo(true)}
                    value='true'
                    name='radio-ativo'
                    className='flex text-quaternary focus:ring-quaternary'
                    data-testid='radio-ativo'
                  />
                </div>
              </div>
            </div>
            <div className='p-3 md:col-span-12 col-span-12 text-center md:text-left'>
              <p>Justificativa:</p>
              {
                <Textarea
                  id='justificativa'
                  className='bg-white'
                  onChange={value => setJustificativa(value.target.value)}
                  required
                  data-testid='input-justificativa'
                />
              }
              {erros?.find(e => e.campo == 'justificativa') != undefined ? (
                <p
                  className='mt-2 text-sm text-red-600 dark:text-red-500'
                  data-testid='input-justificativa-erro'
                >
                  {erros?.find(e => e.campo == 'justificativa')?.erro}
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className='p-3 md:col-span-12 col-span-12 text-center md:text-right'>
              <Alert color='warning' className='mt-3'>
                <div className='flex justify-center'>
                  <ExclamationTriangleIcon className='w-5 h-5 mr-2' />
                  <span>
                    Atenção! Após salvar a edição, o pedido deverá ser
                    reprocessado para a alteração refletir no valor.
                  </span>
                </div>
              </Alert>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className='w-full flex justify-between'>
          <div className='flex justify-between gap-2 items-left'>
            <Button color='gray' onClick={onClose} data-testid='btn-close'>
              Cancelar
            </Button>
          </div>
          <div className='flex justify-between gap-2 items-right'>
            <Button
              color='success'
              onClick={handleEditar}
              className='bg-quaternary'
              data-testid='btn-save'
            >
              <span>Salvar</span>
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default EditarPedidoEmMassaModal
