import { SetStateAction, useMemo, useState } from 'react'

import debounce from 'debounce'
import { useParams } from 'react-router-dom'

import TableItem from '@/components/Table'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import { useGetLancamentos } from '@/hooks/queries/use-get-pedidos'
import { TABLE_HEADERS_LANCAMENTOS_PEDIDO } from '@/pages/Pedidos/constants'
import { formatCurrency } from '@/utils'

const DetailCobrancasPedido = () => {
  const { id } = useParams()
  const [search, setSearch] = useState('')
  const lancamentosPageSize = 10
  const [lancamentosPage, setLancamentosPage] = useState<number>(1)
  const lancamentosInicio = (lancamentosPage - 1) * lancamentosPageSize
  const { data: lancamentosList, isIdle } = useGetLancamentos({
    params: {
      pedidoId: id as string,
      procurar: search,
      inicio: lancamentosInicio,
      comprimento: lancamentosPageSize,
      tipoLancamentoFinanceiro: 1
    }
  })
  const lancamentosTotalPages = Math.ceil(
    (lancamentosList?.conteudo?.quantidadeDeItems ?? 0) / lancamentosPageSize
  )
  const handleSearch = debounce((e: SetStateAction<string>) => {
    setSearch(e)
  }, 500)

  const mapedlancamentos = useMemo(() => {
    return lancamentosList?.conteudo?.resultados.map(item => {
      return {
        descricao: item.descricao,
        valor: formatCurrency(item.valor)
      }
    })
  }, [lancamentosList])

  const valorTotal = lancamentosList?.conteudo?.resultados
    ?.reduce((acc, item) => acc + item?.valor, 0)
    .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  return (
    <>
      <div className='overflow-x-auto'>
        <SearchTableItem
          isAddItem={false}
          isSearchInputEnabled={true}
          setFilterText={e => handleSearch(e)}
        />
        <TableItem
          isActions={false}
          isRowClicable={false}
          data={mapedlancamentos || []}
          headers={TABLE_HEADERS_LANCAMENTOS_PEDIDO}
          deleteAction={() => void 0}
          hasCustomActions={false}
          idKey='pedidoId'
          isIdle={isIdle}
        />
        {!!lancamentosList?.conteudo?.resultados?.length && (
          <div className='w-full pt-5 flex items-center justify-end'>
            <span className='font-bold text-xl'>
              Valor total geral:{' '}
              <span className='text-quaternary'>{valorTotal}</span>
            </span>
          </div>
        )}
        <PaginationItem
          totalItems={
            lancamentosList?.conteudo?.quantidadeDeItems ?? (0 as number)
          }
          totalPages={lancamentosTotalPages}
          pageSize={lancamentosPageSize}
          onPageChange={(pageParam: number) => setLancamentosPage(pageParam)}
        />
      </div>
    </>
  )
}

export default DetailCobrancasPedido
