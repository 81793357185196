const GuestHeader = () => {
  return (
    <div className='flex p-5 bg-quaternary justify-center items-center'>
      <div className='container flex items-center md:flex-row flex-col justify-between'>
        <div className='flex md:flex-row md:w-1/2 container-lg items-center'>
          <img src='/images/logo_icon.svg' alt='Logo' />
          <p className='text-white font-semibold text-2xl mt-2 pl-1 hidden md:block'>
            Facilita a vida
          </p>
        </div>
        <div className='md:w-1/2 flex justify-center flex-col md:items-end items-center pt-0 md:pt-0'>
          <p className='text-white font-semibold text-lg text-center'>
            Vale-Transporte
          </p>
        </div>
      </div>
    </div>
  )
}

export default GuestHeader
