import { useState, useMemo } from 'react'

import { useQueryClient } from 'react-query'
import Select from 'react-select'

import toastService from '@/components/toast/toast-service'
import { usePlataformContext } from '@/contexts/platform.context'
import { useGetBranchesData } from '@/hooks/queries/use-get-branch-data'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { setUserBranch } from '@/services/branches'
import { TokenStorage } from '@/utils/token-storage'

const SelectBranch = () => {
  const [token, setToken] = useState('')
  const [isAuth, setIsAuth] = useState(false)
  const { sendMessage } = usePlataformContext()
  const { data: branches, isLoading } = useGetBranchesData()
  const { data: userData } = useGetUserData()
  const queryClient = useQueryClient()
  const AppUrl = process.env.VITE_APP_ONIX_URL

  const handleSelectedBranch = async (selectedOption: any) => {
    const selectedBranchId = selectedOption.value
    try {
      const data = await setUserBranch(selectedBranchId)
      TokenStorage.storeToken(data.token.token)
      setToken(data.token.token)
      setIsAuth(true)
    } catch (error) {
      console.log(error)
    } finally {
      queryClient.invalidateQueries()
      toastService.success('Unidade alterada.')
      sendMessage({ action: 'RELOAD' })
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  }

  const mapedData = useMemo(() => {
    return branches?.map((item: any) => ({
      value: item.branchId,
      label: `${item.name} - ${item.cnpj}`
    }))
  }, [branches])

  const selectedOption = mapedData?.find(
    option => option.value === userData?.authenticatedBranchId
  )

  const ChangeBranchIframe = ({ token }: { token: string }) => (
    <iframe
      width={250}
      height={250}
      className='hidden overflow-hidden'
      src={AppUrl + '/ApiAuth/AuthenticateFromMicroservice?token=' + token}
      title='Change Branch Authentication'
    />
  )
  return (
    <div className='w-full max-w-[250px]'>
      {isAuth && <ChangeBranchIframe token={token} />}
      <Select
        id='branches'
        onChange={handleSelectedBranch}
        isDisabled={isLoading}
        value={selectedOption}
        options={mapedData}
        className='text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500  w-full p-2 max-w-[400px] min-w-[120px] '
        isSearchable
        escapeClearsValue
        isClearable={false}
        noOptionsMessage={() => 'Sem resultados'}
        loadingMessage={() => 'Carregando...'}
        placeholder='Selecione...'
        styles={{
          menu: base => ({
            ...base,
            width: '139%'
          })
        }}
      />
    </div>
  )
}

export default SelectBranch
