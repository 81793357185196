export enum QueryKey {
  profileUser = 'profileUser',
  permissoes = 'permissoes',
  news = 'news',
  allowedPortal = 'allowedPortal',
  profile = 'profile',
  dashboard = 'dashboard',
  departamentos = 'departamentos',
  locaisDeEntrega = 'locais-de-entrega',
  cargos = 'cargos',
  beneficiarios = 'beneficiarios',
  branches = 'branches',
  accounts = 'accounts',
  cep = 'cep',
  blip = 'blip',
  logisticList = 'logistic-list',
  logisticListItems = 'logistic-list-items',
  pedidosListItems = 'pedidos-list-items',
  pendenciasList = 'pendencias-list-items',
  pedidoItemListDetail = 'pedidos-item-list-detail',
  pedidoItemList = 'pedidos-item-list',
  pedidosPendentesList = 'pedidos-pendentes-list',
  pedidosRevisaoListItems = 'pedidos-revisao-list-items',
  pedidoPendenciasList = 'pedidos-pendencias-list',
  pedidoRepassesList = 'pedidos-repasses-list',
  pedidoLancamentosList = 'pedidos-lancamentos-list',
  vendasList = 'vendas-list',
  pedidoDetalhe = 'pedido-detalhe',
  pedidoEdicao = 'pedido-edicao',
  pedidoEdicaoErros = 'pedido-edicao-erros',
  pedidosDetalhesList = 'pedidos-detalhes-list',
  errosPedido = 'erros-pedido',
  pedidoEdicaoItems = 'pedido-edicao-items',
  pedidosRevisaoOperadoras = 'pedidos-revisao-operadoras',
  pedidosRevisaoEconomiaUnidades = 'pedidos-revisao-economia-unidades',
  logistic = 'logistic',
  logisticStatusList = 'logistic-status-list',
  devolucoesList = 'devolucoes-list',
  downloadDevolucoesReport = 'download-devolucoes-report',
  confirmarPedidos = 'confirmar-pedidos',
  aceiteGestao = 'get-aceite-gestao'
}
