import { DocumentArrowUpIcon } from '@heroicons/react/24/outline'
import { Button, Modal } from 'flowbite-react'

import AcoesDevolucoesSucesso from './acoes-devolucoes-sucesso.component'
import { useDevolucoesContext } from '@/contexts/devolucoes.context'

interface IDetalhesDevolucoesModalProps {
  onClose: () => void
  isOpen: boolean
}

const DetalhesDevolucoesModal: React.FC<IDetalhesDevolucoesModalProps> = ({
  onClose,
  isOpen
}: IDetalhesDevolucoesModalProps) => {
  const {
    setIsActionsOpen,
    setIsResultadoModalOpen,
    handleConfirmarDevolucoesLista
  } = useDevolucoesContext()
  return (
    <>
      <Modal
        popup
        show={isOpen}
        onClose={onClose}
        className='flex items-center'
        size='2xl'
      >
        <Modal.Header className='bg-primary-hover text-white rounded-b-xl'>
          <p className='text-center font-semibold  p-3 text-white'>
            Detahes da Importacão
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className='grid grid-cols-1 gap-4 mt-0'>
            <AcoesDevolucoesSucesso />
            <div>
              <Button
                color='dark'
                size={'sm'}
                onClick={() => {
                  setIsActionsOpen(true)
                  setIsResultadoModalOpen(false)
                }}
              >
                <DocumentArrowUpIcon className='h-5 w-5 mr-1' />
                <span>Enviar novamente</span>
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='border-t'>
          <div className='flex justify-between items-center w-full'>
            <Button
              color='gray'
              size='sm'
              onClick={() => setIsResultadoModalOpen(false)}
            >
              Cancelar importação{' '}
            </Button>
            <Button
              color='success'
              size='sm'
              onClick={handleConfirmarDevolucoesLista}
            >
              Confirmar e Importar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DetalhesDevolucoesModal
