import EditarPedidosContainer from '@/containers/pedidos/editar-pedido.container'
import { EditarPedidosProvider } from '@/contexts/editar-pedido.context'

const EditarPedidoView = () => {
  return (
    <div className='w-full bg-white rounded-md p-5 md:-mt-8'>
      <h1 className='text-2xl pb-2'>Edite seu pedido</h1>
      <EditarPedidosProvider>
        <EditarPedidosContainer />
      </EditarPedidosProvider>
    </div>
  )
}

export default EditarPedidoView
