import { Modal } from 'flowbite-react'

interface IResumoPedidoModalProps {
  onClose: () => void
  isOpen: boolean
}

const ResumoPedidoModal: React.FC<IResumoPedidoModalProps> = ({
  onClose,
  isOpen
}: IResumoPedidoModalProps) => {
  return (
    <>
      <Modal
        popup
        show={isOpen}
        onClose={onClose}
        className='flex items-center'
        size='md'
      >
        <Modal.Header>
          <p className='text-center font-bold p-3'>
            Relatório de resumo do pedido
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className='grid grid-cols-1 gap-4 mt-0'>
            <div>
              <label className='block text-gray-700'>Resumo Completo</label>
              <p>Com todos os usuários do pedido.</p>
              <button
                type='button'
                className='btn btn-primary resumeButton bg-primary-dark text-white py-2 px-4 rounded w-full'
                id='completeResumeButton'
                data-paylotid='2992bcab-3705-42ec-8f14-201b1179dcc3'
              >
                Completo
              </button>
              <div id='completeResumeWarning' className='hidden text-red-500'>
                <i className='fa fa-exclamation-circle'></i> Disponível apenas
                para XLS.
              </div>
            </div>
            <div id='partialResume'>
              <label className='block text-gray-700'>Resumo Parcial</label>
              <p>Sem os usuários do pedido.</p>
              <button
                type='button'
                className='btn btn-primary align-center resumeButton bg-primary-dark text-white py-2 px-4 rounded w-full'
                id='partialResumeButton'
                data-paylotid='2992bcab-3705-42ec-8f14-201b1179dcc3'
              >
                Parcial
              </button>
              <div id='partialResumeWarning' className='hidden text-red-500'>
                <i className='fa fa-exclamation-circle'></i> Disponível apenas
                para PDF.
              </div>
            </div>
          </div>

          <div className='mt-4'>
            <label className='block text-gray-900 font-semibold'>
              Tipo de Relatório:
            </label>
          </div>
          <div className='grid grid-cols-1 gap-4 mt-0 pt-0'>
            <div>
              <input
                type='radio'
                name='reportType'
                id='pdfRadio'
                value='pdf'
                defaultChecked
                className='mr-2'
              />
              <label htmlFor='pdfRadio' className='text-gray-700'>
                PDF
              </label>
            </div>
            <div>
              <input
                type='radio'
                name='reportType'
                id='xlsRadio'
                value='xls'
                className='mr-2'
              />
              <label htmlFor='xlsRadio' className='text-gray-700'>
                XLS
              </label>
            </div>
          </div>

          <div className='mt-4'>
            <label className='block text-gray-900 font-semibold'>
              Agrupar por Departamento:
            </label>
          </div>
          <div className='grid grid-cols-1 gap-4 mt-0 pt-0'>
            <div>
              <input
                type='radio'
                name='groupByDepartment'
                id='groupByDepartmentYes'
                value='yes'
                defaultChecked
                className='mr-2'
              />
              <label htmlFor='groupByDepartmentYes' className='text-gray-700'>
                Sim
              </label>
            </div>
            <div>
              <input
                type='radio'
                name='groupByDepartment'
                id='groupByDepartmentNo'
                value='no'
                className='mr-2'
              />
              <label htmlFor='groupByDepartmentNo' className='text-gray-700'>
                Não
              </label>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ResumoPedidoModal
