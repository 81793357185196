import { QueryFunctionContext } from 'react-query'

import { endPoints } from '@/configs/endPoints'
import {
  IDevolucoesLogsResultado,
  IDevolucoesPedido,
  IDevolucoesPedidoParams,
  IDevolucoesResultado
} from '@/interfaces/devolucoes'
import { IGenericResponse } from '@/interfaces/generic'
import { API } from '@/utils/fetcher'
import { API_PEDIDOS } from '@/utils/pedidos-fetcher'

export async function getDevolucoesItemPedidoList(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: IDevolucoesPedidoParams
      }
    ]
  >
): Promise<IDevolucoesPedido> {
  const [, { params }] = ctx.queryKey
  const response = await API_PEDIDOS.get<IDevolucoesPedido>(
    endPoints.getDevolucoesItemPedidoList,
    {
      ...params
    }
  )

  return response
}

export async function getDevolucoesList(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          inicio: number
          comprimento: number
          procurar?: string
          idEmpregador: string
          dataInicio?: string
          dataFim?: string
          campo: string
          direcao: 'asc' | 'desc'
          situacao?: string
          numeroPedido?: string
          cpf?: string
          idsUnidades: string[]
          tipoDevolucao?: string
        }
      }
    ]
  >
): Promise<IDevolucoesResultado> {
  const [, { params }] = ctx.queryKey
  const response = await API_PEDIDOS.post<IDevolucoesResultado>(
    endPoints.getDevolucoesList,
    {
      ...params,
      idsPedidos: [],
      procurar: params.procurar === '' ? undefined : params.procurar,
      dataInicio: params.dataInicio === '' ? undefined : params.dataInicio,
      dataFim: params.dataFim === '' ? undefined : params.dataFim,
      situacao:
        params.situacao === '' || params.situacao === 'Todos'
          ? undefined
          : params.situacao,
      numeroPedido: params.numeroPedido === '' ? 0 : params.numeroPedido ?? 0,
      cpf: params.cpf === '' ? undefined : params.cpf,
      tipoDevolucao:
        params.tipoDevolucao === '' ? undefined : params.tipoDevolucao
    }
  )

  return response
}

export async function downloadDevolucoesReport( //TO REVIEW
  ctx: QueryFunctionContext<[string, { id: string }]>
): Promise<{ url: string }> {
  const [, { id }] = ctx.queryKey
  const data = await API.get<{ url: string }>(
    `${endPoints.departamentos}/${id}`
  )

  return data
}

export async function getLogsDevolucoes({
  params
}: {
  params: {
    inicio: number
    comprimento: number
    procurar?: string
    campo: string
    direcao: 'asc' | 'desc'
    idGeralDevolucao: string
  }
}): Promise<IDevolucoesLogsResultado> {
  const response = await API_PEDIDOS.get<IDevolucoesLogsResultado>(
    endPoints.logsDevolucoes,
    {
      ...params
    }
  )

  return response
}

export async function downloadComprovante({
  params
}: {
  params: {
    idItemVenda: string
    idUnidade: string
    idUsuarioSolicitante: string
  }
}): Promise<unknown> {
  return await API_PEDIDOS.post<unknown>(endPoints.comprovanteDevolucao, {
    ...params
  })
}

export async function tratarDevolucao(payload: any) {
  const response = await API_PEDIDOS.post<IGenericResponse>(
    endPoints.tratarDevolucao,
    {
      ...payload
    }
  )

  return response
}
