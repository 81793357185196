import { useQuery, UseQueryResult } from 'react-query'

import { QueryKey } from '@/configs/queryKeys'
import { IUnidadesAtivas, IUnidadesParams } from '@/interfaces/unidades'
import { getUnidadesAtivas } from '@/services/unidades'

export function useGetUnidadesAtivas({
  params
}: {
  params: IUnidadesParams
}): UseQueryResult<IUnidadesAtivas> {
  return useQuery([QueryKey.pendenciasList, { params }], getUnidadesAtivas, {
    keepPreviousData: true,
    retry: false,
    enabled: !!params.idDoEmpregador
  })
}
