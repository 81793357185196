import { useEffect } from 'react'

import { Link } from 'react-router-dom'

import SearchList from './search.component'
import NavBar from '../nav-bar'
import SelectBranch from '@/components/select-branch'
import ToolTipMenu from '@/components/tooltip-menu'
import UserMenu from '@/components/user-menu'
import { routeNames } from '@/configs/routePaths'
import { useGetBlipChat } from '@/hooks/queries/use-get-user-data'
import { LoadChat } from '@/utils/load-chat'
import 'intro.js/introjs.css'

const ContentHeader = () => {
  const userMenu = () => <UserMenu />
  const { data, isLoading } = useGetBlipChat()
  useEffect(() => {
    !isLoading && LoadChat(data)
    return
  }, [data])

  return (
    <>
      <div className='flex bg-quaternary shadow justify-center items-center flex-col border-b-2 border-gray-200'>
        <div className='grid grid-cols-12 py-5 md:px-3 px-5 relative w-full md:max-w-[1600px]'>
          <div className='col-span-12 xl:col-span-4 lg:col-span-3 flex md:justify-center justify-between md:pb-5 pb-0 lg:pb-0 lg:justify-start items-center'>
            <Link to={routeNames.dashboard}>
              <img
                src='/images/logo_icon.svg'
                alt='Logo'
                width={60}
                height={25}
                className=' h-auto'
              />
            </Link>
            <div className='block md:hidden'>{userMenu()}</div>
            <p className='font-semibold text-2xl pl-2 pt-1 hidden md:hidden xl:block kg:hidden text-white'>
              Vale-transporte
            </p>
          </div>
          <div className='search-item absolute md:relative md:top-0 md:right-0 right-5 bottom-[-3.2rem] col-span-12 xl:col-span-3 lg:col-span-4 flex justify-center items-center md:pb-5 lg:pb-0'>
            <SearchList />
          </div>
          <div className='col-span-12 xl:col-span-5 lg:col-span-5 flex justify-between md:justify-end items-center md:gap-5 mt-3 md:mt-0'>
            <SelectBranch />
            <p className='text-white px-2 hidden md:block'>
              <img src='/images/bar.svg' />
            </p>
            <ToolTipMenu />
            <div className='hidden md:flex flex-row items-center justify-center'>
              {userMenu()}
            </div>
          </div>
        </div>
        <div className='w-full bg-gray-200'>
          <NavBar />
        </div>
      </div>
    </>
  )
}

export default ContentHeader
