import { useQuery, UseQueryResult } from 'react-query'

import toastService from '@/components/toast/toast-service'
import { QueryKey } from '@/configs/queryKeys'
import {
  IAceiteGestaoInfoResultado,
  IContratoList
} from '@/interfaces/contrato'
import { getAceiteGestao, getContratoPorUnidade } from '@/services/contratos'

export function useGetContratos({
  params
}: {
  params: {
    idEmpregador: string
    idUnidades?: string[]
  }
}): UseQueryResult<IContratoList> {
  return useQuery(['get-contrato-list', { params }], getContratoPorUnidade, {
    staleTime: 1000 * 60 * 60 * 24, // 1 day,
    cacheTime: 10 * (60 * 1000), // 10 mins
    retry: true,
    refetchOnReconnect: false,
    enabled: !!params.idEmpregador && !!params.idUnidades,
    onError: () => {
      toastService.error(
        'Falha ao buscar as informações dos contratos. Por favor, tente novamente.'
      )
    }
  })
}

export function useGetAceiteGestao({
  params
}: {
  params: {
    idEmpregador: string
  }
}): UseQueryResult<IAceiteGestaoInfoResultado> {
  return useQuery([QueryKey.aceiteGestao, { params }], getAceiteGestao, {
    retry: true,
    enabled: !!params.idEmpregador,
    onError: () => {
      toastService.error(
        'Falha ao buscar as informações de aceite da gestão. Por favor, tente novamente.'
      )
    }
  })
}
