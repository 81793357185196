export function customRound(number: number) {
  if (number >= 1.1) {
    return 2
  }
  const integerPart = Math.floor(number)
  const decimalPart = number - integerPart
  if (decimalPart > 0.1) {
    return Math.ceil(number)
  } else {
    return integerPart
  }
}
