import { UseQueryResult, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import toastService from '@/components/toast/toast-service'
import { QueryKey } from '@/configs/queryKeys'
import { routeNames } from '@/configs/routePaths'
import { IPermissoesResponse } from '@/interfaces/user'
import { getUserPermissions } from '@/services/users'

export function useGetPermissoes(): UseQueryResult<IPermissoesResponse> {
  const navigate = useNavigate()
  return useQuery([QueryKey.permissoes], getUserPermissions, {
    keepPreviousData: true,
    enabled: false,
    refetchOnWindowFocus: true,
    onError() {
      toastService.error(
        'Erro ao selecionar a conta, por favor tente novamente.'
      )
      navigate(routeNames.login)
    }
  })
}
