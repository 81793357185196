import { useMemo } from 'react'

import { Button, Label } from 'flowbite-react'
import SelectMulti from 'react-select'
import Select from 'react-tailwindcss-select'

import CustomTextInput from '@/components/basic/input-text/input-text.component'
import CustomSelect from '@/components/basic/select/select.component'
import { usePedidosContext } from '@/contexts/pedidos.context'
import { useGetBranchesData } from '@/hooks/queries/use-get-branch-data'

const AcompanharPedidosFilterForm = () => {
  const {
    formik,
    isLoadingPedidos,
    handleSelectedBranch,
    handleClearForm,
    formRef
  } = usePedidosContext()
  const { data: branches } = useGetBranchesData()

  const mapedData = useMemo(() => {
    const allBranchesOption = {
      value: 'todos',
      label: 'Todas'
    }

    const mappedBranches = branches?.map((item: any) => ({
      value: item.branchId,
      label: `${item.name}-${item.cnpj}`
    }))

    return [allBranchesOption, ...mappedBranches]
  }, [branches])

  return (
    <form
      ref={formRef}
      onSubmit={formik.handleSubmit}
      className='flex flex-col md:grid grid-cols-12 w-full p-3 bg-white gap-5 mb-5 rounded-lg'
    >
      <div className='col-span-8'>
        <Label htmlFor='branches'>Unidade</Label>
        <SelectMulti
          id='idUnidade'
          isMulti
          onChange={handleSelectedBranch}
          value={formik.values.unidades || []}
          options={mapedData}
          className='text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500  w-full'
          isSearchable
          escapeClearsValue
          isClearable={false}
          noOptionsMessage={() => 'Sem resultados'}
          placeholder='Selecione...'
          styles={{
            menu: base => ({
              ...base,
              width: '100%'
            })
          }}
        />
      </div>
      <div className='col-span-4'>
        <CustomTextInput
          type='text'
          field='idPedidos'
          formik={formik}
          label='Nº Pedido'
        />
      </div>
      <div className='col-span-3'>
        <CustomTextInput
          type='date'
          field='dataInicio'
          formik={formik}
          defaultValue={formik.values.dataInicio}
          max={new Date().toISOString().split('T')[0]}
          label='Criados de:'
        />
      </div>
      <div className='col-span-3'>
        <CustomTextInput
          type='date'
          field='dataFim'
          formik={formik}
          defaultValue={formik.values.dataFim}
          min={formik.values.dataInicio}
          label='Até:'
        />
      </div>
      <div className='col-span-3'>
        <CustomTextInput
          type='date'
          field='paymentDateStart'
          formik={formik}
          label='Pagamentos realizados de:'
        />
      </div>
      <div className='col-span-3'>
        <CustomTextInput
          type='date'
          field='paymentDateEnd'
          formik={formik}
          label='Até:'
        />
      </div>
      <div className='col-span-6'>
        <Label htmlFor='selectedLocaisDeEntrega' className='font-semibold mb-2'>
          Situação Financeira
        </Label>
        <Select
          primaryColor='#2563EB'
          placeholder='Selecione...'
          isMultiple
          data-testid='situacao-select'
          value={formik.values.situacoesFinanceiras}
          onChange={value =>
            formik.setFieldValue('situacoesFinanceiras', value)
          }
          options={[
            {
              label: 'Pago',
              value: 'Pago'
            },
            {
              label: 'Não Pago',
              value: 'NaoPago'
            },
            {
              label: 'Cancelado',
              value: 'Cancelado'
            },
            {
              label: 'Liberado',
              value: 'Liberado'
            }
          ]}
        />
      </div>
      <div className='col-span-6'>
        <Label htmlFor='selectedLocaisDeEntrega' className='font-semibold mb-2'>
          Situação Operacional
        </Label>
        <Select
          primaryColor='#2563EB'
          placeholder='Selecione...'
          isMultiple
          data-testid='situacao-select'
          value={formik.values.situacoesOperacionais}
          onChange={value =>
            formik.setFieldValue('situacoesOperacionais', value)
          }
          options={[
            {
              label: 'Não Confirmado',
              value: 'NaoConfirmado'
            },
            {
              label: 'Confirmado',
              value: 'Confirmado'
            },
            {
              label: 'Em Produção',
              value: 'EmProducao'
            },
            {
              label: 'Finalizado',
              value: 'Finalizado'
            },
            {
              label: 'Cancelado',
              value: 'Cancelado'
            }
          ]}
        />
      </div>
      <div className='col-span-4 hidden'>
        <CustomSelect
          options={[
            {
              label: 'Com pendências',
              value: '0'
            },
            {
              label: 'Sem pendências',
              value: '1'
            }
          ]}
          field='tipoPedido'
          formik={formik}
          label='Pedidos'
        />
      </div>
      <div className='col-span-4 hidden'>
        <CustomSelect
          options={[
            {
              label: 'Todos',
              value: '0'
            },
            {
              label: 'Não Pago',
              value: '1'
            },
            {
              label: 'Pago',
              value: '2'
            },
            {
              label: 'Cancelado',
              value: '3'
            },
            {
              label: 'Aguardando pagamento PIX',
              value: '4'
            }
          ]}
          field='tipoSituacaoPedidoVenda'
          formik={formik}
          label='Situação Financeira'
        />
      </div>
      <div className='col-span-4 hidden'>
        <CustomSelect
          options={[
            {
              label: 'Não confirmado',
              value: '0'
            },
            {
              label: 'Processado',
              value: '1'
            },
            {
              label: 'Em produção',
              value: '2'
            },
            {
              label: 'Finalizado',
              value: '3'
            },
            {
              label: 'Cancelado',
              value: '4'
            }
          ]}
          field='DeliveryStatus'
          formik={formik}
          label='Situação Operacional'
        />
      </div>
      <div className='col-span-12 flex justify-end items-end'>
        <div className='flex'>
          <Button
            color='ghost'
            size={'sm'}
            className='text-xs'
            onClick={handleClearForm}
          >
            Limpar
          </Button>
          <Button
            className='bg-quaternary ml-2'
            type='submit'
            size={'sm'}
            disabled={isLoadingPedidos}
            isProcessing={isLoadingPedidos}
          >
            Filtrar
          </Button>
        </div>
      </div>
    </form>
  )
}
export default AcompanharPedidosFilterForm
