import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getDatePlus10Days(): string {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 10)

  // Format the date as a string (e.g., 'YYYY-MM-DD')
  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')
  const day = String(currentDate.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export const formatDate = (date: Date): string => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const formatCurrency = (value: number): string => {
  return value?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

export const appendCurrentTime = (date: string) => {
  const currentTime = new Date().toLocaleTimeString('pt-BR', {
    hour12: false,
    timeZone: 'America/Sao_Paulo'
  })
  return `${date}T${currentTime}`
}
