import {
  createContext,
  useContext,
  ReactNode,
  useState,
  SetStateAction,
  Dispatch,
  useMemo
} from 'react'

import { FormikProps, useFormik } from 'formik'
import { useParams } from 'react-router-dom'

import toastService from '@/components/toast/toast-service'
import { useEditarPedidosEmMassa } from '@/hooks/mutations/use-editar-pedidos-em-massa'
import {
  useEditarItensPedidoMutation,
  useReprocessarPedidoMutation
} from '@/hooks/mutations/use-pedidos-mutation'
import { useGetVendas } from '@/hooks/queries/use-get-pedidos'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import {
  IEdicaoPedidoItem,
  IFormPropsBulkEdit,
  IVendaResultado
} from '@/interfaces/pedidos'

interface EditarPedidoContextProps {
  pedido: IVendaResultado | undefined
  isLoadingPedido: boolean
  formik: FormikProps<IFormProps>
  itens: IEdicaoPedidoItem[] | undefined
  totalItens: number
  setPage: Dispatch<SetStateAction<number>>
  totalPages: number
  pageSize: number
  setPageSize: Dispatch<SetStateAction<number>>
  setSearch: Dispatch<SetStateAction<string>>
  somenteErros: boolean
  setSomenteErros: Dispatch<SetStateAction<boolean>>
  itemEmEdicao: string
  setItemEmEdicao: Dispatch<SetStateAction<string>>
  handleReprocessarPedido: () => void
  enableBulkEdit: () => void
  isModalOpen: boolean
  itensSelecionados: IEdicaoPedidoItem[]
  formikItemsBulkEdit: FormikProps<IFormPropsBulkEdit>
  itensEditBulkOrders: IEdicaoPedidoItem[] | undefined
  checkedItemsBulkEdit: string[]
  itemEditBulkOrders: string
  itemsSelectedEditBulkOrders: IEdicaoPedidoItem[]
  handleCheckboxChangeEditBulkOrders: (itemId: string) => void
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  handleEditar: () => void
  selectedOption: any
  novoPeriodo: number | undefined
  setNovoPeriodo: Dispatch<SetStateAction<number | undefined>>
  erros: { campo: string; erro: string }[] | undefined
  setErros: Dispatch<
    SetStateAction<{ campo: string; erro: string }[] | undefined>
  >
  periodosOptions: { value: number; label: string }[]
  setPeriodosOptions: () => void
  novaQtdDiaria: number | undefined
  ativo: boolean | undefined
  setJustificativa: Dispatch<SetStateAction<string>>
  setNovaQtdDiaria: Dispatch<SetStateAction<number | undefined>>
  setAtivo: Dispatch<SetStateAction<boolean | undefined>>
}

interface IFormProps {
  pedidoId: string
  valorLinha: number
  quantidadeDiaria: number
  periodo: number
  status: boolean
  itemIds: string[]
  justificativa: string
}

const EditarPedidosContext = createContext<
  EditarPedidoContextProps | undefined
>(undefined)

const EditarPedidosProvider = ({ children }: { children: ReactNode }) => {
  const { idPedido, idOperadora, idUnidade } = useParams()
  const { data: userData } = useGetUserData()
  const { mutate: reprocessarPedido } = useReprocessarPedidoMutation()
  const { mutate: editarItemPedido } = useEditarItensPedidoMutation()
  const {
    itensEditBulkOrders,
    itemEditBulkOrders,
    checkedItemsBulkEdit,
    itemsSelectedEditBulkOrders,
    handleCheckboxChangeEditBulkOrders,
    formikItemsBulkEdit,
    setNovoPeriodo,
    erros,
    setErros,
    periodosOptions,
    novaQtdDiaria,
    ativo,
    setJustificativa,
    setAtivo,
    setNovaQtdDiaria,
    justificativa,
    novaTarifa,
    validacaoForm,
    novoPeriodo,
    selectedOption,
    setPeriodosOptions
  } = useEditarPedidosEmMassa()

  const { data: pedido, isLoading: isLoadingPedido } = useGetVendas({
    params: {
      pedidoId: idPedido ?? '',
      unidadeId: idUnidade ?? '',
      operadoraId:
        idOperadora === 'vr-credito' ? undefined : idOperadora ?? undefined,
      inicio: 0,
      comprimento: 1000,
      agrupar: true,
      tipoVenda: idOperadora === 'vr-credito' ? 'VrCredito' : ''
    }
  })

  const [pageSize, setPageSize] = useState<number>(10)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState<number>(1)
  const [somenteErros, setSomenteErros] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [itensSelecionados, setItensSelecionados] = useState<
    IEdicaoPedidoItem[]
  >([])

  const itensInicio = (page - 1) * pageSize
  const itensFim = page * pageSize
  const itens = useMemo(() => {
    let itensVenda = pedido?.conteudo?.resultados[0]?.itensVenda ?? []
    if (somenteErros)
      itensVenda =
        pedido?.conteudo?.resultados[0]?.itensVenda?.filter(
          i => i.errosItem.length != 0
        ) ?? []

    return itensVenda
      ?.filter(
        i =>
          i.trabalhador.nome.includes(search) ||
          i.trabalhador.cpf.includes(search) ||
          i.trabalhador.matricula.includes(search) ||
          i.codigoTarifa.includes(search) ||
          i.descricaoTarifa.includes(search)
      )
      .slice(itensInicio, itensFim)
      .map(item => {
        return {
          pedidoId: pedido?.conteudo?.resultados[0]?.idPedidoVenda,
          vendaId: item.idVenda,
          itemId: item.id,
          nome: item.trabalhador.nome,
          cpf: item.trabalhador.cpf,
          matricula: item.trabalhador.matricula,
          codigoLinha: item.codigoTarifa,
          nomeLinha: item.descricaoTarifa,
          valorLinha: item.tarifa,
          diasCompra: item.diasUso,
          quantidadeDiaria: item.quantidadePorDia,
          valorRecarga: item.total,
          erro: item.errosItem.length != 0 ? item.errosItem[0].mensagem : '',
          status: item.tipoSituacaoItemPedido === 'Ativo',
          variavel: true
        } as IEdicaoPedidoItem
      })
  }, [pedido, page, somenteErros, search, pageSize])
  const totalPages = Math.ceil(
    (pedido?.conteudo?.resultados[0]?.itensVenda
      .filter(i => !somenteErros || i.errosItem.length != 0)
      .filter(
        i =>
          i.trabalhador.nome.includes(search) ||
          i.trabalhador.cpf.includes(search) ||
          i.trabalhador.matricula.includes(search) ||
          i.codigoTarifa.includes(search) ||
          i.descricaoTarifa.includes(search)
      ).length ?? 0) / pageSize
  )
  const totalItens =
    pedido?.conteudo?.resultados[0]?.itensVenda
      .filter(i => !somenteErros || i.errosItem.length != 0)
      .filter(
        i =>
          i.trabalhador.nome.includes(search) ||
          i.trabalhador.cpf.includes(search) ||
          i.trabalhador.matricula.includes(search) ||
          i.codigoTarifa.includes(search) ||
          i.descricaoTarifa.includes(search)
      ).length ?? 0

  const [itemEmEdicao, setItemEmEdicao] = useState('')

  const formik = useFormik({
    initialValues: {
      pedidoId: idPedido,
      itemIds: [itemEmEdicao],
      valorLinha: 1,
      quantidadeDiaria: 1,
      periodo: 1,
      status: true,
      justificativa: ''
    } as IFormProps,
    onSubmit: values => {
      const itensDTO = values.itemIds.map(i => {
        return {
          id: i,
          diasUso: values.periodo,
          quantidadePorDia: values.quantidadeDiaria,
          tarifa: values.valorLinha,
          tipoSituacaoItemPedido: values.status ? 'Ativo' : 'Removido',
          observacao: values.justificativa
        }
      })
      const DTO = {
        atualizadoPor: userData?.userId,
        itensVenda: itensDTO,
        idPedido: idPedido,
        idVenda:
          pedido?.conteudo?.resultados[0]?.itensVenda?.find(
            item => item.id === itemEmEdicao
          )?.idVenda ?? ''
      }
      editarItemPedido(DTO)
    }
  })

  const handleReprocessarPedido = () => {
    const DTO = {
      idsPedidoVenda: [idPedido],
      criadoPor: userData?.userId,
      idEmpregador: userData?.authenticatedAccountId,
      errosIgnorados: false,
      idAplicacao: process.env.VITE_APP_APP_ID
    }
    reprocessarPedido(DTO)
  }

  const enableBulkEdit = () => {
    const selecionados = itens.filter(item =>
      checkedItemsBulkEdit.includes(item.itemId)
    )
    setItensSelecionados(selecionados)
    setIsModalOpen(true)
  }

  const handleEditar = () => {
    formikItemsBulkEdit.setValues({
      pedidoId: formikItemsBulkEdit.values.pedidoId,
      itemIds: checkedItemsBulkEdit,
      periodo: novoPeriodo ?? 1,
      quantidadeDiaria: novaQtdDiaria ?? 1,
      valorLinha: novaTarifa ?? 1,
      status: ativo ?? true,
      justificativa: justificativa,
      handleCheckboxChangeEditBulkOrders:
        formikItemsBulkEdit.values.handleCheckboxChangeEditBulkOrders,
      checkedItemsBulkEdit: formikItemsBulkEdit.values.checkedItemsBulkEdit
    })
    const validacao = validacaoForm()
    setErros(validacao)
    if (validacao?.length === 0) {
      formikItemsBulkEdit.submitForm()
      setIsModalOpen(false)
    } else toastService.error('A edição do item contêm erros')
  }
  const contextValue = useMemo(
    () => ({
      pedido,
      isLoadingPedido,
      formik,
      itens,
      totalItens,
      setPage,
      totalPages,
      pageSize,
      setPageSize,
      setSearch,
      somenteErros,
      setSomenteErros,
      itemEmEdicao,
      setItemEmEdicao,
      handleReprocessarPedido,
      enableBulkEdit,
      isModalOpen,
      itensSelecionados,
      formikItemsBulkEdit,
      itensEditBulkOrders,
      checkedItemsBulkEdit,
      itemEditBulkOrders,
      itemsSelectedEditBulkOrders,
      handleCheckboxChangeEditBulkOrders,
      setIsModalOpen,
      handleEditar,
      selectedOption,
      setNovoPeriodo,
      erros,
      setErros,
      periodosOptions,
      novaQtdDiaria,
      ativo,
      setJustificativa,
      setNovaQtdDiaria,
      setAtivo,
      setPeriodosOptions,
      novoPeriodo
    }),
    [
      pedido,
      isLoadingPedido,
      formik,
      itens,
      totalItens,
      setPage,
      totalPages,
      pageSize,
      setPageSize,
      setSearch,
      somenteErros,
      setSomenteErros,
      itemEmEdicao,
      setItemEmEdicao,
      handleReprocessarPedido,
      enableBulkEdit,
      isModalOpen,
      itensSelecionados,
      formikItemsBulkEdit,
      itensEditBulkOrders,
      checkedItemsBulkEdit,
      itemEditBulkOrders,
      itemsSelectedEditBulkOrders,
      handleCheckboxChangeEditBulkOrders,
      setIsModalOpen,
      handleEditar,
      selectedOption,
      setNovoPeriodo,
      erros,
      setErros,
      periodosOptions,
      novaQtdDiaria,
      ativo,
      setJustificativa,
      setNovaQtdDiaria,
      setAtivo,
      setPeriodosOptions
    ]
  )

  return (
    <EditarPedidosContext.Provider value={contextValue}>
      {children}
    </EditarPedidosContext.Provider>
  )
}

const useEditarPedidosContext = () => {
  const context = useContext(EditarPedidosContext)
  if (context === undefined) {
    throw new Error(
      'useEditarPedidosContext must be used within a EditarPedidosProvider'
    )
  }
  return context
}

export { EditarPedidosProvider, useEditarPedidosContext }
