import { useEffect, useState } from 'react'

import axios from 'axios'
import Cookie from 'js-cookie'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import toastService from '@/components/toast/toast-service'
import Constants from '@/configs/constants'
import { endPoints } from '@/configs/endPoints'
import { OnixRouteNames, routeNames } from '@/configs/routePaths'
import useLocalStorage from '@/hooks/common/use-local-storage'
import { useGetPermissoes } from '@/hooks/queries/use-get-permissoes'
import { IPermissoes } from '@/interfaces/user'
import { getBranchUserAccount } from '@/services/branches'
import { createContext } from '@/utils/create-context'
import { API_URL } from '@/utils/fetcher'
import { TokenStorage } from '@/utils/token-storage'
interface IContextProps {
  logOut: () => void
  isAuthenticated: boolean
  isAllowedV2Pedidos: boolean
  legacyAuth: (token: string) => void
  vrIdAuth: (token: string) => void
  isLoadingAuth: boolean
  isLoadingRedirect: boolean
  handleSelectAccount: (
    selectedBranch: string,
    allowedToPortalNewVersion: boolean
  ) => void
  handleRedirectDashboard: (token: string) => void
  handleRedictAfterLogin: (token: string) => void
  userPermissions: IPermissoes[]
  setStoragePermissions: (permissions: IPermissoes[]) => void
}

interface IProviderProps {
  children: JSX.Element
}

const [useUserContext, UserContext] = createContext<IContextProps>()

const UserProvider = ({ children }: IProviderProps) => {
  const isAuthenticated = Boolean(Cookie.get(Constants.TOKEN_STORAGE_KEY))
  const [storagePermissions, setStoragePermissions] = useLocalStorage(
    'userPermissions',
    ''
  )

  const AppUrl = process.env.VITE_APP_ONIX_URL ?? ''
  const queryClient = useQueryClient()
  const { refetch: getUserPermissions } = useGetPermissoes()
  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(false)
  const [isAuth, setIsAuth] = useState(false)
  const [isLogOut, setIsLogOut] = useState(false)
  const [token, setToken] = useState('')
  const [isLoadingRedirect, setIsLoadingRedirect] = useState<boolean>(false)
  const [isAllowedV2, setIsAllowedV2] = useLocalStorage('isAllowedV2', '')
  const [isAllowedV2Pedidos, setIsAllowedV2Pedidos] = useState<boolean>(
    isAllowedV2 ? (isAllowedV2 as boolean) : false
  )
  const [userPermissions, setUserPermissions] = useState<IPermissoes[]>(
    storagePermissions ? (storagePermissions as IPermissoes[]) : []
  )

  useEffect(() => {
    const isInV1Experience = OnixRouteNames.includes(location.pathname)
    const blip = document.getElementById(Constants.BLIP_REF)
    if (blip != null) {
      blip.className = isInV1Experience ? 'hidden' : 'block'
    }
  }, [location.pathname])

  const navigate = useNavigate()

  const logOut = async () => {
    setIsLogOut(true)
    setIsAuth(true)

    setTimeout(() => {
      setIsAuth(false)
      setIsLogOut(false)
      window.location.href = routeNames.login
    }, 1000)

    localStorage.clear()
    TokenStorage.logout()
    queryClient.clear()
  }

  const legacyAuth = async (token: string | null) => {
    if (token) {
      setIsLoadingAuth(true)
      await axios
        .get(API_URL + endPoints.accounts, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(() => {
          toastService.success('Login realizado!')
          TokenStorage.storeToken(token)
          navigate(routeNames.selectAccounts)
        })
        .catch(error => {
          const messsage =
            error?.response?.data?.userMessage ?? Constants.ERROR_DATA
          toastService.error(messsage)
        })
        .finally(() => {
          setIsLoadingAuth(false)
        })
    }
  }

  const vrIdAuth = async (token: string | null) => {
    if (token) {
      setIsLoadingAuth(true)
      await axios
        .post(API_URL + endPoints.vrIdUser, { AccessToken: token })
        .then((data: any) => data.data)
        .then((data: any) => {
          toastService.success('Login realizado!')
          TokenStorage.storeToken(data.token.token)
          navigate(routeNames.selectAccounts)
        })
        .catch(error => {
          const messsage =
            error?.response?.data?.userMessage ?? Constants.ERROR_DATA
          toastService.error(messsage)
        })
        .finally(() => {
          setIsLoadingAuth(false)
        })
    }
  }

  const handleRedictAfterLogin = (token: string) => {
    setIsLoadingRedirect(true)
    location.replace(
      `${AppUrl}${endPoints.loginWithMicroserviceToken}?token=${token}`
    )
  }

  const handleSelectAccount = async (selectedBranch: string) => {
    setIsLoadingRedirect(true)
    try {
      await getBranchUserAccount(selectedBranch ?? '')
        .then(data => {
          setIsAllowedV2Pedidos(data.allowedToPedidosNewVersion)
          setIsAllowedV2(data.allowedToPedidosNewVersion)
          return data
        })
        .then(content => {
          getUserPermissions()
            .then(data => {
              console.log(
                'USER PERMISSIONS',
                data.data.conteudo.controlesDeAcesso
              )
              setUserPermissions(data.data.conteudo.controlesDeAcesso)
              setStoragePermissions(data.data.conteudo.controlesDeAcesso)
            })
            .then(() => {
              if (content.allowedToPortalNewVersion) {
                TokenStorage.storeToken(content.token.token)
                setToken(content.token.token)
                setIsAuth(true)
                setIsLoadingRedirect(false)
              }
              setTimeout(() => {
                if (!content.allowedToPortalNewVersion) {
                  TokenStorage.logout()
                  handleRedictAfterLogin(content.token.token)
                } else {
                  navigate(routeNames.dashboard)
                }
                navigate(routeNames.dashboard)
              }, 1500)
            })
            .finally(() => {
              setIsLoadingRedirect(false)
            })
        })
    } catch (error) {
      setIsLoadingRedirect(false)
    }
  }

  const handleRedirectDashboard = (token: string) => {
    if (token) {
      setIsLoadingAuth(true)
      setToken(token)
      setIsAuth(true)
      TokenStorage.storeToken(token)
      setTimeout(() => {
        toastService.success('Login realizado!')
        navigate(routeNames.dashboard)
        setIsLoadingAuth(false)
      }, 1500)
    }
  }

  return (
    <>
      <UserContext
        value={{
          isLoadingRedirect,
          logOut,
          isAuthenticated,
          isAllowedV2Pedidos,
          legacyAuth,
          vrIdAuth,
          isLoadingAuth,
          handleSelectAccount,
          handleRedirectDashboard,
          handleRedictAfterLogin,
          setStoragePermissions,
          userPermissions
        }}
      >
        {children}
      </UserContext>
      {isAuth && !isLogOut && (
        <iframe
          title='auth-iframe'
          width={50}
          height={50}
          className='absolute top-0 opacity-0 pointer-events-none overflow-hidden z-[-1]'
          src={AppUrl + `${endPoints.authFromMicrosservice}?token=` + token}
        />
      )}
      {isAuth && isLogOut && (
        <iframe
          title='logout-iframe'
          width={50}
          height={50}
          className='absolute top-0 opacity-0 pointer-events-none overflow-hidden z-[-1]'
          src={AppUrl + endPoints.logoutExternal}
        />
      )}
    </>
  )
}

export { UserProvider, useUserContext }
