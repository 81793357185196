import { SetStateAction, useMemo } from 'react'

import debounce from 'debounce'
import { Button, Modal, Select } from 'flowbite-react'

import Loader from '@/components/Loader'
import TableItem from '@/components/Table'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import { useRevisarPedidosContext } from '@/contexts/revisar-pedido.context'
import { TABLE_HEADERS_REPASSES_PEDIDO } from '@/pages/Pedidos/constants'

interface IRepassesPedidoModalProps {
  onClose: () => void
  isOpen: boolean
}

const subtipoTaxa: { [status: string]: string } = {
  ['RepassePorPercentual']: 'Percentual',
  ['RepassePorColaborador']: 'Por Colaborador'
}

const RepassesPedidoModal: React.FC<IRepassesPedidoModalProps> = ({
  onClose,
  isOpen
}: IRepassesPedidoModalProps) => {
  const {
    isLoadingRepasses,
    repassesList,
    repassesPageSize,
    setRepassesPageSize,
    repassesTotalPages,
    setRepassesPage,
    setSearch
  } = useRevisarPedidosContext()
  const handleSearch = debounce((e: SetStateAction<string>) => {
    setSearch(e)
  }, 500)

  const mappedRepasses = useMemo(() => {
    return repassesList?.conteudo?.resultados?.map(item => {
      return {
        operadora: item.nomeOperadora,
        tipo: subtipoTaxa[item.subtipoTaxa],
        descricao: item.descricao,
        valorUnitario:
          item.subtipoTaxa === 'RepassePorPercentual'
            ? '-'
            : item.valor.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              }),
        quantidade: item.quantidade,
        valorTotal: item.valorTotal?.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        })
      }
    })
  }, [repassesList])
  return (
    <div data-testid='modal-repasses'>
      <Modal
        show={isOpen}
        onClose={onClose}
        className='flex items-center'
        size='5xl'
      >
        <Modal.Header>
          <p className='text-center font-bold p-3'>Lista de Repasses</p>
        </Modal.Header>
        <Modal.Body>
          {isLoadingRepasses ? (
            <div
              className='h-96 w-full flex justify-center items-center'
              data-testid='loader-repasses'
            >
              <Loader size={'xl'} />
            </div>
          ) : (
            <div data-testid='table-repasses'>
              <SearchTableItem
                isAddItem={false}
                isSearchInputEnabled={true}
                setFilterText={e => handleSearch(e)}
              />
              <TableItem
                isActions={false}
                isRowClicable={false}
                data={mappedRepasses || []}
                headers={TABLE_HEADERS_REPASSES_PEDIDO}
                deleteAction={() => void 0}
                hasCustomActions={false}
                idKey='pedidoId'
                isIdle={false}
              />
              <PaginationItem
                totalItems={
                  repassesList?.conteudo?.quantidadeDeItems ?? (0 as number)
                }
                totalPages={repassesTotalPages}
                pageSize={repassesPageSize}
                onPageChange={(pageParam: number) => setRepassesPage(pageParam)}
              >
                <Select
                  id='select-page-size'
                  data-testid='select-page-size'
                  onChange={e => setRepassesPageSize(Number(e.target.value))}
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </Select>
              </PaginationItem>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <div className='flex gap-2'>
              <Button color='gray' onClick={onClose} data-testid='btn-close'>
                Fechar
              </Button>
            </div>
          </>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default RepassesPedidoModal
