export const bancoOptions = [
  {
    label: '001 - Banco dos Brasil S.A.',
    value: '001'
  },
  {
    label: '033 - Banco Santander (Brasil) S.A.',
    value: '033'
  },
  {
    label: '077 - Banco Inter S.A.',
    value: '077'
  },
  {
    label: '104 - Caixa Econômica Federal',
    value: '104'
  },
  {
    label: '208 - Banco BTG Pactual S.A.',
    value: '208'
  },
  {
    label: '260 - Nu Pagamentos S.A (Nubank)',
    value: '260'
  },
  {
    label: '237 - Banco Bradesco S.A.',
    value: '237'
  },
  {
    label: '336 - Banco C6 S.A - C6 Bank',
    value: '336'
  },
  {
    label: '341 - Itaú Unibanco S.A.',
    value: '341'
  },
  {
    label: '389 - Banco Mercantil do Brasil S.A.',
    value: '389'
  },
  {
    label: '422 - Banco Safra S.A.',
    value: '422'
  },
  {
    label: '655 - Banco Votorantim S.A.',
    value: '655'
  },
  {
    label: '756 - Banco Cooperativo do Brasil S.A. - BANCOOB',
    value: '756'
  }
]

export const tipoContaOptions = [
  {
    label: 'Corrente',
    value: 'Corrente'
  },
  {
    label: 'Poupança',
    value: 'Poupança'
  }
]
