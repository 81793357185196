import { QueryFunctionContext } from 'react-query'

import { endPoints } from '@/configs/endPoints'
import { IDepartamentosList } from '@/interfaces/departamentos'
import { IDetalhePedido } from '@/interfaces/detalhe-pedido'
import { IGenericRequest, IGenericResponse } from '@/interfaces/generic'
import {
  IPedidoItem,
  IRevisaoPedido,
  IRevisaoPedidoOperadora,
  IPedidoDetalhe,
  IRepasse,
  ILancamento,
  IRevisaoEconomiaPedidoUnidade,
  IEdicaoPedidoItem,
  IConfirmar,
  IItemVenda,
  IConfirmarPedidosProps,
  IPedidoPendenteResponse,
  IVendaResultado,
  IErrosPedidoResponse
} from '@/interfaces/pedidos'
import { IDadosRequestParams } from '@/interfaces/request'
import { API_PEDIDOS } from '@/utils/pedidos-fetcher'

export async function getPedidos(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          idEmpregador: string
          dataInicio: string
          dataFim: string
          inicio: number
          comprimento: number
          campo: string
          direcao: 'asc' | 'desc'
          situacoesPedidoVenda: string[]
          idUnidades?: string[]
          situacoesFinanceiras: string[]
          situacoesOperacionais: string[]
        }
      }
    ]
  >
): Promise<IPedidoItem> {
  const [, { params }] = ctx.queryKey
  const response = await API_PEDIDOS.post<IPedidoItem>(endPoints.pedidosList, {
    ...params
  })

  return response
}

export async function getPedidosListitensDetail(
  ctx: QueryFunctionContext<
    [
      string,
      {
        pedidoId: string
      }
    ]
  >
): Promise<IDetalhePedido> {
  const [, { pedidoId }] = ctx.queryKey
  const rote = endPoints.detalhePedido.replace('{id-pedido-venda}', pedidoId)
  const response = await API_PEDIDOS.get<IDetalhePedido>(rote)

  return response
}

export async function getRevisaoPedidos(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          idEmpregador: string
          dataInicio: string
          dataFim: string
          inicio: number
          comprimento: number
          campo: string
          direcao: string
          idPedidos?: string[]
        }
      }
    ]
  >
): Promise<IRevisaoPedido[]> {
  const [, { params }] = ctx.queryKey
  const data = await API_PEDIDOS.post<any>(endPoints.pedidosRevisaoList, {
    ...params
  })

  return data.conteudo.resultados.map(
    (pedido: any) =>
      ({
        numeroPedido: pedido.numeroPedido,
        cnpjUnidade: pedido.cnpjUnidade,
        pedidoId: pedido.id,
        unidadeId: pedido.idUnidade,
        unidade: pedido.nomeUnidade,
        numero: pedido.numeroPedido,
        itens: (pedido.beneficios ?? 0).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        }),
        economia: (pedido.economia ?? 0).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        }),
        repasses: (pedido.taxasOperadora ?? 0).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        }),
        cobrancas: (pedido.cobranca ?? 0).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        }),
        tarifas: (
          (pedido.taxaAdministrativa ?? 0) + (pedido.taxasLocalEntrega ?? 0)
        ).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        descontos: (pedido.desconto ?? 0).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        }),
        total: (pedido.total ?? 0).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        }),
        totalComEconomia: (pedido.totalComEconomia ?? 0).toLocaleString(
          'pt-br',
          {
            style: 'currency',
            currency: 'BRL'
          }
        ),
        sucesso: pedido.tipoSituacaoPedidoVenda !== 'Erro',
        periodoUtilizacaoInicial: pedido.periodoUtilizacaoInicial,
        periodoUtilizacaoFinal: pedido.periodoUtilizacaoFinal,
        previsaoPagamento: pedido.previsaoPagamento,
        previsaoDisponibilidade: pedido.previsaoDisponibilidade,
        tipoPedidoVenda: pedido.tipoPedidoVenda,
        utilizaEconomia: pedido.utilizaEconomia,
        situacao: pedido.tipoSituacaoPedidoVenda,
        criadoPorNome: pedido.criadoPorNome,
        canceladoPorNome: pedido.canceladoPorNome,
        valorTotalItensComEconomia: (
          pedido.valorTotalItensComEconomia ?? 0
        ).toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        }),
        situacaoOperacional: pedido.situacaoOperacional,
        situacaoFinanceira: pedido.situacaoFinanceira
      } as IRevisaoPedido)
  )
}

export async function getRevisaoPedidosOperadora(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          pedidoId: string
        }
      }
    ]
  >
): Promise<IRevisaoPedidoOperadora[]> {
  const [, { params }] = ctx.queryKey
  const route = endPoints.pedidosRevisaoOperadorasList.replace(
    '{id-pedido-venda}',
    params.pedidoId
  )
  const data = await API_PEDIDOS.get<IRevisaoPedidoOperadora[]>(route, {
    ...params
  })

  return data
}

export async function getRevisaoEconomiaUnidade( //TO REVIEW
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          pedidoId: string
          previsaoDisponibilidade: any
        }
      }
    ]
  >
): Promise<IRevisaoEconomiaPedidoUnidade[]> {
  const [, { params }] = ctx.queryKey
  const data = await API_PEDIDOS.get<IRevisaoEconomiaPedidoUnidade[]>(
    endPoints.pedidosRevisaoEconomiaUnidadesList,
    {
      ...params
    }
  )

  return data
}

export async function getPedidosPendentes(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          idEmpregador: string
          dataInicio: string
          dataFim: string
          inicio: number
          comprimento: number
          campo: string
          direcao: 'asc' | 'desc'
          procurar?: string
          situacoesPedidoVenda: string[]
          idUnidades?: string[]
          numeroPedido?: string | null
        }
      }
    ]
  >
): Promise<IPedidoPendenteResponse> {
  const [, { params }] = ctx.queryKey
  const response = await API_PEDIDOS.post<IPedidoPendenteResponse>(
    endPoints.pedidosPendentesList,
    {
      ...params
    }
  )

  return response
}

export async function createPedido(payload: any) {
  const response = await API_PEDIDOS.post<IGenericResponse>(
    endPoints.createPedido,
    {
      ...payload
    }
  )

  return response
}

export async function revisarPedido(payload: any) {
  const response = await API_PEDIDOS.patch<IGenericResponse>(
    endPoints.revisarPedido,
    {
      ...payload
    }
  )

  return response
}

export async function editarPedido(payload: any) {
  const endpointEditarItem = endPoints.editarPedido
  const response = await API_PEDIDOS.patch<IGenericResponse>(
    endpointEditarItem,
    {
      ...payload
    }
  )
  return response
}

export async function processarPedidoVenda(payload: {
  idsPedidoVenda: string[]
}) {
  const response = await API_PEDIDOS.patch<IGenericResponse>(
    endPoints.processarPedido,
    {
      ...payload
    }
  )

  return response
}
export async function reprocessarPedido(payload: any) {
  const response = await API_PEDIDOS.post<IGenericResponse>(
    endPoints.reprocessarPedido,
    {
      ...payload
    }
  )

  return response
}

export async function economiaAceite(payload: any) {
  const endpointAceite = endPoints.aceiteGestao.replace(
    '{id-empregador}',
    payload.idEmpregador
  )
  const response = await API_PEDIDOS.post<IGenericResponse>(endpointAceite)

  return response
}

export async function getDetalhePedido(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          pedidoId: string
        }
      }
    ]
  >
): Promise<IPedidoDetalhe> {
  const [, { params }] = ctx.queryKey
  const route = endPoints.pedidoDetalhe.replace(
    '{id-pedido-venda}',
    params.pedidoId
  )
  const data = await API_PEDIDOS.get<IPedidoDetalhe>(route)

  return data
}

export async function getErrosPedido(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: IGenericRequest
      }
    ]
  >
): Promise<IErrosPedidoResponse> {
  const [, { params }] = ctx.queryKey
  const data = await API_PEDIDOS.post<IErrosPedidoResponse>(
    `${endPoints.errosPedido}`,
    {
      ...params
    }
  )

  return data
}

export async function getVenda(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          pedidoId: string
          unidadeId: string
          operadoraId?: string
          vendaId?: string[]
          inicio: number
          comprimento: number
          procurar?: string
          situacoesItem?: string[]
          agrupar: boolean
          tipoVenda?: string
        }
      }
    ]
  >
): Promise<IVendaResultado> {
  const [, { params }] = ctx.queryKey
  const endpointPedidoVenda = endPoints.vendas.replace(
    '{id-pedido-venda}',
    params.pedidoId
  )
  const vendaIds = params.vendaId ?? []
  const data = await API_PEDIDOS.post<IVendaResultado>(endpointPedidoVenda, {
    idVendas: vendaIds,
    idUnidade: params.unidadeId,
    idOperadora: params.operadoraId,
    inicio: params.inicio,
    comprimento: params.comprimento,
    campo: 'CriadoEm',
    direcao: 'desc',
    procurar: params.procurar,
    situacoesItem: params.situacoesItem,
    agrupar: params.agrupar,
    tipoVenda: params.tipoVenda
  })

  return data
}

export async function getEdicaoPedidoItens(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          pedidoId: string
          vendaId: string
          inicio: number
          comprimento: number
          procurar?: string
          somenteErros: boolean
        }
      }
    ]
  >
): Promise<IEdicaoPedidoItem[]> {
  const [, { params }] = ctx.queryKey
  const endpointEditarItem = endPoints.pedidoEdicaoItens.replace(
    '{id-pedido-venda}',
    params.pedidoId
  )
  params.procurar = params.procurar == '' ? undefined : params.procurar
  const data = await API_PEDIDOS.get<any>(endpointEditarItem, {
    idvenda: params.vendaId,
    inicio: params.inicio,
    comprimento: params.comprimento,
    procurar: params.procurar,
    campo: 'CriadoEm',
    direcao: 'desc'
  })

  return data.data.conteudo.resultados.map(
    (item: IItemVenda) =>
      ({
        pedidoId: params.pedidoId,
        vendaId: params.vendaId,
        itemId: item.idItem,
        nome: item.nome,
        cpf: item.cpf,
        matricula: item.matricula,
        codigoLinha: item.codigoLinha,
        nomeLinha: item.descricaoTarifa,
        valorLinha: item.valorLinha,
        diasCompra: item.quantidadeDiasTrabalhdos,
        quantidadeDiaria: item.quantidadePorDia,
        valorRecarga: item.total,
        erro: '',
        status: true,
        variavel: false
      } as IEdicaoPedidoItem)
  )
}

export async function editarItensPedido(payload: any) {
  const endpointEditarItem = endPoints.editarItens
    .replace('{id-pedido-venda}', payload.idPedido)
    .replace('{id-venda}', payload.idVenda)
  const response = await API_PEDIDOS.patch<IGenericResponse>(
    endpointEditarItem,
    {
      ...payload
    }
  )
  return response
}

export async function cancelarPedido(payload: any) {
  const endpointCancelar = endPoints.cancelarPedido.replace(
    '{id-pedido-venda}',
    payload.id
  )
  const response = await API_PEDIDOS.delete<IGenericResponse>(endpointCancelar)
  return response
}

export async function getArquivoPedidoEmRevisao(pedidoId: string) {
  //TO REVIEW
  const data = await API_PEDIDOS.get<any>(
    `${endPoints.arquivoPedidoEmRevisao}/${pedidoId}`
  )

  return data
}

export async function getRepasses(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          pedidoId: string
          vendaId?: string
          inicio: number
          comprimento: number
          procurar?: string
          tipo?: string
        }
      }
    ]
  >
): Promise<IRepasse> {
  const [, { params }] = ctx.queryKey
  const endpointRepasses = endPoints.pedidoRepasses.replace(
    '{id-pedido-venda}',
    params.pedidoId
  )
  params.procurar = params.procurar == '' ? undefined : params.procurar
  params.vendaId = params.vendaId == '' ? undefined : params.vendaId
  const data = await API_PEDIDOS.get<IRepasse>(endpointRepasses, {
    'id-venda': params.vendaId,
    inicio: params.inicio,
    comprimento: params.comprimento,
    procurar: params.procurar,
    campo: 'CriadoEm',
    direcao: 'desc',
    'tipo-taxa': params.tipo
  })

  return data
}

export async function getLancamentos(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          pedidoId: string
          inicio: number
          comprimento: number
          procurar?: string
          tipoLancamentoFinanceiro: number
        }
      }
    ]
  >
): Promise<ILancamento> {
  const [, { params }] = ctx.queryKey
  const endPointLancamento = endPoints.pedidoLancamentos.replace(
    '{id-pedido-venda}',
    params.pedidoId
  )
  params.procurar = params.procurar == '' ? undefined : params.procurar

  const data = await API_PEDIDOS.get<ILancamento>(endPointLancamento, {
    inicio: params.inicio,
    comprimento: params.comprimento,
    procurar: params.procurar,
    tipoLancamentoFinanceiro: params.tipoLancamentoFinanceiro,
    campo: 'CriadoEm',
    direcao: 'desc'
  })

  return data
}

export async function getListaPedidos(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: IConfirmarPedidosProps
      }
    ]
  >
): Promise<IConfirmar> {
  const [, { params }] = ctx.queryKey
  const response = await API_PEDIDOS.post<IConfirmar>(endPoints.pedidoLista, {
    ...params
  })

  return response
}

export async function getDepartamentosListPedidos({
  params
}: {
  params: IDadosRequestParams
}): Promise<IDepartamentosList> {
  const endpointDepartamento = endPoints.departamentosListBusca.replace(
    '{id-unidade}',
    params.idUnidade
  )
  const response = !!params.idUnidade
    ? await API_PEDIDOS.get<IDepartamentosList>(endpointDepartamento, {
        ...params
      })
    : ({} as any)

  return response
}
