import { SetStateAction, useMemo, useState } from 'react'

import { CheckIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline'
import debounce from 'debounce'
import { Button, Checkbox, Select, Tooltip } from 'flowbite-react'

import EditarPedidoModal from './editar-pedido.modal'
import EditarPedidoEmMassaModal from '../editar-pedido-em-massa/editar-pedido-em-massa-modal.component'
import Loader from '@/components/Loader'
import TableItem from '@/components/Table'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import { useEditarPedidosContext } from '@/contexts/editar-pedido.context'
import { TABLE_HEADERS_EDITAR_PEDIDO_ITENS } from '@/pages/Pedidos/constants'

const EditarPedidosItensGrid = () => {
  const {
    itens,
    totalItens,
    isLoadingPedido,
    setPage,
    totalPages,
    pageSize,
    setPageSize,
    setSearch,
    somenteErros,
    setSomenteErros,
    setItemEmEdicao,
    checkedItemsBulkEdit,
    enableBulkEdit,
    isModalOpen,
    itemsSelectedEditBulkOrders,
    handleCheckboxChangeEditBulkOrders,
    setIsModalOpen
  } = useEditarPedidosContext()

  const handleSearch = debounce((e: SetStateAction<string>) => {
    setSearch(e)
  }, 500)

  const [isOpen, setIsOpen] = useState(false)

  const handleEditarItem = (itemId: string) => {
    setItemEmEdicao(itemId)
    setIsOpen(true)
  }

  const handleSomenteErros = (somenteErrosOpcao: boolean) => {
    setPage(1)
    setSomenteErros(somenteErrosOpcao)
  }

  const mappedItens = useMemo(() => {
    return itens?.map(item => {
      return {
        ...item,
        situacao:
          item.erro === undefined || item.erro === '' ? (
            <CheckIcon className='h-5 w-5 text-white bg-quintenary rounded-full justify-center items-center' />
          ) : (
            <Tooltip content={item.erro} placement='top'>
              <XMarkIcon className='h-5 w-5 text-white bg-red-400 rounded-full justify-center items-center' />
            </Tooltip>
          ),
        funcionario: (
          <>
            <span className='flex gap-2 justify-between'>
              <a className='font-semibold'>Nome:</a> {item.nome}
            </span>
            <span className='flex gap-2 justify-between'>
              <a className='font-semibold'>CPF:</a> {item.cpf}
            </span>
            <span className='flex gap-2 justify-between'>
              <a className='font-semibold'> Matrícula:</a> {item.matricula}
            </span>
          </>
        ),
        linha: (
          <>
            <span className='flex gap-2 justify-between'>
              <a className='font-semibold'>Código:</a> {item.codigoLinha}
            </span>
            <span className='flex gap-2 justify-between'>
              <a className='font-semibold'>Linha:</a> {item.nomeLinha}
            </span>
            <span className='flex gap-2 justify-between'>
              <a className='font-semibold'>Valor Unitário:</a>
              {item.valorLinha.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })}
            </span>
          </>
        ),
        recarga: (
          <>
            <span className='flex gap-2 justify-between'>
              <a className='font-semibold'> Valor:</a>{' '}
              {item.valorRecarga.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })}
            </span>
            <span className='flex gap-2 justify-between'>
              <a className='font-semibold'> Dias para compra:</a>
              {item.diasCompra}
            </span>
            <span className='flex gap-2 justify-between'>
              <a className='font-semibold'> Quantidade por dia:</a>
              {item.quantidadeDiaria}
            </span>
          </>
        ),
        ativo: (
          <>
            {item.status ? (
              <a className='font-semibold text-quaternary'>Sim</a>
            ) : (
              <a className='font-semibold text-red-600'>Não</a>
            )}
          </>
        ),
        acoes: (
          <Button
            color='gray'
            className='flex gap-2 justify-center items-center'
            onClick={() => handleEditarItem(item.itemId)}
            data-testid='btn-edit'
          >
            <PencilIcon className='h-5 w-5' />
          </Button>
        ),
        checkbox: (
          <Checkbox
            className='form-checkbox h-5 w-5 text-quaternary cursor-pointer border-gray-300 focus:ring-green-500 checked:focus:ring-green-500 focus:outline-none'
            onChange={() => handleCheckboxChangeEditBulkOrders(item.itemId)}
            data-testid='checkbox-item'
          />
        )
      }
    })
  }, [itens])

  return (
    <>
      <span className='text-quaternary pb-3 text-lg m-1'>Itens</span>
      {isLoadingPedido ? (
        <div
          className='h-96 w-full flex justify-center items-center'
          data-testid='loader-table-itens'
        >
          <Loader size={'xl'} />
        </div>
      ) : (
        <div
          data-testid='table-itens'
          className='flex flex-col md:grid grid-cols-12 w-full p-3'
        >
          <div className='flex flex-col col-span-12'>
            <div className='flex justify-between gap-2'>
              <SearchTableItem
                isAddItem={false}
                isSearchInputEnabled={true}
                setFilterText={e => handleSearch(e)}
              />
              <div className='flex justify-center items-center gap-2'>
                {checkedItemsBulkEdit.length > 0 ? (
                  <Button
                    color='light'
                    className='border'
                    data-testid='btn-select-erros-editar-colaboradores'
                    id='btn-editar-colaboradores'
                    onClick={enableBulkEdit}
                  >
                    <span>Editar Colaboradores</span>
                  </Button>
                ) : null}

                <Button
                  color={somenteErros ? 'success' : 'light'}
                  className='border'
                  onClick={() => handleSomenteErros(true)}
                  data-testid='btn-select-erros'
                >
                  <span>Somente com erros</span>
                </Button>
                <Button
                  color={somenteErros ? 'light' : 'success'}
                  className='border'
                  onClick={() => handleSomenteErros(false)}
                  data-testid='btn-select-todos'
                >
                  <span>Todos</span>
                </Button>
              </div>
            </div>
            <TableItem
              isActions={false}
              isRowClicable={false}
              data={mappedItens || []}
              headers={TABLE_HEADERS_EDITAR_PEDIDO_ITENS}
              deleteAction={() => void 0}
              hasCustomActions={false}
              idKey='vendaId'
              isIdle={false}
            />
            <PaginationItem
              totalItems={totalItens}
              totalPages={totalPages}
              pageSize={pageSize}
              onPageChange={(pageParam: number) => setPage(pageParam)}
            >
              <Select
                id='select-page-size'
                data-testid='select-page-size'
                onChange={e => setPageSize(Number(e.target.value))}
              >
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </Select>
            </PaginationItem>
          </div>
        </div>
      )}
      <EditarPedidoModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <EditarPedidoEmMassaModal
        itensSelecionados={itemsSelectedEditBulkOrders}
        onClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />
    </>
  )
}

export default EditarPedidosItensGrid
