import { endPoints } from '@/configs/endPoints'
import { INotaFiscalResponse } from '@/interfaces/notas-fiscais'
import { API_COBRANCAS } from '@/utils/cobrancas-fetcher'

export async function exportarNotaFiscal(
  idsPedidosVendas: string[],
  dataInicio: string,
  dataFim: string
): Promise<INotaFiscalResponse> {
  const idsQuery = idsPedidosVendas
    .map(id => `idsPedidosVendas=${encodeURIComponent(id)}`)
    .join('&')

  const url = `${endPoints.exportarNotaFiscal}?${idsQuery}&dataFim=${dataFim}&dataInicio=${dataInicio}`

  try {
    const data = await API_COBRANCAS.get<INotaFiscalResponse>(url)
    return data
  } catch (error) {
    console.error('Error exporting nota fiscal:', error)
    throw error
  }
}
