import { useMemo } from 'react'

import Loader from '@/components/Loader'
import { useDetalhePedidoContext } from '@/contexts/detalhe-pedido.context'
import { IRevisaoPedido } from '@/interfaces/pedidos'
import {
  mapedTipoSituacaoFinanceira,
  mapedTipoSituacaoOperacional
} from '@/pages/Pedidos/constants'
import { formatCurrency } from '@/utils'

const DetalhePedidoHeader: React.FC = () => {
  const {
    pedidoData,
    pedidosListitensDetail,
    isLoadingPedidoData,
    isLoadingPedidoListItensDetail
  } = useDetalhePedidoContext()
  const mapedProperties = useMemo(() => {
    const pedidoDataMain = pedidoData?.length
      ? pedidoData[0]
      : ({} as IRevisaoPedido)
    const pedidoDetail = pedidosListitensDetail?.conteudo
    return {
      ...pedidoDetail,
      ...pedidoDataMain
    }
  }, [pedidoData, pedidosListitensDetail])

  const formatDateString = (date: string) => {
    return new Date(date)?.toLocaleDateString('pt-BR')
  }

  return (
    <div className='bg-white rounded-lg p-5 mb-10 mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
      {isLoadingPedidoData || isLoadingPedidoListItensDetail ? (
        <div className='p-10 flex justify-center items-center col-span-12'>
          <Loader size='xl' />
        </div>
      ) : (
        <>
          <div>
            <div className='text-sm text-gray-500'>Número do Pedido</div>
            <div className='text-md text-gray-900 font-bold'>
              {mapedProperties.numeroPedido}
            </div>
          </div>
          {mapedProperties.situacaoFinanceira && (
            <div>
              <div className='text-sm text-gray-500'>Situação financeira</div>
              <div className='text-md font-medium text-gray-900'>
                <div className='flex gap-2'>
                  <span
                    className={`px-5 py-1 rounded-2xl text-xs ${
                      mapedTipoSituacaoFinanceira[
                        mapedProperties.situacaoFinanceira
                      ]?.color
                    } text-white text-center`}
                  >
                    {
                      mapedTipoSituacaoFinanceira[
                        mapedProperties.situacaoFinanceira
                      ]?.title
                    }
                  </span>
                </div>
              </div>
            </div>
          )}

          {mapedProperties.situacaoOperacional && (
            <div>
              <div className='text-sm text-gray-500'>Situação Operacional</div>
              <div className='flex gap-2'>
                <span
                  className={`px-5 py-1 rounded-2xl text-xs ${
                    mapedTipoSituacaoOperacional[
                      mapedProperties.situacaoOperacional
                    ]?.color
                  } text-white text-center`}
                >
                  {
                    mapedTipoSituacaoOperacional[
                      mapedProperties.situacaoOperacional
                    ]?.title
                  }
                </span>
              </div>
            </div>
          )}
          <div>
            <div className='text-sm text-gray-500'>Nome da Unidade</div>
            <div className='text-md font-bold text-gray-900'>
              {mapedProperties.unidade}
            </div>
          </div>
          <div>
            <div className='text-sm text-gray-500'>Data da Criação</div>
            <div className='text-md font-medium text-gray-900'>
              {formatDateString(mapedProperties.criadoEm as string)}
            </div>
          </div>
          <div>
            <div className='text-sm text-gray-500'>Período de Utilização</div>
            <div className='text-md font-medium text-gray-900'>
              {formatDateString(
                mapedProperties.periodoUtilizacaoInicial as string
              )}{' '}
              -{' '}
              {formatDateString(
                mapedProperties.periodoUtilizacaoFinal as string
              )}
            </div>
          </div>
          <div>
            <div className='text-sm text-gray-500'>CNPJ</div>
            <div className='text-md font-bold text-gray-900'>
              {mapedProperties.cnpjUnidade}
            </div>
          </div>
          {mapedProperties.canceladoPorNome !== null ? (
            <div>
              <div className='text-sm text-gray-500'>Cancelado por</div>
              <div className='text-md font-medium text-gray-900'>
                {mapedProperties.canceladoPorNome}
              </div>
            </div>
          ) : (
            <></>
          )}

          <div>
            <div className='text-sm text-gray-500'>Previsão de Pagamento</div>
            <div className='text-md font-medium text-gray-900'>
              {formatDateString(mapedProperties.previsaoPagamento as string)}
            </div>
          </div>
          <div>
            <div className='text-sm text-gray-500'>
              Previsão de Disponibilidade
            </div>
            <div className='text-md font-medium text-gray-900'>
              {formatDateString(
                mapedProperties.previsaoDisponibilidade as string
              )}
            </div>
          </div>
          <div>
            <div className='text-sm text-gray-500'>Contato</div>
            <div className='text-md font-medium text-gray-900'>
              {mapedProperties.criadoPorNome}
            </div>
          </div>
          <div>
            <div className='text-sm text-gray-500'>Tipo de Pedido</div>
            <div className='text-md font-medium text-gray-900'>
              {mapedProperties.tipoPedidoVenda}
            </div>
          </div>
          <div>
            <div className='text-sm text-gray-500'>Valor dos itens</div>
            <div className='text-md font-medium text-gray-900'>
              {mapedProperties.valorTotalItensComEconomia}
            </div>
          </div>
          <div>
            <div className='text-sm text-gray-500'>Repasses</div>
            <div className='text-md font-medium text-gray-900'>
              {formatCurrency(mapedProperties.taxasOperadora)}
            </div>
          </div>
          <div>
            <div className='text-sm text-gray-500'>Tarifas</div>
            <div className='text-md font-medium text-gray-900'>
              {mapedProperties.tarifas}
            </div>
          </div>
          <div>
            <div className='text-sm text-gray-500'>Desconto</div>
            <div className='text-md font-medium text-gray-900'>
              {mapedProperties.descontos}
            </div>
          </div>
          <div>
            <div className='text-sm text-gray-500'>Cobranças</div>
            <div className='text-md font-medium text-gray-900'>
              {mapedProperties.cobrancas}
            </div>
          </div>
          <div>
            <div className='text-sm text-gray-500'>Economia</div>
            <div className='text-md font-medium text-gray-900'>
              {mapedProperties.economia}
            </div>
          </div>
          <div>
            <div className='text-sm text-gray-500'>Total</div>
            <div className='text-xl font-bold text-primary-hover'>
              {mapedProperties?.totalComEconomia}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default DetalhePedidoHeader
