import {
  Dispatch,
  SetStateAction,
  useState,
  createContext,
  useContext,
  ReactNode
} from 'react'

import { useSearchParams } from 'react-router-dom'

import { useGetConfirmarPedidos } from '@/hooks/queries/use-get-pedidos'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { IConfirmar } from '@/interfaces/pedidos'

interface ConfirmarPedidoContextProps {
  pedidoId: string
  setPedidoId: Dispatch<SetStateAction<string>>
  confirmarPedidos: IConfirmar | undefined
  isLoadingPedidos: boolean
  isDrawerOpen: boolean
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
  setPage: Dispatch<SetStateAction<number>>
  totalPages: number
  pageSize: number
  ignorarErros: boolean
  setIgnorarErros: Dispatch<SetStateAction<boolean>>
}

const ConfirmarPedidosContext = createContext<
  ConfirmarPedidoContextProps | undefined
>(undefined)

const ConfirmarPedidosProvider = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams()
  const dataPedido = searchParams.get('data')
  const { data: userData } = useGetUserData()
  const pageSize = 10
  const [pedidoId, setPedidoId] = useState<string>('')
  const [ignorarErros, setIgnorarErros] = useState<boolean>(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  useState<boolean>(false)
  const [, setPage] = useState<number>(1)
  const { data: confirmarPedidos, isLoading: isLoadingPedidos } =
    useGetConfirmarPedidos({
      params: {
        idEmpregador: userData?.authenticatedAccountId || '',
        dataInicio: dataPedido || '',
        dataFim: dataPedido || '',
        inicio: 0,
        comprimento: 100,
        campo: 'CriadoEm',
        direcao: 'desc'
      }
    })
  const totalPages = Math.ceil(
    (confirmarPedidos?.conteudo?.resultados?.length as number) / pageSize
  )

  return (
    <ConfirmarPedidosContext.Provider
      value={{
        pedidoId,
        setPedidoId,
        confirmarPedidos,
        isLoadingPedidos,
        isDrawerOpen,
        setIsDrawerOpen,
        setPage,
        totalPages,
        pageSize,
        ignorarErros,
        setIgnorarErros
      }}
    >
      {children}
    </ConfirmarPedidosContext.Provider>
  )
}

const useConfirmarPedidosContext = () => {
  const context = useContext(ConfirmarPedidosContext)
  if (context === undefined) {
    throw new Error(
      'useConfirmarPedidosContext must be used within a ConfirmarPedidosProvider'
    )
  }
  return context
}

export { ConfirmarPedidosProvider, useConfirmarPedidosContext }
