import { SetStateAction, useMemo, useState } from 'react'

import {
  ArrowPathIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline'
import debounce from 'debounce'
import { Alert, Dropdown, Popover, Select, Tooltip } from 'flowbite-react'
import { useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'

import TooltipItem from '@/components/Encomendas/tooltip-item.component'
import Loader from '@/components/Loader'
import PedidosPendentesFilterForm from '@/components/pedidos/pedidos-pendentes/pedidos-pendentes-filtros.component'
import ReprocessarPedidoModal from '@/components/pedidos/reprocessar-pedido-modal.component'
import CancelarPedidoModal from '@/components/pedidos/revisar-pedidos/cancelar-pedido-popup.modal'
import ErrosPedidoModal from '@/components/pedidos/revisar-pedidos/erros-pedido.modal'
import StepModal from '@/components/step-modal/step-modal.component'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import TableItemPedidos from '@/components/Table/table-item-pedidos.component'
import { accessControl } from '@/configs/accessControl'
import { QueryKey } from '@/configs/queryKeys'
import { routeNames } from '@/configs/routePaths'
import { usePedidosPendentesContext } from '@/contexts/pedidos-pendentes.context'
import { useUserContext } from '@/contexts/user.context'
import {
  useCancelarPedidoMutation,
  useReprocessarPedidoMutation
} from '@/hooks/mutations/use-pedidos-mutation'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { IPedidoPedenteResultado } from '@/interfaces/pedidos'
import {
  statusDetailsByStatus,
  TABLE_HEADERS_PEDIDOS_PENDENTES
} from '@/pages/Pedidos/constants'
import useIsOnRoute from '@/utils/isOnRoute'

type status = 'Erro' | 'EmProcessamento' | 'Pendente' | 'Processado'

const PedidosPendentesContainer = () => {
  const queryClient = useQueryClient()
  const isOnRoute = useIsOnRoute(`/${routeNames.pedidosPendentes}`)
  const {
    pedidosPendentesList,
    isLoadingPedidosPendentes,
    isFetchingPedidosPedentes,
    setSearch,
    pageSize,
    setPageSize,
    totalPages,
    setPage,
    isOnboardingOpen,
    handleClose,
    handleBeforeExit,
    isOnboardingFinishedOrderPending
  } = usePedidosPendentesContext()
  const { data: userData } = useGetUserData()
  const { mutate: reprocessarPedido, isLoading } =
    useReprocessarPedidoMutation()
  const { mutateAsync: cancelarPedido, isLoading: isIdleCancelarPedido } =
    useCancelarPedidoMutation()
  const { userPermissions } = useUserContext()
  const [isOpenErrosModal, setIsOpenErrosModal] = useState(false)
  const [seletedPedido, setSeletedPedido] = useState<IPedidoPedenteResultado>(
    {} as IPedidoPedenteResultado
  )
  const [isCancelarModalOpen, setIsCancelarModalOpen] = useState<boolean>(false)
  const [selectedPedido, setSelectedPedido] = useState<string[]>([])
  const [isReprocessarOpen, setIsReprocessarOpen] = useState<boolean>(false)
  const handleOpenErrosModal = (pedido: IPedidoPedenteResultado) => {
    setIsOpenErrosModal(true)
    setSeletedPedido(pedido)
  }

  const handleOpenReprocessarModal = (pedido: IPedidoPedenteResultado) => {
    setIsReprocessarOpen(true)
    setSelectedPedido(pedido.idsPedidos)
  }
  const handleReprocessarPedido = () => {
    const DTO = {
      idsPedidoVenda: selectedPedido,
      criadoPor: userData?.userId,
      idEmpregador: userData?.authenticatedAccountId,
      errosIgnorados: false,
      idAplicacao: process.env.VITE_APP_APP_ID
    }
    reprocessarPedido(DTO)
    setIsReprocessarOpen(false)
  }

  const handleCancelarPedidoModal = (pedido: IPedidoPedenteResultado) => {
    setSelectedPedido(pedido.idsPedidos)
    setIsCancelarModalOpen(true)
  }

  const handleCancelarPedidoConfirm = () => {
    selectedPedido?.forEach(pedido => {
      const DTO = {
        id: pedido
      }
      cancelarPedido(DTO)
      setIsCancelarModalOpen(false)
    })
  }

  const stepList = [
    {
      title: 'Pedidos Pendentes:​',
      body: 'Essa é a grande novidade da jornada de criação de um pedido. É a etapa mais importante, pois é aqui que você irá confirmar a criação do pedido.',
      step: 1,
      ref: '#pedidos-pendentes',
      totalSteps: 5
    },
    {
      title: '',
      body: 'No menu Pedidos Pendentes teremos três status de SITUAÇÃO PEDIDO, são eles:​Essa é a etapa de análise e validação do pedido.​Processo rápido, mas de sumo importância na jornada. Enquanto o pedido é processado você pode utilizar a plataforma sem nenhum impedimento.​Esse status será apresenta para pedidos que não foram validados após o processamento.​Basta clicar no botão "Erro" para visualizar o erro encontrado.​Este é o status de pedido validado no processamento. Agora você deve APROVAR o pedido para que ele seja criado.​​',
      step: 2,
      ref: '#table-pedidos-pendentes',
      totalSteps: 5
    },
    {
      title: ' ',
      body: 'Basta clicar no botão "Erro" para visualizar o erro encontrado.',
      step: 3,
      ref: '#table-pedidos-pendentes',
      totalSteps: 5
    },
    {
      title: '',
      body: 'Essa é a etapa de análise e validação do pedido.​Processo rápido, mas de suma importância na jornada. Enquanto o pedido é processado você pode utilizar a plataforma sem nenhum impedimento. ​No botão destacado é possível atualizar a análise.',
      step: 4,
      totalSteps: 5,
      ref: '.recarregar'
    },
    {
      title: '',
      body: '​Este é o status de pedido validado no processamento. Agora você deve APROVAR o pedido para que ele seja criado ',
      step: 5,
      totalSteps: 5,
      ref: '#table-pedidos-pendentes'
    }
  ]

  const actions = (
    item: IPedidoPedenteResultado,
    statusDetail: { action: string }
  ) => {
    return {
      ['Pendente']: (
        <Dropdown
          label=''
          placement='left-start'
          dismissOnClick={true}
          renderTrigger={() => (
            <EllipsisVerticalIcon className='w-5 h-5 cursor-pointer' />
          )}
        >
          <Dropdown.Item onClick={() => handleCancelarPedidoModal(item)}>
            Cancelar
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleOpenReprocessarModal(item)}>
            Reprocessar
          </Dropdown.Item>
        </Dropdown>
      ),
      ['Processado']: (
        <div className='flex gap-2'>
          <Link
            to={`/pedidos/revisar-pedidos/?data=${item.criadoEm}`}
            className='text-primary-hover text-xs'
          >
            {statusDetail.action}
          </Link>
        </div>
      ),
      ['EmProcessamento']: (
        <Dropdown
          label=''
          placement='left-start'
          dismissOnClick={true}
          renderTrigger={() => (
            <EllipsisVerticalIcon className='w-5 h-5 cursor-pointer' />
          )}
        >
          <Dropdown.Item onClick={() => handleCancelarPedidoModal(item)}>
            Cancelar
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleOpenReprocessarModal(item)}>
            Reprocessar
          </Dropdown.Item>
        </Dropdown>
      ),
      ['Erro']: (
        <Dropdown
          label=''
          placement='left-start'
          dismissOnClick={true}
          renderTrigger={() => (
            <EllipsisVerticalIcon className='w-5 h-5 cursor-pointer' />
          )}
        >
          <Dropdown.Item onClick={() => handleCancelarPedidoModal(item)}>
            Cancelar
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleOpenReprocessarModal(item)}>
            Reprocessar
          </Dropdown.Item>
        </Dropdown>
      )
    }
  }
  const mapedProperties = useMemo(() => {
    return pedidosPendentesList?.conteudo?.resultados?.map(item => {
      const statusDetail = statusDetailsByStatus[item.status as status]
      return {
        ...item,
        status: (
          <div className='flex gap-2'>
            {item.status === 'Erro' ? (
              <button
                onClick={() => handleOpenErrosModal(item)}
                onKeyDown={e => e.key === 'Enter' && handleOpenErrosModal(item)}
                className={`px-5 flex gap-2 py-1 rounded-2xl text-xs ${statusDetail.badgeClass} text-white cursor-pointer`}
              >
                <EyeIcon className='w-4 h-4 text-white' />
                <span>{statusDetail.text}</span>
              </button>
            ) : (
              <span
                className={`px-5 py-1 rounded-2xl text-xs ${statusDetail.badgeClass} text-white`}
              >
                {statusDetail.text}
              </span>
            )}
          </div>
        ),
        qtPedidos: (
          <Popover
            trigger='hover'
            content={
              <TooltipItem
                title='Pedidos'
                content={[item.numerosPedidos.toString().replace(/,/g, ', ')]}
              />
            }
            placement='top'
          >
            <div className='flex gap-2'>
              <span className='px-5 py-1 rounded-2xl text-xs bg-orange-400 text-white'>
                {item.quantidadePedidos}
              </span>
            </div>
          </Popover>
        ),
        qtUnidades: (
          <Popover
            trigger='hover'
            content={
              <TooltipItem title='Unidades' content={item.nomesUnidades} />
            }
            placement='top'
          >
            <div className='flex gap-2'>
              <span className='px-5 py-1 rounded-2xl text-xs bg-orange-400 text-white'>
                {item.quantidadeUnidades}
              </span>
            </div>
          </Popover>
        ),
        dataCriacao: new Date(item.criadoEm).toLocaleDateString(),
        previsaoEntrega: new Date(item.previsaoEntrega).toLocaleDateString(),
        acoes:
          userPermissions.find(
            permissao => permissao.code === accessControl.criarPedido
          ) !== undefined ? (
            <div className='flex gap-2'>
              {actions(item, statusDetail)[item.status]}
            </div>
          ) : (
            <></>
          )
      }
    })
  }, [pedidosPendentesList, userPermissions])

  const handleSearch = debounce((e: SetStateAction<string>) => {
    setSearch(e)
  }, 500)

  return (
    <>
      <div className='pb-5'>
        <Alert color='warning' icon={InformationCircleIcon} rounded>
          <span className='font-medium'>
            Pedidos que ficarem pendentes por mais de 1 dia, serão cancelados
            automaticamente
          </span>
        </Alert>
      </div>
      <div className='relative'>
        <SearchTableItem
          isAddItem={false}
          isSearchInputEnabled={true}
          filtersEnabled
          setFilterText={e => handleSearch(e)}
        >
          <PedidosPendentesFilterForm />
        </SearchTableItem>
        <div className='absolute right-4 top-4 recarregar'>
          <Tooltip content='Atualizar lista' placement='left'>
            <ArrowPathIcon
              className={`text-primary-dark w-5 h-6 cursor-pointer ${
                isFetchingPedidosPedentes ? 'animate-spin' : ''
              }`}
              onClick={() =>
                queryClient.invalidateQueries(QueryKey.pedidosPendentesList)
              }
            />
          </Tooltip>
        </div>
        {isLoadingPedidosPendentes ? (
          <div className='flex justify-center items-center h-96'>
            <Loader size='lg' />
          </div>
        ) : (
          <>
            <div id='table-pedidos-pendentes'>
              <TableItemPedidos
                isActions={false}
                isRowClicable={false}
                data={mapedProperties || []}
                headers={TABLE_HEADERS_PEDIDOS_PENDENTES}
                deleteAction={() => void 0}
                hasCustomActions={false}
                idKey='criadoEm'
                isIdle={
                  isFetchingPedidosPedentes ||
                  isLoadingPedidosPendentes ||
                  isLoading ||
                  isIdleCancelarPedido
                }
              />
            </div>
            <PaginationItem
              totalItems={
                pedidosPendentesList?.conteudo?.totalRegistros as number
              }
              pageSize={pageSize}
              totalPages={totalPages}
              onPageChange={(pageParam: number) => setPage(pageParam)}
            >
              <Select
                id='select-page-size'
                data-testid='select-page-size'
                onChange={e => setPageSize(Number(e.target.value))}
              >
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </Select>
            </PaginationItem>
          </>
        )}
      </div>
      <ErrosPedidoModal
        isAcompanhamento
        isOpen={isOpenErrosModal}
        onClose={() => setIsOpenErrosModal(false)}
        pedido={seletedPedido}
      />
      <CancelarPedidoModal
        isOpen={isCancelarModalOpen}
        onClose={() => setIsCancelarModalOpen(false)}
        onConfirm={handleCancelarPedidoConfirm}
      />
      <ReprocessarPedidoModal
        isOpen={isReprocessarOpen}
        onClose={() => setIsReprocessarOpen(false)}
        onConfirm={handleReprocessarPedido}
      />
      {isOnRoute && (
        <StepModal
          isOpen={isOnboardingOpen}
          onClose={handleClose}
          onBeforeExit={handleBeforeExit}
          isOnboardingFinishedOrderReview={isOnboardingFinishedOrderPending}
          steps={stepList}
          cookieName='isOnboardingFinishedOrderPending'
        />
      )}
    </>
  )
}

export default PedidosPendentesContainer
