import { SetStateAction, useMemo, useState } from 'react'

import { EyeIcon } from '@heroicons/react/24/outline'
import debounce from 'debounce'
import { Select } from 'flowbite-react'
import { useParams } from 'react-router-dom'

import PedidosModalDetail from './detalhe-item-pedido.modal'
import Loader from '@/components/Loader'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import TableItemPedidos from '@/components/Table/table-item-pedidos.component'
import { DetalheItemsPedidoProvider } from '@/contexts/detalhe-itens-pedido.context'
import { useDetalhePedidoContext } from '@/contexts/detalhe-pedido.context'
import { useGetVendas } from '@/hooks/queries/use-get-pedidos'
import { IItensVenda } from '@/interfaces/pedidos'
import { TABLE_HEADERS_DETALHES_PEDIDOS_OPERADORA } from '@/pages/Pedidos/constants'

const DetailItensPedido = () => {
  const { id } = useParams()
  const [isPedidoItensListModalOpen, setIsPedidoItensListModalOpen] =
    useState<boolean>(false)
  const [selectedItensVenda, setSelectedItensVenda] = useState<IItensVenda[]>(
    []
  )
  const [selectedCnpj, setSelectedCnpj] = useState<string>('')
  const [agrupado, setAgrupado] = useState<boolean>(false)
  const { pedidoData } = useDetalhePedidoContext()
  const handleOpenRepassesModal = (
    itensVenda: IItensVenda[],
    cnpjUnidade: string,
    vendaAgrupada: boolean
  ) => {
    setSelectedCnpj(cnpjUnidade)
    setSelectedItensVenda(itensVenda)
    setAgrupado(vendaAgrupada)
    setIsPedidoItensListModalOpen(true)
  }

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const startIndex = (page - 1) * pageSize
  const endIndex = page * pageSize
  const [search, setSearch] = useState('')
  const unidadePedidoId = pedidoData?.length ? pedidoData[0].unidadeId : ''

  const {
    data: pedidoOperadorasList,
    isLoading: isLoadingPedidoOperadoras,
    isIdle
  } = useGetVendas({
    params: {
      pedidoId: id ?? '',
      unidadeId: unidadePedidoId,
      inicio: 0,
      comprimento: 1000,
      situacoesItem: [
        'Ativo',
        'Devolvido',
        'AguardandoDevolver',
        'Finalizado',
        'CnabGerado'
      ],
      agrupar: true
    }
  })

  const mapedProperties = useMemo(() => {
    return pedidoOperadorasList?.conteudo?.resultados
      .filter(item =>
        item?.nomeOperadora
          ?.toLocaleLowerCase()
          .includes(search?.toLocaleLowerCase())
      )
      .slice(startIndex, endIndex)
      .map(item => {
        let itens = 0
        let repasses = 0
        let contemErro = false
        item.itensVenda.forEach(i => {
          itens = itens + i.totalComEconomia
          repasses = repasses + (i.taxaOperadora ?? 0)
          contemErro = contemErro || i.errosItem.length != 0
        })

        return {
          cnpj: item.cnpjUnidade,
          pedidoId: item.idPedidoVenda,
          vendaId: item.id,
          operadora: item.nomeOperadora,
          tipoCartao: item.tipoCartaoOperadora,
          qtdColaboradores: item.quantidadeDeCpfs,
          itens: itens.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL'
          }),
          itensTotal: itens,
          acoes: (
            <EyeIcon
              className='h-5 w-5 text-primary-dark justify-center items-center cursor-pointer'
              onClick={() =>
                handleOpenRepassesModal(
                  item.itensVenda,
                  item.cnpjUnidade,
                  item.agrupado
                )
              }
            />
          )
        }
      })
      .filter(item => item.itensTotal > 0)
  }, [pedidoOperadorasList, search, pageSize, page])

  const totalPages = Math.ceil(
    (pedidoOperadorasList?.conteudo?.resultados?.filter(item =>
      item?.nomeOperadora
        ?.toLocaleLowerCase()
        .includes(search?.toLocaleLowerCase())
    )?.length ?? 0) / pageSize
  )

  const handleSearch = debounce((e: SetStateAction<string>) => {
    setSearch(e)
  }, 500)

  return (
    <>
      <div className='overflow-x-auto'>
        <SearchTableItem
          isAddItem={false}
          isSearchInputEnabled={true}
          setFilterText={e => handleSearch(e)}
        />
        {isLoadingPedidoOperadoras ? (
          <div className='h-96 w-full flex justify-center items-center'>
            <Loader size={'xl'} />
          </div>
        ) : (
          <div>
            <TableItemPedidos
              isActions={false}
              isRowClicable={false}
              data={mapedProperties || []}
              headers={TABLE_HEADERS_DETALHES_PEDIDOS_OPERADORA}
              deleteAction={() => void 0}
              hasCustomActions={false}
              idKey='vendaId'
              isIdle={isIdle}
              data-testid='table-pedidos-operadoras'
            />
            <PaginationItem
              totalItems={
                mapedProperties?.filter(item => item.operadora.includes(search))
                  ?.length ?? 0
              }
              totalPages={totalPages}
              pageSize={pageSize}
              onPageChange={(pageParam: number) => setPage(pageParam)}
            >
              <Select
                id='select-page-size'
                data-testid='select-page-size'
                onChange={e => setPageSize(Number(e.target.value))}
              >
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </Select>
            </PaginationItem>
          </div>
        )}
      </div>
      <DetalheItemsPedidoProvider>
        <PedidosModalDetail
          selectedItensVenda={selectedItensVenda}
          cnpjUnidade={selectedCnpj}
          agrupado={agrupado}
          isOpen={isPedidoItensListModalOpen}
          onClose={() => setIsPedidoItensListModalOpen(false)}
        />
      </DetalheItemsPedidoProvider>
    </>
  )
}

export default DetailItensPedido
