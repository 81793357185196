import { useEffect, useMemo, useState } from 'react'

import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Button, Checkbox, Label, Select, TextInput } from 'flowbite-react'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'

import ConfirmarPedidoModal from '@/components/pedidos/criar-pedido/confirmar-pedido.modal'
import CriarPedidoForm from '@/components/pedidos/criar-pedido/criar-pedido.form'
import TableItem from '@/components/Table'
import toastService from '@/components/toast/toast-service'
import { routeNames } from '@/configs/routePaths'
import { useCriarPedidosContext } from '@/contexts/criar-pedido.context'
import {
  useCreatePedidoMutation,
  useProcessarPedidovendaMutation
} from '@/hooks/mutations/use-pedidos-mutation'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { IBeneficiarioPedidos } from '@/interfaces/beneficiarios'
import { IPedidoForm } from '@/interfaces/pedidos'
import { TABLE_HEADERS_BENEFICIARIOS_PEDIDOS } from '@/pages/Pedidos/constants'
import { getBenficiarioPorMatriculaCpf } from '@/services/beneficiarios'
import { appendCurrentTime } from '@/utils'
import { combineByIdUnidade } from '@/utils/combine-by-unidade'
import { cpfMask } from '@/utils/masks'

export interface IGrouppedColaboradores {
  [x: string]: IBeneficiarioPedidos[]
}

const CriarPedidoPorColaboradorContainer = () => {
  const navigate = useNavigate()
  const [selectedColaboradores, setSelectedColaboradores] = useState<
    IBeneficiarioPedidos[] | null
  >(null)
  const { data: userData } = useGetUserData()
  const { mutateAsync: createPedido, isLoading: isLoadingCriarPedido } =
    useCreatePedidoMutation()
  const { mutateAsync: processarPedido, isLoading: isLoadingProcessarPedido } =
    useProcessarPedidovendaMutation()
  const [cpfOrMatricula, setCpfOrMatricula] = useState<string>('')
  const [isSelectedContaCorrente, setIsSelectedContaCorrente] = useState(false)
  const [selectedType, setSelectedType] = useState<
    'Cpf' | 'Identificador' | string
  >('Cpf')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { data, setFilteredData, branchesList, configuracoesCreditoCerto } =
    useCriarPedidosContext()
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    setFilteredData(data)
  }, [data])

  const formik = useFormik({
    initialValues: {} as IPedidoForm,
    onSubmit: values => {
      if (!selectedColaboradores) {
        toastService.error('Adicione ao menos um colaborador')
        return
      }
      const DTO = combineByIdUnidade(selectedColaboradores)?.map(
        colaborador => {
          return {
            idRequisicao: crypto.randomUUID(),
            idEmpregador: userData?.authenticatedAccountId || '',
            idUnidade: colaborador.idUnidade,
            idAplicacao: process.env.VITE_APP_APP_ID ?? '',
            periodoUtilizacaoInicial: appendCurrentTime(
              values.periodoUtilizacaoInicial
            ),
            periodoUtilizacaoFinal: appendCurrentTime(
              values.periodoUtilizacaoFinal
            ),
            previsaoDisponibilidade: appendCurrentTime(
              values.previsaoDisponibilidade
            ),
            previsaoPagamento: appendCurrentTime(values.previsaoPagamento),
            tipoPedidoVenda: values.tipoPedidoVenda,
            usarCredito: isSelectedContaCorrente,
            utilizaEconomia: configuracoesCreditoCerto?.conteudo?.find(
              configuracao => configuracao.idUnidade === colaborador.idUnidade
            )?.utilizaEconomia,
            idsLocaisDeEntrega: [],
            idsDepartamentos: [],
            idsCargos: [],
            periodos: [],
            idsTrabalhadores: colaborador.colaboradores.map(item => item.id)
          }
        }
      )

      createPedido({
        pedidos: DTO,
        criadoPor: userData?.userId ?? '',
        criadoEm: new Date()
          .toISOString()
          .replace('T', 'T')
          .replace(/\..+/, '')
          .concat('Z')
      }).then(data => {
        const response = data
        processarPedido({
          idsPedidoVenda: response.conteudo.map((item: any) => item.id)
        }).then(() => {
          setIsModalOpen(true)
        })
      })
    }
  })

  const handleSearchColaborador = async () => {
    if (cpfOrMatricula.length <= 0) {
      return
    }

    if (
      selectedColaboradores?.find(
        item =>
          item.cpf === cpfOrMatricula || item.identificador === cpfOrMatricula
      )
    ) {
      toastService.error('Colaborador já adicionado')
      return
    }

    setIsLoading(true)
    getBenficiarioPorMatriculaCpf({
      params: {
        IdsUnidades: branchesList?.map(unidade => unidade.value) || [],
        TipoDado: selectedType,
        Dado: cpfOrMatricula
      }
    })
      .then(response => {
        if (response.conteudo === undefined)
          toastService.error(
            'Trabalhador não está ativo e/ou não possui benefícios ativos'
          )
        response.conteudo?.forEach(item => {
          setSelectedColaboradores((prevState: any) => {
            if (!prevState) {
              return [item]
            }

            return [...prevState, item]
          })
        })
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false)
        setCpfOrMatricula('')
      })
  }

  const handleRemoverColaborador = (item: IBeneficiarioPedidos) => {
    setSelectedColaboradores(prevState => {
      if (!prevState) {
        return prevState
      }

      return prevState.filter(colaborador => colaborador.id !== item.id)
    })
  }

  const groupByUnidade = useMemo(() => {
    const mapedColaboradores = selectedColaboradores?.map(item => {
      return {
        ...item,
        beneficioTotal: item.beneficioTotal.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        }),
        acoes: (
          <TrashIcon
            className='w-4 h-4 text-red-500 cursor-pointer'
            title='Remover da lista'
            onClick={() => handleRemoverColaborador(item)}
          />
        )
      }
    })
    if (!mapedColaboradores) {
      return []
    }

    return mapedColaboradores.reduce((acc, item) => {
      if (!acc[item.nomeUnidade]) {
        acc[item.nomeUnidade] = []
      }

      acc[item.nomeUnidade].push(item)
      return acc
    }, {} as Record<string, IBeneficiarioPedidos[]>)
  }, [selectedColaboradores]) as IGrouppedColaboradores

  const handleRemoveAll = () => {
    setSelectedColaboradores(null)
  }

  return (
    <>
      <div className='w-full mt-10 flex flex-col md:flex-row gap-5 md:gap-0 justify-between items-end'>
        <div className='flex justify-start items-end gap-2'>
          <div>
            <Label htmlFor='cpf'>Informe o CPF ou Matrícula</Label>
            <div className='flex gap-1'>
              <Select
                onChange={e => {
                  setSelectedType(e.target.value)
                  setCpfOrMatricula('')
                }}
                defaultValue={'Cpf'}
              >
                <option value='Cpf'>CPF</option>
                <option value='Identificador'>Matrícula</option>
              </Select>
              <TextInput
                id='cpf-ou-matricula'
                name='cpf ou matricula'
                className='md:min-w-72'
                placeholder='Digite o valor aqui'
                value={
                  selectedType === 'Cpf'
                    ? cpfMask(cpfOrMatricula)
                    : cpfOrMatricula
                }
                onChange={e => setCpfOrMatricula(e.target.value)}
              />
            </div>
          </div>
          <Button
            className='mt-2 border-2 border-primary text-primary'
            onClick={handleSearchColaborador}
            isProcessing={isLoading}
            outline
            color='light'
            size='sm'
            disabled={isLoading}
          >
            <PlusCircleIcon className='w-5 h-5 mr-2 text-primary-hover' />
            <span className='text-primary-hover'>Adicionar</span>
          </Button>
        </div>
        <div>
          <Button
            className='bg-orange-500 text-white'
            color='orange'
            onClick={handleRemoveAll}
            disabled={!selectedColaboradores?.length}
          >
            <TrashIcon className='w-5 h-5 mr-2' />
            <span>Remover todos</span>
          </Button>
        </div>
      </div>
      <div className='mb-10'>
        {!!selectedColaboradores?.length ? (
          groupByUnidade &&
          Object.keys(groupByUnidade).map((unidade, index) => (
            <>
              <div
                key={index}
                className='w-full mt-5 border border-gray-200 rounded-xl p-3'
              >
                <h2 className='font-semibold pb-3'>{unidade}</h2>
                <hr />
                <TableItem
                  isActions={false}
                  isRowClicable={false}
                  data={groupByUnidade[unidade]}
                  headers={TABLE_HEADERS_BENEFICIARIOS_PEDIDOS}
                  deleteAction={() => void 0}
                  hasCustomActions={false}
                  idKey='userCpf'
                  isIdle={isLoading}
                />
              </div>
            </>
          ))
        ) : (
          <div className='w-full'>
            <div className='bg-gray-100 p-5 mt-5 rounded-lg text-center'>
              <span className='text-gray-600 text-sm font-semibold'>
                Nenhum colaborador selecionado
              </span>
            </div>
          </div>
        )}
      </div>
      <hr className='my-3 bg-cyan-700 h' />
      <h2 className='font-semibold pb-3'>Informações do Pedido</h2>
      <CriarPedidoForm formik={formik}>
        <div className='w-full flex justify-between items-center col-span-12'>
          <div className='flex gap-2 justify-center items-center'>
            <Checkbox
              id='termos'
              checked={isSelectedContaCorrente}
              onClick={e => setIsSelectedContaCorrente(e.currentTarget.checked)}
            />
            <Label className='font-semibold'>
              Utilizar crédito de conta corrente da empresa
            </Label>
          </div>
          <Button
            type='submit'
            className='flex gap-2 justify-center items-center'
            isProcessing={isLoadingCriarPedido || isLoadingProcessarPedido}
            disabled={isLoadingCriarPedido || isLoadingProcessarPedido}
          >
            Incluir pedido
          </Button>
        </div>
      </CriarPedidoForm>
      <ConfirmarPedidoModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
          navigate(`/${routeNames.pedidosPendentes}`)
        }}
        isPedidoComboleto={false}
      />
    </>
  )
}

export default CriarPedidoPorColaboradorContainer
