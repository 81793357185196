import { useState } from 'react'

import classNames from 'classnames'
import { Pagination } from 'flowbite-react'

interface IPaginationItemProps {
  totalPages: number
  totalItems?: number
  pageSize?: number
  onPageChange: (page: number) => void
  children?: React.ReactNode
}

const PaginationItem = ({
  totalPages,
  onPageChange,
  totalItems,
  children
}: IPaginationItemProps) => {
  const [currentPage, setCurrentPage] = useState(1)
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    onPageChange(page)
  }
  return (
    <div className='flex justify-between items-end w-full'>
      {totalItems && totalPages > 1 ? (
        <div className='flex gap-3 justify-start items-center'>
          <span className='text-gray-800 text-sm text-normal'>
            Página <b>{currentPage}</b> de <b>{totalPages}</b>
          </span>
          {children && (
            <div className='flex justify-center items-center gap-3'>
              <span className='text-gray-800 text-sm text-normal'>
                {' '}
                exibindo:{' '}
              </span>
              {children}
              <span className='text-gray-800 text-sm text-normal'>itens </span>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      <Pagination
        className={classNames(
          'flex justify-center items-center mt-6',
          totalPages < 2 ? 'hidden' : 'block'
        )}
        currentPage={currentPage}
        nextLabel=''
        theme={{
          pages: {
            selector: {
              active: 'bg-pagination transition-all'
            }
          }
        }}
        previousLabel=''
        onPageChange={page => handlePageChange(page)}
        showIcons
        totalPages={isNaN(totalPages) ? 0 : totalPages}
      />
    </div>
  )
}

export default PaginationItem
