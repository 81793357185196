interface IStepProps {
  step: number
  title: string
  body: string
  imageSrc?: string
  ref?: string
  totalSteps: number
}

const StepStructure = (stepContent: IStepProps) => (
  <div>
    <div className='p-4 pb-2'>
      <div>
        {stepContent.imageSrc && (
          <img src={stepContent.imageSrc} className=' h-48 w-96' />
        )}
      </div>
      <h3 className='p-2 text text-gray-900 font-semibold text-center'>
        {stepContent.title}
      </h3>
      <p className='p-2 text-center text-gray-900 text-sm'>
        {stepContent.body}
      </p>
      <span className='flex text-gray-600 text-sm justify-center p-2'>
        Passo {stepContent.step} de {stepContent.totalSteps}
      </span>
    </div>
  </div>
)

export default StepStructure
