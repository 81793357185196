import DevolucoesContainer from '@/containers/devolucoes/devolucoes.container'
import { DevolucoesContext } from '@/contexts/devolucoes.context'

const DevolucoesView = () => {
  return (
    <DevolucoesContext>
      <DevolucoesContainer />
    </DevolucoesContext>
  )
}

export default DevolucoesView
