import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import { Button, Modal } from 'flowbite-react'

import DropZoneInput from '@/components/basic/drop-zone/drop-zone.component'
import { useDevolucoesContext } from '@/contexts/devolucoes.context'

interface IResumoPedidoModalProps {
  onClose: () => void
  isOpen: boolean
}

const AcoesDevolucoesModal: React.FC<IResumoPedidoModalProps> = ({
  onClose,
  isOpen
}: IResumoPedidoModalProps) => {
  const { downloadURL, getFileFromDevolucoes, setDevolucoesItemId } =
    useDevolucoesContext()

  const handleCLickDownload = () => {
    getFileFromDevolucoes()
    setDevolucoesItemId('1')
    window.open(downloadURL?.url, '_blank')
  }

  return (
    <>
      <Modal
        popup
        show={isOpen}
        onClose={onClose}
        className='flex items-center'
        size='2xl'
      >
        <Modal.Header
          data-testid='acoes-devolucoes-modal-content'
          className='bg-primary-hover text-white rounded-b-xl'
        >
          <p className='text-center font-semibold  p-3 text-white'>
            Ações de Devoluções
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className='grid grid-cols-1 gap-4 mt-0'>
            <div
              id='download-full-resume'
              className='mt-10 p-5 flex flex-col justify-center items-center gap-3 border rounded-xl'
            >
              <label className='block text-gray-900 font-semibold text-center'>
                Extração da planilha
              </label>
              <p className='text-center text-gray-600 text-sm'>
                Extraia as devoluções em aberto e selecione o tipo de devolução
                para cada beneficiário devolvido
              </p>
              <Button //TO REVIEW
                type='button'
                onClick={handleCLickDownload}
                className='btn btn-primary flex justify-center bg-primary-dark text-white rounded w-fit'
                id='complete-resume-button'
                data-testid='complete-resume-button'
              >
                <DocumentArrowDownIcon className='h-5 w-5 mr-1' />
                <span>Download</span>
              </Button>
            </div>
            <div
              id='download-partial-resume'
              className='p-5 flex relative flex-col justify-center items-center gap-3 rounded-xl'
            >
              <label className='block text-gray-900 font-semibold text-center'>
                Importação da planilha
              </label>
              <p className='text-center text-gray-600 text-sm'>
                Importe a planilha editada para processamento da forma de
                devolução definida para cada beneficiário
              </p>
              <DropZoneInput />
              <div id='partialResumeWarning' className='hidden text-red-500'>
                <i className='fa fa-exclamation-circle'></i> Disponível apenas
                para PDF.
              </div>
            </div>
          </div>
          <div className='w-full flex justify-center items-center'>
            <Button
              onClick={onClose}
              className='btn btn-secondary flex justify-center bg-white border-primary-dark text-priamry-dark rounded w-fit'
              id='close-button'
              data-testid='close-button'
            >
              Fechar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AcoesDevolucoesModal
