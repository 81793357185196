import { useMemo, useState } from 'react'

import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/outline'
import { Button, Checkbox, Label, Modal, Tooltip } from 'flowbite-react'

import ReprocessarPedidoModal from '../reprocessar-pedido-modal.component'
import CustomTextInput from '@/components/basic/input-text/input-text.component'
import Loader from '@/components/Loader'
import TableItemPedidos from '@/components/Table/table-item-pedidos.component'
import { useRevisarPedidosContext } from '@/contexts/revisar-pedido.context'
import { TABLE_HEADERS_GESTAO_ECONOMIA_PEDIDO } from '@/pages/Pedidos/constants'

interface IEconomiaModalProps {
  onClose: () => void
  isOpen: boolean
}

const EconomiaModal: React.FC<IEconomiaModalProps> = ({
  onClose,
  isOpen
}: IEconomiaModalProps) => {
  const {
    isLoadingUnidades,
    unidadesList,
    formik,
    handleReprocessarPedido,
    checkedUnidades,
    setCheckedUnidades,
    handleExportEconomia
  } = useRevisarPedidosContext()

  const [isReprocessarModalOpen, setIsReprocessarModalOpen] = useState(false)
  const [previsaoDisponibilidadeEconomia, setPrevisaoDisponibilidadeEconomia] =
    useState(formik.values.previsaoDisponibilidade)

  const handleCheckboxChange = (id: string) => {
    setCheckedUnidades(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }

  const handleSelectAll = (allSelected: boolean) => {
    setCheckedUnidades(
      unidadesList
        ?.map(pedido => ({
          ...pedido,
          usaEconomia: allSelected
        }))
        .reduce((acc: any, pedido) => {
          acc[pedido.unidadeId] = pedido.usaEconomia
          return acc
        }, {})
    )
  }

  const handleAplicaSugestaoEconomia = () => {
    formik.setFieldValue(
      'previsaoDisponibilidade',
      previsaoDisponibilidadeEconomia
    )
    handleReprocessarPedido()
  }

  const mapedProperties = useMemo(() => {
    return unidadesList?.map(item => {
      return {
        ...item,
        unidadeId: (
          <span className='flex gap-2 justify-between items-center'>
            <Checkbox
              id={item.unidadeId}
              checked={checkedUnidades[item.unidadeId] ?? item.usaEconomia}
              onChange={() => handleCheckboxChange(item.unidadeId)}
              data-testid='check-unidade'
            />
          </span>
        )
      }
    })
  }, [unidadesList, checkedUnidades])

  return (
    <div data-testid='modal-economia'>
      <Modal
        show={isOpen}
        onClose={onClose}
        className='flex items-center'
        size='7xl'
      >
        <Modal.Header className='p-1 gap-2 mb-5'>
          <p className='text-center font-bold p-3'>Crédito Certo</p>
        </Modal.Header>
        <Modal.Body>
          <div className='flex flex-col md:grid grid-cols-12 w-full p-1 bg-white gap-5 mb-5 rounded-lg'>
            <div className='flex flex-col col-span-4 gap-2'>
              <Label>Data Previsão Disponibilização</Label>
              <div className='flex gap-2'>
                <CustomTextInput
                  type='date'
                  field='previsaoDisponibilidadeEconomia'
                  data-testid='previsaoDisponibilidadeEconomia'
                  formik={formik}
                  min={new Date().toISOString().split('T')[0]}
                  value={previsaoDisponibilidadeEconomia}
                  onChange={value =>
                    setPrevisaoDisponibilidadeEconomia(value.target.value)
                  }
                  label=''
                  className='w-full'
                  required
                />
                <Tooltip content='Atualizar Economia' placement='top'>
                  <Button
                    color='outline'
                    className='border border-quaternary'
                    data-testid='btn-atualiza-economia-table'
                  >
                    <ArrowPathRoundedSquareIcon className='h-5 w-5 text-quaternary' />
                  </Button>
                </Tooltip>
              </div>
            </div>
            {isLoadingUnidades ? (
              <div
                className='h-96 w-full flex justify-center items-center'
                data-testid='loader-economia'
              >
                <Loader size={'xl'} />
              </div>
            ) : (
              <div
                data-testid='table-economia'
                className='flex flex-col col-span-12 gap-2'
              >
                <div className='flex justify-left gap-2 items-left'>
                  <Button
                    color='outline'
                    className='flex gap-2 border border-quaternary text-quaternary'
                    data-testid='btn-check-all'
                    onClick={() => handleSelectAll(true)}
                  >
                    <span>Selecionar Todas</span>
                  </Button>
                  <Button
                    color='outline'
                    className='flex gap-2 border border-quaternary text-quaternary'
                    data-testid='btn-check-none'
                    onClick={() => handleSelectAll(false)}
                  >
                    <span>Nenhuma</span>
                  </Button>
                </div>
                <TableItemPedidos
                  isActions={false}
                  isRowClicable={false}
                  data={mapedProperties || []}
                  headers={TABLE_HEADERS_GESTAO_ECONOMIA_PEDIDO}
                  deleteAction={() => void 0}
                  hasCustomActions={false}
                  idKey='pedidoId'
                  isIdle={false}
                />
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <>
            <div className='w-full flex justify-between'>
              <div className='flex justify-between gap-2 items-left'>
                <Button color='gray' onClick={onClose} data-testid='btn-close'>
                  Fechar
                </Button>
              </div>
              <div className='flex justify-between gap-2 items-right'>
                <Button
                  className='flex gap-2 justify-center items-center bg-orange-400 text-white'
                  onClick={handleExportEconomia}
                  data-testid='btn-exportar'
                >
                  <span>Exportar</span>
                </Button>
                <Button
                  color='success'
                  onClick={() => setIsReprocessarModalOpen(true)}
                  className='flex gap-2 justify-center items-center bg-quaternary'
                  data-testid='btn-apply-suggestion'
                >
                  <span>Aplicar Sugestão</span>
                </Button>
              </div>
            </div>
          </>
        </Modal.Footer>
      </Modal>
      <ReprocessarPedidoModal
        isOpen={isReprocessarModalOpen}
        onClose={() => setIsReprocessarModalOpen(false)}
        onConfirm={handleAplicaSugestaoEconomia}
      />
    </div>
  )
}

export default EconomiaModal
