import { SetStateAction, useMemo, useState } from 'react'

import debounce from 'debounce'
import { useParams } from 'react-router-dom'

import BreadCrumbs from '@/components/Breadcrumbs/breadcrumbs.component'
import Loader from '@/components/Loader'
import TableItem from '@/components/Table'
import TimeLineItem from '@/components/Table/Logistica/timeline-item.component'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import { Skeleton } from '@/components/ui/skeleton'
import {
  useGetLogisticItem,
  useGetLogisticsList
} from '@/hooks/queries/use-get-logistics'
import { formatDateString } from '@/utils/date-format'
import { customRound } from '@/utils/round-floats'

const EncomendasEdit = () => {
  const { id } = useParams()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const pageSize = 10
  const { data: logistic, isLoading: isLoadingLogistic } = useGetLogisticItem(
    Number(id) ?? 0
  )
  const {
    data: logisticList,
    isLoading: isLoadingLogisticList,
    isFetching: isFetchingList
  } = useGetLogisticsList({
    page,
    pageSize,
    groupNumber: Number(id) ?? 0,
    search
  })
  const handleSearch = debounce((e: SetStateAction<string>) => {
    setSearch(e)
  }, 500)

  const links = [
    {
      title: 'Logística',
      url: `/logistica`
    },
    {
      title: 'Acompanhamento',
      url: ``
    }
  ]

  const tableHeaders = {
    ['employee']: 'Colaborador',
    ['cpf']: 'CPF',
    ['branchName']: 'Unidade',
    ['orderNumber']: 'Nº Pedido',
    ['operatorCode']: 'Código do produto',
    ['product']: 'Produto',
    ['ticketType']: 'Tipo',
    ['card']: 'Nº Cartão'
  }

  const pedidosSteps = [
    {
      title: 'Pedido realizado',
      icon: '/icons/pedido-realizado.svg'
    },
    {
      title: 'Produto coletado',
      icon: '/icons/produto-coletado.svg'
    },
    {
      title: 'Preparando envio',
      icon: '/icons/preparando-envio.svg'
    },
    {
      title: 'Em transferência para o destino',
      icon: '/icons/em-transferencia.svg'
    },
    {
      title: 'Saiu para entrega ao destinatário',
      icon: '/icons/saiu-entrega.svg'
    },
    {
      title: 'Entrega concluída',
      icon: '/icons/entrega-concluida.svg'
    }
  ]

  const timeline = useMemo(() => {
    return logistic?.timeLineItens?.map((item, index) => ({
      id: index,
      title: pedidosSteps[index].title,
      date: formatDateString(item.eventDate),
      icon: pedidosSteps[index].icon,
      finished: item.finished,
      description: item.description
    }))
  }, [logistic?.timeLineItens])
  return (
    <>
      {isLoadingLogisticList && <Loader key={1} size='2xl' />}
      <div className='min-h-screen'>
        <div className='grid grid-col-span-12 gap-5'>
          <div className='w-full flex col-span-12'>
            <BreadCrumbs links={links} />
          </div>
          <div className='bg-white p-5 rounded-lg col-span-12 flex justify-between items-center'>
            <p className='font-semibold text-sm'>
              Encomenda | {logistic?.deliveryGroupNumber}
            </p>
            {isLoadingLogistic ? (
              <Skeleton className='h-4 w-[250px]' />
            ) : (
              <p className='font-normal italic text-sm'>
                Última atualização em:{' '}
                {formatDateString(logistic?.lastUpdate as string)}
              </p>
            )}
          </div>
          <div className='bg-white p-3 rounded-lg col-span-12'>
            <p className='font-semibold text-lg leading-7'>
              Informações gerais
            </p>

            <div className='grid grid-cols-1 md:grid-cols-12 gap-5 pt-5'>
              <div className='col-span-6 md:col-span-2 flex flex-col'>
                <span className='text-xs text-primary-hover'>
                  Total de itens
                </span>
                <p className='font-bold text-xl'>
                  {isLoadingLogistic ? (
                    <Skeleton className='h-6 w-[50px]' />
                  ) : (
                    logistic?.itemsCount
                  )}
                </p>
              </div>

              <div className='col-span-6 md:col-span-6 flex flex-col'>
                <span className='text-xs text-primary-hover'>
                  Endereço de entrega
                </span>
                <p className='font-bold text-xl'>
                  {isLoadingLogistic ? (
                    <Skeleton className='h-6 w-[200px]' />
                  ) : (
                    logistic?.address
                  )}
                </p>
              </div>

              <div className='col-span-6 md:col-span-2 flex flex-col'>
                <span className='text-xs text-primary-hover'>
                  Previsão de entrega
                </span>
                <p className='font-bold text-xl'>
                  {isLoadingLogistic ? (
                    <Skeleton className='h-6 w-[200px]' />
                  ) : (
                    logistic?.expectedDate
                  )}
                </p>
              </div>

              <div className='col-span-6 md:col-span-2 flex flex-col items-start'>
                <span className='text-xs text-primary-hover'>
                  Baixar comprovante
                </span>
                <div className='flex items-center space-x-2'>
                  <a
                    href={logistic?.urlProofDelivery || '#'}
                    download
                    target='_blank'
                    rel='noopener noreferrer'
                    className='flex items-center px-1 py-0 rounded-xl'
                    title={
                      logistic?.urlProofDelivery
                        ? ''
                        : 'Comprovante indisponível'
                    }
                    style={{
                      backgroundColor: logistic?.urlProofDelivery
                        ? 'rgb(3, 77, 46)'
                        : 'rgb(156, 163, 175)',
                      pointerEvents: logistic?.urlProofDelivery
                        ? 'auto'
                        : 'none'
                    }}
                  >
                    <img
                      src='/icons/baixar-comprovante.svg'
                      alt='Download'
                      className='w-4 h-6'
                    />
                  </a>
                  <p className='font-bold text-xl'>Entrega</p>
                </div>
              </div>
            </div>
          </div>

          <div className='col-span-12 bg-white rounded-lg pt-10 pb-5'>
            <div className='grid grid-cols-12 gap-5 w-full overflow-hidden px-3'>
              {isLoadingLogistic
                ? Array(6)
                    .fill('')
                    .map((_, index) => (
                      <div
                        key={index}
                        className='relative lg:col-span-2 col-span-12 flex justify-start items-center flex-col gap-1'
                      >
                        <Skeleton className='w-[96px] h-[96px] rounded-full' />
                        <Skeleton className='w-[100px] h-[6px]' />
                        <Skeleton className='w-[80px] h-[6px]' />
                      </div>
                    ))
                : timeline?.map((item, index) => (
                    <TimeLineItem key={index} {...item} />
                  ))}
            </div>
          </div>
          <div className='col-span-12 overflow-hidden mb-10'>
            <SearchTableItem
              isAddItem={false}
              setFilterText={e => handleSearch(e)}
            />
            <TableItem
              isActions={false}
              isRowClicable={false}
              data={logisticList?.items ?? []}
              headers={tableHeaders}
              deleteAction={() => void 0}
              idKey='orderNumber'
              isIdle={isFetchingList}
            />
            <PaginationItem
              totalItems={logisticList?.totalItems as number}
              pageSize={pageSize}
              totalPages={customRound(
                (logisticList?.totalItems as number) / pageSize
              )}
              onPageChange={(pageParam: number) => setPage(pageParam)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default EncomendasEdit
