'use client'

import { useState } from 'react'

import {
  InformationCircleIcon,
  PencilIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { Table } from 'flowbite-react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Link, useNavigate } from 'react-router-dom'

import { theme } from './theme'
import DeleteModal from '../delete-modal/index'
import CustomActionsMenuAcompanharPedidos from '../pedidos/acompanhar-pedidos/custom-action-acompanhar-pedidos'
import { IPedido } from '@/interfaces/pedidos'

interface GenericTableProps {
  readonly data: ReadonlyArray<Record<string, any>>
  readonly headers: { readonly [key: string]: string }
  readonly deleteAction: (id: string) => void
  readonly idKey?: string
  readonly isIdle?: boolean
  readonly isRowClicable?: boolean
  readonly isActions?: boolean
  readonly hasCustomActions?: boolean
  readonly customActions?: ReadonlyArray<{
    readonly title: string
    readonly action: (id?: any) => void
  }>
}

export default function TableItemPedidos({
  data,
  headers,
  deleteAction,
  isIdle = false,
  isActions = true,
  isRowClicable = false,
  idKey = 'id',
  hasCustomActions = false
}: GenericTableProps) {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<string>('')
  const [columns, setColumns] = useState(Object.keys(headers))

  const handleDeleteClick = () => deleteAction(selectedId)
  const handleToggleModal = () => setIsOpen(item => !item)
  const handleOpenModal = (id: string) => {
    setSelectedId(id ?? '')
    handleToggleModal()
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return
    const newColumns = Array.from(columns)
    const [removed] = newColumns.splice(result.source.index, 1)
    newColumns.splice(result.destination.index, 0, removed)
    setColumns(newColumns)
  }

  if (!isIdle && data?.length === 0) {
    return (
      <div className='p-5 bg-gray-200 flex w-full justify-center items-center text-center rounded'>
        <InformationCircleIcon width={18} height={18} className='mr-2' />
        <p className='text-gray-900'>Nenhum dado disponível</p>
      </div>
    )
  }

  return (
    <>
      <div
        className={`overflow-x-auto md:overflow-x-auto min-h-60 h-auto transition-opacity ${
          isIdle ? 'opacity-70 pointer-events-none relative' : ''
        }`}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable' direction='horizontal'>
            {provided => (
              <Table
                hoverable
                theme={theme}
                className='rounded-lg'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <Table.Head className='border-b-[1px] '>
                  {columns.map((column, index) => (
                    <Draggable key={column} draggableId={column} index={index}>
                      {provided => (
                        <Table.HeadCell
                          align='char'
                          className='bg-white text-gray-600'
                          id={headers[column]}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {headers[column]}
                        </Table.HeadCell>
                      )}
                    </Draggable>
                  ))}
                  {(isActions || hasCustomActions) && (
                    <Table.HeadCell className='bg-white text-gray-600'>
                      <span className='sr-only'>Edit</span>
                    </Table.HeadCell>
                  )}
                </Table.Head>
                <Table.Body className='divide-y'>
                  {data.map((item, index) => (
                    <Table.Row
                      className={classNames(
                        item?.isRowClicable && 'cursor-pointer',
                        'bg-white dark:border-gray-700 dark:bg-gray-800'
                      )}
                      key={index + 1}
                    >
                      {columns.map(column => (
                        <Table.Cell
                          align='char'
                          className='whitespace-nowrap relative'
                          key={column}
                          onClick={() => {
                            const isClickable =
                              item?.isRowClicable === undefined
                                ? isRowClicable
                                : item.isRowClicable
                            return isClickable
                              ? navigate(`edit/${item[idKey]}`)
                              : null
                          }}
                        >
                          <>
                            {(() => {
                              const isRowClickable =
                                item?.isRowClicable != undefined
                                  ? item.isRowClicable
                                  : !isRowClicable
                              return isRowClickable ? (
                                <span>{item[column]}</span>
                              ) : (
                                <span>
                                  {item[column]}
                                  {(item?.isRowClicable === undefined
                                    ? isRowClicable
                                    : !item.isRowClicable) && (
                                    <div className='absolute w-full h-full left-0 top-0 bg-white opacity-50 pointer-events-none'></div>
                                  )}
                                </span>
                              )
                            })()}
                          </>
                        </Table.Cell>
                      ))}
                      {(isActions || hasCustomActions) && (
                        <Table.Cell className='flex justify-center items-center gap-5 h-full'>
                          {isActions && (
                            <>
                              <span className='font-medium text-cyan-600 hover:underline dark:text-cyan-500'>
                                <Link to={`edit/${item[idKey]}`}>
                                  <PencilIcon
                                    width={16}
                                    height={16}
                                    className='text-gray-800'
                                  />
                                </Link>
                              </span>
                              <span className='font-medium cursor-pointer text-cyan-600 hover:underline dark:text-cyan-500'>
                                <TrashIcon
                                  onClick={() => handleOpenModal(item[idKey])}
                                  width={16}
                                  height={16}
                                  className='text-red-600'
                                />
                              </span>
                            </>
                          )}
                          {hasCustomActions && (
                            <CustomActionsMenuAcompanharPedidos
                              pedido={item as IPedido}
                            />
                          )}
                        </Table.Cell>
                      )}
                    </Table.Row>
                  ))}
                </Table.Body>
                {provided.placeholder}
              </Table>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <DeleteModal
        isOpen={isOpen}
        onClose={handleToggleModal}
        onDelete={handleDeleteClick}
      />
    </>
  )
}
