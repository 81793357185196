import { useState, useMemo, useEffect } from 'react'

import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'

import {
  useEditarItensPedidoMutation,
  useReprocessarPedidoMutation
} from '@/hooks/mutations/use-pedidos-mutation'
import { useGetVendas } from '@/hooks/queries/use-get-pedidos'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { IEdicaoPedidoItem, IFormPropsBulkEdit } from '@/interfaces/pedidos'

export const useEditarPedidosEmMassa = () => {
  const { idPedido, idOperadora, idUnidade } = useParams()
  const { data: userData } = useGetUserData()
  const { mutate: reprocessarPedido } = useReprocessarPedidoMutation()
  const { mutate: editarItemPedido } = useEditarItensPedidoMutation()
  const { data: pedido, isLoading: isLoadingPedido } = useGetVendas({
    params: {
      pedidoId: idPedido ?? '',
      unidadeId: idUnidade ?? '',
      operadoraId:
        idOperadora === 'vr-credito' ? undefined : idOperadora ?? undefined,
      inicio: 0,
      comprimento: 1000,
      agrupar: true,
      tipoVenda: idOperadora === 'vr-credito' ? 'VrCredito' : ''
    }
  })

  const [pageSize, setPageSize] = useState<number>(10)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState<number>(1)
  const [somenteErros, setSomenteErros] = useState(false)
  const [itemEditBulkOrders, setitemEditBulkOrders] = useState('')
  const [checkedItemsBulkEdit, setcheckedItemsBulkEdit] = useState<string[]>([])
  const [itemsSelectedEditBulkOrders, setitemsSelectedEditBulkOrders] =
    useState<IEdicaoPedidoItem[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [novoPeriodo, setNovoPeriodo] = useState<number | undefined>()
  const [novaTarifa, setNovaTarifa] = useState<number | undefined>()
  const [novaQtdDiaria, setNovaQtdDiaria] = useState<number | undefined>()
  const [ativo, setAtivo] = useState<boolean | undefined>()
  const [justificativa, setJustificativa] = useState('')
  const [erros, setErros] = useState<{ campo: string; erro: string }[]>()
  const itensInicio = (page - 1) * pageSize
  const itensFim = page * pageSize
  const itensEditBulkOrders = useMemo(() => {
    let itensVenda = pedido?.conteudo?.resultados[0]?.itensVenda ?? []
    if (somenteErros) {
      itensVenda = itensVenda.filter(i => i.errosItem.length !== 0)
    }
    return itensVenda
      ?.filter(
        i =>
          i.trabalhador.nome.includes(search) ||
          i.trabalhador.cpf.includes(search) ||
          i.trabalhador.matricula.includes(search) ||
          i.codigoTarifa.includes(search) ||
          i.descricaoTarifa.includes(search)
      )
      .slice(itensInicio, itensFim)
      .map(item => ({
        vendaId: item.idVenda,
        pedidoId: pedido?.conteudo?.resultados[0]?.idPedidoVenda,
        idVenda: item.idVenda,
        itemId: item.id,
        nome: item.trabalhador.nome,
        cpf: item.trabalhador.cpf,
        matricula: item.trabalhador.matricula,
        codigoLinha: item.codigoTarifa,
        nomeLinha: item.descricaoTarifa,
        valorLinha: item.tarifa,
        diasCompra: item.diasUso,
        quantidadeDiaria: item.quantidadePorDia,
        valorRecarga: item.total,
        erro: item.errosItem.length !== 0 ? item.errosItem[0].mensagem : '',
        status: item.tipoSituacaoItemPedido === 'Ativo',
        variavel: true
      }))
  }, [pedido, page, somenteErros, search, pageSize])

  const totalPages = Math.ceil(
    (pedido?.conteudo?.resultados[0]?.itensVenda
      .filter(i => !somenteErros || i.errosItem.length !== 0)
      .filter(
        i =>
          i.trabalhador.nome.includes(search) ||
          i.trabalhador.cpf.includes(search) ||
          i.trabalhador.matricula.includes(search) ||
          i.codigoTarifa.includes(search) ||
          i.descricaoTarifa.includes(search)
      ).length ?? 0) / pageSize
  )

  const totalItens =
    pedido?.conteudo?.resultados[0]?.itensVenda
      .filter(i => !somenteErros || i.errosItem.length !== 0)
      .filter(
        i =>
          i.trabalhador.nome.includes(search) ||
          i.trabalhador.cpf.includes(search) ||
          i.trabalhador.matricula.includes(search) ||
          i.codigoTarifa.includes(search) ||
          i.descricaoTarifa.includes(search)
      ).length ?? 0
  const formikItemsBulkEdit = useFormik({
    initialValues: {
      pedidoId: idPedido,
      itemIds: checkedItemsBulkEdit,
      valorLinha: 1,
      quantidadeDiaria: 1,
      periodo: 1,
      status: true,
      justificativa: ''
    } as IFormPropsBulkEdit,

    onSubmit: values => {
      const itensDTO = values.itemIds.map(i => ({
        id: i,
        diasUso: values.periodo,
        quantidadePorDia: values.quantidadeDiaria,
        tarifa: values.valorLinha,
        tipoSituacaoItemPedido: values.status ? 'Ativo' : 'Removido',
        observacao: values.justificativa
      }))

      const DTO = {
        atualizadoPor: userData?.userId,
        itensVenda: itensDTO,
        idPedido: idPedido,
        idVenda: pedido?.conteudo?.resultados[0]?.id
      }

      editarItemPedido(DTO)
    }
  })

  const handleReprocessarPedido = () => {
    const DTO = {
      idsPedidoVenda: [idPedido],
      criadoPor: userData?.userId,
      idEmpregador: userData?.authenticatedAccountId,
      errosIgnorados: false,
      idAplicacao: process.env.VITE_APP_APP_ID
    }
    reprocessarPedido(DTO)
  }

  const enableBulkEdit = () => {
    const selecionados = itensEditBulkOrders.filter(item =>
      checkedItemsBulkEdit.includes(item.itemId)
    )
    setitemsSelectedEditBulkOrders(selecionados)
    console.log(selecionados, 'Itens selecionados antes de abrir a modal')
    console.log(
      checkedItemsBulkEdit,
      'Itens selecionados antes de abrir a modal checkdedItems'
    )
    setIsModalOpen(true)
  }

  function handleCheckboxChangeEditBulkOrders(itemId: string): void {
    setcheckedItemsBulkEdit(prevCheckedItems =>
      prevCheckedItems.includes(itemId)
        ? prevCheckedItems.filter(id => id !== itemId)
        : [...prevCheckedItems, itemId]
    )
  }

  useEffect(() => {
    const item = itensEditBulkOrders?.find(
      i => i.itemId == checkedItemsBulkEdit[0]
    )

    setNovoPeriodo(null)
    setNovaTarifa(item?.valorLinha)
    setNovaQtdDiaria(null)
    setAtivo(true)
    setJustificativa('')
  }, [itensEditBulkOrders, checkedItemsBulkEdit])

  const setPeriodosOptions = () => {
    const periodos = []
    for (let index = 1; index < 32; index++) {
      periodos.push({
        value: index,
        label: `${index} Dias`
      })
    }
    return periodos
  }
  const periodosOptions = setPeriodosOptions()
  const selectedOption = periodosOptions?.find(
    option => option.value === novoPeriodo
  )

  const validacaoForm = () => {
    const erros = []
    if (
      novoPeriodo === undefined ||
      Number.isNaN(novoPeriodo) ||
      novoPeriodo < 1
    )
      erros.push({ campo: 'periodo', erro: 'Período inválido' })
    if (
      novaQtdDiaria === undefined ||
      Number.isNaN(novaQtdDiaria) ||
      novaQtdDiaria < 1
    )
      erros.push({ campo: 'qtdDiaria', erro: 'Quantidade diária inválida' })
    if (
      justificativa === undefined ||
      justificativa === '' ||
      !justificativa.trim()
    )
      erros.push({
        campo: 'justificativa',
        erro: 'Justificativa deve ser preenchida'
      })
    return erros
  }

  return {
    pedido,
    isLoadingPedido,
    formikItemsBulkEdit,
    itensEditBulkOrders,
    totalItens,
    setPage,
    page,
    totalPages,
    pageSize,
    setPageSize,
    setSearch,
    search,
    somenteErros,
    setSomenteErros,
    itemEditBulkOrders,
    setitemEditBulkOrders,
    handleReprocessarPedido,
    handleCheckboxChangeEditBulkOrders,
    itemsSelectedEditBulkOrders,
    enableBulkEdit,
    isModalOpen,
    checkedItemsBulkEdit,
    selectedOption,
    setNovoPeriodo,
    erros,
    setErros,
    periodosOptions,
    novaQtdDiaria,
    ativo,
    setJustificativa,
    setAtivo,
    setNovaQtdDiaria,
    justificativa,
    novaTarifa,
    validacaoForm,
    novoPeriodo,
    setPeriodosOptions
  }
}
