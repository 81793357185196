import { Dispatch, SetStateAction, useState, useEffect, useRef } from 'react'

import { FormikProps, useFormik } from 'formik'
import Cookie from 'js-cookie'

import Constants from '@/configs/constants'
import useLocalStorage from '@/hooks/common/use-local-storage'
import { useGetBranchesData } from '@/hooks/queries/use-get-branch-data'
import { useGetPedidosPendentes } from '@/hooks/queries/use-get-pedidos'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { Branch } from '@/interfaces/branches'
import { IPedidoPendenteResponse } from '@/interfaces/pedidos'
import { createContext } from '@/utils/create-context'

interface IPedidosPendentesContextProps {
  pedidosPendentesList: IPedidoPendenteResponse | undefined
  setFilteredData: Dispatch<SetStateAction<IPedidoPendenteResponse | undefined>>
  filteredData: IPedidoPendenteResponse | undefined
  isLoadingPedidosPendentes: boolean
  isFetchingPedidosPedentes: boolean
  setPage: Dispatch<SetStateAction<number>>
  totalPages: number
  setSearch: Dispatch<SetStateAction<string>>
  search: string
  formik: FormikProps<IFormProps>
  pageSize: number
  branches: Branch[] | undefined
  setPageSize: Dispatch<SetStateAction<number>>
  handleSelectedBranch: (selectedOption: any[]) => void
  formRef: React.RefObject<HTMLFormElement>
  handleClearForm: () => void
  handleClose: () => void
  handleBeforeExit: () => void
  isOnboardingFinishedOrderPending: boolean
  isOnboardingOpen: boolean
}
interface IFormProps {
  dataInicio: string
  dataFim: string
  numeroPedido: string
  idUnidade: string[] | undefined
  situacoesPedidoVenda: { label: string; value: string }[]
  unidades: {
    value: string
    label: string
  }[]
}

interface IFilterValues {
  numeroPedido: string | null
  dataInicio: string
  dataFim: string
  situacoesPedidoVenda: string[]
}

const [usePedidosPendentesContext, PedidosPendentesProvider] =
  createContext<IPedidosPendentesContextProps>()

const PedidosPendentesContext = ({ children }: JSX.Element | any) => {
  const { data: branches } = useGetBranchesData()
  const [preferedPageSize, setPreferedPageSize] = useLocalStorage(
    'preferedPageSize',
    '10'
  )
  const [pageSize, setPageSize] = useState<number>(
    preferedPageSize ? Number(preferedPageSize) : 10
  )
  const [filteredData, setFilteredData] = useState<IPedidoPendenteResponse>()
  const [page, setPage] = useState<number>(1)
  const [search, setSearch] = useState('')
  const { data: userData } = useGetUserData()
  const [filterValues, setFilterValues] = useState<IFilterValues>(
    {} as IFilterValues
  )

  useEffect(() => {
    page > 1 && setPage(1)
    setPreferedPageSize(pageSize)
  }, [pageSize])

  const defaultBranchId =
    branches?.filter(
      item => item.branchId === userData?.authenticatedBranchId
    )[0]?.branchId || ''

  const formik = useFormik<IFormProps>({
    enableReinitialize: true,
    initialValues: {
      dataInicio: new Date(new Date().setMonth(new Date().getMonth() - 1))
        .toISOString()
        .split('T')[0],
      dataFim: new Date().toISOString().split('T')[0],
      numeroPedido: '',
      situacoesPedidoVenda: [
        {
          label: 'Em Processamento',
          value: 'EmProcessamento'
        },
        {
          label: 'Processado',
          value: 'Processado'
        },
        {
          label: 'Erro',
          value: 'Erro'
        },
        {
          label: 'Pendente',
          value: 'Pendente'
        }
      ],
      idUnidade: [defaultBranchId],
      unidades:
        branches
          ?.filter(item => item.branchId === userData?.authenticatedBranchId)
          .map(item => ({
            value: item.branchId,
            label: `${item.name}-${item.cnpj}`
          })) || ([] as any)
    },
    onSubmit: values => {
      const DTO = {
        idUnidades: values.unidades?.some(unit => unit.value === 'todos')
          ? branches?.map(branch => branch.branchId)
          : values.unidades?.map(item => item.value) || [],
        numeroPedido: values.numeroPedido.length ? values.numeroPedido : null,
        dataInicio: values.dataInicio,
        dataFim: values.dataFim,
        situacoesPedidoVenda: values.situacoesPedidoVenda.map(
          item => item.value
        )
      }
      setFilterValues(DTO)
    }
  })

  const startIndex = (page - 1) * pageSize
  const {
    data: pedidosPendentesList,
    isLoading: isLoadingPedidosPendentes,
    isFetching: isFetchingPedidosPedentes
  } = useGetPedidosPendentes({
    params: {
      idEmpregador: userData?.authenticatedAccountId || '',
      inicio: startIndex,
      comprimento: pageSize,
      campo: 'CriadoEm',
      direcao: 'desc',
      procurar: search,
      idUnidades: [defaultBranchId],
      ...filterValues,
      situacoesPedidoVenda: formik.values.situacoesPedidoVenda?.map(
        item => item.value
      ),
      dataInicio: formik.values.dataInicio + 'T00:00:00',
      dataFim: formik.values.dataFim + 'T23:59:59'
    }
  })
  const totalPages = Math.ceil(
    (pedidosPendentesList?.conteudo?.totalRegistros ?? 0) / pageSize
  )

  useEffect(() => setFilteredData(pedidosPendentesList), [])

  const handleSelectedBranch = (selectedOption: any) => {
    if (selectedOption?.some((option: any) => option.value === 'todos')) {
      const allBranchIds = branches?.map((branch: any) => ({
        value: branch.branchId,
        label: `${branch.name}-${branch.cnpj}`
      }))

      formik.setFieldValue('unidades', allBranchIds)

      formik.setFieldValue('unidades', [{ value: 'todos', label: 'Todas' }])
    } else {
      formik.setFieldValue('unidades', selectedOption)
      formik.setFieldValue('unidades', selectedOption)
    }
  }

  const formRef = useRef<HTMLFormElement>(null)
  const handleClearForm = () => {
    formik.resetForm({
      values: {} as any
    })
    formik.submitForm()
    formRef.current?.reset()
  }

  const isOnboardingFinishedOrderPending = !!Cookie.get(
    Constants.iS_ONBOARDING_FINISHED_ORDER_PENDING
  )

  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false)

  const handleBeforeExit = () => {
    setIsOnboardingOpen(false)
  }

  const handleClose = () => {
    setIsOnboardingOpen(false)
  }

  useEffect(() => {
    if (!isOnboardingFinishedOrderPending && !isLoadingPedidosPendentes) {
      setIsOnboardingOpen(true)
    }

    return () => {
      setIsOnboardingOpen(false)
    }
  }, [isOnboardingFinishedOrderPending, isLoadingPedidosPendentes])

  return (
    <PedidosPendentesProvider
      value={{
        branches,
        isFetchingPedidosPedentes,
        pedidosPendentesList,
        filteredData,
        setFilteredData,
        isLoadingPedidosPendentes,
        search,
        setSearch,
        setPage,
        totalPages,
        formik,
        pageSize,
        setPageSize,
        handleSelectedBranch,
        formRef,
        handleClearForm,
        handleClose,
        handleBeforeExit,
        isOnboardingFinishedOrderPending,
        isOnboardingOpen
      }}
    >
      {children}
    </PedidosPendentesProvider>
  )
}

export { PedidosPendentesContext, usePedidosPendentesContext }
