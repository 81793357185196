import { Button, Modal } from 'flowbite-react'

interface ReprocessarPedidoModalProps {
  isOpen: boolean | false
  onClose: any
  onConfirm: () => void
}

export default function ReprocessarPedidoModal({
  isOpen,
  onClose,
  onConfirm
}: ReprocessarPedidoModalProps) {
  return (
    <Modal show={isOpen} size={'xl'} onClose={onClose} popup>
      <Modal.Header />
      <Modal.Body>
        <div className='text-center'>
          <h3 className='mb-5 text-base font-normal text-gray-500'>
            O pedido será reprocessado e ficará visível na tela de
            Acompanhamento de Pedidos Pendentes para ser Aprovado.
          </h3>
          <h3 className='mb-5 text-base font-normal text-gray-500'>
            Deseja continuar?
          </h3>
          <div className='flex justify-center gap-4'>
            <Button color='gray' onClick={onClose} data-testid='btn-close'>
              Não
            </Button>
            <Button
              color='success'
              onClick={onConfirm}
              data-testid='btn-confirm'
            >
              Sim
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
