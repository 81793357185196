import { useMemo, useState } from 'react'

import { Button, Modal } from 'flowbite-react'

import Loader from '@/components/Loader'
import PaginationItem from '@/components/Table/pagination-item'
import TableItemPedidos from '@/components/Table/table-item-pedidos.component'
import { useGetPendencias } from '@/hooks/queries/use-get-pendencias'
import { TABLE_HEADERS_PENDENCIAS_PEDIDO } from '@/pages/Pedidos/constants'
import { exportToXls } from '@/utils/export-to-xls'
interface IPendenciasPedidoModalProps {
  onClose: () => void
  isOpen: boolean
  pedidoId: string
  unidadeId?: string
}

const PendenciasPedidoModal: React.FC<IPendenciasPedidoModalProps> = ({
  onClose,
  isOpen,
  pedidoId,
  unidadeId
}: IPendenciasPedidoModalProps) => {
  const [page, setPage] = useState<number>(1)
  const pageSize = 10
  const startIndex = (page - 1) * pageSize
  const { data: pendenciasList, isLoading: isLoadingPendencias } =
    useGetPendencias({
      params: {
        idPedido: pedidoId,
        inicio: startIndex,
        comprimento: pageSize,
        procurar: '',
        campo: 'CriadoEm',
        direcao: 'desc',
        idUnidade: unidadeId || '',
        situacoesItem: ['Removido']
      }
    })

  const mapedValues = useMemo(() => {
    return pendenciasList?.conteudo?.resultados
      ?.filter(item => item?.pendencias?.length > 0)
      ?.flatMap(pendencia =>
        pendencia.pendencias.map(p => ({
          numeroPedidoDeVenda: p.numeroPedidoVenda,
          usuario: p.nome,
          nomeUnidade: p.nomeUnidade,
          cnpjUnidade: p.cnpjUnidade,
          cpf: p.cpf,
          beneficio: p.beneficio,
          descricao: p.pendenciasErros.map(erros => (
            <ul className='list-disc flex text-sm' key={erros.mensagem}>
              <li>{erros.mensagem}</li>
            </ul>
          )),
          descricaoRaw: JSON.stringify(
            p.pendenciasErros.map(item => item.mensagem).join(', ')
          )
        }))
      )
  }, [pendenciasList])

  const dadosArquivoPendencias = () => {
    const exportFile = mapedValues.map(p => ({
      'Número do pedido': p.numeroPedidoDeVenda,
      Usuário: p.usuario,
      'Nome da Unidade': p.nomeUnidade,
      'CNPJ da unidade': p.cnpjUnidade,
      CPF: p.cpf,
      Benefício: p.beneficio,
      Erro: p.descricaoRaw
    }))
    return exportFile
  }

  const totalPages = Math.ceil(
    (pendenciasList?.conteudo?.quantidadeDeItems ?? 0) / pageSize
  )
  return (
    <>
      <Modal
        show={isOpen}
        onClose={onClose}
        className='flex items-center'
        size='5xl'
      >
        <Modal.Header>
          <p className='text-center font-bold p-3'>Lista de Pendências</p>
        </Modal.Header>
        <Modal.Body>
          {isLoadingPendencias ? (
            <div
              className='h-96 w-full flex justify-center items-center'
              data-testid='loader-pendencias'
            >
              <Loader size={'xl'} />
            </div>
          ) : (
            <div data-testid='table-pendencias'>
              <TableItemPedidos
                isActions={false}
                isRowClicable={false}
                data={mapedValues || []}
                headers={TABLE_HEADERS_PENDENCIAS_PEDIDO}
                deleteAction={() => void 0}
                hasCustomActions={false}
                idKey='id'
                isIdle={false}
              />
              <PaginationItem
                totalItems={
                  pendenciasList?.conteudo?.quantidadeDeItems as number
                }
                totalPages={totalPages}
                pageSize={pageSize}
                onPageChange={(pageParam: number) => setPage(pageParam)}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <div className='flex gap-2'>
              <Button
                className='bg-orange-400 text-white'
                onClick={() =>
                  exportToXls(
                    dadosArquivoPendencias() as any,
                    'pendencias_pedido_colaboradores'
                  )
                }
                data-testid='btn-export'
              >
                Exportar
              </Button>
              <Button color='gray' onClick={onClose} data-testid='btn-close'>
                Fechar
              </Button>
            </div>
          </>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PendenciasPedidoModal
