import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { WithAuth } from './with-auth'
import { WithGuest } from './with-guest'
import { accessControl } from '@/configs/accessControl'
import { OnixRouteNames, routeNames } from '@/configs/routePaths'
import { useUserContext } from '@/contexts/user.context'
import ForgotPassword from '@/pages/account/forgot-password'
import Dashboard from '@/pages/Dashboard'
import Encomendas from '@/pages/Encomendas'
import EncomendasEdit from '@/pages/Encomendas/edit'
import EncomendasView from '@/pages/Encomendas/view'
import Login from '@/pages/Login'
import Pedidos from '@/pages/Pedidos'
import AcompanharPedidosView from '@/pages/Pedidos/acompanhar-pedidos'
import ConfirmarPedidoView from '@/pages/Pedidos/confirmar-pedidos'
import CriarPedidoView from '@/pages/Pedidos/criar-pedidos'
import PedidoDetalheView from '@/pages/Pedidos/detalhe'
import DevolucoesView from '@/pages/Pedidos/devolucoes'
import EditarPedidoView from '@/pages/Pedidos/editar-pedidos'
import RevisarPedidoView from '@/pages/Pedidos/revisar-pedidos'
import Platform from '@/pages/Platform'
import SelectAccount from '@/pages/select-account'

export function Router() {
  const location = useLocation()
  const { isAuthenticated, userPermissions, isAllowedV2Pedidos } =
    useUserContext()
  const hasVrIdAccessToken = location.search.includes('accessToken')

  return (
    <Routes>
      <Route
        path={routeNames.homeDashboard}
        element={<Navigate to={routeNames.dashboard} />}
      />
      <Route
        path={routeNames.root}
        element={
          <Navigate
            to={
              isAuthenticated && !hasVrIdAccessToken
                ? routeNames.homeDashboard
                : routeNames.login + location.search
            }
          />
        }
      />
      {/* Guest routes */}
      <Route path={routeNames.root} element={<WithGuest />}>
        <Route path={routeNames.login} element={<Login />} />
        <Route path={routeNames.CheckRecharge} element={<Platform />} />
        <Route path={routeNames.selectAccounts} element={<SelectAccount />} />
        <Route path={routeNames.forgotPassword} element={<Platform />} />
      </Route>
      {/* Auth routes */}
      <Route path={routeNames.root} element={<WithAuth />}>
        <Route
          path={`/${routeNames.encomendas}`}
          key={'Encomendas'}
          element={<Encomendas />}
        >
          <Route
            path={`/${routeNames.encomendas}`}
            element={<EncomendasView />}
          />
          <Route path={routeNames.edit} element={<EncomendasEdit />} />
        </Route>
        {!isAllowedV2Pedidos && (
          <Route path={routeNames.pedidosOld} element={<Platform />} />
        )}

        {/* Pedidos */}
        <Route
          path={`/${routeNames.pedidos}`}
          key={'Pedidos'}
          element={<Pedidos />}
        >
          {isAllowedV2Pedidos ? (
            <>
              <Route
                path={`/${routeNames.pedidos}`}
                element={<AcompanharPedidosView />}
              />
              <Route
                path={`/${routeNames.pedidosAprovados}`}
                element={<AcompanharPedidosView />}
              />
              <Route
                path={`/${routeNames.pedidosPendentes}`}
                element={<AcompanharPedidosView />}
              />
              <Route
                path={`/${routeNames.pedidosView}`}
                element={<PedidoDetalheView />}
              />
              {userPermissions.find(
                permissao => permissao.code === accessControl.criarPedido
              ) !== undefined ? (
                <>
                  <Route
                    path={routeNames.criarPedidos}
                    element={<CriarPedidoView />}
                  />
                  <Route
                    path={routeNames.revisarPedidos}
                    element={<RevisarPedidoView />}
                  />
                  <Route
                    path={routeNames.confirmarPedidos}
                    element={<ConfirmarPedidoView />}
                  />
                  <Route
                    path={routeNames.editarPedidos}
                    element={<EditarPedidoView />}
                  />
                </>
              ) : (
                <>
                  <Route
                    path={routeNames.criarPedidos}
                    element={<AcompanharPedidosView />}
                  />
                  <Route
                    path={routeNames.revisarPedidos}
                    element={<AcompanharPedidosView />}
                  />
                  <Route
                    path={routeNames.confirmarPedidos}
                    element={<AcompanharPedidosView />}
                  />
                  <Route
                    path={routeNames.editarPedidos}
                    element={<AcompanharPedidosView />}
                  />
                </>
              )}
              <Route
                path={routeNames.devolucoes}
                element={<DevolucoesView />}
              />
            </>
          ) : (
            <></>
          )}
        </Route>

        <Route path={``} key={'Conta Corrente'} element={<Pedidos />}>
          <Route path={routeNames.contaCorrente} element={<Platform />} />
        </Route>
        <Route path={``} key={'Crédito Certo'} element={<Pedidos />}>
          <Route path={routeNames.gestaoDeSaldo} element={<Platform />} />
        </Route>

        <Route path={routeNames.dashboard} element={<Dashboard />} />
        <Route path={routeNames.homeDashboard} element={<Platform />} />
        <Route path={routeNames.forgotPassword} element={<ForgotPassword />} />
        <Route path='*' element={<Platform />} />
        {OnixRouteNames.map(route => (
          <Route path={route} element={<Platform />} key={route} />
        ))}
      </Route>
    </Routes>
  )
}
