import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Tabs } from 'flowbite-react'
import { Link } from 'react-router-dom'

import DetailCobrancasPedido from '@/components/pedidos/detalhe-pedido/cobrancas/detalhe-cobrancas-pedido.component'
import DetailDescontosPedido from '@/components/pedidos/detalhe-pedido/descontos/detalhe-descontos-pedido.component'
import DetalhePedidoHeader from '@/components/pedidos/detalhe-pedido/detalhe-pedido-header.component'
import DetailDevolucoesPedido from '@/components/pedidos/detalhe-pedido/devolucoes/detalhe-devolucoes-pedido.component'
import DetailItensPedido from '@/components/pedidos/detalhe-pedido/itens/detalhe-itens-pedido.component'
import DetailRepassesPedido from '@/components/pedidos/detalhe-pedido/repasses/detalhe-repasses-pedido.component'
import DetailTaxasPedido from '@/components/pedidos/detalhe-pedido/taxas/detalhe-taxas-pedido.component'
import { routeNames } from '@/configs/routePaths'
import { TAB_THEME } from '@/pages/Pedidos/detalhe/tab-theme'

const DetalhePedidoContainer = () => {
  return (
    <>
      <div className='w-full flex justify-end items-center'>
        <Link to={`/${routeNames.pedidos}`}>
          <div className='flex gap-2 justify-center items-center cursor-pointer'>
            <ArrowLeftIcon className='w-4 h-4 text-primary-dark' />
            <span className='text-gray-600 text-sm'>Volta para pedidos</span>
          </div>
        </Link>
      </div>
      <DetalhePedidoHeader />
      <div className='w-full'>
        <Tabs
          aria-label='Tabs with underline'
          variant='default'
          theme={TAB_THEME}
        >
          <Tabs.Item active title='Itens'>
            <DetailItensPedido />
          </Tabs.Item>
          <Tabs.Item title='Repasses'>
            <DetailRepassesPedido />
          </Tabs.Item>
          <Tabs.Item title='Taxas'>
            <DetailTaxasPedido />
          </Tabs.Item>
          <Tabs.Item title='Devoluções'>
            <DetailDevolucoesPedido />
          </Tabs.Item>
          <Tabs.Item title='Descontos'>
            <DetailDescontosPedido />
          </Tabs.Item>
          <Tabs.Item title='Cobranças'>
            <DetailCobrancasPedido />
          </Tabs.Item>
        </Tabs>
      </div>
    </>
  )
}

export default DetalhePedidoContainer
