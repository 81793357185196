import { Suspense } from 'react'

import Loader from '@/components/Loader'
import DashboardContainer from '@/containers/dashboard.container'

const Dashboard = () => {
  return (
    <Suspense fallback={<Loader size='lg' />}>
      <DashboardContainer />
    </Suspense>
  )
}

export default Dashboard
