import { useMemo, useState } from 'react'

import Select from 'react-select'

import Loader from '@/components/Loader'
import TableItem from '@/components/Table'
import PaginationItem from '@/components/Table/pagination-item'
import { useEditarPedidosContext } from '@/contexts/editar-pedido.context'
import { IEdicaoPedidoErros } from '@/interfaces/pedidos'
import { TABLE_HEADERS_EDITAR_PEDIDO_ERROS } from '@/pages/Pedidos/constants'

const EditarPedidosErrosGrid = () => {
  const { pedido, isLoadingPedido } = useEditarPedidosContext()

  const [erroSelected, setErroSelected] = useState('')
  const [pageSize, setPageSize] = useState<number>(10)
  const [page, setPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(1)
  const itensInicio = (page - 1) * pageSize
  const itensFim = page * pageSize
  const [totalItens, setTotalItens] = useState<number>(0)

  const mappedErros = useMemo(() => {
    const erros: IEdicaoPedidoErros[] = []
    pedido?.conteudo.resultados[0].itensVenda.forEach(item => {
      item.errosItem.forEach(i => {
        erros.push({
          vendaId: item.idVenda,
          motivo: i.mensagem,
          nome: item.trabalhador.nome,
          matricula: item.trabalhador.matricula,
          cpf: item.trabalhador.cpf
        } as IEdicaoPedidoErros)
      })
    })

    if (erroSelected != undefined && erroSelected != '') {
      setTotalPages(
        Math.ceil(
          (erros.filter(e => e.motivo === erroSelected).length ?? 0) / pageSize
        )
      )
      setTotalItens(erros.filter(e => e.motivo === erroSelected).length ?? 0)
      return erros
        .filter(e => e.motivo === erroSelected)
        .slice(itensInicio, itensFim)
    }

    setTotalPages(Math.ceil((erros.length ?? 0) / pageSize))
    setTotalItens(erros.length ?? 0)
    return erros.slice(itensInicio, itensFim)
  }, [pedido, erroSelected, pageSize, page])

  const errosOptions = mappedErros
    ?.map((erro: IEdicaoPedidoErros) => ({
      value: erro.motivo,
      label: `${erro.motivo}`
    }))
    .filter(
      (option, index, options) =>
        options.findIndex(o => o.value === option.value) === index
    )

  const selectedOption = errosOptions?.find(
    option => option.value === erroSelected
  )

  const pageSizeOptions = [
    {
      label: '10',
      value: 10
    },
    {
      label: '25',
      value: 25
    },
    {
      label: '50',
      value: 50
    },
    {
      label: '100',
      value: 100
    }
  ]

  return (
    <>
      <span className='text-quaternary pb-3 text-lg m-1'>
        Erros encontrados
      </span>
      {isLoadingPedido ? (
        <div
          className='h-96 w-full flex justify-center items-center'
          data-testid='loader-table-erros'
        >
          <Loader size={'xl'} />
        </div>
      ) : (
        <div
          className='flex flex-col md:grid grid-cols-12 w-full p-3'
          data-testid='table-erros'
        >
          <div className='flex flex-col col-span-6'>
            <Select
              placeholder='Selecione um motivo'
              className='text-gray-900 text-sm rounded-lg w-60% p-2'
              isSearchable
              escapeClearsValue
              isClearable={true}
              noOptionsMessage={() => 'Sem resultados'}
              value={selectedOption}
              onChange={selected => setErroSelected(selected?.value ?? '')}
              options={errosOptions}
              data-testid='select-motivo'
            />
          </div>
          <div className='flex flex-col col-span-12 gap-2'>
            <TableItem
              isActions={false}
              isRowClicable={false}
              data={mappedErros || []}
              headers={TABLE_HEADERS_EDITAR_PEDIDO_ERROS}
              deleteAction={() => void 0}
              hasCustomActions={false}
              idKey='vendaId'
              isIdle={false}
            />
            <PaginationItem
              totalItems={totalItens}
              totalPages={totalPages}
              pageSize={pageSize}
              onPageChange={(pageParam: number) => setPage(pageParam)}
            >
              <Select
                placeholder='Selecione uma quantidade'
                isClearable={false}
                noOptionsMessage={() => 'Sem resultados'}
                id='select-page-size'
                data-testid='select-page-size'
                value={{
                  label: pageSize.toString(),
                  value: pageSize
                }}
                onChange={value => setPageSize(Number(value?.value))}
                options={pageSizeOptions}
              ></Select>
            </PaginationItem>
          </div>
        </div>
      )}
    </>
  )
}

export default EditarPedidosErrosGrid
