import { useState } from 'react'

function useSessionStorage<T>(key: string, initialValue: T) {
  // Get the stored value from sessionStorage or use the initial value
  const getStoredValue = () => {
    try {
      const item = window.sessionStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.error(`Error reading sessionStorage key "${key}":`, error)
      return initialValue
    }
  }

  // Initialize state with the stored value
  const [storedValue, setStoredValue] = useState<T>(getStoredValue)

  // Define a setter function that also updates sessionStorage
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.error(`Error setting sessionStorage key "${key}":`, error)
    }
  }

  return [storedValue, setValue] as const
}

export default useSessionStorage
