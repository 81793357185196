import axios, { AxiosError, AxiosResponse } from 'axios'

import { TokenStorage } from './token-storage'
import { Http } from '../interfaces/http'
import toastService from '@/components/toast/toast-service'
import { routeNames } from '@/configs/routePaths'

export const API_URL = process.env.VITE_APP_API_PEDIDOS_URL

export class PedidosFetcher implements Http {
  private readonly handleRequest = async (config: any) => {
    const TOKEN = TokenStorage.get()
    const AUTHORIZATION_CODE = TokenStorage.getAuthorizationCode()

    config.headers.Authorization = `bearer ${TOKEN}`
    config.headers['access_token'] = AUTHORIZATION_CODE

    return config
  }

  private readonly httpAxios = axios.create({
    baseURL: API_URL,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      client_id: process.env.VITE_API_SENSEDIA_CLIENT_ID
    }
  })

  constructor() {
    this.httpAxios.interceptors.request.use(this.handleRequest)
    this.httpAxios.interceptors.response.use(
      this.handleResponse,
      this.handleError
    )
  }

  private readonly handleResponse = (response: AxiosResponse) => {
    if (response.data.message) {
      toastService.success(response.data.message)
    }
    return response
  }

  private readonly handleError = (error: AxiosError) => {
    switch (error.request.status) {
      case 401:
        toastService.error('Sessão expirada. Faça login novamente')
        window.location.href = routeNames.login
        TokenStorage.logout()
        break
      case 500:
        toastService.error('Error interno')
        break
      default:
        break
    }
    return Promise.reject(error)
  }

  async get<T>(
    path: string,
    params?: Record<string, any>,
    config?: any
  ): Promise<T> {
    const { data } = await this.httpAxios
      .get(path, { ...config, params })
      .catch(error => Promise.reject(new Error(error)))
    return data as T
  }

  async post<T>(
    path: string,
    params?: Record<string, any>,
    config?: any
  ): Promise<T> {
    const response = await this.httpAxios
      .post(path, { ...params }, { ...config })
      .catch(error => Promise.reject(new Error(error)))
    return response.data as T
  }

  async put<T>(
    path: string,
    params?: Record<string, any>,
    config?: any
  ): Promise<T> {
    const response = await this.httpAxios
      .put(path, { ...params }, { ...config })
      .catch(error => Promise.reject(new Error(error)))
    return response.data as T
  }

  async patch<T>(
    path: string,
    params?: Record<string, any>,
    config?: any
  ): Promise<T> {
    const response = await this.httpAxios
      .patch(path, { ...params }, { ...config })
      .catch(error => Promise.reject(new Error(error)))
    return response.data as T
  }

  async delete<T>(path: string, params?: any, config?: any): Promise<T> {
    const response = await this.httpAxios
      .delete(path, { ...config, params })
      .catch(error => Promise.reject(new Error(error)))
    return response.data as T
  }
}

export const API_PEDIDOS = new PedidosFetcher()
