import { useMemo } from 'react'

import { CheckIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Button, Drawer } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'

import Loader from '../Loader'
import TableItemPedidos from '../Table/table-item-pedidos.component'
import { useRevisarPedidosContext } from '@/contexts/revisar-pedido.context'
import { TABLE_HEADERS_REVISAO_PEDIDOS_OPERADORA } from '@/pages/Pedidos/constants'

interface DrawerPedidoRevisaoProps {
  isOpen: boolean | false
  onClose: any
}

export default function DrawerPedidoRevisao({
  isOpen,
  onClose
}: DrawerPedidoRevisaoProps) {
  const {
    pedidoOperadorasList,
    isLoadingPedidoOperadoras,
    unidadeId,
    unidade,
    handleOpenRepassesModal
  } = useRevisarPedidosContext()

  const navigate = useNavigate()

  const mapedProperties = useMemo(() => {
    return pedidoOperadorasList?.conteudo?.resultados?.map(item => {
      let itens = 0
      let repasses = 0
      let contemErro = false
      item.itensVenda.forEach(i => {
        itens = itens + i.total
        repasses = repasses + (i.taxaOperadora ?? 0)
        contemErro = contemErro || i.errosItem.length != 0
      })

      return {
        pedidoId: item.idPedidoVenda,
        vendaId: item.id,
        operadora: item.nomeOperadora,
        tipoCartao: item.tipoCartaoOperadora,
        qtdColaboradores: item.quantidadeDeCpfs,
        itens: itens.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        }),
        repasses: (
          <>
            <span className='flex gap-2 justify-between items-center'>
              {repasses.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              })}
              <InformationCircleIcon
                className='h-5 w-5 text-quintenary justify-center items-center'
                onClick={() =>
                  handleOpenRepassesModal(
                    item.idPedidoVenda,
                    'Repasse',
                    item.id
                  )
                }
              />
            </span>
          </>
        ),
        situacao: contemErro ? (
          <XMarkIcon className='h-5 w-5 text-white bg-red-400 rounded-full justify-center items-center' />
        ) : (
          <CheckIcon className='h-5 w-5 text-white bg-quintenary rounded-full justify-center items-center' />
        ),
        acoes: (
          <Button
            color='gray'
            className='flex gap-2 justify-center items-center'
            onClick={() =>
              navigate(
                `/pedidos/${item.idPedidoVenda}/editar-pedidos/${
                  item.vendaOperadora?.idOperadora ?? 'vr-credito'
                }/${unidadeId}`
              )
            }
            data-testid='btn-edit'
          >
            <PencilIcon className='h-5 w-5' />
          </Button>
        )
      }
    })
  }, [pedidoOperadorasList])

  return (
    <div data-testid='drawer-pedidos'>
      <Drawer
        open={isOpen}
        onClose={onClose}
        position='bottom'
        className='max-h-[70%]'
      >
        <Drawer.Header title={unidade} titleIcon={() => <></>} />
        <Drawer.Items>
          {isLoadingPedidoOperadoras ? (
            <div className='h-96 w-full flex justify-center items-center'>
              <Loader size={'xl'} />
            </div>
          ) : (
            <div>
              <TableItemPedidos
                isActions={false}
                isRowClicable={false}
                data={mapedProperties || []}
                headers={TABLE_HEADERS_REVISAO_PEDIDOS_OPERADORA}
                deleteAction={() => void 0}
                hasCustomActions={false}
                idKey='vendaId'
                isIdle={false}
                data-testid='table-pedidos-operadoras'
              />
            </div>
          )}
        </Drawer.Items>
      </Drawer>
    </div>
  )
}
