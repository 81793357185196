import { SetStateAction, useMemo } from 'react'

import { ArrowPathIcon } from '@heroicons/react/24/outline'
import debounce from 'debounce'
import { Select, Tooltip } from 'flowbite-react'
import { useQueryClient } from 'react-query'

import Loader from '@/components/Loader'
import AcompanharPedidosFilterForm from '@/components/pedidos/acompanhar-pedidos/pedidos-filtros.component'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import TableItemPedidos from '@/components/Table/table-item-pedidos.component'
import { QueryKey } from '@/configs/queryKeys'
import { usePedidosContext } from '@/contexts/pedidos.context'
import {
  mapedTipoSituacaoFinanceira,
  mapedTipoSituacaoOperacional,
  TABLE_HEADERS_ACOMPANHAR_PEDIDOS
} from '@/pages/Pedidos/constants'

const AcompanharPedidosContainer = () => {
  const {
    pedidosList,
    isLoadingPedidos,
    isFetchingPedidos,
    pageSize,
    setPageSize,
    totalPages,
    setPage,
    setSearch
  } = usePedidosContext()
  const queryClient = useQueryClient()

  const mapedProperties = useMemo(() => {
    return pedidosList?.conteudo?.resultados?.map(item => {
      return {
        ...item,
        nomeUnidade: (
          <Tooltip content={item.nomeUnidade} placement='top'>
            <p className='truncate max-w-[120px] overflow-hidden'>
              {item.nomeUnidade}
            </p>
          </Tooltip>
        ),
        situacaoFinanceira: (
          <div className='flex gap-2'>
            <span
              className={`px-5 py-1 rounded-2xl text-xs ${
                mapedTipoSituacaoFinanceira[item.situacaoFinanceira]?.color
              } text-white text-center`}
            >
              {mapedTipoSituacaoFinanceira[item.situacaoFinanceira]?.title}
            </span>
          </div>
        ),
        situacaoOperacional: (
          <div className='flex gap-2'>
            <span
              className={`px-5 py-1 rounded-2xl text-xs ${
                mapedTipoSituacaoOperacional[item.situacaoOperacional]?.color
              } text-white text-center`}
            >
              {mapedTipoSituacaoOperacional[item.situacaoOperacional]?.title}
            </span>
          </div>
        ),
        status: (
          <div className='flex gap-2'>
            <span
              className={`px-5 py-1 rounded-2xl text-xs ${
                item.situacao === 'Finalizado'
                  ? 'bg-quaternary'
                  : ' bg-gray-400'
              } text-white`}
            >
              {item.situacao === 'EmProcessamento'
                ? 'Em Processamento'
                : item.situacao}
            </span>
          </div>
        ),
        situacaoFinanceiraRaw: item.situacaoFinanceira,
        criadoEm: new Date(item.criadoEm).toLocaleDateString(),
        criadoEmRaw: item.criadoEm,
        rpsGerada:
          [
            'Cancelado',
            'Pago',
            'Liberado',
            'EmProducao',
            'Finalizado'
          ].includes(item.situacao) ||
          ['Pago', 'Liberado'].includes(item.situacaoFinanceira) ||
          ['EmProducao', 'Finalizado'].includes(item.situacaoOperacional),
        emissao: new Date(item.emissao).toLocaleDateString(),
        entrega: new Date(item.entrega).toLocaleDateString(),
        pagamento: new Date(item.pagamento).toLocaleDateString(),
        total: item.total.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })
      }
    })
  }, [pedidosList])

  const handleSearch = debounce((e: SetStateAction<string>) => {
    setSearch(e)
  }, 500)

  return (
    <div className='relative'>
      <SearchTableItem
        isAddItem={false}
        isSearchInputEnabled={true}
        filtersEnabled
        setFilterText={e => handleSearch(e)}
      >
        <AcompanharPedidosFilterForm />
      </SearchTableItem>
      <div className='absolute right-4 top-4'>
        <Tooltip content='Atualizar lista' placement='left'>
          <ArrowPathIcon
            className={`text-primary-dark w-5 h-6 cursor-pointer ${
              isFetchingPedidos ? 'animate-spin' : ''
            }`}
            onClick={() =>
              queryClient.invalidateQueries(QueryKey.pedidosListItems)
            }
          />
        </Tooltip>
      </div>

      {isLoadingPedidos ? (
        <div className='flex justify-center items-center h-96'>
          <Loader size='lg' />
        </div>
      ) : (
        <>
          <TableItemPedidos
            isActions={false}
            isRowClicable={false}
            data={mapedProperties || []}
            headers={TABLE_HEADERS_ACOMPANHAR_PEDIDOS}
            deleteAction={() => void 0}
            hasCustomActions={true}
            idKey='id'
            isIdle={isFetchingPedidos || isLoadingPedidos}
          />
          <PaginationItem
            totalItems={pedidosList?.conteudo?.totalRegistros as number}
            pageSize={pageSize}
            totalPages={totalPages}
            onPageChange={(pageParam: number) => setPage(pageParam)}
          >
            <Select
              id='select-page-size'
              data-testid='select-page-size'
              onChange={e => setPageSize(Number(e.target.value))}
            >
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </Select>
          </PaginationItem>
        </>
      )}
    </div>
  )
}

export default AcompanharPedidosContainer
