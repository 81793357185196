import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { FormikProps, useFormik } from 'formik'

import toastService from '@/components/toast/toast-service'
import { useGetBranchesData } from '@/hooks/queries/use-get-branch-data'
import {
  useDownloadDevolucoesReport,
  useGetDevolucoesList
} from '@/hooks/queries/use-get-devolucoes'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { IDadosBancarios } from '@/interfaces/beneficiarios'
import { Branch } from '@/interfaces/branches'
import {
  IDevolucao,
  IDevolucoesResultado,
  ILogsDevolucoes
} from '@/interfaces/devolucoes'
import { getDadosBancariosBeneficiario } from '@/services/beneficiarios'
import { downloadComprovante, getLogsDevolucoes } from '@/services/devolucoes'
import { createContext } from '@/utils/create-context'

interface IDevolucoesContextProps {
  devolucoesList: IDevolucoesResultado | undefined
  isLoadingDevolucoes: boolean
  isFetchingDevolucoes: boolean
  setPage: Dispatch<SetStateAction<number>>
  totalPages: number
  totalItens: number
  setSearch: Dispatch<SetStateAction<string>>
  search: string
  formik: FormikProps<IFormProps>
  pageSize: number
  setPageSize: Dispatch<SetStateAction<number>>
  isActionsOpen: boolean
  handleDownloadComprovante: ({
    params
  }: {
    params: {
      idItemVenda: string
      idUnidade: string
    }
  }) => Promise<void>
  setIsActionsOpen: Dispatch<SetStateAction<boolean>>
  downloadURL: { url: string } | undefined
  getFileFromDevolucoes: () => void
  setDevolucoesItemId: Dispatch<SetStateAction<string>>
  isConfirmarPedidosModalOpen: boolean
  setIsConfirmarPedidosModalOpen: Dispatch<SetStateAction<boolean>>
  handleConfirmarPedidosModalOpen: () => void
  isMotivoDevolucaoModalOpen: boolean
  handleMotivoDevolucaoModalOpen: () => void
  setIsMotivoDevolucaoModalOpen: Dispatch<SetStateAction<boolean>>
  isResultadoModalOpen: boolean
  setIsResultadoModalOpen: Dispatch<SetStateAction<boolean>>
  handleConfirmarDevolucoesLista: () => void
  isTratativasOpen: boolean
  setIsTratativasOpen: Dispatch<SetStateAction<boolean>>
  dadosBancarios: IDadosBancarios | undefined
  devolucaoSelecionada: IDevolucao | undefined
  handleAbrirTratativas: (devolucaoEmTratativa: IDevolucao) => void
  setDevolucaoSelecionada: Dispatch<SetStateAction<IDevolucao | undefined>>
  isHistoricoDevolucaoModalOpen: boolean
  setIsHistoricoDevolucaoModalOpen: Dispatch<SetStateAction<boolean>>
  handleAbrirHistorico: (devolucaoEmTratativa: IDevolucao) => void
  logsDevolucao: ILogsDevolucoes[]
  branches: Branch[]
}

interface IFormProps {
  dataInicio: string
  dataFim: string
  situacao: string
  tipoDevolucao: string
  numeroPedido: string
  unidades: {
    value: string
    label: string
  }[]
  cpf: string
  idsUnidades?: string[]
}

const [useDevolucoesContext, PedidosProvider] =
  createContext<IDevolucoesContextProps>()

const DevolucoesContext = ({ children }: JSX.Element | any) => {
  const { data: userData } = useGetUserData()
  const { data: branches } = useGetBranchesData()
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [devolucoesItemId, setDevolucoesItemId] = useState<string>('')
  const [devolucaoSelecionada, setDevolucaoSelecionada] = useState<IDevolucao>()
  const [isTratativasOpen, setIsTratativasOpen] = useState(false)
  const [dadosBancarios, setDadosBancarios] = useState<IDadosBancarios>()
  const [logsDevolucao, setLogsDevolucao] = useState<ILogsDevolucoes[]>()
  const [isActionsOpen, setIsActionsOpen] = useState(false)
  const [isMotivoDevolucaoModalOpen, setIsMotivoDevolucaoModalOpen] =
    useState<boolean>(false)
  const [isHistoricoDevolucaoModalOpen, setIsHistoricoDevolucaoModalOpen] =
    useState<boolean>(false)
  const [isConfirmarPedidosModalOpen, setIsConfirmarPedidosModalOpen] =
    useState<boolean>(false)
  const [isResultadoModalOpen, setIsResultadoModalOpen] =
    useState<boolean>(false)
  const [filterValues, setFilterValues] = useState<IFormProps>({} as IFormProps)

  useEffect(() => {
    page > 1 && setPage(1)
  }, [pageSize])

  const defaultBranchId =
    branches?.filter(
      item => item.branchId === userData?.authenticatedBranchId
    )[0]?.branchId || ''

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dataInicio: new Date(new Date().setMonth(new Date().getMonth() - 1))
        .toISOString()
        .split('T')[0],
      dataFim: new Date().toISOString().split('T')[0],
      unidades:
        branches
          ?.filter(item => item.branchId === userData?.authenticatedBranchId)
          .map(item => ({
            value: item.branchId,
            label: `${item.name}-${item.cnpj}`
          })) || ([] as any),
      idsUnidades: [defaultBranchId]
    } as IFormProps,
    onSubmit: values => {
      setFilterValues({
        idsUnidades: values.unidades?.map(item => item.value),
        cpf: values.cpf,
        numeroPedido: values.numeroPedido,
        tipoDevolucao: values.tipoDevolucao,
        situacao: values.situacao,
        dataInicio: values.dataInicio,
        dataFim: values.dataFim,
        unidades: values.unidades
      })
    }
  })

  const [search, setSearch] = useState('')
  const inicio = (page - 1) * pageSize
  const {
    data: devolucoesList,
    isLoading: isLoadingDevolucoes,
    isFetching: isFetchingDevolucoes
  } = useGetDevolucoesList({
    params: {
      inicio: inicio,
      comprimento: pageSize,
      procurar: search,
      campo: 'DataDevolucao',
      direcao: 'desc',
      idEmpregador: userData?.authenticatedAccountId ?? '',
      idsUnidades: formik.values.idsUnidades,
      ...filterValues,
      dataInicio: formik.values.dataInicio + 'T00:00:00',
      dataFim: formik.values.dataFim + 'T23:59:59'
    }
  })
  const totalPages = Math.ceil(
    (devolucoesList?.conteudo?.quantidadeDeItems ?? 0) / pageSize
  )
  const totalItens = devolucoesList?.conteudo?.resultados?.length ?? 0

  const { data: downloadURL, refetch: getFileFromDevolucoes } =
    useDownloadDevolucoesReport({ id: devolucoesItemId })

  const handleConfirmarPedidosModalOpen = () => {
    setIsConfirmarPedidosModalOpen(true)
  }

  const handleMotivoDevolucaoModalOpen = () => {
    setIsMotivoDevolucaoModalOpen(true)
  }

  const handleConfirmarDevolucoesLista = () => {
    toastService.success('Devoluções confirmadas com sucesso')
    setIsResultadoModalOpen(false)
  }

  const handleAbrirHistorico = (devolucaoEmTratativa: IDevolucao) => {
    setLogsDevolucao([])
    setIsHistoricoDevolucaoModalOpen(true)
    setDevolucaoSelecionada(devolucaoEmTratativa)
    getLogsDevolucoes({
      params: {
        inicio: 0,
        comprimento: 1000,
        campo: 'CriadoEm',
        direcao: 'desc',
        idGeralDevolucao: devolucaoEmTratativa?.idGeralDevolucao ?? ''
      }
    })
      .then(response => {
        setLogsDevolucao(response?.conteudo?.resultados)
      })
      .catch(error => {
        toastService.error(error)
      })
  }

  const handleAbrirTratativas = (devolucaoEmTratativa: IDevolucao) => {
    setIsTratativasOpen(true)
    setDevolucaoSelecionada(devolucaoEmTratativa)
    setDadosBancarios({
      idItemVenda: '',
      idPedidoVenda: '',
      idVenda: '',
      nome: '',
      cpf: '',
      numeroDoBanco: '',
      nomeDoBanco: '',
      tipoDeContaBancaria: '',
      agenciaBancaria: '',
      digitoDaAgenciaBancaria: '',
      contaBancaria: '',
      digitoDaContaBancaria: ''
    })

    getDadosBancariosBeneficiario({
      params: {
        idPedido: devolucaoEmTratativa?.idPedido ?? '',
        idVenda: devolucaoEmTratativa?.idVenda ?? '',
        idItemVenda:
          devolucaoEmTratativa?.idGeralDevolucao ??
          devolucaoEmTratativa?.id ??
          ''
      }
    })
      .then(response => {
        setDadosBancarios(response?.conteudo)
      })
      .catch(error => {
        toastService.error(error)
      })
  }

  const handleDownloadComprovante = async ({
    params
  }: {
    params: {
      idItemVenda: string
      idUnidade: string
    }
  }) => {
    downloadComprovante({
      params: {
        ...params,
        idUsuarioSolicitante: userData.userId ?? ''
      }
    })
      .then(() => {
        toastService.success(
          'Download solicitado! O comprovante estará disponível em relatórios.'
        )
      })
      .catch(() => {
        toastService.error('Erro ao solicitar download do comprovante')
      })
  }

  return (
    <PedidosProvider
      value={{
        handleDownloadComprovante,
        handleConfirmarDevolucoesLista,
        isResultadoModalOpen,
        setIsResultadoModalOpen,
        devolucoesList,
        isLoadingDevolucoes,
        isFetchingDevolucoes,
        search,
        setSearch,
        setPage,
        totalPages,
        totalItens,
        formik,
        pageSize,
        setPageSize,
        isActionsOpen,
        setIsActionsOpen,
        downloadURL,
        setDevolucoesItemId,
        getFileFromDevolucoes,
        isConfirmarPedidosModalOpen,
        setIsConfirmarPedidosModalOpen,
        handleConfirmarPedidosModalOpen,
        handleMotivoDevolucaoModalOpen,
        setIsMotivoDevolucaoModalOpen,
        isMotivoDevolucaoModalOpen,
        isTratativasOpen,
        setIsTratativasOpen,
        dadosBancarios,
        devolucaoSelecionada,
        handleAbrirTratativas,
        setDevolucaoSelecionada,
        setIsHistoricoDevolucaoModalOpen,
        isHistoricoDevolucaoModalOpen,
        handleAbrirHistorico,
        logsDevolucao,
        branches
      }}
    >
      {children}
    </PedidosProvider>
  )
}

export { DevolucoesContext, useDevolucoesContext }
