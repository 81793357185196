import Cookie from 'js-cookie'

import Constants from '../configs/constants'
import { IUser } from '../interfaces/user'

export class TokenStorage {
  static storeToken(token: string) {
    Cookie.set(Constants.TOKEN_STORAGE_KEY, token, {
      secure: true,
      sameSite: 'Strict'
    })
  }
  static storeBffPedidosToken(token: string) {
    Cookie.set(Constants.TOKEN_STORAGE_PEDIDOS_KEY, token, {
      secure: true,
      sameSite: 'Strict'
    })
  }
  static storeUserData(userData?: IUser) {
    Cookie.set(Constants.USER_STORAGE_KEY, JSON.stringify(userData))
  }

  static getUserData(): string | undefined {
    return Cookie.get(Constants.USER_STORAGE_KEY)
  }

  static get(): string | undefined {
    return Cookie.get(Constants.TOKEN_STORAGE_KEY)
  }

  static setAuthorizationCode(token: string, expires: number) {
    Cookie.set(Constants.AUTHORIZATION_CODE, token, {
      secure: true,
      sameSite: 'Strict',
      expires: expires
    })
  }

  static getAuthorizationCode(): string | undefined {
    return Cookie.get(Constants.AUTHORIZATION_CODE)
  }

  static logout() {
    Cookie.remove(Constants.TOKEN_STORAGE_KEY)
    Cookie.remove(Constants.USER_STORAGE_KEY)
    Cookie.remove(Constants.ONIX_SESSION_ID_KEY)
    Cookie.remove(Constants.ONIX_STORAGE_KEY)
    Cookie.remove(Constants.USER_ONBOARDING)
    Cookie.remove(Constants.AUTHORIZATION_CODE)
    const blip = document.getElementById(Constants.BLIP_REF)
    if (blip != null) {
      blip.remove()
    }
  }
}
