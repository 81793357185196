import ConfirmarPedidosContainer from '@/containers/pedidos/confirmar-pedidos.container'
import { ConfirmarPedidosProvider } from '@/contexts/confirmar-pedido.context'

const ConfirmarPedidoView = () => {
  return (
    <div className='w-full bg-white rounded-md p-5 md:-mt-8'>
      <ConfirmarPedidosProvider>
        <ConfirmarPedidosContainer />
      </ConfirmarPedidosProvider>
    </div>
  )
}

export default ConfirmarPedidoView
