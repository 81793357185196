import { Spinner } from 'flowbite-react'

const Loader = ({ size }: any) => {
  return (
    <div
      className='h-full w-full flex justify-center items-center'
      data-testid='content-loader'
    >
      <div className='flex justify-center items-center flex-col'>
        <img src='/images/logo_green.png' alt='Logo' width={200} height={25} />
        <Spinner size={size} color={'success'} />
      </div>
    </div>
  )
}

export default Loader
