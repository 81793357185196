const TooltipItem = ({
  title,
  content
}: {
  title: string
  content: string[]
}) => {
  return (
    <div className='w-64 text-sm text-gray-500 bg-white shadow-lg'>
      <div className='border-b border-gray-200 bg-primary-dark px-3 py-2 dark:border-gray-600 dark:bg-gray-700'>
        <h3 className='font-semibold text-white dark:text-white'>{title}</h3>
      </div>
      <div className='px-3 py-2'>
        <ul className='text-xs pb-1 list-disc ml-3'>
          {content?.map(item => (
            <li key={item} className='whitespace-break-spaces'>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default TooltipItem
