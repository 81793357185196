import { SetStateAction, useMemo, useState } from 'react'

import debounce from 'debounce'
import { useParams } from 'react-router-dom'

import Loader from '@/components/Loader'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import TableItemPedidos from '@/components/Table/table-item-pedidos.component'
import { useGetDevolucoesItemPedidoList } from '@/hooks/queries/use-get-devolucoes'
import { TABLE_HEADERS_DEVOLUCOES } from '@/pages/Pedidos/constants'

const DetailDevolucoesPedido = () => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const pageSize = 10
  const startIndex = (page - 1) * pageSize
  const { id } = useParams()
  const {
    data: devolucoesItemList,
    isLoading,
    isIdle
  } = useGetDevolucoesItemPedidoList({
    params: {
      inicio: startIndex,
      comprimento: pageSize,
      procurar: search,
      campo: 'Identificador',
      direcao: 'desc',
      idPedidoVenda: id || ''
    }
  })
  const totalPages = Math.ceil(
    (devolucoesItemList?.conteudo?.quantidadeDeItems ?? 0) / pageSize
  )

  const mapedValues = useMemo(() => {
    return devolucoesItemList?.conteudo?.resultados?.map(item => {
      return {
        ...item,
        status: (
          <div className='flex gap-2'>
            <span className='px-5 py-1 rounded-2xl text-xs bg-gray-400 text-white text-center'>
              {item.status}
            </span>
          </div>
        ),
        total: item.total.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL'
        }),
        dataDevolucao: item.dataDevolucao
          ? new Date(item.dataDevolucao).toLocaleDateString()
          : '-'
      }
    })
  }, [devolucoesItemList])

  const handleSearch = debounce((e: SetStateAction<string>) => {
    setSearch(e)
  }, 500)

  return (
    <>
      <div className='overflow-x-auto'>
        {isLoading ? (
          <div className='h-[400px] w-full'>
            <Loader size='lg' />
          </div>
        ) : (
          <>
            <SearchTableItem
              isAddItem={false}
              isSearchInputEnabled={true}
              setFilterText={e => handleSearch(e)}
            />
            <TableItemPedidos
              isActions={false}
              isRowClicable={false}
              data={mapedValues || []}
              headers={TABLE_HEADERS_DEVOLUCOES}
              deleteAction={() => void 0}
              hasCustomActions={false}
              idKey='itemId'
              isIdle={isIdle}
            />
            <PaginationItem
              totalItems={
                devolucoesItemList?.conteudo?.resultados?.length as number
              }
              totalPages={totalPages}
              pageSize={pageSize}
              onPageChange={(pageParam: number) => setPage(pageParam)}
            />
          </>
        )}
      </div>
    </>
  )
}

export default DetailDevolucoesPedido
