import { exportToExcel } from 'react-json-to-excel'

export function exportToXls<T extends object>(
  data: T[],
  fileName: string
): void {
  if (data.length === 0) return

  exportToExcel(data, fileName, false)
}
