import RevisarPedidosContainer from '@/containers/pedidos/revisar-pedido.container'
import { RevisarPedidosProvider } from '@/contexts/revisar-pedido.context'

const RevisarPedidoView = () => {
  return (
    <div className='w-full bg-white rounded-md p-5 md:-mt-8'>
      <h1 className='text-2xl pb-2'>Revise seu pedido</h1>
      <RevisarPedidosProvider>
        <RevisarPedidosContainer />
      </RevisarPedidosProvider>
    </div>
  )
}

export default RevisarPedidoView
