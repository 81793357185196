import { useMemo } from 'react'

import { Button, Label } from 'flowbite-react'
import SelectMulti from 'react-select'
import Select from 'react-tailwindcss-select'

import CustomTextInput from '@/components/basic/input-text/input-text.component'
import { usePedidosPendentesContext } from '@/contexts/pedidos-pendentes.context'

const PedidosPendentesFilterForm = () => {
  const {
    formik,
    isLoadingPedidosPendentes,
    branches,
    handleSelectedBranch,
    handleClearForm,
    formRef
  } = usePedidosPendentesContext()

  const currentDate = new Date()
  const maxDate = new Date()
  maxDate.setDate(currentDate.getDate() + 60)

  const mapedData = useMemo(() => {
    const allBranchesOption = {
      value: 'todos',
      label: 'Todas'
    }

    const mappedBranches = Array.isArray(branches)
      ? branches.map(branch => ({
          value: branch.branchId,
          label: `${branch.name}-${branch.cnpj}`
        }))
      : []

    return [allBranchesOption, ...mappedBranches]
  }, [branches])

  return (
    <form
      ref={formRef}
      onSubmit={formik.handleSubmit}
      className='flex flex-col md:grid grid-cols-12 w-full p-3 bg-white gap-5 mb-5 rounded-lg'
    >
      <div className='col-span-4'>
        <CustomTextInput
          type='text'
          field='numeroPedido'
          formik={formik}
          label='Nº Pedido'
        />
      </div>
      <div className='col-span-4'>
        <CustomTextInput
          type='date'
          field='dataInicio'
          formik={formik}
          defaultValue={formik.values.dataInicio}
          max={new Date().toISOString().split('T')[0]}
          label='Data Inicial Pedido de Venda:'
        />
      </div>
      <div className='col-span-4'>
        <CustomTextInput
          type='date'
          field='dataFim'
          defaultValue={formik.values.dataFim}
          formik={formik}
          // max={maxDateString}
          min={formik.values.dataInicio}
          label='Data Final Pedido de Venda:'
        />
      </div>
      <div className='col-span-6'>
        <Label htmlFor='selectedLocaisDeEntrega' className='font-semibold mb-2'>
          Situação
        </Label>
        <Select
          primaryColor='#2563EB'
          placeholder='Selecione...'
          isMultiple
          noOptionsMessage='Nada encontrado'
          data-testid='situacao-select'
          value={formik.values.situacoesPedidoVenda}
          onChange={value =>
            formik.setFieldValue('situacoesPedidoVenda', value)
          }
          options={[
            {
              label: 'Pendente',
              value: 'Pendente'
            },
            {
              label: 'Em Processamento',
              value: 'EmProcessamento'
            },
            {
              label: 'Processado',
              value: 'Processado'
            },
            {
              label: 'Erro',
              value: 'Erro'
            }
          ]}
        />
      </div>
      <div className='col-span-6'>
        <Label htmlFor='branches'>Unidade</Label>
        <SelectMulti
          id='idUnidade'
          isMulti
          onChange={handleSelectedBranch}
          value={formik.values.unidades || []}
          options={mapedData}
          className='text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500  w-full'
          isSearchable
          escapeClearsValue
          isClearable={false}
          noOptionsMessage={() => 'Sem resultados'}
          placeholder='Selecione...'
          styles={{
            menu: base => ({
              ...base,
              width: '100%'
            })
          }}
        />
      </div>

      <div className='col-span-12 flex justify-end items-end'>
        <div className='flex'>
          <Button
            color='ghost'
            size={'sm'}
            className='text-xs'
            data-testid='clear-button'
            onClick={handleClearForm}
          >
            Limpar
          </Button>
          <Button
            className='bg-quaternary ml-2'
            type='submit'
            size={'sm'}
            data-testid='submit-button'
            disabled={isLoadingPedidosPendentes}
            isProcessing={isLoadingPedidosPendentes}
          >
            Filtrar
          </Button>
        </div>
      </div>
    </form>
  )
}
export default PedidosPendentesFilterForm
