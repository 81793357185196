import { Modal } from 'flowbite-react'

import Constants from '@/configs/constants'

interface ContactModalProps {
  onClose: () => void
  isOpen: boolean
}

const ContactModal: React.FC<ContactModalProps> = ({
  onClose,
  isOpen
}: ContactModalProps) => {
  return (
    <>
      <Modal
        popup
        show={isOpen}
        onClose={onClose}
        className='flex items-center'
      >
        <Modal.Header>
          <p className='text-center font-bold px-3'>Contatos</p>
        </Modal.Header>
        <Modal.Body className='flex items-center justify-center'>
          <img
            src='/images/head_phone_green.svg'
            alt='Logo'
            width={145}
            height={45}
            title='Fale conosco'
          />
          <div className='p-3 ml-8 flex flex-col gap-1'>
            <p className='font-semibold'>Central de Relacionamento VR</p>
            <p>
              <span className='text-quaternary'>
                <a href={`tel:${Constants.CONTACT_TELVR}`}>
                  {Constants.CONTACT_TELVR}{' '}
                </a>
              </span>
              - Capital e regiões metropolitanas{' '}
            </p>
            <p>
              <span className='text-quaternary'>
                <a href={`tel:${Constants.CONTACT_OTERS}`}>
                  {Constants.CONTACT_OTERS}{' '}
                </a>
              </span>
              - Demais localidades{' '}
            </p>
            <p className='pt-1 mb-0'>
              Para acessar o Atendimento Online, clique no botão de ajuda,
              localizado no canto inferior direito da tela.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ContactModal
