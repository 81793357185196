import {
  Dispatch,
  SetStateAction,
  useState,
  createContext,
  useContext,
  ReactNode,
  useMemo
} from 'react'

import { useParams, useSearchParams } from 'react-router-dom'

import {
  useGetPedidoDetalhe,
  useGetPedidoItemListDetail,
  useGetRevisaoPedidos
} from '@/hooks/queries/use-get-pedidos'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'
import { IDetalhePedido } from '@/interfaces/detalhe-pedido'
import { IPedidoDetalhe, IRevisaoPedido } from '@/interfaces/pedidos'

interface IDetalhePedidoProps {
  isEnabled: boolean
  isLoadingPedidoListItensDetail: boolean
  setIsEnabled: Dispatch<SetStateAction<boolean>>
  pedido: IPedidoDetalhe | undefined
  pedidoData: IRevisaoPedido[] | undefined
  isLoadingPedidoData: boolean
  pedidosListitensDetail: IDetalhePedido | undefined
  pedidoHeaders: {
    title: string
    value: string | undefined
  }[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  detailPage: number
  setDetailPage: Dispatch<SetStateAction<number>>
  selectedItemId: string | undefined
  setSelectedItemId: Dispatch<SetStateAction<string | undefined>>
}

const DetalhePedidoContext = createContext<IDetalhePedidoProps | undefined>(
  undefined
)

const DetalhePedidoProvider = ({ children }: { children: ReactNode }) => {
  const { id } = useParams()
  const { data: userData } = useGetUserData()
  const [searchParams] = useSearchParams()
  const [page, setPage] = useState<number>(1)
  const [detailPage, setDetailPage] = useState<number>(1)
  const [isEnabled, setIsEnabled] = useState<boolean>(false)
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>(
    undefined
  )
  const dataCriacao = searchParams.get('data')
  const { data: pedido } = useGetPedidoDetalhe({
    params: {
      pedidoId: String(id)
    }
  })

  const { data: pedidoData, isLoading: isLoadingPedidoData } =
    useGetRevisaoPedidos({
      params: {
        idEmpregador: userData?.authenticatedAccountId ?? '',
        idPedidos: [id ?? ''],
        dataInicio: dataCriacao ?? '',
        dataFim: dataCriacao ?? '',
        inicio: 0,
        comprimento: 1,
        campo: 'CriadoEm',
        direcao: 'desc'
      }
    })

  const {
    data: pedidosListitensDetail,
    isLoading: isLoadingPedidoListItensDetail
  } = useGetPedidoItemListDetail({
    pedidoId: String(id)
  })

  const pedidoHeaders = useMemo(() => {
    return Object.keys(pedido || {}).map(key => {
      return {
        title: key,
        value: pedido ? pedido[key as keyof IPedidoDetalhe] : undefined
      }
    })
  }, [pedido])

  return (
    <DetalhePedidoContext.Provider
      value={{
        isLoadingPedidoData,
        isLoadingPedidoListItensDetail,
        isEnabled,
        setIsEnabled,
        pedido,
        pedidoHeaders,
        pedidoData,
        page,
        setPage,
        selectedItemId,
        setSelectedItemId,
        pedidosListitensDetail,
        detailPage,
        setDetailPage
      }}
    >
      {children}
    </DetalhePedidoContext.Provider>
  )
}

const useDetalhePedidoContext = () => {
  const context = useContext(DetalhePedidoContext)
  if (context === undefined) {
    throw new Error(
      'useDetalhePedidoContext must be used within a DetalhePedidoProvider'
    )
  }
  return context
}

export { DetalhePedidoProvider, useDetalhePedidoContext }
