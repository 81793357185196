const AcoesDevolucoesSucesso = () => {
  const resumoList = [
    {
      title: 'Total de Empresas',
      value: '2'
    },
    {
      title: 'Total de usuários',
      value: '12'
    },
    {
      title: 'Total de devoluções com erro',
      value: '10'
    }
  ]

  const devolucoesList = [
    {
      title: 'Depósito Bancário',
      value: '8'
    },
    {
      title: 'Reprocessar',
      value: '1'
    },
    {
      title: 'Descontar na próxima fatura',
      value: '4'
    }
  ]
  return (
    <>
      <div className='w-full p-3'>
        <h3 className='font-semibold text-primary-dark'>Resumo</h3>
        <span className='text-sm text-gray-700 '>
          Verifique os dados abaixo antes de prosseguir com a importação das
          devoluções
        </span>
        <div className='w-full grid grid-cols-12 gap-3 mt-5'>
          {resumoList.map((item, index) => (
            <div
              key={index}
              className='border rounded-lg p-3 flex justify-between flex-col w-full col-span-4 h-full'
            >
              <span className='text-gray-600 text-sm'>{item.title}</span>
              <span className='text-4xl  font-semibold text-primary-dark'>
                {item.value}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className='w-full p-3'>
        <h3 className='font-semibold text-primary-dark'>
          Total de itens selecionados por tipo de devolução:
        </h3>
        <div className='w-full grid grid-cols-12 gap-3 mt-5'>
          {devolucoesList.map((item, index) => (
            <div
              key={index}
              className='border rounded-lg p-3 flex justify-between flex-col w-full col-span-4 h-full'
            >
              <span className='text-gray-600 text-sm'>{item.title}</span>
              <span className='text-4xl  font-semibold text-primary-dark'>
                {item.value}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default AcoesDevolucoesSucesso
