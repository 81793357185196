import { QueryFunctionContext, UseInfiniteQueryResult } from 'react-query'

import { endPoints } from '@/configs/endPoints'
import {
  IBeneficiario,
  IBeneficiarios,
  IBeneficiarioItems,
  IBeneficionarioList,
  IDadosBancariosResultado
} from '@/interfaces/beneficiarios'
import { IPagination } from '@/interfaces/pagination'
import { API } from '@/utils/fetcher'
import { API_PEDIDOS } from '@/utils/pedidos-fetcher'

export async function createBeneficiarios(payload: IBeneficiarios) {
  const { name } = payload
  const response = await API.post<unknown>(endPoints.beneficiarios, {
    name
  })

  return response
}

export async function getBeneficiarios(
  ctx: QueryFunctionContext<[string, { page: number; pageSize: number }]>
): Promise<IBeneficiarioItems> {
  const [, { page, pageSize }] = ctx.queryKey
  const data = await API.get<IBeneficiarioItems>(`${endPoints.beneficiarios}`, {
    page,
    pageSize
  })

  return data
}

export async function getBeneficiariosList(
  ctx: QueryFunctionContext<[string, { page: number; pageSize: number }]>
): Promise<IBeneficiarioItems> {
  const [, { page, pageSize }] = ctx.queryKey
  const data = await API.get<IBeneficiarioItems>(
    `${endPoints.beneficiariosList}`,
    {
      page,
      pageSize
    }
  )

  return data
}

export async function getBeneficiariosInfinite(
  ctx: QueryFunctionContext<[string, { dataQuery: any }]>
): Promise<UseInfiniteQueryResult<IPagination<IBeneficiario[]>>> {
  const { pageParam } = ctx
  const [, { dataQuery }] = ctx.queryKey
  const data = await API.get<IBeneficiario>(
    `${endPoints.beneficiarios}/?page=${pageParam || 1}`,
    dataQuery
  )

  return data
}

export async function getBeneficiario(
  ctx: QueryFunctionContext<[string, { beneficiarioId: string }]>
): Promise<IBeneficiario[]> {
  const [, { beneficiarioId }] = ctx.queryKey
  const data = await API.get<IBeneficiario[]>(
    `${endPoints.beneficiarios}/${beneficiarioId}`
  )

  return data
}

export async function editBeneficiarios(payload: IBeneficiarios) {
  const { name, beneficiarioId } = payload
  const response = await API.put<unknown>(
    `${endPoints.beneficiarios}/${beneficiarioId}`,
    {
      name
    }
  )
  return response
}

export async function deleteBeneficiarios(beneficiarioId: string) {
  const response = await API.delete(
    `${endPoints.beneficiarios}/${beneficiarioId}`
  )
  return response
}

export async function getBenficiarioPorMatriculaCpf({
  params
}: {
  params: {
    IdsUnidades: string[]
    TipoDado: 'Cpf' | 'Identificador' | string
    Dado: string
  }
}): Promise<IBeneficionarioList> {
  const response = await API_PEDIDOS.post<IBeneficionarioList>(
    endPoints.trabalhadores,
    {
      IdsUnidades: params.IdsUnidades,
      TipoDado: params.TipoDado,
      Dado: params.Dado
    }
  )
  return response
}

export async function getDadosBancariosBeneficiario({
  params
}: {
  params: {
    idPedido: string
    idVenda: string
    idItemVenda: string
  }
}): Promise<IDadosBancariosResultado> {
  const endpointObterItem = endPoints.dadosBancariosBeneficiario
    .replace('{id-pedido-venda}', params.idPedido)
    .replace('{id-venda}', params.idVenda)
    .replace('{id-item-venda}', params.idItemVenda)

  const response = await API_PEDIDOS.get<IDadosBancariosResultado>(
    endpointObterItem
  )

  return response
}
