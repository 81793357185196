import { useMemo, useRef, useState } from 'react'

import { Button, Popover } from 'flowbite-react'
import { useFormik } from 'formik'

import { TABBLE_HEADERS } from '../constants'
import CustomTextInput from '@/components/basic/input-text/input-text.component'
import CustomSelect from '@/components/basic/select/select.component'
import TooltipItem from '@/components/Encomendas/tooltip-item.component'
import Loader from '@/components/Loader'
import TableItem from '@/components/Table'
import LogisticAlerts from '@/components/Table/Logistica/alerts.component'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import { useGetBranchesData } from '@/hooks/queries/use-get-branch-data'
import {
  useGetLogistics,
  useGetStatusList
} from '@/hooks/queries/use-get-logistics'
import { ItemLogistic } from '@/interfaces/logistics'

interface IFormProps {
  StartSalesOrderCreatedOn: string
  EndSalesOrderCreatedOn: string
  DeliveryStatus: string
  orderNumber: string
  BranchCnpj: string
}

const EncomendasView = () => {
  const formRef = useRef<HTMLFormElement>(null)
  const [page, setPage] = useState(1)
  const [filterValues, setFilterValues] = useState<IFormProps>({} as IFormProps)
  const { data: statusList } = useGetStatusList()
  const { data: branches } = useGetBranchesData()

  const isCollectStatus = (status: string) => {
    return [
      'ColetaSolicitada',
      'ColetaGerada',
      'ColetaProcessada',
      'Solicitado',
      'Criado'
    ].includes(status)
  }

  const mapedData = useMemo(() => {
    return branches?.map((item: any) => ({
      value: item.cnpj,
      label: `${item.cnpj}`
    }))
  }, [branches])

  const statusListOptions = useMemo(() => {
    return statusList?.map(item => {
      return {
        label: item.description,
        value: String(item.value)
      }
    })
  }, [statusList])

  const pageSize = 20

  const formik = useFormik({
    initialValues: {} as IFormProps,
    onSubmit: values => {
      const DTO = {
        ...values,
        StartSalesOrderCreatedOn:
          values.StartSalesOrderCreatedOn == undefined
            ? values.StartSalesOrderCreatedOn
            : values.StartSalesOrderCreatedOn + 'T00:00:00',
        EndSalesOrderCreatedOn:
          values.EndSalesOrderCreatedOn === undefined
            ? values.EndSalesOrderCreatedOn
            : values.EndSalesOrderCreatedOn + 'T23:59:59'
      }
      setFilterValues(DTO)
    }
  })

  const { data: logisticList, isLoading: isLoadingLogistic } = useGetLogistics({
    params: {
      page,
      ItemsPerPage: pageSize,
      ...filterValues
    }
  })

  const mapedStatus = (statusItem: string) => {
    function filterContent(arrayList: string[]) {
      return arrayList?.filter(item => statusItem === item)[0]
    }
    switch (statusItem) {
      case filterContent([
        'ColetaSolicitada',
        'ColetaGerada',
        'ColetaProcessada'
      ]):
        return 'bg-orange-400 text-white'
      case filterContent([
        'Em Trânsito',
        'Produto Coletado',
        'Preparando Envio',
        'Saiu para Entrega',
        'Falha na tentativa de Entrega'
      ]):
        return 'bg-yellow-300 text-black'
      case filterContent(['Falha na Entrega']):
        return 'bg-red-600 text-white'
      case filterContent(['Entregue']):
        return 'bg-quaternary text-white'
      default:
        return 'bg-orange-400 text-white'
    }
  }

  const mapedProperties = useMemo(() => {
    return logisticList?.items.map((item: ItemLogistic) => {
      return {
        ...item,
        address: item.cityState + item.street,
        isRowClicable: ![
          'ColetaProcessada',
          'ColetaGerada',
          'Solicitado'
        ].includes(item.deliveryStatus),
        qt_unidades: (
          <Popover
            trigger='hover'
            content={
              <TooltipItem title='Unidades' content={item.branchNames} />
            }
            placement='top'
          >
            <span className='px-5 py-1 rounded-2xl text-xs bg-green-100 text-primary-hover text-center'>
              {item.branchNames.length}
            </span>
          </Popover>
        ),
        qt_pedidos: (
          <Popover
            trigger='hover'
            content={<TooltipItem title='Pedidos' content={item?.orders} />}
            placement='top'
          >
            <span className='px-5 py-1 rounded-2xl text-xs bg-quaternary text-white text-center'>
              {item.orderQuantity}
            </span>
          </Popover>
        ),
        situacao: (
          <span
            className={`px-5 py-1 rounded-2xl text-xs ${mapedStatus(
              item.deliveryStatus
            )} text-center`}
          >
            {isCollectStatus(item.deliveryStatus)
              ? 'Aguardando Coleta'
              : item.deliveryStatus}
          </span>
        )
      }
    })
  }, [logisticList])

  const handleClearForm = () => {
    formik.resetForm({
      values: {} as IFormProps
    })
    formik.submitForm()
    formRef.current?.reset()
  }

  return (
    <div className='col-span-12'>
      <LogisticAlerts />
      <SearchTableItem
        isAddItem={false}
        isSearchInputEnabled={false}
        filtersEnabled
      >
        <form
          ref={formRef}
          onSubmit={formik.handleSubmit}
          className='flex flex-col md:grid grid-cols-12 w-full p-3 bg-white gap-5 mb-5 rounded-lg'
        >
          <div className='col-span-4'>
            <CustomTextInput
              type='text'
              field='OrderNumber'
              formik={formik}
              label='Nº Pedido'
            />
          </div>
          <div className='col-span-4'>
            <CustomTextInput
              type='date'
              field='StartSalesOrderCreatedOn'
              formik={formik}
              label='Data inicial pedido de compra'
            />
          </div>
          <div className='col-span-4'>
            <CustomTextInput
              type='date'
              field='EndSalesOrderCreatedOn'
              formik={formik}
              label='Data final pedido de compra'
            />
          </div>
          <div className='col-span-4'>
            <CustomSelect
              options={statusListOptions ?? []}
              field='DeliveryStatus'
              formik={formik}
              label='Situação'
            />
          </div>
          <div className='col-span-4'>
            <CustomSelect
              options={mapedData ?? []}
              field='BranchCnpj'
              formik={formik}
              label='CNPJ Unidade'
            />
          </div>
          <div className='col-span-12 flex justify-end items-end'>
            <div className='flex'>
              <Button
                color='ghost'
                size={'sm'}
                className='text-xs'
                onClick={handleClearForm}
              >
                Limpar
              </Button>
              <Button
                className='bg-quaternary ml-2'
                type='submit'
                size={'sm'}
                disabled={isLoadingLogistic}
                isProcessing={isLoadingLogistic}
              >
                Filtrar
              </Button>
            </div>
          </div>
        </form>
      </SearchTableItem>
      {isLoadingLogistic ? (
        <div className='h-96 w-full flex justify-center items-center'>
          <Loader size={'xl'} />
        </div>
      ) : (
        <>
          <TableItem
            isActions={false}
            isRowClicable={true}
            data={mapedProperties || []}
            headers={TABBLE_HEADERS}
            deleteAction={() => void 0}
            idKey='groupNumber'
            isIdle={false}
          />
          <PaginationItem
            totalItems={logisticList?.totalItems as number}
            pageSize={pageSize}
            totalPages={logisticList?.totalPages as number}
            onPageChange={(pageParam: number) => setPage(pageParam)}
          />
        </>
      )}
      <div className='mb-10'></div>
    </div>
  )
}

export default EncomendasView
