import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import toastService from '../../components/toast/toast-service'
import { QueryKey } from '@/configs/queryKeys'
import { routeNames } from '@/configs/routePaths'
import {
  cancelarPedido,
  createPedido,
  economiaAceite,
  editarItensPedido,
  editarPedido,
  processarPedidoVenda,
  reprocessarPedido,
  revisarPedido
} from '@/services/pedidos'

export function useCreatePedidoMutation() {
  const queryClient = useQueryClient()
  return useMutation(createPedido, {
    onSuccess: async () => {
      queryClient.invalidateQueries(QueryKey.pedidosPendentesList)
      queryClient.invalidateQueries(QueryKey.pedidosListItems)
    },
    onError: async (error: any) => {
      const errosList = (error?.response?.data?.erros as []) || []
      errosList.forEach((erro: any) => {
        toastService.error(erro)
      })
    }
  })
}

export function useRevisarPedidoMutation(criadoEm: string) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(revisarPedido, {
    onSuccess: async () => {
      toastService.success('Pedido aprovado!')
      queryClient.invalidateQueries(QueryKey.pedidosListItems)
      queryClient.invalidateQueries(QueryKey.vendasList)
      navigate(
        `/${routeNames.pedidos}/${routeNames.confirmarPedidos}?data=${criadoEm}`
      )
    },
    onError: async (error: any) => {
      const errosList = (error?.response?.data?.erros as []) || []
      errosList.forEach((erro: any) => {
        toastService.error(erro)
      })
    }
  })
}

export function useReprocessarPedidoMutation() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation(reprocessarPedido, {
    onSuccess: async () => {
      toastService.success('Pedido foi enviado para processamento!')
      queryClient.invalidateQueries(QueryKey.pedidosPendentesList)
      queryClient.invalidateQueries(QueryKey.pedidosListItems)
      navigate(`/${routeNames.pedidosPendentes}`)
    },
    onError: async (error: any) => {
      const errosList = (error?.response?.data?.erros as []) || []
      errosList.forEach((erro: any) => {
        toastService.error(erro)
      })
    }
  })
}

export function useProcessarPedidovendaMutation() {
  return useMutation(processarPedidoVenda, {
    onError: async (error: any) => {
      const errosList = (error?.response?.data?.erros as []) || []
      errosList.forEach((erro: any) => {
        toastService.error(erro)
      })
    }
  })
}

export function useAceiteEconomiaMutation() {
  return useMutation(economiaAceite, {
    onSuccess: async () => {
      toastService.success('Termos de Crédito Certo aceitos!')
    },
    onError: async (error: any) => {
      const errosList = (error?.response?.data?.erros as []) || []
      errosList.forEach((erro: any) => {
        toastService.error(erro)
      })
    }
  })
}

export function useEditarPedidoMutation() {
  const queryClient = useQueryClient()
  return useMutation(editarPedido, {
    onError: async (error: any) => {
      const errosList = (error?.response?.data?.erros as []) || []
      errosList.forEach((erro: any) => {
        toastService.error(erro)
      })
    },
    onSuccess: async () => {
      queryClient.invalidateQueries()
    }
  })
}

export function useCancelarPedidoMutation() {
  const queryClient = useQueryClient()
  return useMutation(cancelarPedido, {
    onSuccess: async () => {
      toastService.success('Pedido cancelado!')
      queryClient.invalidateQueries(QueryKey.pedidosListItems)
      queryClient.invalidateQueries(QueryKey.pedidosPendentesList)
    },
    onError: async (error: any) => {
      const errosList = (error?.response?.data?.erros as []) || []
      errosList.forEach((erro: any) => {
        toastService.error(erro)
      })
    }
  })
}

export function useEditarItensPedidoMutation() {
  const queryClient = useQueryClient()
  return useMutation(editarItensPedido, {
    onSuccess: async () => {
      toastService.success('Item editado!')
      queryClient.invalidateQueries()
    },
    onError: async (error: any) => {
      const errosList = (error?.response?.data?.erros as []) || []
      errosList.forEach((erro: any) => {
        toastService.error(erro)
      })
    }
  })
}
