import { QueryFunctionContext } from 'react-query'

import { endPoints } from '@/configs/endPoints'
import { IUnidadesAtivas, IUnidadesParams } from '@/interfaces/unidades'
import { API_PEDIDOS } from '@/utils/pedidos-fetcher'

export async function getUnidadesAtivas(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: IUnidadesParams
      }
    ]
  >
): Promise<IUnidadesAtivas> {
  const [, { params }] = ctx.queryKey
  const endpointUnidadesAtivas = endPoints.unidadesAtivas.replace(
    '{id-empregador}',
    params.idDoEmpregador
  )
  const response = await API_PEDIDOS.get<IUnidadesAtivas>(
    endpointUnidadesAtivas
  )

  return response
}
