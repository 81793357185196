import { useQuery, UseQueryResult } from 'react-query'

import { QueryKey } from '@/configs/queryKeys'
import { IPendencias, IPendenciasParams } from '@/interfaces/pendencias'
import { getPendenciasPedidos } from '@/services/pendencias'

export function useGetPendencias({
  params
}: {
  params: IPendenciasParams
}): UseQueryResult<IPendencias> {
  return useQuery([QueryKey.pendenciasList, { params }], getPendenciasPedidos, {
    keepPreviousData: true,
    retry: false,
    enabled: params.idPedido !== '',
    initialData: {} as IPendencias
  })
}
