import DetalhePedidoContainer from '@/containers/pedidos/detalhe-pedido.container'
import { DetalhePedidoProvider } from '@/contexts/detalhe-pedido.context'

const PedidoDetalheView = () => {
  return (
    <>
      <DetalhePedidoProvider>
        <DetalhePedidoContainer />
      </DetalhePedidoProvider>
    </>
  )
}

export default PedidoDetalheView
