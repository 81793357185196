import { SetStateAction, useMemo } from 'react'

import debounce from 'debounce'
import { Button, Modal, Select } from 'flowbite-react'

import Loader from '@/components/Loader'
import TableItem from '@/components/Table'
import PaginationItem from '@/components/Table/pagination-item'
import SearchTableItem from '@/components/Table/search'
import { useRevisarPedidosContext } from '@/contexts/revisar-pedido.context'
import {
  TABLE_HEADERS_LANCAMENTOS_PEDIDO,
  TIPO_LANCAMENTO
} from '@/pages/Pedidos/constants'
import { formatCurrency } from '@/utils'

interface ILancamentosPedidoModalProps {
  onClose: () => void
  isOpen: boolean
}

const LancamentosPedidoModal: React.FC<ILancamentosPedidoModalProps> = ({
  onClose,
  isOpen
}: ILancamentosPedidoModalProps) => {
  const {
    isLoadingLancamentos,
    lancamentosList,
    lancamentosPageSize,
    setLancamentosPageSize,
    lancamentosTotalPages,
    setLancamentosPage,
    setSearch,
    lancamentosTipo
  } = useRevisarPedidosContext()
  const handleSearch = debounce((e: SetStateAction<string>) => {
    setSearch(e)
  }, 500)

  const getTipoLancamento = (tipo: number) => {
    return TIPO_LANCAMENTO.find(l => l.value === tipo)?.label ?? 'Lançamentos'
  }

  const mappedlancamentos = useMemo(() => {
    return lancamentosList?.conteudo?.resultados.map(item => {
      return {
        descricao: item.descricao,
        valor: formatCurrency(item.valor)
      }
    })
  }, [lancamentosList])

  return (
    <div data-testid='modal-lancamentos'>
      <Modal
        show={isOpen}
        onClose={onClose}
        className='flex items-center'
        size='5xl'
      >
        <Modal.Header>
          <p className='text-center font-bold p-3'>
            Lista de {getTipoLancamento(lancamentosTipo)}
          </p>
        </Modal.Header>
        <Modal.Body>
          {isLoadingLancamentos ? (
            <div
              className='h-96 w-full flex justify-center items-center'
              data-testid='loader-lancamentos'
            >
              <Loader size={'xl'} />
            </div>
          ) : (
            <div data-testid='table-lancamentos'>
              <SearchTableItem
                isAddItem={false}
                isSearchInputEnabled={true}
                setFilterText={e => handleSearch(e)}
              />
              <TableItem
                isActions={false}
                isRowClicable={false}
                data={mappedlancamentos || []}
                headers={TABLE_HEADERS_LANCAMENTOS_PEDIDO}
                deleteAction={() => void 0}
                hasCustomActions={false}
                idKey='pedidoId'
                isIdle={false}
              />
              <PaginationItem
                totalItems={
                  lancamentosList?.conteudo?.quantidadeDeItems ?? (0 as number)
                }
                totalPages={lancamentosTotalPages}
                pageSize={lancamentosPageSize}
                onPageChange={(pageParam: number) =>
                  setLancamentosPage(pageParam)
                }
              >
                <Select
                  id='select-page-size'
                  data-testid='select-page-size'
                  onChange={e => setLancamentosPageSize(Number(e.target.value))}
                >
                  <option value='10'>10</option>
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                </Select>
              </PaginationItem>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <div className='flex gap-2'>
              <Button color='gray' onClick={onClose} data-testid='btn-close'>
                Fechar
              </Button>
            </div>
          </>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default LancamentosPedidoModal
