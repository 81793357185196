import { Status } from '@/interfaces/common'

export const OPTIONS = [
  { value: '1', label: 'Solicitado' },
  { value: '2', label: 'Entregue' },
  { value: '3', label: 'Colet Recusada' },
  { value: '4', label: 'Inativo' },
  { value: '5', label: 'Coleta Gerada' },
  { value: '6', label: 'Entrega Gerada' },
  { value: '7', label: 'Entrega Solicitada' },
  { value: '8', label: 'Entrega Recusada' },
  { value: '9', label: 'Coleta Processada' },
  { value: '10', label: 'Entrega Processada' },
  { value: '11', label: 'Coleta Finalizada' }
]

export const TABLE_HEADERS_ACOMPANHAR_PEDIDOS = {
  ['numeroPedido']: 'N. Pedido',
  ['cnpj']: 'CNPJ',
  ['nomeUnidade']: 'Nome da Unidade',
  ['criadoEm']: 'Criado em',
  ['pagamento']: 'Pagamento',
  ['entrega']: 'Entrega',
  ['total']: 'Total',
  ['situacaoFinanceira']: 'Situação Financeira',
  ['situacaoOperacional']: 'Situação Operacional'
}

export const TABLE_HEADERS_PEDIDOS_PENDENTES = {
  ['qtUnidades']: 'Qt. Unidades',
  ['qtPedidos']: 'Qt. Pedidos',
  ['dataCriacao']: 'Data Criação',
  ['previsaoEntrega']: 'Prev. Entrega',
  ['status']: 'Situação Pedido',
  ['acoes']: ''
}

export const TABLE_HEADERS_REVISAO_PEDIDOS = {
  ['detalhes']: '',
  ['situacao']: 'Situação',
  ['numero']: 'Nº do pedido',
  ['cnpjUnidade']: 'CNPJ',
  ['unidade']: 'Unidade',
  ['total']: 'Total',
  ['economia']: 'Economia',
  ['itens']: 'Itens',
  ['repasses']: 'Repasses',
  ['cobrancas']: 'Cobranças',
  ['tarifas']: 'Tarifas',
  ['descontos']: 'Descontos'
}

export const TABLE_HEADERS_REVISAO_PEDIDOS_OPERADORA = {
  ['situacao']: 'Situação',
  ['operadora']: 'Operadora',
  ['tipoCartao']: 'Tipo de cartão',
  ['qtdColaboradores']: 'Quantidade de Colaboradores',
  ['itens']: 'Itens',
  ['repasses']: 'Repasses',
  ['acoes']: 'Ações'
}

export const TABLE_HEADERS_DETALHES_PEDIDOS_OPERADORA = {
  ['operadora']: 'Fornecedor',
  ['qtdColaboradores']: 'Quantidade',
  ['itens']: 'Valor Total',
  ['acoes']: 'Ações'
}

export const TABLE_HEADERS_ITENS_PEDIDOS = {
  ['itemId']: 'N. Pedido',
  ['quantidade']: 'Quantidade',
  ['valorTotal']: 'Valor Total',
  ['detalhes']: ''
}

export const TABLE_HEADERS_REPASSES = {
  ['itemId']: 'Descrição',
  ['valorTotal']: 'Valor Total'
}

export const TABLE_HEADERS_DEVOLUCOES = {
  ['cpf']: 'CPF',
  ['nome']: 'Nome',
  ['identificador']: 'Identificador',
  ['total']: 'Valor',
  ['dataDevolucao']: 'Data Demanda',
  ['tipoDevolucao']: 'Descrição status',
  ['status']: 'Status Devolução'
}

export const TABLE_HEADERS_ITENS_PEDIDOS_DETALHE = {
  ['cnpj']: 'CNPJ',
  ['cpf']: 'CPF',
  ['nome']: 'Nome',
  ['nomeLinha']: 'Nome Linha',
  ['codigoLinha']: 'Cod. Linha',
  ['matricula']: 'Matrícula',
  ['codigoPostoTrabalho']: 'Cod. Posto Trabalho',
  ['departamento']: 'Departamento',
  ['cargo']: 'Cargo',
  ['codigoOperadora']: 'Cod. Operadora',
  ['qtdDiasTrabalhados']: 'Qtd Dias Trabalhados',
  ['valorLinha']: 'Valor Linha',
  ['qtdPorDia']: 'Qtd Por Dia',
  ['totalBeneficio']: 'Total Benefício',
  ['economiaGerada']: 'Economia Gerada',
  ['totalPedido']: 'Total Pedido'
}

export const TABLE_HEADERS_PENDENCIAS_PEDIDO = {
  ['usuario']: 'Usuário',
  ['cpf']: 'CPF',
  ['beneficio']: 'Benefício',
  ['descricao']: 'Descrição do Erro'
}

export const TABLE_HEADERS_BENEFICIARIOS_PEDIDOS = {
  ['cpf']: 'CPF',
  ['nome']: 'Beneficiário',
  ['identificador']: 'Matrícula',
  ['cargo']: 'Cargo',
  ['departamento']: 'Departamento',
  ['beneficioTotal']: 'Valor',
  ['acoes']: ''
}

export const TABLE_HEADERS_REPASSES_PEDIDO = {
  ['operadora']: 'Operadora',
  ['tipo']: 'Tipo de Taxa',
  ['descricao']: 'Descrição',
  ['valorUnitario']: 'Valor Unitário',
  ['quantidade']: 'Quantidade',
  ['valorTotal']: 'Valor Total'
}

export const TABLE_HEADERS_LANCAMENTOS_PEDIDO = {
  ['descricao']: 'Descrição',
  ['valor']: 'Valor'
}

export const TABLE_HEADERS_TARIFAS = {
  ['descricao']: 'Descrição',
  ['valorTotal']: 'Valor'
}

export const TABLE_HEADERS_LISTA_ERROS_PEDIDO = {
  ['cnpj']: 'CNPJ',
  ['erro']: 'Descrição'
}

export const TIPO_LANCAMENTO = [
  { value: 0, label: 'Descontos' },
  { value: 1, label: 'Cobranças' }
]

export const TABLE_HEADERS_GESTAO_ECONOMIA_PEDIDO = {
  ['unidadeId']: '',
  ['unidade']: 'Unidade',
  ['totalItens']: 'Total Itens',
  ['totalEconomia']: 'Total Economia',
  ['valor']: 'Valor'
}

export const TABLE_HEADERS_DEVOLUCOES_PAGE = {
  ['data']: 'Data',
  ['cpf']: 'CPF',
  ['nome']: 'Nome',
  ['tipoDevolucao']: 'Tipo',
  ['status']: 'Situação',
  ['valor']: 'Valor',
  ['codigoDoBeneficio']: 'Cod. Benefício',
  ['descricaoBeneficio']: 'Benefício',
  ['numeroPedido']: 'Pedido',
  ['matricula']: 'Matrícula',
  ['identificador']: 'Id Operadora',
  ['unidade']: 'Unidade',
  ['actions']: ''
}

export const TABLE_HEADERS_EDITAR_PEDIDO_ERROS = {
  ['motivo']: 'Motivo do Erro',
  ['nome']: 'Nome',
  ['matricula']: 'Matrícula',
  ['cpf']: 'CPF'
}

export const TABLE_HEADERS_EDITAR_PEDIDO_ITENS = {
  ['acoes']: '',
  ['situacao']: 'Situação',
  ['ativo']: 'Ativo',
  ['funcionario']: 'Funcionário',
  ['linha']: 'Linha',
  ['recarga']: 'Dados da Recarga'
}

export const TABLE_HEADERS_DETALHES_PEDIDO_ITENS = {
  ['cnpj']: 'CNPJ',
  ['funcionario']: 'Funcionário',
  ['linha']: 'Linha',
  ['recarga']: 'Dados da Recarga',
  ['economia']: 'Economia'
}

export const TABLE_HEADERS_CONFIRMACAO_PEDIDOS = {
  ['cnpjUnidade']: 'CNPJ',
  ['nomeUnidade']: 'Razão Social',
  ['numeroPedido']: 'Pedido',
  ['valorTotalItensComEconomia']: 'Vlr. Itens',
  ['valorTarifas']: 'Vlr. Tarifas',
  ['valorRepasses']: 'Vlr. Repasses',
  ['cobranca']: 'Vlr. Cobranças',
  ['desconto']: 'Vlr. Descontos',
  ['total']: 'Vlr. Total'
}

export const TABLE_HEADERS_LOGS_DEVOLUCOES = {
  ['data']: 'Data',
  ['descricao']: 'Descrição'
}

export const mapedTipoSituacaoFinanceira = {
  ['NaoPago']: { title: 'Não Pago', color: 'bg-red-600' },
  ['Pago']: { title: 'Pago', color: 'bg-primary-hover' },
  ['AguardandoLiberacao']: {
    title: 'Aguardando Liberação',
    color: 'bg-yellow-400'
  },
  ['Liberado']: { title: 'Liberado', color: 'bg-yellow-400' },
  ['Cancelado']: { title: 'Cancelado', color: 'bg-gray-400' }
}

export const mapedTipoSituacaoOperacional = {
  ['NaoConfirmado']: { title: 'Não Confirmado', color: 'bg-red-600' },
  ['Confirmado']: { title: 'Confirmado', color: 'bg-yellow-400' },
  ['EmProducao']: { title: 'Em Produção', color: 'bg-yellow-400' },
  ['Finalizado']: { title: 'Finalizado', color: 'bg-primary-hover' },
  ['Cancelado']: { title: 'Cancelado', color: 'bg-gray-400' }
}

export const TABLE_HEADERS_LISTA_PERMITIR_CRIAR_PEDIDO = {
  ['descricao']: 'Unidade',
  ['descricaoErro']: 'Descrição do Erro'
}

export const statusDetailsByStatus: Record<
  Status,
  { badgeClass: string; text: string; action: string }
> = {
  Erro: { badgeClass: 'bg-red-600', text: 'Erro', action: 'Revisar pedido' },
  EmProcessamento: {
    badgeClass: 'bg-yellow-400',
    text: 'Em Processamento',
    action: 'Reprocessar pedido'
  },
  Pendente: {
    badgeClass: 'bg-gray-400',
    text: 'Pendente',
    action: 'Reprocessar Pedido'
  },
  Processado: {
    badgeClass: 'bg-primary-hover',
    text: 'Processado',
    action: 'Aprovar Pedido'
  }
}
