import { useState } from 'react'

import {
  FunnelIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon
} from '@heroicons/react/24/outline'
import { Button } from 'flowbite-react'
import { Link } from 'react-router-dom'

import { routeNames } from '@/configs/routePaths'

interface ISeachTableItem {
  setFilterText?: (value: React.SetStateAction<string>) => void
  children?: JSX.Element
  filtersEnabled?: boolean
  isSearchInputEnabled?: boolean
  isAddItem?: boolean
  customActions?: () => JSX.Element
}

const SearchTableItem = ({
  setFilterText = () => void 0,
  filtersEnabled = false,
  isSearchInputEnabled = true,
  isAddItem = true,
  children,
  customActions
}: ISeachTableItem) => {
  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false)

  return (
    <>
      <div className='md:flex flex-col md:flex-row justify-between items-center mb-4'>
        <div className='flex items-center justify-between w-full'>
          <div className='flex justify-center items-center'>
            {isSearchInputEnabled && (
              <div className='relative w-full md:w-fit'>
                <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                  <MagnifyingGlassIcon className='w-5 h-5 text-gray-500' />
                </div>
                <input
                  type='search'
                  id='table-search'
                  onChange={e => setFilterText(e?.target.value)}
                  className='block border p-4 pl-10 text-sm text-gray-900 border-gray-200 transition-all rounded-lg max-w-4xl md:w-96 w-full bg-white focus:ring-green-500 focus:border-green-500'
                  placeholder='Pesquisar...'
                />
              </div>
            )}
            {filtersEnabled && (
              <div
                className={`flex justify-center items-center cursor-pointer  p-3 rounded ml-2 ${
                  isFiltersVisible ? 'bg-green-100' : ''
                }`}
                onClick={() => setIsFiltersVisible(value => !value)}
              >
                <FunnelIcon
                  width={16}
                  height={16}
                  className={`text-green-800`}
                />
                <span
                  className={`text-sm text-green-800 font-semibold ml-1 md:block hidden`}
                >
                  <p>{isFiltersVisible ? 'Fechar filtros' : 'Filtros'}</p>
                </span>
              </div>
            )}
          </div>
          {customActions && customActions()}
        </div>
        {isAddItem && (
          <div className='fixed md:relative right-5 bottom-5 md:right-0 md:bottom-0 z-10'>
            <Link to={`${routeNames.add}`}>
              <Button
                size='lg'
                className='bg-primary hover:bg-primary-hover transition shadow md:shadow-none'
              >
                <PlusCircleIcon className='md:mr-2 h-5 w-5' />
                <p className='hidden md:block'>Adicionar Item</p>
              </Button>
            </Link>
          </div>
        )}
      </div>
      {isFiltersVisible && children}
    </>
  )
}

export default SearchTableItem
