import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { Alert, Button, Label, Modal } from 'flowbite-react'
import { Link } from 'react-router-dom'

import { routeNames } from '@/configs/routePaths'
interface IConfirmarPedidoModalProps {
  onClose: () => void
  isOpen: boolean
  isPedidoComboleto?: boolean
}

const ConfirmarPedidoModal: React.FC<IConfirmarPedidoModalProps> = ({
  onClose,
  isOpen
}: IConfirmarPedidoModalProps) => {
  return (
    <>
      <Modal
        show={isOpen}
        onClose={onClose}
        className='flex items-center'
        size='xl'
      >
        <Modal.Body>
          <div className='mb-5 text-center'>
            <Alert color={'success'}>
              <div className='flex flex-col justify-center items-center gap-2 mb-4'>
                <CheckCircleIcon className='md:w-8 md:h-8 w-12 h-12 text-green-500' />
                <span className='block text-center text-2xl font-semibold'>
                  Pedido(s) Criado(s).
                </span>
              </div>
              <Label className='block mb-1 text-gray-600 leading-7'>
                Atenção! Você precisa "Aprovar" seu pedido para continuar.{' '}
                Acesse o menu{' '}
                <Link
                  className='font-bold underline'
                  to={`/${routeNames.pedidosPendentes}`}
                >
                  <b>"Acompanhar Pedidos"</b>
                </Link>{' '}
                na aba "Pedidos Pendentes" para revisar o pedido e aprová-lo.
                <br /> O boleto será gerado após a confirmação e assim que
                tivermos o retorno do banco estará disponível no menu{' '}
                <Link
                  className='font-bold underline'
                  to={`/${routeNames.pedidosPendentes}`}
                >
                  "Acompanhar Pedidos"
                </Link>{' '}
                na aba <b>"Pedidos Aprovados"</b>.
              </Label>
              <Label className='block mt-4 text-gray-600 leading-7'>
                Pedidos gerados com valor de desconto maior ou igual ao seu
                valor não exige pagamento de título.
              </Label>
            </Alert>
          </div>
          <div className='flex gap-2 justify-center items-center'>
            <Button color='gray' onClick={onClose} data-testid='btn-close'>
              Fechar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ConfirmarPedidoModal
