import { useCallback, useMemo, useState } from 'react'

import {
  ArrowDownTrayIcon,
  ArrowLeftIcon,
  CheckIcon,
  PlusIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { InformationCircleIcon } from '@heroicons/react/24/solid'
import { Alert, Button, Checkbox, Label, Tooltip } from 'flowbite-react'
import { Link, useNavigate } from 'react-router-dom'

import DrawerPedidoRevisao from '@/components/drawer/pedido-revisao.component'
import Loader from '@/components/Loader'
import ReprocessarPedidoModal from '@/components/pedidos/reprocessar-pedido-modal.component'
import CancelarPedidoModal from '@/components/pedidos/revisar-pedidos/cancelar-pedido-popup.modal'
import EconomiaModal from '@/components/pedidos/revisar-pedidos/economia-pedido.modal'
import ErrosPedidoModal from '@/components/pedidos/revisar-pedidos/erros-pedido.modal'
import LancamentosPedidoModal from '@/components/pedidos/revisar-pedidos/lancamentos-pedido.modal'
import PrimeiraEconomiaModal from '@/components/pedidos/revisar-pedidos/primeira-economia.modal'
import RepassesPedidoModal from '@/components/pedidos/revisar-pedidos/repasses-pedido.modal'
import RevisarPedidoForm from '@/components/pedidos/revisar-pedidos/revisar-pedidos.form'
import TarifasPedidoModal from '@/components/pedidos/revisar-pedidos/tarifas-pedido.modal'
import StepModal from '@/components/step-modal/step-modal.component'
import TableItemPedidos from '@/components/Table/table-item-pedidos.component'
import { routeNames } from '@/configs/routePaths'
import { useRevisarPedidosContext } from '@/contexts/revisar-pedido.context'
import { IRevisaoPedido } from '@/interfaces/pedidos'
import {
  TABLE_HEADERS_REVISAO_PEDIDOS,
  TIPO_LANCAMENTO
} from '@/pages/Pedidos/constants'
import useIsOnRoute from '@/utils/isOnRoute'

const RevisarPedidosContainer = () => {
  const {
    pedidosList,
    isLoadingPedidos,
    isFetchingPedidos,
    isDrawerOpen,
    setIsDrawerOpen,
    setPedidoId,
    setUnidade,
    setUnidadeId,
    handleExportarPedido,
    isRepassesModalOpen,
    setIsRepassesModalOpen,
    isTarifasModalOpen,
    setIsTarifasModalOpen,
    handleOpenRepassesModal,
    handleOpenLancamentosModal,
    isLancamentosModalOpen,
    setIsLancamentosModalOpen,
    ignorarErros,
    setIgnorarErros,
    isReprocessarOpen,
    setIsReprocessarOpen,
    handleReprocessarPedido,
    handleRevisarPedido,
    handleCancelarPedidos,
    isReprocessandoPedido,
    isSubmitingPedido,
    contratos,
    handleUtilizarEconomiaPedido,
    podeAprovar,
    aceiteGestao,
    economiaVisivel,
    handleUtilizarEconomiaPedidoFlex,
    handleChangeEconomiaTipoFlex,
    handleEconomiaAlterada,
    handleBeforeExit,
    handleClose,
    isOnboardingFinishedOrderReview,
    isOnboardingOpen
  } = useRevisarPedidosContext()

  const isOnRoute = useIsOnRoute(
    `/${routeNames.pedidos}/${routeNames.revisarPedidos}/`
  )
  const isDisabled = isReprocessandoPedido || isSubmitingPedido
  const [isEconomiaModalOpen, setIsEconomiaModalOpen] = useState<boolean>(false)
  const [isAceiteEconomiaModalOpen, setIsAceiteEconomiaModalOpen] =
    useState<boolean>(false)
  const [isErrosModalOpen, setIsErrosModalOpen] = useState<boolean>(false)
  const [selectedPedido, setSelectedPedido] = useState<IRevisaoPedido>(
    {} as IRevisaoPedido
  )

  const handleErrosModalOpen = (pedido: IRevisaoPedido) => {
    setIsErrosModalOpen(true)
    setSelectedPedido(pedido)
  }

  const hasUtilizaEconomia = useCallback(() => {
    return pedidosList?.some(pedido => pedido.utilizaEconomia)
  }, [pedidosList])

  const handleReprocessarPedidoAction = () => {
    if (hasUtilizaEconomia()) {
      if (!aceiteGestao?.conteudo?.possuiGestao) {
        setIsAceiteEconomiaModalOpen(true)
        setIsReprocessarOpen(true)
      } else {
        setIsReprocessarOpen(true)
      }
    } else {
      setIsReprocessarOpen(true)
    }
  }

  const handleOpenDrawer = (
    pedidoOpcaoId: string,
    unidadeIdOpcao: string,
    unidadeOpcao: string
  ) => {
    setPedidoId(pedidoOpcaoId)
    setUnidadeId(unidadeIdOpcao)
    setUnidade(unidadeOpcao)
    setIsDrawerOpen(true)
  }

  const handleIgnorarErros = () => {
    setIgnorarErros(!ignorarErros)
  }

  const [temErro, setTemErro] = useState<boolean>(false)
  const handleReprocessarClose = () => {
    setIgnorarErros(false)
    setIsReprocessarOpen(false)
  }

  const [isCancelarModalOpen, setIsCancelarModalOpen] = useState<boolean>(false)
  const handleCancelarPedidoConfirm = () => {
    handleCancelarPedidos()
    navigate(`/${routeNames.pedidosPendentes}`)
  }

  const navigate = useNavigate()

  const [economias, setEconomias] = useState<
    { idPedido: string; utilizaEconomia: boolean }[]
  >([])

  const handleChangeEconomia = (
    pedidoAlteradoId: string,
    utilizaEconomia: boolean
  ) => {
    const economiasAtualizadas = economias?.map(economia => {
      if (economia.idPedido === pedidoAlteradoId)
        return { idPedido: economia.idPedido, utilizaEconomia: utilizaEconomia }
      return {
        idPedido: economia.idPedido,
        utilizaEconomia: economia.utilizaEconomia
      }
    })
    setEconomias(economiasAtualizadas)
    handleEconomiaAlterada(pedidoAlteradoId, utilizaEconomia)
  }

  const mapedProperties = useMemo(() => {
    if (economias === undefined || economias?.length === 0) {
      const economiasAtualizadas = pedidosList?.map(pedido => {
        return {
          idPedido: pedido.pedidoId,
          utilizaEconomia: pedido.utilizaEconomia ?? true
        }
      })
      setEconomias(economiasAtualizadas)
    }
    return pedidosList?.map(item => {
      const gestaoAutomatica =
        contratos?.conteudo?.find(
          conteudo => conteudo.idUnidade === item.unidadeId
        )?.gestaoAutomatica ??
        contratos?.conteudo?.find(contrato => contrato.idUnidade === null)
          ?.gestaoAutomatica

      const pedidoUtilizaEconomia =
        economias?.find(economia => economia.idPedido === item.pedidoId)
          ?.utilizaEconomia ?? item.utilizaEconomia

      if (!item.sucesso && !temErro) setTemErro(true)

      return {
        ...item,
        unidade: (
          <Tooltip content={item.unidade} placement='top'>
            <p className='truncate max-w-[140px] overflow-hidden'>
              {item.unidade}
            </p>
          </Tooltip>
        ),
        economia: (
          <div
            className={`flex justify-start items-center gap-2 ${
              economiaVisivel && pedidoUtilizaEconomia
                ? 'text-green-600'
                : 'text-gray-500'
            }`}
          >
            <Checkbox
              id={'check-economia-' + item.pedidoId}
              className={`flex  ${
                gestaoAutomatica ? 'text-quintenary' : 'text-quaternary'
              } focus:ring-green-600 ${economiaVisivel ? '' : 'hidden'}`}
              defaultChecked={pedidoUtilizaEconomia}
              onChange={event => {
                if (gestaoAutomatica) {
                  handleUtilizarEconomiaPedido(item, event.target.checked)
                  handleChangeEconomia(item.pedidoId, event.target.checked)
                } else {
                  handleUtilizarEconomiaPedidoFlex(item, event.target.checked)
                  handleChangeEconomiaTipoFlex(item, event.target.checked)
                }
              }}
              title={`${gestaoAutomatica ? 'Economia Automática' : ''}`}
              disabled={gestaoAutomatica}
            />
            <span>{economiaVisivel ? item.economia : 'R$ 0,00'}</span>
          </div>
        ),

        cobrancas: (
          <span className='flex gap-2 justify-between items-center'>
            {item.cobrancas}
            <InformationCircleIcon
              className='h-5 w-5 text-quintenary justify-center items-center'
              onClick={() =>
                handleOpenLancamentosModal(
                  item.pedidoId,
                  TIPO_LANCAMENTO.find(l => l.label === 'Cobranças')?.value ?? 1
                )
              }
              data-testid='btn-open-cobrancas'
            />
          </span>
        ),
        tarifas: (
          <span className='flex gap-2 justify-between items-center'>
            {item.tarifas}
            <InformationCircleIcon
              className='h-5 w-5 text-quintenary justify-center items-center'
              onClick={() => handleOpenRepassesModal(item.pedidoId, 'Tarifas')}
              data-testid='btn-open-tarifas'
            />
          </span>
        ),
        descontos: (
          <span className='flex gap-2 justify-between items-center'>
            {item.descontos}
            <InformationCircleIcon
              className='h-5 w-5 text-quintenary justify-center items-center'
              onClick={() =>
                handleOpenLancamentosModal(
                  item.pedidoId,
                  TIPO_LANCAMENTO.find(l => l.label === 'Descontos')?.value ?? 3
                )
              }
              data-testid='btn-open-descontos'
            />
          </span>
        ),
        total: (
          <b className='text-black'>
            {economiaVisivel && pedidoUtilizaEconomia
              ? item.totalComEconomia
              : item.total}
          </b>
        ),
        itens: (
          <b className='text-black'>
            {economiaVisivel && pedidoUtilizaEconomia
              ? item.valorTotalItensComEconomia
              : item.itens}
          </b>
        ),
        repasses: (
          <span className='flex gap-2 justify-between items-center'>
            {item.repasses}
            <InformationCircleIcon
              className='h-5 w-5 text-quintenary justify-center items-center'
              onClick={() => handleOpenRepassesModal(item.pedidoId, 'Repasse')}
              data-testid='btn-open-repasses'
            />
          </span>
        ),
        situacao: item.sucesso ? (
          <div className='flex justify-center items-center'>
            <CheckIcon
              className='h-5 w-5 text-white bg-quintenary rounded-full '
              title='Sucesso'
            />
          </div>
        ) : (
          <button
            className='flex justify-center items-center'
            onClick={() => handleErrosModalOpen(item)}
            onKeyDown={e => e.key === 'Enter' && handleErrosModalOpen(item)}
            aria-label='Ver erros do pedido'
            type='button'
          >
            <XMarkIcon
              className='h-5 w-5 text-white bg-red-400 rounded-full cursor-pointer'
              title='Erro'
            />
          </button>
        ),
        detalhes: (
          <Button
            color='gray'
            className='flex gap-2 justify-center items-center'
            size='xs'
            onClick={() =>
              handleOpenDrawer(item.pedidoId, item.unidadeId, item.unidade)
            }
            data-testid='btn-open-drawer'
            id='detalhes-pedido'
          >
            <PlusIcon className='h-5 w-5' />
          </Button>
        )
      }
    })
  }, [pedidosList, economias, economiaVisivel])

  const hasErrors = useMemo(() => {
    return pedidosList?.some(item => !item.sucesso)
  }, [pedidosList])

  const isAlterado = useMemo(() => {
    return pedidosList?.some(item => item.situacao === 'Alterado')
  }, [pedidosList])

  const hasGestaoAutomatica = useMemo(() => {
    return (
      pedidosList
        ?.map(pedido => {
          return (
            contratos?.conteudo?.find(
              conteudo => conteudo.idUnidade === pedido.unidadeId
            )?.gestaoAutomatica ??
            contratos?.conteudo?.find(contrato => contrato.idUnidade === null)
              ?.gestaoAutomatica
          )
        })
        .filter(gestao => gestao).length > 0
    )
  }, [contratos, pedidosList])

  const hasEconomia = useMemo(() => {
    return pedidosList?.some(item => {
      return (
        parseFloat(item.economia.replace('R$', '').replace(',', '.').trim()) > 0
      )
    })
  }, [pedidosList])

  const stepList = [
    {
      step: 1,
      title:
        'Conta que é aqui que controla a economia  e so fica habilitar se o cliente for flex',
      body: 'blabla',
      ref: 'tbody td:nth-child(7)',
      totalSteps: 7
    },
    {
      step: 2,
      title:
        'Contar que é aqui que o cliente visualiza se existir erros​ Print x​Print check do sucesso​ Explicado cada um',
      body: 'Clique em "Exportar" para baixar os dados do pedido',
      ref: 'tbody td:nth-child(2)',
      totalSteps: 7
    },
    {
      step: 3,
      title: 'Contar que é aqui que expande os itens do pedido',
      body: 'blabla',
      ref: '#detalhes-pedido',
      totalSteps: 7
    },
    {
      step: 4,
      title: 'CancelarPedido',
      body: 'blabla',
      ref: '#CancelarPedido',
      totalSteps: 7
    },
    {
      step: 5,
      title: '​ReprocessarPedido',
      body: 'blabla',
      ref: '#ReprocessarPedido',
      totalSteps: 7
    },
    {
      step: 6,
      title: 'AprovarPedido',
      body: 'blabla',
      ref: '#AprovarPedido',
      totalSteps: 7
    },
    {
      step: 7,
      title:
        'Explica que essa tela é a ultima para conferencia antes de aprovar o pedido',
      body: 'blabla',
      ref: '#table-pedidos-revisao',
      totalSteps: 7
    }
  ]

  return (
    <>
      {isLoadingPedidos ? (
        <div
          className='h-96 w-full flex justify-center items-center'
          data-testid='loader-pedidos-revisao'
        >
          <Loader size={'xl'} />
        </div>
      ) : (
        <>
          <div className='w-full hidden justify-end items-center md:-mt-10'>
            <Button
              size='xs'
              className='flex gap-2 justify-center items-center bg-orange-400 text-white'
              onClick={() => handleExportarPedido()}
              data-testid='btn-exportar-conteudo'
            >
              <div className='flex justify-center items-center gap-2'>
                <ArrowDownTrayIcon className='h-4 w-4' />
                <span>Exportar</span>
              </div>
            </Button>
          </div>
          {!hasGestaoAutomatica && hasEconomia && (
            <Alert color='success'>
              Parabéns! Seu pedido oferece uma ótima oportunidade de economia.
              Clique na opção "Economia" abaixo e aproveite!
            </Alert>
          )}
          <div data-testid='table-pedidos-revisao' id='table-pedidos-revisao'>
            <TableItemPedidos
              isActions={false}
              isRowClicable={false}
              data={mapedProperties || []}
              headers={TABLE_HEADERS_REVISAO_PEDIDOS}
              deleteAction={() => void 0}
              hasCustomActions={false}
              idKey='pedidoId'
              isIdle={false}
            />
          </div>
        </>
      )}
      <hr className='my-3 bg-cyan-700 h' />
      <RevisarPedidoForm />
      <div className='w-full flex justify-between items-left'>
        {hasErrors && (
          <div className='flex gap-2 justify-center items-center'>
            <Checkbox
              id='ignora-erros'
              data-testid='check-ignore'
              className={`flex text-red-600 focus:ring-red-600`}
              checked={ignorarErros}
              onChange={() => handleIgnorarErros()}
              disabled={!temErro}
            />
            <Label
              className={`font-semibold ${temErro ? '' : 'text-gray-400'}`}
            >
              Desejo remover o(s) usuário(s) e/ou empresas com problema(s) e
              prosseguir com os demais dados corretos
            </Label>
          </div>
        )}
      </div>
      <hr className='my-3 bg-cyan-700 h' />
      <div className='w-full flex justify-between'>
        <div className='flex justify-between gap-2 items-left'>
          <Button
            color='light'
            size='sm'
            className='md:flex hidden gap-2 justify-center items-center'
            data-testid='btn-voltar'
          >
            <Link to={`/${routeNames.pedidosPendentes}`}>
              <div className='flex justify-center items-center gap-2'>
                <ArrowLeftIcon className='h-4 w-4' />
                <span>Voltar</span>
              </div>
            </Link>
          </Button>
          <Button
            className='gap-2 justify-center items-center bg-orange-400 text-white hidden'
            data-testid='btn-credito-certo'
          >
            <span>Crédito Certo</span>
          </Button>
        </div>
        <div className='flex justify-between gap-2 items-right'>
          <Button
            color='failure'
            size='sm'
            className='flex gap-2 justify-center items-center'
            onClick={() => setIsCancelarModalOpen(true)}
            data-testid='btn-cancelar-pedido'
            id='CancelarPedido'
          >
            <span>Cancelar pedido</span>
          </Button>
          <Button
            size='sm'
            className='hidden gap-2 justify-center items-center bg-orange-400 text-white md:hidden'
            onClick={() => handleExportarPedido()}
            data-testid='btn-exportar'
          >
            <div className='flex justify-center items-center gap-2'>
              <ArrowDownTrayIcon className='h-4 w-4' />
              <span>Exportar</span>
            </div>
          </Button>
          <Button
            color='success'
            size='sm'
            className='flex gap-2 justify-center items-center bg-quaternary'
            onClick={() => handleReprocessarPedidoAction()}
            disabled={
              (podeAprovar && !hasErrors && !isAlterado) ||
              isDisabled ||
              isLoadingPedidos ||
              isFetchingPedidos
            }
            isProcessing={isDisabled || isLoadingPedidos || isFetchingPedidos}
            data-testid='btn-reprocessar'
            id='ReprocessarPedido'
          >
            <span>Reprocessar Pedido</span>
          </Button>
          <Button
            color='success'
            size='sm'
            className='flex gap-2 justify-center items-center bg-quaternary'
            onClick={() => handleRevisarPedido()}
            disabled={!podeAprovar || hasErrors || isAlterado || isDisabled}
            isProcessing={isDisabled}
            data-testid='btn-confirm'
            id='AprovarPedido'
          >
            <span>Aprovar Pedido</span>
          </Button>
        </div>
      </div>
      <ErrosPedidoModal
        isOpen={isErrosModalOpen}
        onClose={() => setIsErrosModalOpen(false)}
        pedido={selectedPedido}
      />
      <RepassesPedidoModal
        isOpen={isRepassesModalOpen}
        onClose={() => setIsRepassesModalOpen(false)}
      />
      <TarifasPedidoModal
        isOpen={isTarifasModalOpen}
        onClose={() => setIsTarifasModalOpen(false)}
      />
      <LancamentosPedidoModal
        isOpen={isLancamentosModalOpen}
        onClose={() => setIsLancamentosModalOpen(false)}
      />
      <DrawerPedidoRevisao
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
      <ReprocessarPedidoModal
        isOpen={isReprocessarOpen}
        onClose={() => handleReprocessarClose()}
        onConfirm={() => handleReprocessarPedido()}
      />
      <CancelarPedidoModal
        isOpen={isCancelarModalOpen}
        onClose={() => setIsCancelarModalOpen(false)}
        onConfirm={() => handleCancelarPedidoConfirm()}
      />
      <EconomiaModal
        isOpen={isEconomiaModalOpen}
        onClose={() => setIsEconomiaModalOpen(false)}
      />
      <PrimeiraEconomiaModal
        isOpen={isAceiteEconomiaModalOpen}
        onClose={() => setIsAceiteEconomiaModalOpen(false)}
        onAccept={() => {
          setIsReprocessarOpen(true)
        }}
        valorPadrao={aceiteGestao?.conteudo?.valorPadrao}
      />
      {isOnRoute && (
        <StepModal
          isOpen={isOnboardingOpen}
          onClose={handleClose}
          onBeforeExit={handleBeforeExit}
          isOnboardingFinishedOrderReview={isOnboardingFinishedOrderReview}
          steps={stepList}
          cookieName='isOnboardingFinishedOrderReview'
        />
      )}
    </>
  )
}

export default RevisarPedidosContainer
